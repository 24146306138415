/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * master.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Vendors and Frameworks
 * + Helpers
 *	 - Variables
 *		-- Colors and Mixins
 * 		-- Typography (Fonts and Icons)
 * + Layout
 * + Components
 * + 
 * + Shame (Should always be the last item in stylesheet)
 *
 * Read more:
 * - http://www.sitepoint.com/architecture-sass-project/
 * - https://css-tricks.com/sass-style-guide/
 *
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Vendors / Frameworks----------------------------------------------------*/
@import '../bower_components/foundation/scss/foundation.scss'; // Foundation Components
//@import 'helpers/font-awesome.css'; // Font Awesome
// Font Awesome can be found at: https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css
/*
This directory should contain all the CSS files from external libraries and frameworks.
Putting those aside in the same folder is a good way to tell “Hey, this is not from me, not my code, not my responsibility”.
On a side note, we could also have a "vendors-extensions/" folder where we store files overriding some tiny bits from vendors.
This is to avoid editing the vendor files themselves, which is generally not a good idea.
*/

/*-Helpers-----------------------------------------------------------------*/
//This folder gathers all Sass tools and helpers we’ll use across the project (like functions and mixins)
@import "helpers/variables.scss";		// Sass Variables 
// @import "helpers/functions.scss";	// Custom SASS Functions not included in Foundation/functions.scss)
// @import "helpers/mixins.scss";		// Sass Mixins 
// @import "helpers/helpers.scss";		// Class & placeholders helpers 

/*-Layout------------------------------------------------------------------*/
//This is a "macro" directory (defining the global wireframe). It contains a number of files for the main sections of the layout
@import "layout/layout.scss";

//@import "components/navigation.scss";	// Navigation

@import "layout/new-header.scss";
@import "layout/morden.scss";

@import "layout/navbar.scss";

/*-Components--------------------------------------------------------------*/
//This is a "micro" directory (for smaller components). It can contain all kinds of specific modules
//There will be a lot of files in components/ since our site should be mostly composed of tiny modules.
@import "components/buttons.scss";			// Buttons
@import "components/alerts.scss";			// Alerts
@import "components/tables.scss";			// Lists
@import "components/modals.scss";			// Modals
@import "components/carousel.scss";			// Carousel
@import "components/image-cropper.scss";	// Image Cropper
@import "components/avatars.scss";			// Profile Avatars


/*-For Shame---------------------------------------------------------------*/
// If you need to make a quick fix, you can do it here:
@import "shame";