/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * forums.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Forums Landing Page
 * + Forum Boards
 * + Forum Post
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Forum Landing Page------------------------------------------------------*/
.forums {}
.forums-landing {
	&.content { margin-top: 0; }
/* --- Hero --- */
	.hero-banner {
		background-color: rgba(0,0,0,0.35);
		background-repeat: no-repeat;
		background-position: center top;
		@media #{$small-only} {
		//	margin-bottom: rem-calc(20px);
			background-image: url('../images/category-banners/forums-home_sm.jpg');
			.container { height: rem-calc(259px); }
		}
		@media #{$medium-only} {
		//	margin-bottom: rem-calc(30px);
			background-image: url('../images/category-banners/forums-home_md.jpg');
			.container { height: rem-calc(335px); }
		}
		@media #{$large-up} {
		//	margin-bottom: rem-calc(40px);
			background-image: url('../images/category-banners/forums-home_lg.jpg');
			.container { height: rem-calc(405px); }
		}
		@media #{$xlarge-up} {
			background-image: url('../images/category-banners/forums-home_xl.jpg');
			background-repeat: no-repeat;
			background-position: center top;
		}
		@media #{$xxlarge-up} {
			background: url('../images/category-banners/forums-home_xl.jpg'), #b4b4b4;
			background: url('../images/category-banners/forums-home_xl.jpg'), -moz-linear-gradient(left,  #b4b4b4 49%, #9d9ea0 51%);
			background: url('../images/category-banners/forums-home_xl.jpg'), -webkit-gradient(linear, left top, right top, color-stop(49%,#b4b4b4), color-stop(51%,#9d9ea0));
			background: url('../images/category-banners/forums-home_xl.jpg'), -webkit-linear-gradient(left,  #b4b4b4 49%,#9d9ea0 51%);
			background: url('../images/category-banners/forums-home_xl.jpg'), -o-linear-gradient(left,  #b4b4b4 49%,#9d9ea0 51%);
			background: url('../images/category-banners/forums-home_xl.jpg'), -ms-linear-gradient(left,  #b4b4b4 49%,#9d9ea0 51%);
			background: url('../images/category-banners/forums-home_xl.jpg'), linear-gradient(to right,  #b4b4b4 49%,#9d9ea0 51%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4b4b4', endColorstr='#9d9ea0',GradientType=1 );
			background-repeat: no-repeat;
			background-position: center top;
		}
		.container {
			position: relative;
			h2 {
				color: #fff;
				font-weight: 300;
			}
			.search-blogs {
				position: absolute;
				color: #fff;
				left: 0; 
				bottom: 50px;
				.input-group {
					max-width: rem-calc(477px);
					button { margin-bottom: 0; }
				}

			}
			.write-post {
				position: absolute;
				right: 0;
				bottom: 45px;
			}
			@media #{$large-up} {
				h2 {
					margin: rem-calc(85px 0 100px);
					line-height: rem-calc(50px);
				}
			}
			@media #{$medium-only} {
				h2 {
					margin: rem-calc(80px 0);
					line-height: rem-calc(35px);
				}
				.search-blogs {
					max-width: 50%;
				}
			}
			@media #{$small-only} {
				h2 {
					margin: rem-calc(45px 0 30px);
					line-height: rem-calc(27px);
				}
				.search-blogs {
					position: static;
					float: left;
					clear: left;
					max-width: 50%;
					text-align: center;
				}
				.write-post {
					position: relative;
					float: right;
					margin-top: rem-calc(50px);
					padding: rem-calc(5px 10px);
					font-size: rem-calc(12px);
				}
			}
			@media #{$xsmall-only} {
				h2 {
					font-size: rem-calc(24px);
					line-height: rem-calc(24px);
					margin: rem-calc(30px 10px 20px 0);
					br { display: none; }

				}
				.search-blogs {
					max-width: 80%;
					text-align: left;
					margin-bottom: 10px;
					.input-group {
						margin-top: rem-calc(5px);
						width: 100%;
					}
				}
				.write-post {
					float: none;
				}
			}//END media:xsmall
		}
	}
/* ---- General Page --- */
	.forum-boards {
		.forum-table.forum-list {
			display: table;
			border:0px;
			border-bottom: solid 1px $table-border;
			.table-header {
				background-color: $main-blue;
				color: #fff;
				font-size: rem-calc(16px);
			}
			.row {
				display: table-row;
				.cell {
					padding: rem-calc(12px 15px);
					vertical-align: middle;
					&.board-label {
						width: 60%;
						text-align: left;
					}
					&.count {
						width: 20%;
						text-align: center;
						border-left: solid 1px $table-border;
					}
					@media #{$xsmall-only}, #{$medium-only} {
						font-size: rem-calc(14px);
						padding: rem-calc(8px 10px);
					}//END media:xsmall,medium
				}
				.board-title {
					font-size: rem-calc(18px);
					font-weight: 300;
				}
				.meta-icons {
					font-size: rem-calc(14px);
					font-weight: 500;
				}
				@media #{$xsmall-only}, #{$medium-only} {
					.board-title {
						float: left;
						font-size: rem-calc(14px);
					}
					.meta-icons {
						font-weight: 400;
						a { font-weight: 400; }
						.views, .comments { display: block; }
					}
				}
			}
			@media #{$small-only} {
				.table-striped {
					width: 100%;
					border: none;
					&:last-child {
						border-bottom: solid 1px $table-border;
						border-top: none;
						.row {
							&:nth-of-type(odd) { background: $table-background; }
							&:nth-of-type(even) { background: #fff; }
						}
					}
				}
			}
		}
		}//END .forum-boards
		.load-more { margin-top: rem-calc(10px); }
	.main-content {
	}//END .main-content

}//END .forums-landing
/*------------------------------------------------------Forum Landing Page-*/

/*-Forum Boards------------------------------------------------------------*/
.forum-dep {}
	/*--- Hero/Cover/banner ---*/
.forum-topic, .forum-board {
	&.cover-photo {
		position: relative;
		height: rem-calc(200px);
		background-repeat: no-repeat;
		background-position: center top;
		color: #fff;
		margin-bottom: rem-calc(20px);
		@media #{$small-only} { margin: 0 -15px 20px; }
/*		.cover-gradient {
			position: absolute;
			width: 100%;
			height: 100%;

			background: -moz-linear-gradient(top, rgba(55,108,175,0.26) 0%, rgba(6,11,18,0.26) 100%);
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(55,108,175,0.26)), color-stop(100%,rgba(6,11,18,0.26)));
			background: -webkit-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -o-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -ms-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: linear-gradient(to bottom, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42376caf', endColorstr='#42060b12',GradientType=0 );
		}
*/
		.hero-content {
			position: relative;
			width: 100%;
			height: 100%;
			padding: rem-calc(10px 15px 0px 15px);
			.hero-description {
				display: block;
				max-width: 100%;
				margin: rem-calc(5px 0 10px 0);
			}
			@media #{$medium-up} {
				padding: rem-calc(15px 30px);
				.hero-description {
					display: inline-block;
					max-width: 55%;
				}
				.btn { float: right; }
			}
			@media #{$xsmall-only}, #{$medium-only} {
				.btn {
					font-size: rem-calc(14px);
					line-height: 24px;
					padding: rem-calc(6px 15px);
				}
			}//END media:xsmall

		}
	}//END .cover-photo
}// END .forum-dep
/*------------------------------------------------------------Forum Boards-*/

/*-Forum Tables------------------------------------------------------------*/
.cview-container .forum-table,
.forum-table {
	width: 100%;
	color: $gray7;
	&:last-child {
		border-bottom: solid 1px $table-border;
	}
	.forum-topic {
		font-size: rem-calc(14px);
		.forum-topic-title {
			font-size: rem-calc(16px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.author.avatar {
			float: left;
			margin-right: rem-calc(10px);
		}
	}

	.topic-interaction {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: rem-calc(14px);
		}
		a { color: $gray9; }
	}
	.last-reply {
		font-size: rem-calc(14px);
		.post-date { font-size: rem-calc(12px); }
	}
	.forum-table.sponsored {
		color: $gray3;
		font-size: rem-calc(11px);
		.row {
			padding-top: rem-calc(4px);
			.cell {
				width: 100%;
				font-size: rem-calc(14px);
				line-height: rem-calc(20px);
				@media #{$medium-down} {
					font-size: rem-calc(13px);
					line-height: rem-calc(18px);
				}
				.forum-topic-title {
					font-size: rem-calc(15px);
					font-weight:500;
					@media #{$medium-down} {
						font-size: rem-calc(14px);
						font-weight: 400;
					}
				}
				.sponsored {
					font-size: rem-calc(11px);
					color: #999;
					@media #{$medium-down} {
						font-size: rem-calc(10px);
					}
				}
				.last-reply {
					font-weight: 300;
					a {
						font-size: rem-calc(15px);
						font-weight: 500;
						@media #{$medium-down} {
							font-size: rem-calc(14px);
							font-weight: 400;
						}
					}
				}
				&.forum-topic {
					//Native Ads
					.post-header {
						margin: 0;
						.post-headline {
							font-size: rem-calc(16px);
							line-height: rem-calc(24px);
							font-weight: 500;
						}
					}
					.post-content {
						padding: 0;
						p {
							margin: 0;
							font-size: rem-calc(14px);
							line-height: rem-calc(18px);
							a { font-size: rem-calc(12px); }
						}
					}
					.native-ad-img { display: none; }
				}//END .forum-topic

			}//END .cell
		}
	}//END .forum-ad.sponsored
	@media #{$xsmall-only} {
		.forum-topic {
			font-size: rem-calc(12px);
			.forum-topic-title { font-size: rem-calc(14px); }
			.post-date { display: none; }
		}
		.topic-interaction ul { font-size: rem-calc(12px); }
		.last-reply {
			font-size: rem-calc(12px);
			.post-date { font-size: rem-calc(10px); }
		}
	}//END media:xsamll
}
/*--- Forums Table - Two-Thirds Width ---*/
.cview-container .forum-table.long,
.forum-table.long {
	@media #{$large-up} {
		.cell { vertical-align: middle; }
		.forum-topic { width: 53%; }
		.last-reply {
			width: 32%;
			text-align: right;
			.mobile-dot { display: none; }
		}
		.topic-interaction {
			width: 14%;
			border-right: solid 1px $table-border;
			border-left: solid 1px $table-border;
			padding: rem-calc(12px 15px);
		}
		@media #{$large-only} {
			.cell { padding: rem-calc(10px); }
			.forum-topic-title { max-width: 250px; }
		}
		@media #{$xlarge-up} {
			.cell { padding: rem-calc(12px 20px); }
			.forum-topic-title { max-width: 300px; }
		}
	}//END media:large-up
	@media #{$small-only}, #{$medium-only} {
		&.table, .row, .cell,
		&.table .row .cell { display: block; }
		width: 100%;
		.row { padding: rem-calc(12px 10px); }

		.forum-topic {
			float: left;
			width: 80%;
		}
		.topic-interaction {
			float: right;
			width: 20%;
		}
		.last-reply {
			clear: both;
			text-align: left;
			border-top: solid 1px $silver;
			padding-top: rem-calc(5px);
			margin-top: rem-calc(5px);
			.line-break { display: none; }
			.mobile-dot { display: inline; }
		}
	}//END media:small,medium
	+ .pagination-centered {
		margin-top: rem-calc(40px);
	}
}//END .forum-table

/*--- Forums Table - Two-Thirds Width ---*/
.cview-container .forum-table.short,
.forum-table.short {
	&.table, .row, .cell,
	&.table .row .cell { display: block; }
	width: 100%;
	.row { padding: rem-calc(12px 10px); }
	.forum-topic {
		float: left;
		width: 75%;
		padding-bottom: rem-calc(5px);
		margin-bottom: rem-calc(5px);
	}
	.topic-interaction {
		float: left;
		width: 25%;
		.inline-list { float: right; }
	}
	.last-reply {
		float: none;
		clear: both;
		max-width: 100%;
		margin-left: rem-calc(50px);
		border-top: solid 1px $silver;
		br { display: none; }
		/*.post-date::before {
			content: '\2022';
			font-size: rem-calc(14px);
			padding-right: rem-calc(3px);
		}*/
	}
	&.mini {
		border: none;
		.forum-topic {
			padding-bottom: 0;
			margin-bottom: 0;
		}
		.last-reply {
			border-top: none;
			clear: none;
		}
	}
}
/*------------------------------------------------------------Forum Tables-*/

/*-Forum Post--------------------------------------------------------------*/
.forum-topic {
	/*--- Hero/Cover/banner ---*/
/*	.forum-topic.cover-photo {
		position: relative;
		height: rem-calc(184px); // will likely change to 200
		background-repeat: no-repeat;
		background-position: center top;
		color: #fff;
		margin-bottom: rem-calc(20px);
		@media #{$small-only} { margin: 0 -15px 20px; }
		.cover-gradient {
			position: absolute;
			width: 100%;
			height: 100%;
/*			// will likely bring this back
			background: -moz-linear-gradient(top, rgba(55,108,175,0.26) 0%, rgba(6,11,18,0.26) 100%);
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(55,108,175,0.26)), color-stop(100%,rgba(6,11,18,0.26)));
			background: -webkit-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -o-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -ms-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: linear-gradient(to bottom, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42376caf', endColorstr='#42060b12',GradientType=0 );
*//*		}
		.hero-content {
			position: relative;
			width: 100%;
			height: 100%;
			padding: rem-calc(10px 15px 0px 15px);
			.board-description {
				display: block;
				max-width: 100%;
				margin: rem-calc(5px 0 10px 0);
			}
			@media #{$medium-up} {
				padding: rem-calc(15px 30px);
				.hero-description {
					display: inline-block;
					max-width: 55%;
				}
				.btn { float: right; }
			}

		}
	}//END .cover-photo
*/
	/*--- Body Content ---*/
	.forum-post {
		.post-headline {
		//	display: table-cell;
			text-align: left;
			color: $main-blue;
			font-size: 1.5rem;
		}
		@media #{$medium-up} {
			.post-headline {
				font-size: 2rem;
			}			
		}
		.row.post-comment {
			border-top: solid 1px $silver;
			@media #{$medium-up} { padding-top: rem-calc(24px); }
			@media #{$small-only} {
				padding-top: rem-calc(5px);
				.user-info { padding-top: rem-calc(5px); }
			}
			.date {
				display: block;
				text-align: right;
				margin: rem-calc(22px 0 16px);
				font-size: rem-calc(14px);
				color: $gray9;
			}
			.commenter.profile {
				.profile-name {
					color: $main-blue;
					font-size: rem-calc(20px);
					font-weight: 500;

				//	These are technically the same, but use both
					overflow-wrap: break-word;
					word-wrap: break-word;
					word-break: break-word;
				//	Adds a hyphen where the word breaks, if supported
					-ms-hyphens: auto;
					-moz-hyphens: auto;
					-webkit-hyphens: auto;
					hyphens: auto;
				}
				.profile-job, .profile-company {
					font-weight: 300;
					font-size: rem-calc(14px);
					line-height: rem-calc(18px);}
				.profile-job { color: $gray4; }
				.profile-company { color: $gray9; }
				@media #{$small-only}, #{$medium-only} {
					.avatar-status-widget {
						float: left;
						width: rem-calc(75px);
						height: rem-calc(75px);
						margin-right: rem-calc(10px);
					}//END .avatar-status-widget
				}//END media:xsmall
			}
			.comment-content { margin-top: rem-calc(5px); }
		}
	}
	.replies.comment-section {
		border-top: solid 1px $grey6;
		h3 .pager {
			display: inline;
			ul.pagination { float: right; }
		}
	}
	.commentSection .pagination-centered { margin-top: rem-calc(30px); }
}// END .forum-topic
/*--------------------------------------------------------------Forum Post-*/
