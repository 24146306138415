/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * radio-buttons.scss
 * =========================================================================
 * 
 * @NOTE: Please DON'T convert these background-image posistions to rems!
 *		- "p:not(#ie*)" is a way to style everything in modern browsers
 *
 * @TODO: Update TOC as necessary.
 *      - Update image-sprite
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

//overwriting foundation defaults - required for custom inputs
input[type="radio"] { margin: 0 0 0 0; }

//* Hide the input, but have it still be clickable
p:not(#ie8) > input[type=radio] {
	opacity: 0;

	float: left;
	width: rem-calc(24px);
	height: rem-calc(24px);
}
p:not(#ie8) > input[type=radio] + label {
	margin: 0;
	clear: none;
	line-height: rem-calc(24px);

	// Left padding makes room for the images
	padding: rem-calc(0 0 0 30px);
	// covering existing button
	margin-left: rem-calc(-28px);

	//adding breathing room between lines
	margin-bottom: rem-calc(5px);

	// Make look clickable because they are
	cursor: pointer;
	background: url('../images/components/ds_custom-radios.png') 0 -25px no-repeat;
}
// Changed to checked graphic
p:not(#ie8) > input[type=radio]:checked + label {
	background-position: 0 0;
}
// Changed to disabled graphic
p:not(#ie8) > input[type=radio]:disabled + label,
p:not(#ie8) > input[type=radio]:hover:disabled + label,
p:not(#ie8) > input[type=radio]:focus:disabled + label,
p:not(#ie8) > input[type=radio]:disabled + label:hover,
p:not(#ie8) > input[type=radio]:disabled + label:hover:active { 
	background-position: 0 -75px; 
}
// Changed to disabled/checked graphic
p:not(#ie8) > input[type=radio]:disabled:checked + label,
p:not(#ie8) > input[type=radio]:hover:disabled:checked + label,
p:not(#ie8) > input[type=radio]:focus:disabled:checked + label,
p:not(#ie8) > input[type=radio]:disabled:checked + label:hover,
p:not(#ie8) > input[type=radio]:disabled:checked + label:hover:active {
	 background-position: 0 -50px; 
}
/*
// Changed to hover graphic
p:not(#ie8) > input[type=radio]:hover + label,
p:not(#ie8) > input[type=radio]:focus + label,
p:not(#ie8) > input[type=radio] + label:hover { 
	background-position: 0 -179px; 
}
// Changed to hover/checked graphic
p:not(#ie8) > input[type=radio]:hover:checked + label,
p:not(#ie8) > input[type=radio]:focus:checked + label,
p:not(#ie8) > input[type=radio]:checked + label:hover  { 
	background-position: 0 -259px;
}
// Changed to active graphic
p:not(#ie8) > input[type=radio]:active + label,
p:not(#ie8) > input[type=radio] + label:hover:active { 
	background-position: 0 -199px; 
}
// Changed to active/checked graphic
p:not(#ie8) > input[type=radio]:active:checked + label,
p:not(#ie8) > input[type=radio]:checked + label:hover:active {
	 background-position: 0 -279px; 
}
*/