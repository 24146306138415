/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * footer.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + footer
 * - Logo
 * - Bottomnav
 * + NagBar
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Footer Navigation-------------------------------------------------------*/
.footer {
	background-color: $tertiary;
	color: #fff;
	clear: both;
	margin-top: rem-calc(40px);

	.container { padding: rem-calc(46px 15px); }
	a {
		color: #fff;
		&:hover { text-decoration: underline; }
	}
	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	@media #{$small-only} {
		.container { padding: rem-calc(15px 0px); }
		a:hover { text-decoration: none; }
	}
/* --- Logo --- */
	.logo-tagline {
		margin-bottom: rem-calc(24px);
		@media #{$small-only} {
			max-width: rem-calc(300px);
			margin: rem-calc(0 0 15px 18px);
		}
		.logo {
			width: rem-calc(197px);
			height: rem-calc(43px);
		}
		.tagline {
			font-size: rem-calc(15px);
			font-weight: 100;
			font-style: italic;
			@media #{$small-only} {
				display: none;
				font-size: rem-calc(14px);
				margin-left: rem-calc(50px);
			}
		}
	}
/* ---Bottomnav --- */
	.bottomnav {
		> li > a {
			font-size: rem-calc(16px);
			font-weight: 500;
		}
		.bottomsubnav {
			margin-left: 0;
			li a {
				font-size: rem-calc(15px);
				line-height: rem-calc(24px);
				font-weight: 100;
				&:hover { font-weight: 400; }
			}
		}
		@media #{$small-only} {
			//Collapse like the top-nav
			> li {
				float: none;
				clear: both;
				width: 100%;
				.has-dd {
					border-top: solid 1px #fff;
					padding: rem-calc(10px 20px);
					width: 100%;
					&.dd-active { padding: rem-calc(10px 20px 0); }
				}
			}
		}
		&.nav-left {
			margin: 0;
			> li {
				@media #{$medium-up} {
					float: left;
					width: rem-calc(180px);
					&:last-child {
						width: rem-calc(170px);
						margin-left: rem-calc(10px);
					}
				}
				@media #{$large-up} { width: rem-calc(180px); }
				@media #{$xlarge-up} { width: rem-calc(190px); }
			}
		}//END .nav-left
		&.nav-right {
			> li { margin: 0 auto; }
			.social {
				display: block;
				max-width: 114px;
				> ul {
					height: rem-calc(21px);
					margin-left: 0;
				}
				.social-logo {
					float: left;
					background-color: #fff;
					color: $tertiary;
					width: rem-calc(21px);
					height: rem-calc(21px);
					line-height: rem-calc(18px);
					text-align: center;
					&.img-circle {}
					i.fa {
						color: $tertiary;
						font-size: rem-calc(13px);
						&.fa-twitter {
							font-size: rem-calc(18px);
							line-height: rem-calc(24px);
							padding-left: rem-calc(2px);
						}
					}
					margin-left: rem-calc(10px);
					&:first-child { margin-left: 0; }
				}
			}//END .social
			.getInvolved { margin: rem-calc(0 0 20px 0); }
			.legal {
				font-size: rem-calc(11px);
				line-height: rem-calc(16px);
				font-weight: 100;
				margin: rem-calc(20px 0 0);
				a {
					font-size: rem-calc(11px);
					font-weight: 100;
				}
			}//END .legal

			@media #{$small-only} {
				clear: both;
				margin: 0;
				padding: 0;
				.getInvolved {
					margin: rem-calc(0 0 20px 0);
					border-bottom: solid 1px #fff;
				}
				.social { margin-left: rem-calc(20); }
				.legal {
					border-top: solid 1px #fff;
					padding: rem-calc(10px 20px);
					.line-break { display: none; }
				}
			}
			@media #{$medium-only} {
				clear: both;
				text-align: center;
				padding-top: rem-calc(20px);
				.legal .line-break { display: none; }
			}
			@media #{$large-up} {
				float: right;
				margin-top: rem-calc(-41px);
				padding-left: rem-calc(37px);
				border-left: solid 1px #fff;
				position: relative;
				// > li { text-align: left; }
				.getInvolved {
					position: relative;
					top: rem-calc(41px);
					margin-bottom: rem-calc(52px);
				}
				.social {
					position: absolute;
					top: 0;
					left: rem-calc(38px);
				}
				.legal {
					position: relative;
					clear: both;
					.link-break { display: none; }
				}
			}

		}//END .nav-right

	} //END .bottomnav
}
/*-------------------------------------------------------Footer Navigation-*/

/*-Nagbar------------------------------------------------------------------*/
.nagbar {
	bottom: 0;
	position: fixed;
	z-index: 150;
	_position: absolute;
	_top: expression(eval(document.documentElement.scrollTop+(document.documentElement.clientHeight-this.offsetHeight)));
//	height: 150px;
	width: 100%;
	background: rgba(0,0,0,0.7);
	display: none;

}
.nag {
	padding: rem-calc(70px 0px);
	h2 {
//		float: left;
		color: #ffffff;
		font-size: rem-calc(36px);
		line-height: 93px;
		vertical-align: middle;
	}
	a {
		float: right;
		margin: 24px 0;
	//	margin-top: 12px;
	//	margin-right: 12px;
	}
	@media #{$medium-only} {
		h2 {
			font-size: rem-calc(24px);
		}
	}
	@media #{$small-only} {
		h2 {
			font-size: rem-calc(20px);
			text-align: center;
		}
		a {
			float: none;
			margin: 10px auto;
		}
	}
	@media #{$xsmall-only} {
		padding: rem-calc(10px 0px);
		h2 {
			font-size: rem-calc(16px);
			text-align: center;
			line-height: 50px;
			font-weight: 400;
		}
		a.btn.btn-lg {
			float: none;
			margin: 10px auto 0;
			font-size: rem-calc(16px);
			padding: rem-calc(10px 24px);
		}
		img.avatar.large {
			margin: 10px calc(50% - 46px) 10px;
			width: 4rem;
			height: 4rem;
		}
	}
}
/*-Nagbar------------------------------------------------------------------*/
