/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * forms.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Forms
 * + Form Components
 *   - Checkboxes
 *   - Radio Buttons
 *   - Switches
 *   - Sliders
 * + 
 *
 *
 * @TODO: Update TOC as necessary.
 *		- Update color-variable names after update from colors.scss
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Forms-------------------------------------------------------------------*/
form {
	label {
		display: inline-block;
		color: $form-readonly;
	}
	textarea,
	input {
		&[type="textarea"],
		&[type="search"],
		&[type="text"] {
			display: inline-block;
			border: solid 1px $form-border;
			border-radius: 3px;
			padding: rem-calc(0px 10px);
			margin: rem-calc(5px 0);
			font-size: rem-calc(16px);
			font-weight: 300;
			&[disabled] { background-color: $form-disabled-bg; }
			&.has-warning { border-color: $form-border-warning; }
			&.has-error { border-color: $form-border-error; }
			&.has-success { border-color: $form-border-success; }
			&[readonly] {
				color: $form-readonly;
				&::-webkit-input-placeholder { color: $form-readonly; }
				:-moz-placeholder { color: $form-readonly; }
				::-moz-placeholder { color: $form-readonly; }
				:-ms-input-placeholder { color: $form-readonly; }
				//overwriting the Foundation styles
				border: 0px;
				background-color: #fff;
				outline: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
			&::-webkit-input-placeholder { color: $form-placeholder; }
			:-moz-placeholder { color: $form-placeholder; }
			::-moz-placeholder { color: $form-placeholder; }
			:-ms-input-placeholder { color: $form-placeholder; }
		}
		&[type="textarea"], textarea { display: block; }
//      &.disabled { background-color: $form-disabled-bg; }
		&.event-msg {}
	}
	.form-element-icon {
		display: inline-block;
		position: relative;
		.material-icons {
			position: absolute;
			margin: rem-calc(8px 10px 0);
			font-size: rem-calc(30px);
			color: $form-placeholder-icon;
			pointer-events: none;
		}
		input { padding-left: rem-calc(40px); }
	}
	.form-element-required {
		display: inline-block;
		position: relative;
		.required-sticker {
			position: absolute;
			top: rem-calc(5px);
			right: 0;
			z-index: 2;
			width: 0;
			height: 0;
			border-top: rem-calc(30px) solid $form-border-error;
			border-left: rem-calc(30px) solid transparent;
			span {
				position: absolute;
				z-index: 3;
				top: rem-calc(-26px);
				right: rem-calc(3px);
				color: #ffffff;
				font-weight: 300;
				font-size: rem-calc(24px);
			}
		}
	}
	.description { height:rem-calc(120px); }
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
	input.form-control {
		display: table-cell;
		position: relative;
		z-index: 2;

		float: left;
		width: 100%;
		margin: 0;

		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.input-group-btn {
		position: relative;
		display: table-cell;
		font-size: 0;
		width: 1%;
		white-space: nowrap;
		vertical-align: middle;
		button.btn {
  			position: relative;
  			padding: rem-calc(5px 10px);
  			border: solid 1px $form-border;
  			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			color: $form-border;
			background-color: #fff;
			&:hover {
				color: #fff;
				background-color: $form-border;
			}
		}
	}
}
@media #{$medium-up} {
	.topnav-search {
		.input-group .input-group-btn button.btn { padding: rem-calc(0 4px); }
	}
}
@media #{$small-only} {
	.topnav-search .input-group {
		max-width: 96%;
		margin: 0 auto;
		.search-box { max-height: rem-calc(36px); }
	}
}
/*-------------------------------------------------------------------Forms-*/

/*-Form Components---------------------------------------------------------*/
@import "../components/checkboxes";
@import "../components/radio-buttons";
@import "../components/switches";
@import "../components/input-sliders";
/*---------------------------------------------------------Form Components-*/
