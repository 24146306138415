.align-items-center {
  display: flex !important;
  align-items: center !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.border-0 {
  border: none !important;
}
.p-0,
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.text-white {
  color: white !important;
}
.affix {
  background: #376cb0 !important;
  height: 74px;
}
.ds-header {
  background: unset;
  width: 100%;
  z-index: 9;
  .container-fluid {
    width: 100vw;
    height: 65px;
    .navbar-header {
      width: 20%;
      float: left;
    }
    #myNavbar {
      width: 80%;
      float: left;
    }
    .in {
      background: #1c559c;
      min-height: 235px;
      .controls {
        bottom: 0;
        position: absolute;
        right: 20px;
        background: #1c559c;
        width: 60%;
      }
    }
  }
  .nav-logo {
    margin: 17px;
    max-height: 35px;
    width: auto;
  }
  .navbar-nav {
    height: 50px;
    .three-dots {
      position: relative;
      .about-us {
        display: none;
        background: white;
        color: black;
        position: absolute;
        width: 110px;
        top: 50px;
        opacity: 1;
        right: -73px;
        border-radius: 5px;
        padding: 3px 0;
        text-align: center;
        cursor: pointer;
        &:hover {
          display: block;
        }
        a {
          color: black;
          text-decoration: none;
        }
      }
      &:hover {
        & > a {
          opacity: 0.3 !important;
        }
        .about-us {
          display: block;
        }
      }
      img {
        width: 16px !important;
        height: auto !important;
        margin: 13px 4px;
      }
    }
    li {
      a:hover {
        background-color: unset;
        opacity: unset;
      }
      a {
        font-size: 12px !important;
        line-height: 14px;
        color: white;
        padding: 10px;
        background-color: transparent;
        opacity: 0.7;
      }
      .navbar-default .container .navbar-nav li > a:hover {
        opacity: 0.8;
        background-color: unset;
        color: white;
      }
    }

    .navbar-right {
      li {
        a {
          padding-right: 0;
          padding-left: 0;
          padding-bottom: 5px;
        }
      }
      li:first-child {
        a {
          margin: 0;
        }
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;
      .sub-dropdown {
        position: relative;
      }

      .sub-dropdown-content {
        border-radius: 5px;
        display: none;
        padding: 10px 0px;
        position: absolute;
        background-color: #ffffff;
        min-width: 160px;
        z-index: 1;
        left: 140px;
        top: -10px;
      }

      .sub-dropdown-content a {
        color: #636468;
        padding: 12px 20px;
        margin: 0px;
        display: block;
        font-weight: 500;
        text-align: left;
      }

      .sub-dropdown-content a:hover {
        background-color: #376caf;
        color: white;
      }

      .sub-dropdown:hover > .sub-dropdown-content {
        display: block;
      }
    }

    .dropdown-content {
      .arrow-up {
        position: absolute;
        top: -8px;
        left: 76px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
      }
      a {
        color: #636468;
        padding: 12px 20px;
        margin: 0px;
        display: block;
        font-weight: 500;
        text-align: left;
        min-width: 160px;
        opacity: 1 !important;
      }
      border-radius: 5px;
      display: none;
      padding: 10px 0px;
      position: absolute;
      background-color: #ffffff;
      z-index: 1;
      left: -37px;
    }

    .dropdown-content a:hover {
      background-color: #376caf;
      color: white;
    }

    .dropdown:hover > .dropdown-content {
      display: block;
    }
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0;
    margin: 10px 0;
    padding-right: 15px;
    .lock {
      background-color: transparent;
      opacity: 1;
      color: white;
      font-size: 12px;
      padding: 0;
      width: 140px;
      height: 40px;
      margin-bottom: 0;
      i {
        color: white !important;
        font-size: 12px !important;
      }
    }
    .divider {
      background-color: white;
      opacity: 0.5;
      width: 2px;
      height: calc(65px - 30px);
    }
    .search-box::placeholder {
      color: white !important;
      opacity: 1; /* Firefox */
      font-weight: 300;
    }

    .search-box::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white !important;
      font-weight: 300;
    }

    .search-box::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white !important;
      font-weight: 300;
    }
    .search-box {
      background-color: hsla(0, 0%, 100%, 0.1);
      border-radius: 20px;
      border: none;
      height: 35px;
      overflow: hidden;
      opacity: 0.8;
      input {
        background: transparent;
        border: none;
        padding-left: 32px;
        width: 160px;
      }
    }
    .input-group-btn {
      .btn-transparent {
        background: none;
        border: none;
      }
    }
  }

  .item {
    float: left;
    cursor: pointer;
    color: #fff;
    text-align: center;
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      color: white;
      font-weight: unset;
      /* width: 86px; */
      padding: 10px 10px;
      /* margin: unset !important; */
      img {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: 28px;
        padding-top: 3px;
      }
    }
  }

  .selected-item {
    font-weight: bold;
    a {
      opacity: unset !important;
      border-bottom: 3px solid #fff;
    }
  }
  .small-avatar {
    .topnav-dd {
      li {
        a {
          color: #376caf;
        }
        a:hover {
          color: #fff;
        }
      }
    }
  }
}
.head-banner {
  .icons {
    a {
      color: white;
    }
  }
  .box_innnenr {
    padding-bottom: 0px;
    p {
      min-height: unset;
    }
  }
}

@media screen and (max-width: 1055px) {
  .ds-header .navbar-nav li a {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1400px) {
  .head-banner {
    .height-0 {
      height: 0px !important;
    }
    .box_innnenr {
      min-height: unset !important;
    }
    .bannertexts {
      h2 {
        font-size: 17px !important;
      }
      h1 {
        font-size: 30px !important;
      }
    }
  }
  .vendors-home {
    .padding-unset {
      padding: usnet !important;
    }
    .hcm-nav {
      .height-0 {
        height: 0px !important;
      }
      .box_innnenr {
        min-height: unset !important;
      }
      .bannertexts {
        h2 {
          font-size: 17px !important;
        }
        h1 {
          font-size: 30px !important;
        }
      }
    }
  }
}
