@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Roboto+Slab:100,300,400,700|Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.button,
body,
button,
label {
  font-weight: 400
}

.alert-box,
body {
  position: relative
}

.clearfix:after,
[class*=block-grid-]:after {
  clear: both
}

.breadcrumbs,
.button-bar .button-group div {
  overflow: hidden
}

.btn-loader:not(.disabled):before,
.slideshow-wrapper .preloader,
body {
  -webkit-animation-timing-function: linear
}

.cropper-modal-cover .preview-btns,
.cropper-modal-sm .preview-btns,
.invisible,
.reveal-modal {
  visibility: hidden
}

.antialiased,
.material-icons {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

meta.foundation-version {
  font-family: "/5.5.2/"
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0
}

meta.foundation-mq-small-only {
  font-family: "/(max-width: 767px)/";
  width: 0
}

meta.foundation-mq-medium {
  font-family: "/(min-width:768px)/";
  width: 768px
}

meta.foundation-mq-medium-only {
  font-family: "/(min-width:768px) and (max-width:991px)/";
  width: 768px
}

meta.foundation-mq-large {
  font-family: "/(min-width:992px)/";
  width: 992px
}

meta.foundation-mq-large-only {
  font-family: "/(min-width:992px) and (max-width:1199px)/";
  width: 992px
}

meta.foundation-mq-xlarge {
  font-family: "/(min-width:1200px)/";
  width: 1200px
}

meta.foundation-mq-xlarge-only {
  font-family: "/(min-width:1200px) and (max-width:1919px)/";
  width: 1200px
}

meta.foundation-mq-xxlarge {
  font-family: "/(min-width:1920px)/";
  width: 1920px
}

meta.foundation-data-attribute-namespace {
  font-family: false
}

body,
html {
  height: 100%;
  font-size: 100%
}

html {
  box-sizing: border-box
}

*,
:after,
:before {
  box-sizing: inherit
}

body {
  background: #fff;
  color: #222;
  cursor: auto;
  font-style: normal;
  margin: 0;
  padding: 0
}

a:hover {
  cursor: pointer
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic
}

#map_canvas embed,
#map_canvas img,
#map_canvas object,
.map_canvas embed,
.map_canvas img,
.map_canvas object,
.mqa-display embed,
.mqa-display img,
.mqa-display object {
  max-width: none!important
}

.f-dropdown,
.f-dropdown.drop-right {
  max-width: 200px;
  list-style: none;
  max-height: none
}

.left {
  float: left!important
}

.right {
  float: right!important
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table
}

.hide {
  display: none
}

img {
  display: inline-block;
  vertical-align: middle
}

select {
  width: 100%
}

.alert-box {
  border-style: solid;
  border-width: 1px;
  display: block;
  margin-bottom: 1.25rem;
  padding: .875rem 1.5rem .875rem .875rem;
  transition: opacity .3s ease-out;
  background-color: #008CBA;
  border-color: #0078a0;
  color: #FFF
}

.alert-box .close {
  right: .25rem;
  background: inherit;
  color: #333;
  font-size: 1.375rem;
  line-height: .9;
  margin-top: -.6875rem;
  padding: 0 6px 4px;
  position: absolute;
  top: 50%
}

.alert-box .close:focus,
.alert-box .close:hover {
  opacity: .5
}

.alert-box.radius {
  border-radius: 3px
}

.alert-box.round {
  border-radius: 1000px
}

.alert-box.secondary {
  background-color: #e7e7e7;
  border-color: #c7c7c7;
  color: #4f4f4f
}

.alert-box.alert-close {
  opacity: 0
}

[class*=block-grid-] {
  display: block;
  padding: 0;
  margin: 0 -.625rem
}

[class*=block-grid-]:after,
[class*=block-grid-]:before {
  content: " ";
  display: table
}

[class*=block-grid-]>li {
  display: block;
  float: left;
  height: auto;
  padding: 0 .625rem 1.25rem
}

@media only screen {
  .small-block-grid-1>li {
    list-style: none;
    width: 100%
  }
  .small-block-grid-1>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-1>li:nth-of-type(1n+1) {
    clear: both
  }
  .small-block-grid-2>li {
    list-style: none;
    width: 50%
  }
  .small-block-grid-2>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-2>li:nth-of-type(2n+1) {
    clear: both
  }
  .small-block-grid-3>li {
    list-style: none;
    width: 33.3333333333%
  }
  .small-block-grid-3>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-3>li:nth-of-type(3n+1) {
    clear: both
  }
  .small-block-grid-4>li {
    list-style: none;
    width: 25%
  }
  .small-block-grid-4>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-4>li:nth-of-type(4n+1) {
    clear: both
  }
  .small-block-grid-5>li {
    list-style: none;
    width: 20%
  }
  .small-block-grid-5>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-5>li:nth-of-type(5n+1) {
    clear: both
  }
  .small-block-grid-6>li {
    list-style: none;
    width: 16.6666666667%
  }
  .small-block-grid-6>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-6>li:nth-of-type(6n+1) {
    clear: both
  }
  .small-block-grid-7>li {
    list-style: none;
    width: 14.2857142857%
  }
  .small-block-grid-7>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-7>li:nth-of-type(7n+1) {
    clear: both
  }
  .small-block-grid-8>li {
    list-style: none;
    width: 12.5%
  }
  .small-block-grid-8>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-8>li:nth-of-type(8n+1) {
    clear: both
  }
  .small-block-grid-9>li {
    list-style: none;
    width: 11.1111111111%
  }
  .small-block-grid-9>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-9>li:nth-of-type(9n+1) {
    clear: both
  }
  .small-block-grid-10>li {
    list-style: none;
    width: 10%
  }
  .small-block-grid-10>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-10>li:nth-of-type(10n+1) {
    clear: both
  }
  .small-block-grid-11>li {
    list-style: none;
    width: 9.0909090909%
  }
  .small-block-grid-11>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-11>li:nth-of-type(11n+1) {
    clear: both
  }
  .small-block-grid-12>li {
    list-style: none;
    width: 8.3333333333%
  }
  .small-block-grid-12>li:nth-of-type(1n) {
    clear: none
  }
  .small-block-grid-12>li:nth-of-type(12n+1) {
    clear: both
  }
}

@media (min-width:768px) {
  .medium-block-grid-1>li {
    list-style: none;
    width: 100%
  }
  .medium-block-grid-1>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-1>li:nth-of-type(1n+1) {
    clear: both
  }
  .medium-block-grid-2>li {
    list-style: none;
    width: 50%
  }
  .medium-block-grid-2>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-2>li:nth-of-type(2n+1) {
    clear: both
  }
  .medium-block-grid-3>li {
    list-style: none;
    width: 33.3333333333%
  }
  .medium-block-grid-3>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-3>li:nth-of-type(3n+1) {
    clear: both
  }
  .medium-block-grid-4>li {
    list-style: none;
    width: 25%
  }
  .medium-block-grid-4>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-4>li:nth-of-type(4n+1) {
    clear: both
  }
  .medium-block-grid-5>li {
    list-style: none;
    width: 20%
  }
  .medium-block-grid-5>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-5>li:nth-of-type(5n+1) {
    clear: both
  }
  .medium-block-grid-6>li {
    list-style: none;
    width: 16.6666666667%
  }
  .medium-block-grid-6>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-6>li:nth-of-type(6n+1) {
    clear: both
  }
  .medium-block-grid-7>li {
    list-style: none;
    width: 14.2857142857%
  }
  .medium-block-grid-7>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-7>li:nth-of-type(7n+1) {
    clear: both
  }
  .medium-block-grid-8>li {
    list-style: none;
    width: 12.5%
  }
  .medium-block-grid-8>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-8>li:nth-of-type(8n+1) {
    clear: both
  }
  .medium-block-grid-9>li {
    list-style: none;
    width: 11.1111111111%
  }
  .medium-block-grid-9>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-9>li:nth-of-type(9n+1) {
    clear: both
  }
  .medium-block-grid-10>li {
    list-style: none;
    width: 10%
  }
  .medium-block-grid-10>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-10>li:nth-of-type(10n+1) {
    clear: both
  }
  .medium-block-grid-11>li {
    list-style: none;
    width: 9.0909090909%
  }
  .medium-block-grid-11>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-11>li:nth-of-type(11n+1) {
    clear: both
  }
  .medium-block-grid-12>li {
    list-style: none;
    width: 8.3333333333%
  }
  .medium-block-grid-12>li:nth-of-type(1n) {
    clear: none
  }
  .medium-block-grid-12>li:nth-of-type(12n+1) {
    clear: both
  }
}

@media (min-width:992px) {
  .large-block-grid-1>li {
    list-style: none;
    width: 100%
  }
  .large-block-grid-1>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-1>li:nth-of-type(1n+1) {
    clear: both
  }
  .large-block-grid-2>li {
    list-style: none;
    width: 50%
  }
  .large-block-grid-2>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-2>li:nth-of-type(2n+1) {
    clear: both
  }
  .large-block-grid-3>li {
    list-style: none;
    width: 33.3333333333%
  }
  .large-block-grid-3>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-3>li:nth-of-type(3n+1) {
    clear: both
  }
  .large-block-grid-4>li {
    list-style: none;
    width: 25%
  }
  .large-block-grid-4>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-4>li:nth-of-type(4n+1) {
    clear: both
  }
  .large-block-grid-5>li {
    list-style: none;
    width: 20%
  }
  .large-block-grid-5>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-5>li:nth-of-type(5n+1) {
    clear: both
  }
  .large-block-grid-6>li {
    list-style: none;
    width: 16.6666666667%
  }
  .large-block-grid-6>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-6>li:nth-of-type(6n+1) {
    clear: both
  }
  .large-block-grid-7>li {
    list-style: none;
    width: 14.2857142857%
  }
  .large-block-grid-7>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-7>li:nth-of-type(7n+1) {
    clear: both
  }
  .large-block-grid-8>li {
    list-style: none;
    width: 12.5%
  }
  .large-block-grid-8>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-8>li:nth-of-type(8n+1) {
    clear: both
  }
  .large-block-grid-9>li {
    list-style: none;
    width: 11.1111111111%
  }
  .large-block-grid-9>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-9>li:nth-of-type(9n+1) {
    clear: both
  }
  .large-block-grid-10>li {
    list-style: none;
    width: 10%
  }
  .large-block-grid-10>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-10>li:nth-of-type(10n+1) {
    clear: both
  }
  .large-block-grid-11>li {
    list-style: none;
    width: 9.0909090909%
  }
  .large-block-grid-11>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-11>li:nth-of-type(11n+1) {
    clear: both
  }
  .large-block-grid-12>li {
    list-style: none;
    width: 8.3333333333%
  }
  .large-block-grid-12>li:nth-of-type(1n) {
    clear: none
  }
  .large-block-grid-12>li:nth-of-type(12n+1) {
    clear: both
  }
}

.button-bar:after,
.button-group:after,
.row .row.collapse:after,
.row .row:after,
.row:after,
.tabs-content:after,
.tabs:after {
  clear: both
}

.breadcrumbs {
  display: block;
  list-style: none;
  margin-left: 0;
  background-color: #f4f4f4;
  border-radius: 3px
}

.breadcrumbs>* {
  color: #008CBA;
  float: left;
  line-height: .6875rem;
  margin: 0;
  text-transform: uppercase
}

.breadcrumbs>:focus a,
.breadcrumbs>:hover a {
  text-decoration: underline
}

.breadcrumbs>.current:focus,
.breadcrumbs>.current:focus a,
.breadcrumbs>.current:hover,
.breadcrumbs>.current:hover a,
.button,
.label,
.sub-nav dd a,
.sub-nav dt a,
.sub-nav li a,
.vevent abbr,
a,
button {
  text-decoration: none
}

.breadcrumbs>.current,
.breadcrumbs>.current a {
  color: #333;
  cursor: default
}

.breadcrumbs>.unavailable,
.breadcrumbs>.unavailable a {
  color: #999
}

.breadcrumbs>.unavailable a:focus,
.breadcrumbs>.unavailable:focus,
.breadcrumbs>.unavailable:hover,
.breadcrumbs>.unavailable:hover a {
  color: #999;
  cursor: not-allowed;
  text-decoration: none
}

.breadcrumbs>:before {
  content: "/";
  margin: 0 .75rem;
  position: relative;
  top: 1px
}

.breadcrumbs>:first-child:before {
  content: " ";
  margin: 0
}

[aria-label=breadcrumbs] [aria-hidden=true]:after {
  content: "/"
}

.button,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem;
  font-size: 1rem;
  background-color: #008CBA;
  border-color: #007095;
  color: #FFF;
  transition: background-color .3s ease-out
}

.button:focus,
.button:hover,
button:focus,
button:hover {
  background-color: #007095;
  color: #FFF
}

.button.secondary,
button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333
}

.button.secondary:focus,
.button.secondary:hover,
button.secondary:focus,
button.secondary:hover {
  background-color: #b9b9b9;
  color: #333
}

.button.success,
button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFF
}

.button.success:focus,
.button.success:hover,
button.success:focus,
button.success:hover {
  background-color: #368a55;
  color: #FFF
}

.button.alert,
button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #FFF
}

.button.alert:focus,
.button.alert:hover,
button.alert:focus,
button.alert:hover {
  background-color: #cf2a0e;
  color: #FFF
}

.button.warning,
button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFF
}

.button.warning:focus,
.button.warning:hover,
button.warning:focus,
button.warning:hover {
  background-color: #cf6e0e;
  color: #FFF
}

.button.info,
button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333
}

.button.info:focus,
.button.info:hover,
button.info:focus,
button.info:hover {
  background-color: #61b6d9;
  color: #FFF
}

.button.large,
button.large {
  padding: 1.125rem 2.25rem 1.1875rem;
  font-size: 1.25rem
}

.button.small,
button.small {
  padding: .875rem 1.75rem .9375rem;
  font-size: .8125rem
}

.button.tiny,
button.tiny {
  padding: .625rem 1.25rem .6875rem;
  font-size: .6875rem
}

.button.expand,
button.expand {
  padding-left: 0;
  padding-right: 0;
  width: 100%
}

.button.left-align,
button.left-align {
  text-align: left;
  text-indent: .75rem
}

.button.right-align,
button.right-align {
  text-align: right;
  padding-right: .75rem
}

.button.radius,
button.radius {
  border-radius: 3px
}

.button.round,
button.round {
  border-radius: 1000px
}

.button.disabled,
.button[disabled],
button.disabled,
button[disabled] {
  background-color: #008CBA;
  border-color: #007095;
  color: #FFF;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled:focus,
.button.disabled:hover,
.button[disabled]:focus,
.button[disabled]:hover,
button.disabled:focus,
button.disabled:hover,
button[disabled]:focus,
button[disabled]:hover {
  color: #FFF;
  background-color: #008CBA
}

.button.disabled.secondary,
.button[disabled].secondary,
button.disabled.secondary,
button[disabled].secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled.secondary:focus,
.button.disabled.secondary:hover,
.button[disabled].secondary:focus,
.button[disabled].secondary:hover,
button.disabled.secondary:focus,
button.disabled.secondary:hover,
button[disabled].secondary:focus,
button[disabled].secondary:hover {
  color: #333;
  background-color: #e7e7e7
}

.button.disabled.success,
.button[disabled].success,
button.disabled.success,
button[disabled].success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFF;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled.success:focus,
.button.disabled.success:hover,
.button[disabled].success:focus,
.button[disabled].success:hover,
button.disabled.success:focus,
button.disabled.success:hover,
button[disabled].success:focus,
button[disabled].success:hover {
  color: #FFF;
  background-color: #43AC6A
}

.button.disabled.alert,
.button[disabled].alert,
button.disabled.alert,
button[disabled].alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #FFF;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled.alert:focus,
.button.disabled.alert:hover,
.button[disabled].alert:focus,
.button[disabled].alert:hover,
button.disabled.alert:focus,
button.disabled.alert:hover,
button[disabled].alert:focus,
button[disabled].alert:hover {
  color: #FFF;
  background-color: #f04124
}

.button.disabled.warning,
.button[disabled].warning,
button.disabled.warning,
button[disabled].warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFF;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled.warning:focus,
.button.disabled.warning:hover,
.button[disabled].warning:focus,
.button[disabled].warning:hover,
button.disabled.warning:focus,
button.disabled.warning:hover,
button[disabled].warning:focus,
button[disabled].warning:hover {
  color: #FFF;
  background-color: #f08a24
}

.button.disabled.info,
.button[disabled].info,
button.disabled.info,
button[disabled].info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333;
  box-shadow: none;
  cursor: default;
  opacity: .7
}

.button.disabled.info:focus,
.button.disabled.info:hover,
.button[disabled].info:focus,
.button[disabled].info:hover,
button.disabled.info:focus,
button.disabled.info:hover,
button[disabled].info:focus,
button[disabled].info:hover {
  color: #FFF;
  background-color: #a0d3e8
}

.f-dropdown,
.f-dropdown.drop-left,
.f-dropdown.drop-right,
.f-dropdown.drop-top {
  background: #FFF;
  font-size: .875rem
}

button::-moz-focus-inner {
  border: 0;
  padding: 0
}

@media (min-width:768px) {
  .button,
  button {
    display: inline-block
  }
}

.button-group {
  list-style: none;
  margin: 0;
  left: 0
}

.button-group:after,
.button-group:before {
  content: " ";
  display: table
}

.button-group.even-2 li,
.button-group.even-3 li,
.button-group.even-4 li,
.button-group.even-5 li,
.button-group.even-6 li,
.button-group.even-7 li,
.button-group.even-8 li,
.button-group>li {
  display: inline-block;
  margin: 0 -2px
}

.button-group.even-2 li {
  width: 50%
}

.button-group.even-2 li .button,
.button-group.even-2 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-2 li:first-child .button,
.button-group.even-2 li:first-child button {
  border-left: 0
}

.button-group.even-2 li .button,
.button-group.even-2 li button {
  width: 100%
}

.button-group.even-3 li {
  width: 33.3333333333%
}

.button-group.even-3 li .button,
.button-group.even-3 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-3 li:first-child .button,
.button-group.even-3 li:first-child button {
  border-left: 0
}

.button-group.even-3 li .button,
.button-group.even-3 li button {
  width: 100%
}

.button-group.even-4 li {
  width: 25%
}

.button-group.even-4 li .button,
.button-group.even-4 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-4 li:first-child .button,
.button-group.even-4 li:first-child button {
  border-left: 0
}

.button-group.even-4 li .button,
.button-group.even-4 li button {
  width: 100%
}

.button-group.even-5 li {
  width: 20%
}

.button-group.even-5 li .button,
.button-group.even-5 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-5 li:first-child .button,
.button-group.even-5 li:first-child button {
  border-left: 0
}

.button-group.even-5 li .button,
.button-group.even-5 li button {
  width: 100%
}

.button-group.even-6 li {
  width: 16.6666666667%
}

.button-group.even-6 li .button,
.button-group.even-6 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-6 li:first-child .button,
.button-group.even-6 li:first-child button {
  border-left: 0
}

.button-group.even-6 li .button,
.button-group.even-6 li button {
  width: 100%
}

.button-group.even-7 li {
  width: 14.2857142857%
}

.button-group.even-7 li .button,
.button-group.even-7 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-7 li:first-child .button,
.button-group.even-7 li:first-child button {
  border-left: 0
}

.button-group.even-7 li .button,
.button-group.even-7 li button {
  width: 100%
}

.button-group.even-8 li {
  width: 12.5%
}

.button-group.even-8 li .button,
.button-group.even-8 li button,
.button-group.radius.stack>*>button,
.button-group.round.stack>*>button,
.button-group.stack>li>button {
  width: 100%
}

.button-group.even-8 li .button,
.button-group.even-8 li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.even-8 li:first-child .button,
.button-group.even-8 li:first-child button {
  border-left: 0
}

.button-group>li .button,
.button-group>li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group>li:first-child .button,
.button-group>li:first-child button {
  border-left: 0
}

.button-group.stack>li {
  display: block;
  margin: 0;
  float: none
}

.button-group.stack>li .button,
.button-group.stack>li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.stack>li:first-child .button,
.button-group.stack>li:first-child button {
  border-left: 0
}

.button-group.stack>li .button,
.button-group.stack>li>button {
  border-left-width: 0;
  border-top: 1px solid;
  display: block;
  margin: 0
}

.button-group.stack>li:first-child .button,
.button-group.stack>li:first-child button {
  border-top: 0
}

.button-group.stack-for-small>li {
  display: inline-block;
  margin: 0 -2px
}

.button-group.stack-for-small>li .button,
.button-group.stack-for-small>li>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.stack-for-small>li:first-child .button,
.button-group.stack-for-small>li:first-child button {
  border-left: 0
}

@media (max-width:767px) {
  .button-group.stack-for-small>li {
    display: block;
    margin: 0
  }
  .button-group.stack-for-small>li .button,
  .button-group.stack-for-small>li>button {
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, .5)
  }
  .button-group.stack-for-small>li:first-child .button,
  .button-group.stack-for-small>li:first-child button {
    border-left: 0
  }
  .button-group.stack-for-small>li .button,
  .button-group.stack-for-small>li>button {
    border-left-width: 0;
    border-top: 1px solid;
    display: block;
    margin: 0
  }
  .button-group.stack-for-small>li>button {
    width: 100%
  }
  .button-group.stack-for-small>li:first-child .button,
  .button-group.stack-for-small>li:first-child button {
    border-top: 0
  }
}

.button-group.radius>* {
  display: inline-block;
  margin: 0 -2px
}

.button-group.radius>* .button,
.button-group.radius>*>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.radius>:first-child .button,
.button-group.radius>:first-child button {
  border-left: 0
}

.button-group.radius>*,
.button-group.radius>*>.button,
.button-group.radius>*>a,
.button-group.radius>*>button {
  border-radius: 0
}

.button-group.radius>:first-child,
.button-group.radius>:first-child>.button,
.button-group.radius>:first-child>a,
.button-group.radius>:first-child>button {
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px
}

.button-group.radius>:last-child,
.button-group.radius>:last-child>.button,
.button-group.radius>:last-child>a,
.button-group.radius>:last-child>button {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px
}

.button-group.radius.stack>* {
  display: block;
  margin: 0
}

.button-group.radius.stack>* .button,
.button-group.radius.stack>*>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.radius.stack>:first-child .button,
.button-group.radius.stack>:first-child button {
  border-left: 0
}

.button-group.radius.stack>* .button,
.button-group.radius.stack>*>button {
  border-left-width: 0;
  border-top: 1px solid;
  display: block;
  margin: 0
}

.button-group.radius.stack>:first-child .button,
.button-group.radius.stack>:first-child button {
  border-top: 0
}

.button-group.radius.stack>*,
.button-group.radius.stack>*>.button,
.button-group.radius.stack>*>a,
.button-group.radius.stack>*>button {
  border-radius: 0
}

.button-group.radius.stack>:first-child,
.button-group.radius.stack>:first-child>.button,
.button-group.radius.stack>:first-child>a,
.button-group.radius.stack>:first-child>button {
  -webkit-top-left-radius: 3px;
  -webkit-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px
}

.button-group.radius.stack>:last-child,
.button-group.radius.stack>:last-child>.button,
.button-group.radius.stack>:last-child>a,
.button-group.radius.stack>:last-child>button {
  -webkit-bottom-left-radius: 3px;
  -webkit-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px
}

@media (min-width:768px) {
  .button-group.radius.stack-for-small>* {
    display: inline-block;
    margin: 0 -2px
  }
  .button-group.radius.stack-for-small>* .button,
  .button-group.radius.stack-for-small>*>button {
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, .5)
  }
  .button-group.radius.stack-for-small>:first-child .button,
  .button-group.radius.stack-for-small>:first-child button {
    border-left: 0
  }
  .button-group.radius.stack-for-small>*,
  .button-group.radius.stack-for-small>*>.button,
  .button-group.radius.stack-for-small>*>a,
  .button-group.radius.stack-for-small>*>button {
    border-radius: 0
  }
  .button-group.radius.stack-for-small>:first-child,
  .button-group.radius.stack-for-small>:first-child>.button,
  .button-group.radius.stack-for-small>:first-child>a,
  .button-group.radius.stack-for-small>:first-child>button {
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
  }
  .button-group.radius.stack-for-small>:last-child,
  .button-group.radius.stack-for-small>:last-child>.button,
  .button-group.radius.stack-for-small>:last-child>a,
  .button-group.radius.stack-for-small>:last-child>button {
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
  }
}

@media (max-width:767px) {
  .button-group.radius.stack-for-small>* {
    display: block;
    margin: 0
  }
  .button-group.radius.stack-for-small>* .button,
  .button-group.radius.stack-for-small>*>button {
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, .5)
  }
  .button-group.radius.stack-for-small>:first-child .button,
  .button-group.radius.stack-for-small>:first-child button {
    border-left: 0
  }
  .button-group.radius.stack-for-small>* .button,
  .button-group.radius.stack-for-small>*>button {
    border-left-width: 0;
    border-top: 1px solid;
    display: block;
    margin: 0
  }
  .button-group.radius.stack-for-small>*>button {
    width: 100%
  }
  .button-group.radius.stack-for-small>:first-child .button,
  .button-group.radius.stack-for-small>:first-child button {
    border-top: 0
  }
  .button-group.radius.stack-for-small>*,
  .button-group.radius.stack-for-small>*>.button,
  .button-group.radius.stack-for-small>*>a,
  .button-group.radius.stack-for-small>*>button {
    border-radius: 0
  }
  .button-group.radius.stack-for-small>:first-child,
  .button-group.radius.stack-for-small>:first-child>.button,
  .button-group.radius.stack-for-small>:first-child>a,
  .button-group.radius.stack-for-small>:first-child>button {
    -webkit-top-left-radius: 3px;
    -webkit-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
  }
  .button-group.radius.stack-for-small>:last-child,
  .button-group.radius.stack-for-small>:last-child>.button,
  .button-group.radius.stack-for-small>:last-child>a,
  .button-group.radius.stack-for-small>:last-child>button {
    -webkit-bottom-left-radius: 3px;
    -webkit-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
  }
}

.button-group.round>* {
  display: inline-block;
  margin: 0 -2px
}

.button-group.round>* .button,
.button-group.round>*>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.round>:first-child .button,
.button-group.round>:first-child button {
  border-left: 0
}

.button-group.round>*,
.button-group.round>*>.button,
.button-group.round>*>a,
.button-group.round>*>button {
  border-radius: 0
}

.button-group.round>:first-child,
.button-group.round>:first-child>.button,
.button-group.round>:first-child>a,
.button-group.round>:first-child>button {
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px
}

.button-group.round>:last-child,
.button-group.round>:last-child>.button,
.button-group.round>:last-child>a,
.button-group.round>:last-child>button {
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px
}

.button-group.round.stack>* {
  display: block;
  margin: 0
}

.button-group.round.stack>* .button,
.button-group.round.stack>*>button {
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, .5)
}

.button-group.round.stack>:first-child .button,
.button-group.round.stack>:first-child button {
  border-left: 0
}

.button-group.round.stack>* .button,
.button-group.round.stack>*>button {
  border-left-width: 0;
  border-top: 1px solid;
  display: block;
  margin: 0
}

.button-group.round.stack>:first-child .button,
.button-group.round.stack>:first-child button {
  border-top: 0
}

.button-group.round.stack>*,
.button-group.round.stack>*>.button,
.button-group.round.stack>*>a,
.button-group.round.stack>*>button {
  border-radius: 0
}

.button-group.round.stack>:first-child,
.button-group.round.stack>:first-child>.button,
.button-group.round.stack>:first-child>a,
.button-group.round.stack>:first-child>button {
  -webkit-top-left-radius: 1rem;
  -webkit-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

.button-group.round.stack>:last-child,
.button-group.round.stack>:last-child>.button,
.button-group.round.stack>:last-child>a,
.button-group.round.stack>:last-child>button {
  -webkit-bottom-left-radius: 1rem;
  -webkit-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem
}

@media (min-width:768px) {
  .button-group.round.stack-for-small>* {
    display: inline-block;
    margin: 0 -2px
  }
  .button-group.round.stack-for-small>* .button,
  .button-group.round.stack-for-small>*>button {
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, .5)
  }
  .button-group.round.stack-for-small>:first-child .button,
  .button-group.round.stack-for-small>:first-child button {
    border-left: 0
  }
  .button-group.round.stack-for-small>*,
  .button-group.round.stack-for-small>*>.button,
  .button-group.round.stack-for-small>*>a,
  .button-group.round.stack-for-small>*>button {
    border-radius: 0
  }
  .button-group.round.stack-for-small>:first-child,
  .button-group.round.stack-for-small>:first-child>.button,
  .button-group.round.stack-for-small>:first-child>a,
  .button-group.round.stack-for-small>:first-child>button {
    -webkit-border-bottom-left-radius: 1000px;
    -webkit-border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px
  }
  .button-group.round.stack-for-small>:last-child,
  .button-group.round.stack-for-small>:last-child>.button,
  .button-group.round.stack-for-small>:last-child>a,
  .button-group.round.stack-for-small>:last-child>button {
    -webkit-border-bottom-right-radius: 1000px;
    -webkit-border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px
  }
}

.f-dropdown.content>:last-child,
.f-dropdown.drop-left>:last-child,
.f-dropdown.drop-right>:last-child,
.f-dropdown.drop-top>:last-child,
.f-dropdown>:last-child {
  margin-bottom: 0
}

@media (max-width:767px) {
  .button-group.round.stack-for-small>* {
    display: block;
    margin: 0
  }
  .button-group.round.stack-for-small>* .button,
  .button-group.round.stack-for-small>*>button {
    border-left: 1px solid;
    border-color: rgba(255, 255, 255, .5)
  }
  .button-group.round.stack-for-small>:first-child .button,
  .button-group.round.stack-for-small>:first-child button {
    border-left: 0
  }
  .button-group.round.stack-for-small>* .button,
  .button-group.round.stack-for-small>*>button {
    border-left-width: 0;
    border-top: 1px solid;
    display: block;
    margin: 0
  }
  .button-group.round.stack-for-small>*>button {
    width: 100%
  }
  .button-group.round.stack-for-small>:first-child .button,
  .button-group.round.stack-for-small>:first-child button {
    border-top: 0
  }
  .button-group.round.stack-for-small>*,
  .button-group.round.stack-for-small>*>.button,
  .button-group.round.stack-for-small>*>a,
  .button-group.round.stack-for-small>*>button {
    border-radius: 0
  }
  .button-group.round.stack-for-small>:first-child,
  .button-group.round.stack-for-small>:first-child>.button,
  .button-group.round.stack-for-small>:first-child>a,
  .button-group.round.stack-for-small>:first-child>button {
    -webkit-top-left-radius: 1rem;
    -webkit-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }
  .button-group.round.stack-for-small>:last-child,
  .button-group.round.stack-for-small>:last-child>.button,
  .button-group.round.stack-for-small>:last-child>a,
  .button-group.round.stack-for-small>:last-child>button {
    -webkit-bottom-left-radius: 1rem;
    -webkit-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem
  }
}

.button-bar:after,
.button-bar:before {
  content: " ";
  display: table
}

.button-bar .button-group {
  float: left;
  margin-right: .625rem
}

.f-dropdown {
  display: none;
  left: -9999px;
  margin-left: 0;
  position: absolute;
  border: 1px solid #ccc;
  height: auto;
  width: 100%;
  z-index: 89;
  margin-top: 2px
}

.f-dropdown:after,
.f-dropdown:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute
}

.f-dropdown.drop-left,
.f-dropdown.drop-left>:first-child,
.f-dropdown.drop-right>:first-child,
.f-dropdown>:first-child {
  margin-top: 0
}

.f-dropdown.open {
  display: block
}

.f-dropdown:before {
  border: 6px inset;
  border-color: transparent transparent #FFF;
  border-bottom-style: solid;
  top: -12px;
  left: 10px;
  z-index: 89
}

.f-dropdown:after {
  border: 7px inset;
  border-color: transparent transparent #ccc;
  border-bottom-style: solid;
  top: -14px;
  left: 9px;
  z-index: 88
}

.f-dropdown.right:before {
  left: auto;
  right: 10px
}

.f-dropdown.right:after {
  left: auto;
  right: 9px
}

.f-dropdown.drop-right {
  display: none;
  left: -9999px;
  position: absolute;
  border: 1px solid #ccc;
  height: auto;
  width: 100%;
  z-index: 89;
  margin-top: 0;
  margin-left: 2px
}

.f-dropdown.drop-right:after,
.f-dropdown.drop-right:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute
}

.f-dropdown.drop-right.open {
  display: block
}

.f-dropdown.drop-right:before {
  border: 6px inset;
  border-color: transparent #FFF transparent transparent;
  border-right-style: solid;
  top: 10px;
  left: -12px;
  z-index: 89
}

.f-dropdown.drop-right:after {
  border: 7px inset;
  border-color: transparent #ccc transparent transparent;
  border-right-style: solid;
  top: 9px;
  left: -14px;
  z-index: 88
}

.f-dropdown.drop-left {
  display: none;
  left: -9999px;
  list-style: none;
  position: absolute;
  border: 1px solid #ccc;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-left: -2px;
  max-width: 200px
}

.f-dropdown.drop-left:after,
.f-dropdown.drop-left:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  left: auto;
  position: absolute
}

.f-dropdown.content,
.f-dropdown.drop-top {
  margin-left: 0;
  max-height: none;
  max-width: 200px;
  list-style: none
}

.f-dropdown.drop-left.open {
  display: block
}

.f-dropdown.drop-left:before {
  border: 6px inset;
  border-color: transparent transparent transparent #FFF;
  border-left-style: solid;
  top: 10px;
  right: -12px;
  z-index: 89
}

.f-dropdown.drop-left:after {
  border: 7px inset;
  border-color: transparent transparent transparent #ccc;
  border-left-style: solid;
  top: 9px;
  right: -14px;
  z-index: 88
}

.f-dropdown.drop-top {
  display: none;
  left: -9999px;
  position: absolute;
  border: 1px solid #ccc;
  height: auto;
  width: 100%;
  z-index: 89;
  margin-top: -2px
}

.f-dropdown.drop-top:after,
.f-dropdown.drop-top:before {
  height: 0;
  width: 0;
  top: auto;
  right: auto;
  display: block;
  position: absolute;
  content: ""
}

.f-dropdown.content>:first-child,
.f-dropdown.drop-top>:first-child {
  margin-top: 0
}

.f-dropdown.drop-top.open {
  display: block
}

.f-dropdown.drop-top:before {
  border: 6px inset;
  border-color: #FFF transparent transparent;
  border-top-style: solid;
  bottom: -12px;
  left: 10px;
  z-index: 89
}

.f-dropdown.drop-top:after {
  border: 7px inset;
  border-color: #ccc transparent transparent;
  border-top-style: solid;
  bottom: -14px;
  left: 9px;
  z-index: 88
}

select[multiple],
textarea[rows] {
  height: auto
}

.f-dropdown li {
  cursor: pointer;
  font-size: .875rem;
  line-height: 1.125rem;
  margin: 0
}

.f-dropdown li:focus,
.f-dropdown li:hover {
  background: #EEE
}

.f-dropdown li.radius {
  border-radius: 3px
}

.f-dropdown li a {
  display: block;
  padding: .5rem;
  color: #555
}

.f-dropdown.content {
  display: none;
  left: -9999px;
  position: absolute;
  background: #FFF;
  border: 1px solid #ccc;
  font-size: .875rem;
  height: auto;
  padding: 1.25rem;
  width: 100%;
  z-index: 89
}

.f-dropdown.content.open {
  display: block
}

.f-dropdown.tiny {
  max-width: 200px
}

.f-dropdown.small {
  max-width: 300px
}

.f-dropdown.medium {
  max-width: 500px
}

.f-dropdown.large {
  max-width: 800px
}

.f-dropdown.mega {
  width: 100%!important;
  max-width: 100%!important
}

.f-dropdown.mega.open {
  left: 0!important
}

.dropdown.button,
button.dropdown {
  position: relative;
  padding-right: 3.5625rem
}

.dropdown.button::after,
button.dropdown::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  border-width: .375rem;
  right: 1.40625rem;
  margin-top: -.15625rem;
  border-color: #FFF transparent transparent
}

.postfix,
.prefix,
select {
  font-size: .875rem;
  height: 2.3125rem
}

.label,
.postfix,
.prefix,
.slideshow-wrapper {
  position: relative
}

.dropdown.button.tiny,
button.dropdown.tiny {
  padding-right: 2.625rem
}

.dropdown.button.tiny:after,
button.dropdown.tiny:after {
  border-width: .375rem;
  right: 1.125rem;
  margin-top: -.125rem
}

.dropdown.button.tiny::after,
button.dropdown.tiny::after {
  border-color: #FFF transparent transparent
}

.dropdown.button.small,
button.dropdown.small {
  padding-right: 3.0625rem
}

.dropdown.button.small::after,
button.dropdown.small::after {
  border-width: .4375rem;
  right: 1.3125rem;
  margin-top: -.15625rem;
  border-color: #FFF transparent transparent
}

.dropdown.button.large,
button.dropdown.large {
  padding-right: 3.625rem
}

.dropdown.button.large::after,
button.dropdown.large::after {
  border-width: .3125rem;
  right: 1.71875rem;
  margin-top: -.15625rem;
  border-color: #FFF transparent transparent
}

.dropdown.button.secondary:after,
button.dropdown.secondary:after {
  border-color: #333 transparent transparent
}

form .row .row {
  margin: 0 -.5rem
}

form .row .row .column,
form .row .row .columns {
  padding: 0 .5rem
}

form .row .row.collapse {
  margin: 0
}

input[type=file],
input[type=checkbox],
input[type=radio],
label.inline,
select {
  margin: 0 0 1rem
}

form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0
}

form .row .row.collapse input {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: .5rem
}

label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: .875rem;
  line-height: 1.5;
  margin-bottom: 0
}

label.right {
  float: none!important;
  text-align: right
}

.label,
.orbit-bullets,
.orbit-bullets-container,
.pagination-centered,
.postfix,
.prefix,
.prefix.button {
  text-align: center
}

label.inline {
  padding: .5625rem 0
}

label small {
  text-transform: capitalize;
  color: #676767
}

.postfix,
.prefix {
  border-style: solid;
  border-width: 1px;
  display: block;
  line-height: 2.3125rem;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  z-index: 2
}

.postfix.button {
  border-color: true
}

.prefix.button {
  border: none;
  padding: 0
}

.prefix.button.radius {
  border-radius: 3px 0 0 3px;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px
}

.postfix.button.radius {
  border-radius: 0 3px 3px 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px
}

.prefix.button.round {
  border-radius: 1000px 0 0 1000px;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px
}

.postfix.button.round {
  border-radius: 0 1000px 1000px 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px
}

label.prefix,
span.prefix {
  background: #f2f2f2;
  border-right: none;
  color: #333;
  border-color: #ccc
}

label.postfix,
span.postfix {
  background: #f2f2f2;
  color: #333;
  border-color: #ccc
}

fieldset[disabled] input[type=text],
fieldset[disabled] input[type=password],
fieldset[disabled] input[type=date],
fieldset[disabled] input[type=datetime],
fieldset[disabled] input[type=datetime-local],
fieldset[disabled] input[type=month],
fieldset[disabled] input[type=week],
fieldset[disabled] input[type=email],
fieldset[disabled] input[type=number],
fieldset[disabled] input[type=search],
fieldset[disabled] input[type=tel],
fieldset[disabled] input[type=time],
fieldset[disabled] input[type=url],
fieldset[disabled] input[type=color],
fieldset[disabled] textarea,
input[type=text]:disabled,
input[type=text][disabled],
input[type=text][readonly],
input[type=password]:disabled,
input[type=password][disabled],
input[type=password][readonly],
input[type=date]:disabled,
input[type=date][disabled],
input[type=date][readonly],
input[type=datetime]:disabled,
input[type=datetime][disabled],
input[type=datetime][readonly],
input[type=datetime-local]:disabled,
input[type=datetime-local][disabled],
input[type=datetime-local][readonly],
input[type=month]:disabled,
input[type=month][disabled],
input[type=month][readonly],
input[type=week]:disabled,
input[type=week][disabled],
input[type=week][readonly],
input[type=email]:disabled,
input[type=email][disabled],
input[type=email][readonly],
input[type=number]:disabled,
input[type=number][disabled],
input[type=number][readonly],
input[type=search]:disabled,
input[type=search][disabled],
input[type=search][readonly],
input[type=tel]:disabled,
input[type=tel][disabled],
input[type=tel][readonly],
input[type=time]:disabled,
input[type=time][disabled],
input[type=time][readonly],
input[type=url]:disabled,
input[type=url][disabled],
input[type=url][readonly],
input[type=color]:disabled,
input[type=color][disabled],
input[type=color][readonly],
select:disabled,
textarea:disabled,
textarea[disabled],
textarea[readonly] {
  background-color: #DDD;
  cursor: default
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=color],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #FFF;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .75);
  display: block;
  font-family: inherit;
  font-size: .875rem;
  height: 2.25rem;
  margin: 0 0 1rem;
  padding: .5rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color .15s linear, background .15s linear
}

input[type=text]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=color]:focus,
textarea:focus {
  background: #fafafa;
  border-color: #999;
  outline: 0
}

input[type=text].radius,
input[type=password].radius,
input[type=date].radius,
input[type=datetime].radius,
input[type=datetime-local].radius,
input[type=month].radius,
input[type=week].radius,
input[type=email].radius,
input[type=number].radius,
input[type=search].radius,
input[type=tel].radius,
input[type=time].radius,
input[type=url].radius,
input[type=color].radius,
textarea.radius {
  border-radius: 3px
}

form .row .prefix-radius.row.collapse button,
form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse select,
form .row .prefix-radius.row.collapse textarea {
  border-radius: 0 3px 3px 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px
}

form .row .postfix-radius.row.collapse button,
form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse select,
form .row .postfix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse .prefix {
  border-radius: 3px 0 0 3px;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px
}

form .row .postfix-radius.row.collapse .postfix {
  border-radius: 0 3px 3px 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px
}

form .row .prefix-round.row.collapse button,
form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse select,
form .row .prefix-round.row.collapse textarea {
  border-radius: 0 1000px 1000px 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px
}

form .row .postfix-round.row.collapse button,
form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse select,
form .row .postfix-round.row.collapse textarea,
form .row .prefix-round.row.collapse .prefix {
  border-radius: 1000px 0 0 1000px;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px
}

form .row .postfix-round.row.collapse .postfix {
  border-radius: 0 1000px 1000px 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px
}

input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0
}

textarea {
  min-height: 50px;
  max-width: 100%
}

::-webkit-input-placeholder {
  color: #ccc
}

:-moz-placeholder {
  color: #ccc
}

::-moz-placeholder {
  color: #ccc
}

:-ms-input-placeholder {
  color: #ccc
}

select {
  -webkit-appearance: none!important;
  -moz-appearance: none!important;
  background-color: #FAFAFA;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  color: rgba(0, 0, 0, .75);
  font-family: inherit;
  line-height: normal;
  padding: .5rem;
  border-radius: 0
}

select::-ms-expand {
  display: none
}

select.radius {
  border-radius: 3px
}

select:hover {
  background-color: #f3f3f3;
  border-color: #999
}

input[type=checkbox]+label,
input[type=radio]+label {
  display: inline-block;
  margin-left: .5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline
}

input[type=file] {
  width: 100%
}

fieldset {
  border: 1px solid #DDD;
  margin: 1.125rem 0;
  padding: 1.25rem
}

fieldset legend {
  background: #FFF;
  font-weight: 700;
  margin: 0;
  padding: 0 .1875rem
}

[data-abide] .error small.error,
[data-abide] .error span.error,
[data-abide] small.error,
[data-abide] span.error {
  display: block;
  font-size: .75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: .375rem .5625rem .5625rem;
  background: #f04124;
  color: #FFF
}

[data-abide] small.error,
[data-abide] span.error {
  display: none
}

.error small.error,
small.error,
span.error {
  display: block;
  font-size: .75rem;
  font-style: italic;
  margin-top: -1px;
  padding: .375rem .5625rem .5625rem;
  background: #f04124;
  font-weight: 400
}

small.error,
span.error {
  margin-bottom: 1rem;
  color: #FFF
}

.error input,
.error select,
.error textarea {
  margin-bottom: 0
}

.error input[type=checkbox],
.error input[type=radio],
.error small.error {
  margin-bottom: 1rem
}

.error label,
.error label.error {
  color: #f04124
}

.error small.error {
  color: #FFF
}

.error>label>small {
  background: 0 0;
  color: #676767;
  display: inline;
  font-size: 60%;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize
}

.error span.error-message {
  display: block
}

input.error,
select.error,
textarea.error {
  margin-bottom: 0
}

label.error {
  color: #f04124
}

.label {
  display: inline-block;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-bottom: auto;
  white-space: nowrap;
  padding: .25rem .5rem;
  font-size: .6875rem;
  background-color: #008CBA;
  color: #FFF
}

.label.radius {
  border-radius: 3px
}

.label.round {
  border-radius: 1000px
}

.label.alert {
  background-color: #f04124;
  color: #FFF
}

.label.warning {
  background-color: #f08a24;
  color: #FFF
}

.label.success {
  background-color: #43AC6A;
  color: #FFF
}

.label.secondary {
  background-color: #e7e7e7;
  color: #333
}

.label.info {
  background-color: #a0d3e8;
  color: #333
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.slideshow-wrapper ul {
  list-style-type: none;
  margin: 0
}

.slideshow-wrapper ul li,
.slideshow-wrapper ul li .orbit-caption {
  display: none
}

.slideshow-wrapper .orbit-container li,
.slideshow-wrapper .orbit-container li .orbit-caption,
.slideshow-wrapper ul li:first-child {
  display: block
}

.slideshow-wrapper .orbit-container {
  background-color: transparent
}

.slideshow-wrapper .orbit-container .orbit-bullets li {
  display: inline-block
}

.slideshow-wrapper .preloader {
  border-radius: 1000px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  animation-timing-function: linear;
  border: 3px solid;
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px
}

.orbit-container {
  background: 0 0;
  overflow: hidden;
  position: relative;
  width: 100%
}

.orbit-container .orbit-slides-container {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.orbit-container .orbit-slides-container img {
  display: block;
  max-width: 100%
}

.orbit-container .orbit-slides-container>* {
  position: absolute;
  top: 0;
  width: 100%;
  margin-left: 100%
}

.orbit-container .orbit-slides-container>:first-child {
  margin-left: 0
}

.orbit-container .orbit-slides-container>* .orbit-caption {
  bottom: 0;
  position: absolute;
  background-color: rgba(51, 51, 51, .8);
  color: #FFF;
  font-size: .875rem;
  padding: .625rem .875rem;
  width: 100%
}

.orbit-container .orbit-slide-number {
  left: 10px;
  background: 0 0;
  color: #FFF;
  font-size: 12px;
  position: absolute;
  top: 10px;
  z-index: 10
}

.orbit-container .orbit-slide-number span {
  font-weight: 700;
  padding: .3125rem
}

.orbit-container .orbit-timer {
  position: absolute;
  top: 12px;
  right: 10px;
  height: 6px;
  width: 100px;
  z-index: 10
}

.orbit-container .orbit-timer .orbit-progress {
  height: 3px;
  background-color: rgba(255, 255, 255, .3);
  display: block;
  width: 0;
  position: relative;
  right: 20px;
  top: 5px
}

.orbit-container .orbit-timer>span {
  border: 4px solid #FFF;
  border-bottom: none;
  border-top: none;
  display: none;
  height: 14px;
  position: absolute;
  top: 0;
  width: 11px;
  right: 0
}

.orbit-container .orbit-timer.paused>span {
  top: 0;
  width: 11px;
  height: 14px;
  border: 8px inset;
  border-left-style: solid;
  border-color: transparent transparent transparent #FFF;
  right: -4px
}

.orbit-container .orbit-timer.paused>span.dark {
  border-left-color: #333
}

.orbit-container:hover .orbit-timer>span {
  display: block
}

.orbit-container .orbit-next,
.orbit-container .orbit-prev {
  background-color: transparent;
  color: #fff;
  height: 60px;
  line-height: 50px;
  margin-top: -25px;
  position: absolute;
  text-indent: -9999px!important;
  width: 36px;
  z-index: 10
}

.orbit-container .orbit-next:hover,
.orbit-container .orbit-prev:hover {
  background-color: rgba(0, 0, 0, .3)
}

.orbit-container .orbit-next>span,
.orbit-container .orbit-prev>span {
  border: 10px inset;
  display: block;
  height: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 0
}

.orbit-container .orbit-prev>span {
  border-right-style: solid;
  border-color: transparent #FFF transparent transparent
}

.orbit-container .orbit-prev:hover>span {
  border-right-color: #FFF
}

.orbit-container .orbit-next>span {
  border-color: transparent transparent transparent #FFF;
  border-left-style: solid;
  left: 50%;
  margin-left: -4px
}

.orbit-container .orbit-next:hover>span {
  border-left-color: #FFF
}

.panel,
.panel.callout {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  color: #333
}

.orbit-bullets {
  display: block;
  float: none;
  margin: 0 auto 30px;
  overflow: hidden;
  position: relative;
  top: 10px
}

.orbit-bullets li {
  background: #CCC;
  cursor: pointer;
  display: inline-block;
  float: none;
  height: .5625rem;
  margin-right: 6px;
  width: .5625rem;
  border-radius: 1000px
}

.orbit-bullets li.active {
  background: #999
}

.orbit-bullets li:last-child {
  margin-right: 0
}

.touch .orbit-bullets,
.touch .orbit-container .orbit-next,
.touch .orbit-container .orbit-prev {
  display: none
}

@media (min-width:768px) {
  .touch .orbit-container .orbit-next,
  .touch .orbit-container .orbit-prev {
    display: inherit
  }
  .touch .orbit-bullets {
    display: block
  }
}

@media (max-width:767px) {
  .orbit-stack-on-small .orbit-slides-container {
    height: auto!important
  }
  .orbit-stack-on-small .orbit-slides-container>* {
    margin: 0!important;
    opacity: 1!important;
    position: relative
  }
  .orbit-bullets,
  .orbit-next,
  .orbit-prev,
  .orbit-stack-on-small .orbit-slide-number,
  .orbit-timer {
    display: none
  }
}

.panel.callout>:first-child,
.panel>:first-child {
  margin-top: 0
}

ul.pagination {
  display: block;
  margin-left: -.3125rem;
  min-height: 1.5rem
}

ul.pagination li {
  font-size: .875rem;
  margin-left: .3125rem;
  display: block;
  float: left
}

ul.pagination li a,
ul.pagination li button {
  transition: background-color .3s ease-out;
  background: 0 0;
  display: block;
  padding: .0625rem .625rem
}

.switch label,
.switch label:after {
  transition: left .15s ease-out;
  display: block
}

ul.pagination li a:focus,
ul.pagination li button:focus,
ul.pagination li:hover a,
ul.pagination li:hover button {
  background: #e6e6e6
}

ul.pagination li.unavailable a,
ul.pagination li.unavailable button {
  cursor: default
}

ul.pagination li.unavailable a:focus,
ul.pagination li.unavailable button:focus,
ul.pagination li.unavailable:hover a,
ul.pagination li.unavailable:hover button {
  background: 0 0
}

ul.pagination li.current a,
ul.pagination li.current button {
  background: #008CBA;
  cursor: default
}

ul.pagination li.current a:focus,
ul.pagination li.current a:hover,
ul.pagination li.current button:focus,
ul.pagination li.current button:hover {
  background: #008CBA
}

.pagination-centered ul.pagination li {
  display: inline-block;
  float: none
}

.panel {
  margin-bottom: 1.25rem
}

.panel>:last-child {
  margin-bottom: 0
}

.panel dl,
.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6,
.panel li,
.panel p {
  color: #333
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6 {
  line-height: 1;
  margin-bottom: .625rem
}

.panel h1.subheader,
.panel h2.subheader,
.panel h3.subheader,
.panel h4.subheader,
.panel h5.subheader,
.panel h6.subheader {
  line-height: 1.4
}

.panel.callout {
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #ecfaff
}

.pricing-table .price,
.pricing-table .title {
  font-weight: 400;
  padding: .9375rem 1.25rem;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.panel.callout>:last-child {
  margin-bottom: 0
}

.panel.callout dl,
.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6,
.panel.callout li,
.panel.callout p {
  color: #333
}

.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6 {
  line-height: 1;
  margin-bottom: .625rem
}

.panel.callout h1.subheader,
.panel.callout h2.subheader,
.panel.callout h3.subheader,
.panel.callout h4.subheader,
.panel.callout h5.subheader,
.panel.callout h6.subheader {
  line-height: 1.4
}

.panel.callout a:not(.button) {
  color: #008CBA
}

.panel.callout a:not(.button):focus,
.panel.callout a:not(.button):hover {
  color: #0078a0
}

.panel.radius {
  border-radius: 3px
}

.pricing-table {
  border: 1px solid #DDD;
  margin-left: 0;
  margin-bottom: 1.25rem
}

.pricing-table * {
  list-style: none;
  line-height: 1
}

.pricing-table .title {
  background-color: #333;
  color: #EEE;
  font-size: 1rem;
  text-align: center
}

.pricing-table .price {
  background-color: #F6F6F6;
  color: #333;
  font-size: 2rem;
  text-align: center
}

.pricing-table .bullet-item,
.pricing-table .description {
  border-bottom: dotted 1px #DDD;
  padding: .9375rem;
  background-color: #FFF;
  text-align: center;
  font-weight: 400
}

.pricing-table .description {
  color: #777;
  font-size: .75rem;
  line-height: 1.4
}

.pricing-table .bullet-item {
  color: #333;
  font-size: .875rem
}

.pricing-table .cta-button {
  background-color: #FFF;
  padding: 1.25rem 1.25rem 0;
  text-align: center
}

.progress {
  background-color: #F6F6F6;
  border: 1px solid #fff;
  margin-bottom: .625rem;
  padding: .125rem
}

.progress .meter {
  background: #008CBA;
  display: block;
  height: 100%
}

.progress.secondary .meter {
  background: #e7e7e7;
  display: block;
  height: 100%
}

.progress.success .meter {
  background: #43AC6A;
  display: block;
  height: 100%
}

.progress.alert .meter {
  background: #f04124;
  display: block;
  height: 100%
}

.progress.radius {
  border-radius: 3px
}

.progress.radius .meter {
  border-radius: 2px
}

.progress.round {
  border-radius: 1000px
}

.progress.round .meter {
  border-radius: 999px
}

.range-slider,
.range-slider.vertical-range {
  border: 1px solid #DDD;
  margin: 1.25rem 0;
  position: relative;
  touch-action: none
}

.range-slider {
  display: block;
  width: 100%;
  background: #FAFAFA
}

.range-slider.vertical-range {
  display: inline-block;
  height: 12.5rem;
  width: 1rem
}

.range-slider.vertical-range .range-slider-handle {
  bottom: -10.5rem;
  margin-left: -.5rem;
  margin-top: 0;
  position: absolute
}

.range-slider.vertical-range .range-slider-active-segment {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: initial;
  bottom: 0;
  height: auto;
  width: .875rem
}

.range-slider.radius {
  background: #FAFAFA;
  border-radius: 3px
}

.range-slider.radius .range-slider-handle {
  background: #008CBA;
  border-radius: 3px
}

.range-slider.radius .range-slider-handle:hover {
  background: #007ba4
}

.range-slider.round {
  background: #FAFAFA;
  border-radius: 1000px
}

.range-slider.round .range-slider-handle {
  background: #008CBA
}

.range-slider.round .range-slider-handle:hover {
  background: #007ba4
}

.range-slider.disabled,
.range-slider[disabled] {
  background: #FAFAFA;
  cursor: not-allowed;
  opacity: .7
}

.range-slider.disabled .range-slider-handle:hover,
.range-slider[disabled] .range-slider-handle:hover {
  background: #007ba4
}

.range-slider-active-segment {
  background: #e5e5e5;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
  display: inline-block;
  height: .875rem;
  position: absolute
}

.range-slider-handle {
  border: 1px solid;
  cursor: pointer;
  display: inline-block;
  height: 1.375rem;
  position: absolute;
  top: -.3125rem;
  width: 2rem;
  z-index: 1;
  touch-action: manipulation;
  background: #008CBA
}

.reveal-modal,
.reveal-modal.radius {
  border-radius: 3px
}

.row .row.collapse:after,
.row .row.collapse:before,
.row .row:after,
.row .row:before,
.row:after,
.row:before {
  content: " ";
  display: table
}

.range-slider-handle:hover {
  background: #007ba4
}

.row.collapse>.column,
.row.collapse>.columns {
  padding-left: 0;
  padding-right: 0
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0
}

.row .row {
  margin: 0 -.9375rem;
  max-width: none;
  width: auto
}

.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto
}

.column,
.columns {
  padding-left: .9375rem;
  padding-right: .9375rem;
  width: 100%;
  float: left
}

.column+.column:last-child,
.column+.columns:last-child,
.columns+.column:last-child,
.columns+.columns:last-child {
  float: right
}

.column+.column.end,
.column+.columns.end,
.columns+.column.end,
.columns+.columns.end {
  float: left
}

@media only screen {
  .column,
  .columns,
  .small-pull-0,
  .small-pull-1,
  .small-pull-10,
  .small-pull-11,
  .small-pull-2,
  .small-pull-3,
  .small-pull-4,
  .small-pull-5,
  .small-pull-6,
  .small-pull-7,
  .small-pull-8,
  .small-pull-9,
  .small-push-0,
  .small-push-1,
  .small-push-10,
  .small-push-11,
  .small-push-2,
  .small-push-3,
  .small-push-4,
  .small-push-5,
  .small-push-7,
  .small-push-8,
  .small-push-9 {
    position: relative
  }
  .small-push-0 {
    left: 0;
    right: auto
  }
  .small-pull-0 {
    right: 0;
    left: auto
  }
  .small-push-1 {
    left: 8.3333333333%;
    right: auto
  }
  .small-pull-1 {
    right: 8.3333333333%;
    left: auto
  }
  .small-push-2 {
    left: 16.6666666667%;
    right: auto
  }
  .small-pull-2 {
    right: 16.6666666667%;
    left: auto
  }
  .small-push-3 {
    left: 25%;
    right: auto
  }
  .small-pull-3 {
    right: 25%;
    left: auto
  }
  .small-push-4 {
    left: 33.3333333333%;
    right: auto
  }
  .small-pull-4 {
    right: 33.3333333333%;
    left: auto
  }
  .small-push-5 {
    left: 41.6666666667%;
    right: auto
  }
  .small-pull-5 {
    right: 41.6666666667%;
    left: auto
  }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto
  }
  .small-pull-6 {
    right: 50%;
    left: auto
  }
  .small-push-7 {
    left: 58.3333333333%;
    right: auto
  }
  .small-pull-7 {
    right: 58.3333333333%;
    left: auto
  }
  .small-push-8 {
    left: 66.6666666667%;
    right: auto
  }
  .small-pull-8 {
    right: 66.6666666667%;
    left: auto
  }
  .small-push-9 {
    left: 75%;
    right: auto
  }
  .small-pull-9 {
    right: 75%;
    left: auto
  }
  .small-push-10 {
    left: 83.3333333333%;
    right: auto
  }
  .small-pull-10 {
    right: 83.3333333333%;
    left: auto
  }
  .small-push-11 {
    left: 91.6666666667%;
    right: auto
  }
  .small-pull-11 {
    right: 91.6666666667%;
    left: auto
  }
  .column,
  .columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
  .small-1 {
    width: 8.3333333333%
  }
  .small-2 {
    width: 16.6666666667%
  }
  .small-3 {
    width: 25%
  }
  .small-4 {
    width: 33.3333333333%
  }
  .small-5 {
    width: 41.6666666667%
  }
  .small-6 {
    width: 50%
  }
  .small-7 {
    width: 58.3333333333%
  }
  .small-8 {
    width: 66.6666666667%
  }
  .small-9 {
    width: 75%
  }
  .small-10 {
    width: 83.3333333333%
  }
  .small-11 {
    width: 91.6666666667%
  }
  .small-12 {
    width: 100%
  }
  .small-offset-0 {
    margin-left: 0!important
  }
  .small-offset-1 {
    margin-left: 8.3333333333%!important
  }
  .small-offset-2 {
    margin-left: 16.6666666667%!important
  }
  .small-offset-3 {
    margin-left: 25%!important
  }
  .small-offset-4 {
    margin-left: 33.3333333333%!important
  }
  .small-offset-5 {
    margin-left: 41.6666666667%!important
  }
  .small-offset-6 {
    margin-left: 50%!important
  }
  .small-offset-7 {
    margin-left: 58.3333333333%!important
  }
  .small-offset-8 {
    margin-left: 66.6666666667%!important
  }
  .small-offset-9 {
    margin-left: 75%!important
  }
  .small-offset-10 {
    margin-left: 83.3333333333%!important
  }
  .small-offset-11 {
    margin-left: 91.6666666667%!important
  }
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto
  }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none
  }
  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0
  }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none
  }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left
  }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right
  }
  .row.small-collapse>.column,
  .row.small-collapse>.columns {
    padding-left: 0;
    padding-right: 0
  }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0
  }
  .row.small-uncollapse>.column,
  .row.small-uncollapse>.columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
}

@media (min-width:768px) {
  .medium-pull-0,
  .medium-pull-1,
  .medium-pull-10,
  .medium-pull-11,
  .medium-pull-2,
  .medium-pull-3,
  .medium-pull-4,
  .medium-pull-5,
  .medium-pull-6,
  .medium-pull-7,
  .medium-pull-8,
  .medium-pull-9,
  .medium-push-0,
  .medium-push-1,
  .medium-push-10,
  .medium-push-11,
  .medium-push-2,
  .medium-push-3,
  .medium-push-4,
  .medium-push-5,
  .medium-push-6,
  .medium-push-7,
  .medium-push-8,
  .medium-push-9,
  .pull-0,
  .pull-1,
  .pull-10,
  .pull-11,
  .pull-2,
  .pull-3,
  .pull-4,
  .pull-5,
  .pull-6,
  .pull-7,
  .pull-8,
  .pull-9,
  .push-0,
  .push-1,
  .push-10,
  .push-11,
  .push-2,
  .push-3,
  .push-4,
  .push-5,
  .push-6,
  .push-7,
  .push-8,
  .push-9 {
    position: relative
  }
  .medium-push-0 {
    left: 0;
    right: auto
  }
  .medium-pull-0 {
    right: 0;
    left: auto
  }
  .medium-push-1 {
    left: 8.3333333333%;
    right: auto
  }
  .medium-pull-1 {
    right: 8.3333333333%;
    left: auto
  }
  .medium-push-2 {
    left: 16.6666666667%;
    right: auto
  }
  .medium-pull-2 {
    right: 16.6666666667%;
    left: auto
  }
  .medium-push-3 {
    left: 25%;
    right: auto
  }
  .medium-pull-3 {
    right: 25%;
    left: auto
  }
  .medium-push-4 {
    left: 33.3333333333%;
    right: auto
  }
  .medium-pull-4 {
    right: 33.3333333333%;
    left: auto
  }
  .medium-push-5 {
    left: 41.6666666667%;
    right: auto
  }
  .medium-pull-5 {
    right: 41.6666666667%;
    left: auto
  }
  .medium-push-6 {
    left: 50%;
    right: auto
  }
  .medium-pull-6 {
    right: 50%;
    left: auto
  }
  .medium-push-7 {
    left: 58.3333333333%;
    right: auto
  }
  .medium-pull-7 {
    right: 58.3333333333%;
    left: auto
  }
  .medium-push-8 {
    left: 66.6666666667%;
    right: auto
  }
  .medium-pull-8 {
    right: 66.6666666667%;
    left: auto
  }
  .medium-push-9 {
    left: 75%;
    right: auto
  }
  .medium-pull-9 {
    right: 75%;
    left: auto
  }
  .medium-push-10 {
    left: 83.3333333333%;
    right: auto
  }
  .medium-pull-10 {
    right: 83.3333333333%;
    left: auto
  }
  .medium-push-11 {
    left: 91.6666666667%;
    right: auto
  }
  .medium-pull-11 {
    right: 91.6666666667%;
    left: auto
  }
  .column,
  .columns {
    position: relative;
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
  .medium-1 {
    width: 8.3333333333%
  }
  .medium-2 {
    width: 16.6666666667%
  }
  .medium-3 {
    width: 25%
  }
  .medium-4 {
    width: 33.3333333333%
  }
  .medium-5 {
    width: 41.6666666667%
  }
  .medium-6 {
    width: 50%
  }
  .medium-7 {
    width: 58.3333333333%
  }
  .medium-8 {
    width: 66.6666666667%
  }
  .medium-9 {
    width: 75%
  }
  .medium-10 {
    width: 83.3333333333%
  }
  .medium-11 {
    width: 91.6666666667%
  }
  .medium-12 {
    width: 100%
  }
  .medium-offset-0 {
    margin-left: 0!important
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%!important
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%!important
  }
  .medium-offset-3 {
    margin-left: 25%!important
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%!important
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%!important
  }
  .medium-offset-6 {
    margin-left: 50%!important
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%!important
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%!important
  }
  .medium-offset-9 {
    margin-left: 75%!important
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%!important
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%!important
  }
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto
  }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none
  }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0
  }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none
  }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left
  }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right
  }
  .row.medium-collapse>.column,
  .row.medium-collapse>.columns {
    padding-left: 0;
    padding-right: 0
  }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0
  }
  .row.medium-uncollapse>.column,
  .row.medium-uncollapse>.columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
  .push-0 {
    left: 0;
    right: auto
  }
  .pull-0 {
    right: 0;
    left: auto
  }
  .push-1 {
    left: 8.3333333333%;
    right: auto
  }
  .pull-1 {
    right: 8.3333333333%;
    left: auto
  }
  .push-2 {
    left: 16.6666666667%;
    right: auto
  }
  .pull-2 {
    right: 16.6666666667%;
    left: auto
  }
  .push-3 {
    left: 25%;
    right: auto
  }
  .pull-3 {
    right: 25%;
    left: auto
  }
  .push-4 {
    left: 33.3333333333%;
    right: auto
  }
  .pull-4 {
    right: 33.3333333333%;
    left: auto
  }
  .push-5 {
    left: 41.6666666667%;
    right: auto
  }
  .pull-5 {
    right: 41.6666666667%;
    left: auto
  }
  .push-6 {
    left: 50%;
    right: auto
  }
  .pull-6 {
    right: 50%;
    left: auto
  }
  .push-7 {
    left: 58.3333333333%;
    right: auto
  }
  .pull-7 {
    right: 58.3333333333%;
    left: auto
  }
  .push-8 {
    left: 66.6666666667%;
    right: auto
  }
  .pull-8 {
    right: 66.6666666667%;
    left: auto
  }
  .push-9 {
    left: 75%;
    right: auto
  }
  .pull-9 {
    right: 75%;
    left: auto
  }
  .push-10 {
    left: 83.3333333333%;
    right: auto
  }
  .pull-10 {
    right: 83.3333333333%;
    left: auto
  }
  .push-11 {
    left: 91.6666666667%;
    right: auto
  }
  .pull-11 {
    right: 91.6666666667%;
    left: auto
  }
}

@media (min-width:992px) {
  .large-pull-0,
  .large-pull-1,
  .large-pull-10,
  .large-pull-11,
  .large-pull-2,
  .large-pull-3,
  .large-pull-4,
  .large-pull-5,
  .large-pull-6,
  .large-pull-7,
  .large-pull-8,
  .large-pull-9,
  .large-push-0,
  .large-push-1,
  .large-push-10,
  .large-push-11,
  .large-push-2,
  .large-push-3,
  .large-push-4,
  .large-push-5,
  .large-push-6,
  .large-push-7,
  .large-push-8,
  .large-push-9,
  .pull-0,
  .pull-1,
  .pull-10,
  .pull-11,
  .pull-2,
  .pull-3,
  .pull-4,
  .pull-5,
  .pull-6,
  .pull-7,
  .pull-8,
  .pull-9,
  .push-0,
  .push-1,
  .push-10,
  .push-11,
  .push-2,
  .push-3,
  .push-4,
  .push-5,
  .push-6,
  .push-7,
  .push-8,
  .push-9 {
    position: relative
  }
  .large-push-0 {
    left: 0;
    right: auto
  }
  .large-pull-0 {
    right: 0;
    left: auto
  }
  .large-push-1 {
    left: 8.3333333333%;
    right: auto
  }
  .large-pull-1 {
    right: 8.3333333333%;
    left: auto
  }
  .large-push-2 {
    left: 16.6666666667%;
    right: auto
  }
  .large-pull-2 {
    right: 16.6666666667%;
    left: auto
  }
  .large-push-3 {
    left: 25%;
    right: auto
  }
  .large-pull-3 {
    right: 25%;
    left: auto
  }
  .large-push-4 {
    left: 33.3333333333%;
    right: auto
  }
  .large-pull-4 {
    right: 33.3333333333%;
    left: auto
  }
  .large-push-5 {
    left: 41.6666666667%;
    right: auto
  }
  .large-pull-5 {
    right: 41.6666666667%;
    left: auto
  }
  .large-push-6 {
    left: 50%;
    right: auto
  }
  .large-pull-6 {
    right: 50%;
    left: auto
  }
  .large-push-7 {
    left: 58.3333333333%;
    right: auto
  }
  .large-pull-7 {
    right: 58.3333333333%;
    left: auto
  }
  .large-push-8 {
    left: 66.6666666667%;
    right: auto
  }
  .large-pull-8 {
    right: 66.6666666667%;
    left: auto
  }
  .large-push-9 {
    left: 75%;
    right: auto
  }
  .large-pull-9 {
    right: 75%;
    left: auto
  }
  .large-push-10 {
    left: 83.3333333333%;
    right: auto
  }
  .large-pull-10 {
    right: 83.3333333333%;
    left: auto
  }
  .large-push-11 {
    left: 91.6666666667%;
    right: auto
  }
  .large-pull-11 {
    right: 91.6666666667%;
    left: auto
  }
  .column,
  .columns {
    position: relative;
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
  .large-1 {
    width: 8.3333333333%
  }
  .large-2 {
    width: 16.6666666667%
  }
  .large-3 {
    width: 25%
  }
  .large-4 {
    width: 33.3333333333%
  }
  .large-5 {
    width: 41.6666666667%
  }
  .large-6 {
    width: 50%
  }
  .large-7 {
    width: 58.3333333333%
  }
  .large-8 {
    width: 66.6666666667%
  }
  .large-9 {
    width: 75%
  }
  .large-10 {
    width: 83.3333333333%
  }
  .large-11 {
    width: 91.6666666667%
  }
  .large-12 {
    width: 100%
  }
  .large-offset-0 {
    margin-left: 0!important
  }
  .large-offset-1 {
    margin-left: 8.3333333333%!important
  }
  .large-offset-2 {
    margin-left: 16.6666666667%!important
  }
  .large-offset-3 {
    margin-left: 25%!important
  }
  .large-offset-4 {
    margin-left: 33.3333333333%!important
  }
  .large-offset-5 {
    margin-left: 41.6666666667%!important
  }
  .large-offset-6 {
    margin-left: 50%!important
  }
  .large-offset-7 {
    margin-left: 58.3333333333%!important
  }
  .large-offset-8 {
    margin-left: 66.6666666667%!important
  }
  .large-offset-9 {
    margin-left: 75%!important
  }
  .large-offset-10 {
    margin-left: 83.3333333333%!important
  }
  .large-offset-11 {
    margin-left: 91.6666666667%!important
  }
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto
  }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none
  }
  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0
  }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none
  }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left
  }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right
  }
  .row.large-collapse>.column,
  .row.large-collapse>.columns {
    padding-left: 0;
    padding-right: 0
  }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0
  }
  .row.large-uncollapse>.column,
  .row.large-uncollapse>.columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
    float: left
  }
  .push-0 {
    left: 0;
    right: auto
  }
  .pull-0 {
    right: 0;
    left: auto
  }
  .push-1 {
    left: 8.3333333333%;
    right: auto
  }
  .pull-1 {
    right: 8.3333333333%;
    left: auto
  }
  .push-2 {
    left: 16.6666666667%;
    right: auto
  }
  .pull-2 {
    right: 16.6666666667%;
    left: auto
  }
  .push-3 {
    left: 25%;
    right: auto
  }
  .pull-3 {
    right: 25%;
    left: auto
  }
  .push-4 {
    left: 33.3333333333%;
    right: auto
  }
  .pull-4 {
    right: 33.3333333333%;
    left: auto
  }
  .push-5 {
    left: 41.6666666667%;
    right: auto
  }
  .pull-5 {
    right: 41.6666666667%;
    left: auto
  }
  .push-6 {
    left: 50%;
    right: auto
  }
  .pull-6 {
    right: 50%;
    left: auto
  }
  .push-7 {
    left: 58.3333333333%;
    right: auto
  }
  .pull-7 {
    right: 58.3333333333%;
    left: auto
  }
  .push-8 {
    left: 66.6666666667%;
    right: auto
  }
  .pull-8 {
    right: 66.6666666667%;
    left: auto
  }
  .push-9 {
    left: 75%;
    right: auto
  }
  .pull-9 {
    right: 75%;
    left: auto
  }
  .push-10 {
    left: 83.3333333333%;
    right: auto
  }
  .pull-10 {
    right: 83.3333333333%;
    left: auto
  }
  .push-11 {
    left: 91.6666666667%;
    right: auto
  }
  .pull-11 {
    right: 91.6666666667%;
    left: auto
  }
}

.reveal-modal-bg {
  bottom: 0;
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1004;
  left: 0
}

.reveal-modal {
  display: none;
  top: 0;
  width: 100%;
  z-index: 1005;
  left: 0;
  background-color: #FFF;
  padding: 1.875rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4)
}

.reveal-modal .column,
.reveal-modal .columns {
  min-width: 0
}

.reveal-modal>:first-child {
  margin-top: 0
}

.reveal-modal>:last-child {
  margin-bottom: 0
}

.reveal-modal.round {
  border-radius: 1000px
}

.reveal-modal.collapse {
  padding: 0
}

@media (min-width:768px) {
  .reveal-modal {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 80%;
    top: 6.25rem
  }
  .reveal-modal.tiny {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 30%
  }
  .reveal-modal.small {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 40%
  }
  .reveal-modal.medium {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 60%
  }
  .reveal-modal.large {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 70%
  }
  .reveal-modal.xlarge {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 95%
  }
}

.reveal-modal.full {
  height: 100vh;
  height: 100%;
  left: 0;
  margin-left: 0!important;
  max-width: none!important;
  min-height: 100vh;
  top: 0
}

@media (min-width:768px) {
  .reveal-modal.full {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 100%
  }
}

.reveal-modal.toback {
  z-index: 1003
}

.reveal-modal .close-reveal-modal {
  color: #AAA;
  cursor: pointer;
  font-size: 2.5rem;
  line-height: 1;
  position: absolute;
  top: .625rem;
  right: 1.375rem
}

.sub-nav {
  display: block;
  margin: -.25rem 0 1.125rem;
  overflow: hidden;
  padding-top: .25rem;
  width: auto
}

.sub-nav dt {
  text-transform: uppercase
}

.sub-nav dd,
.sub-nav dt,
.sub-nav li {
  color: #999;
  float: left;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  margin-left: 1rem;
  margin-bottom: 0
}

.sub-nav dd a,
.sub-nav dt a,
.sub-nav li a {
  color: #999;
  padding: .1875rem 1rem
}

.sub-nav dd a:hover,
.sub-nav dt a:hover,
.sub-nav li a:hover {
  color: #737373
}

.sub-nav dd.active a,
.sub-nav dt.active a,
.sub-nav li.active a {
  border-radius: 3px;
  background: #008CBA;
  color: #FFF;
  cursor: default;
  font-weight: 400;
  padding: .1875rem 1rem
}

.sub-nav dd.active a:hover,
.sub-nav dt.active a:hover,
.sub-nav li.active a:hover {
  background: #0078a0
}

.switch {
  border: none;
  margin-bottom: 1.5rem;
  outline: 0;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.switch label {
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative;
  text-indent: 100%;
  height: 2rem;
  width: 4rem
}

.switch input {
  left: 10px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 9px
}

.switch input+label {
  margin-left: 0;
  margin-right: 0
}

.switch label:after {
  content: "";
  left: .25rem;
  position: absolute;
  top: .25rem;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  height: 1.5rem;
  width: 1.5rem;
  background: #FFF
}

.switch input:checked+label:after {
  left: 2.25rem
}

.switch.large label {
  height: 2.5rem;
  width: 5rem
}

.switch.large label:after {
  height: 2rem;
  width: 2rem
}

.switch.large input:checked+label:after {
  left: 2.75rem
}

.switch.small label {
  height: 1.75rem;
  width: 3.5rem
}

.switch.small label:after {
  height: 1.25rem;
  width: 1.25rem
}

.switch.small input:checked+label:after {
  left: 2rem
}

.switch.tiny label {
  height: 1.5rem;
  width: 3rem
}

.switch.tiny label:after {
  height: 1rem;
  width: 1rem
}

.switch.tiny input:checked+label:after {
  left: 1.75rem
}

.switch.radius label {
  border-radius: 4px
}

.switch.radius label:after {
  border-radius: 3px
}

.switch.round {
  border-radius: 1000px
}

.switch.round label,
.switch.round label:after {
  border-radius: 2rem
}

table {
  background: #FFF;
  border: 1px solid #DDD;
  margin-bottom: 1.25rem;
  table-layout: auto
}

table caption {
  background: 0 0;
  color: #222;
  font-size: 1rem;
  font-weight: 700
}

table tfoot,
table thead {
  background: #F5F5F5
}

table tfoot tr td,
table tfoot tr th,
table thead tr td,
table thead tr th {
  color: #222;
  font-size: .875rem;
  font-weight: 700;
  padding: .5rem .625rem .625rem
}

table tr td,
table tr th {
  color: #222;
  font-size: .875rem;
  padding: .5625rem .625rem;
  text-align: left
}

table tr.alt,
table tr.even,
table tr:nth-of-type(even) {
  background: #F9F9F9
}

table tbody tr td,
table tbody tr th,
table tfoot tr td,
table tfoot tr th,
table thead tr th,
table tr td {
  display: table-cell;
  line-height: 1.125rem
}

.tabs {
  margin-bottom: 0!important;
  margin-left: 0
}

.tabs:after,
.tabs:before {
  content: " ";
  display: table
}

.tabs .nav-tab-title,
.tabs .tab-title,
.tabs dd {
  float: left;
  list-style: none;
  margin-bottom: 0!important;
  position: relative
}

.tabs .nav-tab-title>a,
.tabs .tab-title>a,
.tabs dd>a {
  display: block;
  background-color: #EFEFEF;
  color: #222;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  padding: 1rem 2rem
}

.tabs .nav-tab-title>a:hover,
.tabs .tab-title>a:hover,
.tabs dd>a:hover {
  background-color: #e1e1e1
}

.tabs .nav-tab-title.active a,
.tabs .tab-title.active a,
.tabs dd.active a {
  background-color: #FFF;
  color: #222
}

.tabs.radius .tab:first-child a,
.tabs.radius dd:first-child a {
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px
}

.tabs.radius .tab:last-child a,
.tabs.radius dd:last-child a {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px
}

.tabs.vertical .tab-title,
.tabs.vertical dd {
  position: inherit;
  float: none;
  display: block;
  top: auto
}

.tabs-content {
  margin-bottom: 1.5rem;
  width: 100%
}

.tabs-content:after,
.tabs-content:before {
  content: " ";
  display: table
}

.tabs-content>.content {
  display: none;
  float: left;
  padding: .9375rem 0;
  width: 100%
}

.tabs-content>.content.active {
  display: block;
  float: none
}

.tabs-content>.content.contained {
  padding: .9375rem
}

.tabs-content.vertical {
  display: block
}

.tabs-content.vertical>.content {
  padding: 0 .9375rem
}

@media (min-width:768px) {
  .tabs.vertical {
    float: left;
    margin: 0;
    margin-bottom: 1.25rem!important;
    max-width: 20%;
    width: 20%
  }
  .tabs-content.vertical {
    float: left;
    margin-left: -1px;
    max-width: 80%;
    padding-left: 1rem;
    width: 80%
  }
}

.no-js .tabs-content>.content {
  display: block;
  float: none
}

.has-tip {
  border-bottom: dotted 1px #CCC;
  color: #333;
  cursor: help;
  font-weight: 700
}

.has-tip:focus,
.has-tip:hover {
  border-bottom: dotted 1px #003f54;
  color: #008CBA
}

.has-tip.tip-left,
.has-tip.tip-right {
  float: none!important
}

.tooltip {
  background: #333;
  color: #FFF;
  display: none;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3;
  max-width: 300px;
  padding: .75rem;
  position: absolute;
  width: 100%;
  z-index: 1006;
  left: 50%
}

.tooltip>.nub {
  border: 5px solid;
  display: block;
  height: 0;
  pointer-events: none;
  position: absolute;
  top: -10px;
  width: 0;
  left: 5px
}

.tooltip>.nub.rtl {
  left: auto;
  right: 5px
}

.tooltip.radius {
  border-radius: 3px
}

.tooltip.round {
  border-radius: 1000px
}

.tooltip.round>.nub {
  left: 2rem
}

.tooltip.opened {
  border-bottom: dotted 1px #003f54!important;
  color: #008CBA!important
}

.tap-to-close {
  color: #777;
  display: block;
  font-size: .625rem;
  font-weight: 400
}

@media (min-width:768px) {
  .tooltip>.nub {
    border-color: transparent transparent #333;
    top: -10px
  }
  .tooltip.tip-top>.nub {
    border-color: #333 transparent transparent;
    bottom: -10px;
    top: auto
  }
  .tooltip.tip-left,
  .tooltip.tip-right {
    float: none!important
  }
  .tooltip.tip-left>.nub {
    border-color: transparent transparent transparent #333;
    left: auto;
    margin-top: -5px;
    right: -10px;
    top: 50%
  }
  .tooltip.tip-right>.nub {
    border-color: transparent #333 transparent transparent;
    left: -10px;
    margin-top: -5px;
    right: auto;
    top: 50%
  }
}

meta.foundation-mq-topbar {
  font-family: "/only screen and (min-width:768px)/";
  width: "768px"
}

.contain-to-grid {
  width: 100%;
  background: #333
}

.contain-to-grid .top-bar {
  margin-bottom: 0
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0
}

.fixed.expanded:not(.top-bar) {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  width: 100%
}

.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  width: 100%;
  z-index: 99
}

.fixed.expanded:not(.top-bar) .top-bar-section {
  margin-top: 2.8125rem;
  z-index: 98
}

.top-bar {
  background: #333;
  height: 2.8125rem;
  line-height: 2.8125rem;
  margin-bottom: 0;
  overflow: hidden;
  position: relative
}

.top-bar ul {
  list-style: none;
  margin-bottom: 0
}

.top-bar .row {
  max-width: none
}

.top-bar form,
.top-bar input,
.top-bar select {
  margin-bottom: 0
}

.top-bar input,
.top-bar select {
  font-size: .75rem;
  height: 1.75rem;
  padding-bottom: .35rem;
  padding-top: .35rem
}

.top-bar .button,
.top-bar button {
  font-size: .75rem;
  margin-bottom: 0;
  padding-bottom: .4125rem;
  padding-top: .4125rem
}

@media (max-width:767px) {
  .reveal-modal {
    min-height: 100vh
  }
  .top-bar .button,
  .top-bar button {
    position: relative;
    top: -1px
  }
}

.top-bar .title-area {
  margin: 0;
  position: relative
}

.top-bar .name {
  font-size: 16px;
  height: 2.8125rem;
  margin: 0
}

.top-bar .name h1,
.top-bar .name h2,
.top-bar .name h3,
.top-bar .name h4,
.top-bar .name p,
.top-bar .name span {
  font-size: 1.0625rem;
  line-height: 2.8125rem;
  margin: 0
}

.top-bar .name h1 a,
.top-bar .name h2 a,
.top-bar .name h3 a,
.top-bar .name h4 a,
.top-bar .name p a,
.top-bar .name span a {
  color: #FFF;
  display: block;
  font-weight: 400;
  padding: 0 .9375rem;
  width: 75%
}

.top-bar .toggle-topbar {
  position: absolute;
  right: 0;
  top: 0
}

.top-bar .toggle-topbar a {
  color: #FFF;
  display: block;
  font-size: .8125rem;
  font-weight: 700;
  height: 2.8125rem;
  line-height: 2.8125rem;
  padding: 0 .9375rem;
  position: relative;
  text-transform: uppercase
}

.top-bar .toggle-topbar.menu-icon {
  margin-top: -16px;
  top: 50%
}

.top-bar .toggle-topbar.menu-icon a {
  color: #FFF;
  height: 34px;
  line-height: 33px;
  padding: 0 2.5rem 0 .9375rem;
  position: relative
}

.top-bar .toggle-topbar.menu-icon a span::after {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  margin-top: -8px;
  top: 50%;
  right: .9375rem;
  box-shadow: 0 0 0 1px #FFF, 0 7px 0 1px #FFF, 0 14px 0 1px #FFF;
  width: 16px
}

.top-bar-section,
.top-bar-section .has-dropdown {
  position: relative
}

.top-bar .toggle-topbar.menu-icon a span:hover:after {
  box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px ""
}

.top-bar.expanded {
  background: 0 0;
  height: auto
}

.top-bar.expanded .title-area {
  background: #333
}

.top-bar.expanded .toggle-topbar a {
  color: #888
}

.top-bar.expanded .toggle-topbar a span::after {
  box-shadow: 0 0 0 1px #888, 0 7px 0 1px #888, 0 14px 0 1px #888
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .top-bar.expanded .top-bar-section .dropdown,
  .top-bar.expanded .top-bar-section .has-dropdown.moved>.dropdown {
    clip: initial
  }
  .top-bar.expanded .top-bar-section .has-dropdown:not(.moved)>ul {
    padding: 0
  }
}

.top-bar-section {
  left: 0;
  width: auto;
  transition: left .3s ease-out
}

.top-bar-section ul {
  display: block;
  font-size: 16px;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%
}

.top-bar-section .divider,
.top-bar-section [role=separator] {
  border-top: solid 1px #1a1a1a;
  clear: both;
  height: 1px;
  width: 100%
}

.top-bar-section ul li>a {
  color: #FFF;
  display: block;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .8125rem;
  font-weight: 400;
  padding: 12px 0 12px .9375rem;
  text-transform: none;
  width: 100%
}

.top-bar-section ul li>a.button,
.top-bar-section ul li>button {
  font-size: .8125rem;
  padding-left: .9375rem;
  padding-right: .9375rem
}

.top-bar-section ul li>a.button {
  background-color: #008CBA;
  border-color: #007095;
  color: #FFF
}

.top-bar-section ul li>a.button:focus,
.top-bar-section ul li>a.button:hover {
  background-color: #007095;
  color: #FFF
}

.top-bar-section ul li>a.button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333
}

.top-bar-section ul li>a.button.secondary:focus,
.top-bar-section ul li>a.button.secondary:hover {
  background-color: #b9b9b9;
  color: #333
}

.top-bar-section ul li>a.button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFF
}

.top-bar-section ul li>a.button.success:focus,
.top-bar-section ul li>a.button.success:hover {
  background-color: #368a55;
  color: #FFF
}

.top-bar-section ul li>a.button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #FFF
}

.top-bar-section ul li>a.button.alert:focus,
.top-bar-section ul li>a.button.alert:hover {
  background-color: #cf2a0e;
  color: #FFF
}

.top-bar-section ul li>a.button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFF
}

.top-bar-section ul li>a.button.warning:focus,
.top-bar-section ul li>a.button.warning:hover {
  background-color: #cf6e0e;
  color: #FFF
}

.top-bar-section ul li>a.button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333
}

.top-bar-section ul li>a.button.info:focus,
.top-bar-section ul li>a.button.info:hover {
  background-color: #61b6d9;
  color: #FFF
}

.top-bar-section ul li>button {
  background-color: #008CBA;
  border-color: #007095;
  color: #FFF
}

.top-bar-section ul li>button:focus,
.top-bar-section ul li>button:hover {
  background-color: #007095;
  color: #FFF
}

.top-bar-section ul li>button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333
}

.top-bar-section ul li>button.secondary:focus,
.top-bar-section ul li>button.secondary:hover {
  background-color: #b9b9b9;
  color: #333
}

.top-bar-section ul li>button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFF
}

.top-bar-section ul li>button.success:focus,
.top-bar-section ul li>button.success:hover {
  background-color: #368a55;
  color: #FFF
}

.top-bar-section ul li>button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #FFF
}

.top-bar-section ul li>button.alert:focus,
.top-bar-section ul li>button.alert:hover {
  background-color: #cf2a0e;
  color: #FFF
}

.top-bar-section ul li>button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFF
}

.top-bar-section ul li>button.warning:focus,
.top-bar-section ul li>button.warning:hover {
  background-color: #cf6e0e;
  color: #FFF
}

.top-bar-section ul li>button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333
}

.top-bar-section ul li>button.info:focus,
.top-bar-section ul li>button.info:hover {
  background-color: #61b6d9;
  color: #FFF
}

.top-bar-section ul li:hover:not(.has-form)>a {
  color: #FFF
}

.top-bar-section ul li.active>a {
  background: #008CBA;
  color: #FFF
}

.top-bar-section ul li.active>a:hover {
  background: #0078a0;
  color: #FFF
}

.top-bar-section .has-form {
  padding: .9375rem
}

.top-bar-section .has-dropdown>a:after {
  border: 5px inset;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent rgba(255, 255, 255, .4);
  border-left-style: solid;
  margin-right: .9375rem;
  margin-top: -4.5px;
  position: absolute;
  top: 50%;
  right: 0
}

.top-bar-section .has-dropdown.moved {
  position: static
}

.top-bar-section .has-dropdown.moved>.dropdown {
  height: auto;
  overflow: visible;
  clip: auto;
  display: block;
  position: absolute!important;
  width: 100%
}

.top-bar-section .has-dropdown.moved>a:after {
  display: none
}

.top-bar-section .dropdown {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute!important;
  width: 1px;
  display: block;
  padding: 0;
  top: 0;
  z-index: 99;
  left: 100%
}

.top-bar-section .dropdown li {
  height: auto;
  width: 100%
}

.top-bar-section .dropdown li a {
  font-weight: 400;
  padding: 8px .9375rem
}

.top-bar-section .dropdown li a.parent-link {
  font-weight: 400
}

.top-bar-section .dropdown li.parent-link,
.top-bar-section .dropdown li.title h5 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.125rem
}

.top-bar-section .dropdown li.parent-link a,
.top-bar-section .dropdown li.title h5 a {
  color: #FFF;
  display: block
}

.top-bar-section .dropdown li.parent-link a:hover,
.top-bar-section .dropdown li.title h5 a:hover {
  background: 0 0
}

.top-bar-section .dropdown li.has-form {
  padding: 8px .9375rem
}

.top-bar-section .dropdown li .button,
.top-bar-section .dropdown li button {
  top: auto
}

.top-bar-section .dropdown label {
  color: #777;
  font-size: .625rem;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px .9375rem 2px;
  text-transform: uppercase
}

.js-generated {
  display: block
}

@media only screen and (min-width:768px) {
  .top-bar,
  .top-bar.expanded {
    background: #333
  }
  .top-bar .title-area,
  .top-bar-section ul li {
    float: left
  }
  .top-bar {
    overflow: visible
  }
  .top-bar:after,
  .top-bar:before {
    content: " ";
    display: table
  }
  .top-bar:after {
    clear: both
  }
  .top-bar .toggle-topbar {
    display: none
  }
  .top-bar .name h1 a,
  .top-bar .name h2 a,
  .top-bar .name h3 a,
  .top-bar .name h4 a,
  .top-bar .name h5 a,
  .top-bar .name h6 a {
    width: auto
  }
  .top-bar .button,
  .top-bar button,
  .top-bar input,
  .top-bar select {
    font-size: .875rem;
    height: 1.75rem;
    position: relative;
    top: .53125rem
  }
  .contain-to-grid .top-bar {
    margin: 0 auto;
    max-width: 62.5rem
  }
  .top-bar-section {
    transition: none 0 0;
    left: 0!important
  }
  .top-bar-section ul {
    display: inline;
    height: auto!important;
    width: auto
  }
  .top-bar-section ul li .js-generated {
    display: none
  }
  .top-bar-section li.hover>a:not(.button) {
    background: #222;
    color: #FFF
  }
  .top-bar-section li.active:not(.has-form) a:not(.button) {
    background: #008CBA;
    color: #FFF;
    line-height: 2.8125rem;
    padding: 0 .9375rem
  }
  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    background: #0078a0;
    color: #FFF
  }
  .top-bar-section .has-dropdown>a {
    padding-right: 2.1875rem!important
  }
  .top-bar-section .has-dropdown>a:after {
    border: 5px inset;
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-color: rgba(255, 255, 255, .4) transparent transparent;
    border-top-style: solid;
    margin-top: -2.5px;
    top: 1.40625rem
  }
  .top-bar-section .has-dropdown.moved {
    position: relative
  }
  .top-bar-section .has-dropdown.moved>.dropdown {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
    display: block
  }
  .top-bar-section .has-dropdown.hover>.dropdown,
  .top-bar-section .has-dropdown.not-click:hover>.dropdown,
  .top-bar-section .has-dropdown>a:focus+.dropdown {
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute!important
  }
  .top-bar-section .has-dropdown .dropdown li.has-dropdown>a:after {
    border: none;
    content: "\00bb";
    top: .1875rem;
    right: 5px
  }
  .top-bar-section .dropdown {
    left: 0;
    background: 0 0;
    min-width: 100%;
    top: auto
  }
  .top-bar-section .dropdown li a {
    background: #333;
    color: #FFF;
    line-height: 2.8125rem;
    padding: 12px .9375rem;
    white-space: nowrap
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button) {
    background: #333;
    color: #FFF
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active):hover>a:not(.button) {
    color: #FFF;
    background: #222
  }
  .top-bar-section .dropdown li label {
    background: #333;
    white-space: nowrap
  }
  .top-bar-section .dropdown li .dropdown {
    left: 100%;
    top: 0
  }
  .top-bar-section>ul>.divider,
  .top-bar-section>ul>[role=separator] {
    border-right: solid 1px #4e4e4e;
    border-bottom: none;
    border-top: none;
    clear: none;
    height: 2.8125rem;
    width: 0
  }
  .top-bar-section .has-form {
    background: #333;
    height: 2.8125rem;
    padding: 0 .9375rem
  }
  .top-bar-section .right li .dropdown {
    left: auto;
    right: 0
  }
  .top-bar-section .right li .dropdown li .dropdown {
    right: 100%
  }
  .top-bar-section .left li .dropdown {
    right: auto;
    left: 0
  }
  .top-bar-section .left li .dropdown li .dropdown {
    left: 100%
  }
  .no-js .top-bar-section ul li:hover>a {
    background: #222;
    color: #FFF
  }
  .no-js .top-bar-section ul li:active>a {
    background: #008CBA;
    color: #FFF
  }
  .no-js .top-bar-section .has-dropdown:hover>.dropdown,
  .no-js .top-bar-section .has-dropdown>a:focus+.dropdown {
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute!important
  }
}

.container-fluid:after,
.container:after,
hr {
  clear: both
}

.text-left {
  text-align: left!important
}

.text-right {
  text-align: right!important
}

.text-center {
  text-align: center!important
}

.text-justify {
  text-align: justify!important
}

@media (max-width:767px) {
  .small-only-text-left {
    text-align: left!important
  }
  .small-only-text-right {
    text-align: right!important
  }
  .small-only-text-center {
    text-align: center!important
  }
  .small-only-text-justify {
    text-align: justify!important
  }
}

@media only screen {
  .small-text-left {
    text-align: left!important
  }
  .small-text-right {
    text-align: right!important
  }
  .small-text-center {
    text-align: center!important
  }
  .small-text-justify {
    text-align: justify!important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .medium-only-text-left {
    text-align: left!important
  }
  .medium-only-text-right {
    text-align: right!important
  }
  .medium-only-text-center {
    text-align: center!important
  }
  .medium-only-text-justify {
    text-align: justify!important
  }
}

@media (min-width:768px) {
  .medium-text-left {
    text-align: left!important
  }
  .medium-text-right {
    text-align: right!important
  }
  .medium-text-center {
    text-align: center!important
  }
  .medium-text-justify {
    text-align: justify!important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .large-only-text-left {
    text-align: left!important
  }
  .large-only-text-right {
    text-align: right!important
  }
  .large-only-text-center {
    text-align: center!important
  }
  .large-only-text-justify {
    text-align: justify!important
  }
}

@media (min-width:992px) {
  .large-text-left {
    text-align: left!important
  }
  .large-text-right {
    text-align: right!important
  }
  .large-text-center {
    text-align: center!important
  }
  .large-text-justify {
    text-align: justify!important
  }
}

@media (min-width:1200px) and (max-width:1919px) {
  .xlarge-only-text-left {
    text-align: left!important
  }
  .xlarge-only-text-right {
    text-align: right!important
  }
  .xlarge-only-text-center {
    text-align: center!important
  }
  .xlarge-only-text-justify {
    text-align: justify!important
  }
}

@media (min-width:1200px) {
  .xlarge-text-left {
    text-align: left!important
  }
  .xlarge-text-right {
    text-align: right!important
  }
  .xlarge-text-center {
    text-align: center!important
  }
  .xlarge-text-justify {
    text-align: justify!important
  }
}

@media (min-width:1920px) and (max-width:99999999px) {
  .xxlarge-only-text-left {
    text-align: left!important
  }
  .xxlarge-only-text-right {
    text-align: right!important
  }
  .xxlarge-only-text-center {
    text-align: center!important
  }
  .xxlarge-only-text-justify {
    text-align: justify!important
  }
}

blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0
}

a {
  line-height: inherit
}

a:focus,
a:hover {
  color: #0078a0
}

a img {
  border: none
}

p {
  font-family: inherit;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility
}

.subheader,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: .2rem;
  margin-bottom: .5rem
}

p.lead {
  font-size: 1.21875rem;
  line-height: 1.6
}

p aside {
  font-size: .875rem;
  font-style: italic;
  line-height: 1.35
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-style: normal;
  line-height: 1.4;
  text-rendering: optimizeLegibility
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #6f6f6f;
  font-size: 60%;
  line-height: 0
}

h1 {
  font-size: 2.125rem
}

h2 {
  font-size: 1.6875rem
}

h3 {
  font-size: 1.375rem
}

h4,
h5 {
  font-size: 1.125rem
}

h6 {
  font-size: 1rem
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f
}

b,
em,
i,
small,
strong {
  line-height: inherit
}

hr {
  border: solid #DDD;
  border-width: 1px 0 0;
  height: 0;
  margin: 1.25rem 0 1.1875rem
}

em,
i {
  font-style: italic
}

b,
strong {
  font-weight: 700
}

small {
  font-size: 60%
}

code {
  background-color: #f8f8f8;
  border-color: #dfdfdf;
  border-style: solid;
  border-width: 1px;
  color: #333;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  padding: .125rem .3125rem .0625rem
}

.vcard .fn,
.vevent .summary,
.vevent abbr,
dl dt {
  font-weight: 700
}

dl,
ol,
ul {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
  margin-bottom: 1.25rem
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p.p1 {
  font-family: Roboto, Helvetica Neue, Arial Narrow, Helvetica, Arial, sans-serif
}

ul {
  margin-left: 1.1rem
}

ul.no-bullet {
  margin-left: 0
}

ul.no-bullet li ol,
ul.no-bullet li ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none
}

ul li ol,
ul li ul {
  margin-left: 1.25rem;
  margin-bottom: 0
}

ul.circle,
ul.disc,
ul.square {
  margin-left: 1.1rem
}

ul.circle li ul,
ul.disc li ul,
ul.square li ul {
  list-style: inherit
}

ul.square {
  list-style-type: square
}

ul.circle {
  list-style-type: circle
}

ul.disc {
  list-style-type: disc
}

ul.no-bullet {
  list-style: none
}

ol {
  margin-left: 1.4rem
}

ol li ol,
ol li ul {
  margin-left: 1.25rem;
  margin-bottom: 0
}

dl dt {
  margin-bottom: .3rem
}

dl dd {
  margin-bottom: .75rem
}

.vcard,
blockquote {
  margin: 0 0 1.25rem
}

abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222;
  cursor: help
}

abbr {
  text-transform: none
}

abbr[title] {
  border-bottom: 1px dotted #DDD
}

blockquote {
  padding: .5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #DDD
}

blockquote cite {
  display: block;
  font-size: .8125rem;
  color: #555
}

blockquote cite:before {
  content: "\2014 \0020"
}

blockquote cite a,
blockquote cite a:visited {
  color: #555
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f
}

.vcard {
  display: inline-block;
  border: 1px solid #DDD;
  padding: .625rem .75rem
}

.vcard li {
  margin: 0;
  display: block
}

.vcard .fn {
  font-size: .9375rem
}

.vevent abbr {
  cursor: default;
  border: none;
  padding: 0 .0625rem
}

@media only screen {
  .hide-for-large,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-medium,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-down,
  .show-for-medium-down,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit!important
  }
  .hide-for-large-down,
  .hide-for-medium-down,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none!important
  }
  .hidden-for-large,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-medium,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-down,
  .visible-for-medium-down,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static!important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto
  }
  .hidden-for-large-down,
  .hidden-for-medium-down,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-medium,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px
  }
  table.hide-for-large,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-medium,
  table.hide-for-medium-only,
  table.hide-for-medium-up,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-down,
  table.show-for-medium-down,
  table.show-for-small,
  table.show-for-small-down,
  table.show-for-small-only,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table!important
  }
  thead.hide-for-large,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-medium,
  thead.hide-for-medium-only,
  thead.hide-for-medium-up,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-down,
  thead.show-for-medium-down,
  thead.show-for-small,
  thead.show-for-small-down,
  thead.show-for-small-only,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group!important
  }
  tbody.hide-for-large,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-only,
  tbody.hide-for-medium-up,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-down,
  tbody.show-for-medium-down,
  tbody.show-for-small,
  tbody.show-for-small-down,
  tbody.show-for-small-only,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group!important
  }
  tr.hide-for-large,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-medium,
  tr.hide-for-medium-only,
  tr.hide-for-medium-up,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-down,
  tr.show-for-medium-down,
  tr.show-for-small,
  tr.show-for-small-down,
  tr.show-for-small-only,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row
  }
  td.hide-for-large,
  td.hide-for-large-only,
  td.hide-for-large-up,
  td.hide-for-medium,
  td.hide-for-medium-only,
  td.hide-for-medium-up,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-down,
  td.show-for-medium-down,
  td.show-for-small,
  td.show-for-small-down,
  td.show-for-small-only,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-only,
  th.hide-for-large-up,
  th.hide-for-medium,
  th.hide-for-medium-only,
  th.hide-for-medium-up,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-down,
  th.show-for-medium-down,
  th.show-for-small,
  th.show-for-small-down,
  th.show-for-small-only,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell!important
  }
}

@media (min-width:768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4
  }
  h1 {
    font-size: 2.75rem
  }
  h2 {
    font-size: 2.3125rem
  }
  h3 {
    font-size: 1.6875rem
  }
  h4 {
    font-size: 1.4375rem
  }
  h5 {
    font-size: 1.125rem
  }
  h6 {
    font-size: 1rem
  }
  .hide-for-large,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-down,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit!important
  }
  .hide-for-large-down,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none!important
  }
  .hidden-for-large,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-down,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static!important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto
  }
  .hidden-for-large-down,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px
  }
  table.hide-for-large,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-down,
  table.show-for-medium,
  table.show-for-medium-down,
  table.show-for-medium-only,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table!important
  }
  thead.hide-for-large,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-down,
  thead.show-for-medium,
  thead.show-for-medium-down,
  thead.show-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group!important
  }
  tbody.hide-for-large,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-down,
  tbody.show-for-medium,
  tbody.show-for-medium-down,
  tbody.show-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group!important
  }
  tr.hide-for-large,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-down,
  tr.show-for-medium,
  tr.show-for-medium-down,
  tr.show-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row
  }
  td.hide-for-large,
  td.hide-for-large-only,
  td.hide-for-large-up,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-down,
  td.show-for-medium,
  td.show-for-medium-down,
  td.show-for-medium-only,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-only,
  th.hide-for-large-up,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-down,
  th.show-for-medium,
  th.show-for-medium-down,
  th.show-for-medium-only,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell!important
  }
}

@media (min-width:992px) {
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit!important
  }
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none!important
  }
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static!important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px
  }
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large,
  table.show-for-large-down,
  table.show-for-large-only,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table!important
  }
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large,
  thead.show-for-large-down,
  thead.show-for-large-only,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group!important
  }
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large,
  tbody.show-for-large-down,
  tbody.show-for-large-only,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group!important
  }
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large,
  tr.show-for-large-down,
  tr.show-for-large-only,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row
  }
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large,
  td.show-for-large-down,
  td.show-for-large-only,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large,
  th.show-for-large-down,
  th.show-for-large-only,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell!important
  }
}

@media (min-width:1200px) {
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-down {
    display: inherit!important
  }
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none!important
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge-down {
    position: static!important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto
  }
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px
  }
  table.hide-for-large,
  table.hide-for-large-down,
  table.hide-for-large-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge,
  table.show-for-xlarge-down,
  table.show-for-xlarge-only,
  table.show-for-xlarge-up,
  table.show-for-xxlarge-down {
    display: table!important
  }
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.hide-for-large-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge,
  thead.show-for-xlarge-down,
  thead.show-for-xlarge-only,
  thead.show-for-xlarge-up,
  thead.show-for-xxlarge-down {
    display: table-header-group!important
  }
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.hide-for-large-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.show-for-xlarge-only,
  tbody.show-for-xlarge-up,
  tbody.show-for-xxlarge-down {
    display: table-row-group!important
  }
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.hide-for-large-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge,
  tr.show-for-xlarge-down,
  tr.show-for-xlarge-only,
  tr.show-for-xlarge-up,
  tr.show-for-xxlarge-down {
    display: table-row
  }
  td.hide-for-large,
  td.hide-for-large-down,
  td.hide-for-large-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge,
  td.show-for-xlarge-down,
  td.show-for-xlarge-only,
  td.show-for-xlarge-up,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-down,
  th.hide-for-large-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge,
  th.show-for-xlarge-down,
  th.show-for-xlarge-only,
  th.show-for-xlarge-up,
  th.show-for-xxlarge-down {
    display: table-cell!important
  }
}

@media (min-width:1920px) {
  .xxlarge-text-left {
    text-align: left!important
  }
  .xxlarge-text-right {
    text-align: right!important
  }
  .xxlarge-text-center {
    text-align: center!important
  }
  .xxlarge-text-justify {
    text-align: justify!important
  }
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .hide-for-xlarge-only,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: inherit!important
  }
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .show-for-xlarge-only {
    display: none!important
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .hidden-for-xlarge-only,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    position: static!important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto
  }
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .visible-for-xlarge-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px
  }
  table.hide-for-large,
  table.hide-for-large-down,
  table.hide-for-large-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-down,
  table.hide-for-xlarge-only,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-up,
  table.show-for-xxlarge,
  table.show-for-xxlarge-down,
  table.show-for-xxlarge-only,
  table.show-for-xxlarge-up {
    display: table!important
  }
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.hide-for-large-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-down,
  thead.hide-for-xlarge-only,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-up,
  thead.show-for-xxlarge,
  thead.show-for-xxlarge-down,
  thead.show-for-xxlarge-only,
  thead.show-for-xxlarge-up {
    display: table-header-group!important
  }
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.hide-for-large-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-down,
  tbody.hide-for-xlarge-only,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-up,
  tbody.show-for-xxlarge,
  tbody.show-for-xxlarge-down,
  tbody.show-for-xxlarge-only,
  tbody.show-for-xxlarge-up {
    display: table-row-group!important
  }
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.hide-for-large-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-down,
  tr.hide-for-xlarge-only,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-up,
  tr.show-for-xxlarge,
  tr.show-for-xxlarge-down,
  tr.show-for-xxlarge-only,
  tr.show-for-xxlarge-up {
    display: table-row
  }
  td.hide-for-large,
  td.hide-for-large-down,
  td.hide-for-large-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-down,
  td.hide-for-xlarge-only,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-up,
  td.show-for-xxlarge,
  td.show-for-xxlarge-down,
  td.show-for-xxlarge-only,
  td.show-for-xxlarge-up,
  th.hide-for-large,
  th.hide-for-large-down,
  th.hide-for-large-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-down,
  th.hide-for-xlarge-only,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-up,
  th.show-for-xxlarge,
  th.show-for-xxlarge-down,
  th.show-for-xxlarge-only,
  th.show-for-xxlarge-up {
    display: table-cell!important
  }
}

.hide-for-portrait,
.show-for-landscape {
  display: inherit!important
}

.hide-for-landscape,
.show-for-portrait {
  display: none!important
}

table.hide-for-landscape,
table.show-for-portrait {
  display: table!important
}

thead.hide-for-landscape,
thead.show-for-portrait {
  display: table-header-group!important
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
  display: table-row-group!important
}

tr.hide-for-landscape,
tr.show-for-portrait {
  display: table-row!important
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell!important
}

@media only screen and (orientation:landscape) {
  .hide-for-portrait,
  .show-for-landscape {
    display: inherit!important
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: none!important
  }
  table.hide-for-portrait,
  table.show-for-landscape {
    display: table!important
  }
  thead.hide-for-portrait,
  thead.show-for-landscape {
    display: table-header-group!important
  }
  tbody.hide-for-portrait,
  tbody.show-for-landscape {
    display: table-row-group!important
  }
  tr.hide-for-portrait,
  tr.show-for-landscape {
    display: table-row!important
  }
  td.hide-for-portrait,
  td.show-for-landscape,
  th.hide-for-portrait,
  th.show-for-landscape {
    display: table-cell!important
  }
}

@media only screen and (orientation:portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: inherit!important
  }
  .hide-for-portrait,
  .show-for-landscape {
    display: none!important
  }
  table.hide-for-landscape,
  table.show-for-portrait {
    display: table!important
  }
  thead.hide-for-landscape,
  thead.show-for-portrait {
    display: table-header-group!important
  }
  tbody.hide-for-landscape,
  tbody.show-for-portrait {
    display: table-row-group!important
  }
  tr.hide-for-landscape,
  tr.show-for-portrait {
    display: table-row!important
  }
  td.hide-for-landscape,
  td.show-for-portrait,
  th.hide-for-landscape,
  th.show-for-portrait {
    display: table-cell!important
  }
}

.show-for-touch {
  display: none!important
}

.hide-for-touch,
.touch .show-for-touch {
  display: inherit!important
}

.touch .hide-for-touch {
  display: none!important
}

.touch table.show-for-touch,
table.hide-for-touch {
  display: table!important
}

.touch thead.show-for-touch,
thead.hide-for-touch {
  display: table-header-group!important
}

.touch tbody.show-for-touch,
tbody.hide-for-touch {
  display: table-row-group!important
}

.touch tr.show-for-touch,
tr.hide-for-touch {
  display: table-row!important
}

.touch td.show-for-touch,
.touch th.show-for-touch,
td.hide-for-touch,
th.hide-for-touch {
  display: table-cell!important
}

.show-for-sr,
.show-on-focus {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute!important;
  width: 1px
}

.show-on-focus:active,
.show-on-focus:focus {
  position: static!important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto
}

.print-only {
  display: none!important
}

@media print {
  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid
  }
  * {
    background: 0 0!important;
    box-shadow: none!important;
    color: #000!important;
    text-shadow: none!important
  }
  a,
  a:visited {
    text-decoration: underline
  }
  a[href]:after {
    content: " (" attr(href) ")"
  }
  abbr[title]:after {
    content: " (" attr(title) ")"
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""
  }
  blockquote,
  pre {
    border: 1px solid #999
  }
  thead {
    display: table-header-group
  }
  img {
    max-width: 100%!important
  }
  @page {
    margin: .5cm
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }
  h2,
  h3 {
    page-break-after: avoid
  }
  .hide-on-print {
    display: none!important
  }
  .print-only {
    display: block!important
  }
  .hide-for-print {
    display: none!important
  }
  .show-for-print {
    display: inherit!important
  }
  table.show-for-print {
    display: table!important
  }
  thead.show-for-print {
    display: table-header-group!important
  }
  tbody.show-for-print {
    display: table-row-group!important
  }
  tr.show-for-print {
    display: table-row!important
  }
  td.show-for-print,
  th.show-for-print {
    display: table-cell!important
  }
}

a.a1,
a:hover {
  text-decoration: none
}

@media not print {
  .show-for-print {
    display: none!important
  }
}

.line-clamp,
ul.ul li:before {
  display: block;
  position: relative
}

.btn-green-login {
  background-color: #48a54c;
  border-color: #48a54c
}

.btn-green-login:hover {
  background-color: #65cf6a;
  border-color: #65cf6a
}

.btn-green-login.faded:active,
.btn-green-login.faded:focus,
.btn-green-login.faded:hover,
.btn-green-login:active,
.btn-green-login:focus {
  background-color: #2e7731;
  border-color: #2e7731
}

.btn-green-login.disabled,
.btn-green-login.disabled:active,
.btn-green-login.disabled:focus,
.btn-green-login.disabled:hover,
.btn-green-login.faded {
  color: #74b776;
  background-color: #eaf6eb;
  border-color: #eaf6eb
}

.btn-blue,
.btn-blue-login,
.reveal-modal .close-reveal-modal.btn-close.btn-blue {
  background-color: #376caf;
  border-color: #376caf
}

.btn-blue-login:hover,
.btn-blue:hover,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:hover {
  background-color: #5da5ff;
  border-color: #5da5ff
}

.btn-blue-login.faded:active,
.btn-blue-login.faded:focus,
.btn-blue-login.faded:hover,
.btn-blue-login:active,
.btn-blue-login:focus,
.btn-blue.faded:active,
.btn-blue.faded:focus,
.btn-blue.faded:hover,
.btn-blue:active,
.btn-blue:focus,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:active,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:focus,
.reveal-modal .faded.close-reveal-modal.btn-close.btn-blue:hover {
  background-color: #274360;
  border-color: #274360
}

.btn-blue-login.disabled,
.btn-blue-login.disabled:active,
.btn-blue-login.disabled:focus,
.btn-blue-login.disabled:hover,
.btn-blue-login.faded,
.btn-blue.disabled,
.btn-blue.disabled:active,
.btn-blue.disabled:focus,
.btn-blue.disabled:hover,
.btn-blue.faded,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-blue,
.reveal-modal .faded.close-reveal-modal.btn-close.btn-blue {
  color: #9fb9d9;
  background-color: #e9eff7;
  border: 1px solid #9fb9d9
}

.btn-red {
  background-color: #ff5018;
  border-color: #ff5018
}

.btn-red:hover {
  background-color: #ff7d68;
  border-color: #ff7d68
}

.btn-red.faded:active,
.btn-red.faded:focus,
.btn-red.faded:hover,
.btn-red:active,
.btn-red:focus {
  background-color: #a4321f;
  border-color: #a4321f
}

.btn-red.disabled,
.btn-red.disabled:active,
.btn-red.disabled:focus,
.btn-red.disabled:hover,
.btn-red.faded {
  color: #f0b7bc;
  background-color: #f8e9e6;
  border: 1px solid #f0b7bc
}

.btn-green {
  background-color: #0a9e02;
  border-color: #0a9e02
}

.btn-green:hover {
  background-color: #65cf6a;
  border-color: #65cf6a
}

.btn-green.faded:active,
.btn-green.faded:focus,
.btn-green.faded:hover,
.btn-green:active,
.btn-green:focus {
  background-color: #2e7731;
  border-color: #2e7731
}

.btn-green.disabled,
.btn-green.disabled:active,
.btn-green.disabled:focus,
.btn-green.disabled:hover,
.btn-green.faded {
  color: #74b776;
  background-color: #eaf6eb;
  border: 1px solid #74b776
}

.btn-orange {
  background-color: #cc6500;
  border-color: #cc6500
}

.btn-orange:hover {
  background-color: #fb9149;
  border-color: #fb9149
}

.btn-orange.faded:active,
.btn-orange.faded:focus,
.btn-orange.faded:hover,
.btn-orange:active,
.btn-orange:focus {
  background-color: #8e4412;
  border-color: #8e4412
}

.btn-orange.disabled,
.btn-orange.disabled:active,
.btn-orange.disabled:focus,
.btn-orange.disabled:hover,
.btn-orange.faded {
  color: #dfaf8f;
  background-color: #f9f0e9;
  border: 1px solid #dfaf8f
}

.btn-yellow {
  background-color: #fddd1c;
  border-color: #fddd1c
}

.btn-yellow:hover {
  background-color: #f7d283;
  border-color: #f7d283
}

.btn-yellow.faded:active,
.btn-yellow.faded:focus,
.btn-yellow.faded:hover,
.btn-yellow:active,
.btn-yellow:focus {
  background-color: #d89d1f;
  border-color: #d89d1f
}

.btn-yellow.disabled,
.btn-yellow.disabled:active,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:hover,
.btn-yellow.faded {
  color: #e3ca94;
  background-color: #faf3e5;
  border: 1px solid #e3ca94
}

.btn-blue,
.btn-blue-login,
.btn-stroked-blue,
.reveal-modal .close-reveal-modal.btn-close.btn-blue,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue {
  border-color: #376caf;
  color: #376caf
}

.btn-stroked-blue:hover,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue:hover {
  background-color: #e9eff7;
  border-color: #5da5ff;
  color: #5da5ff
}

.btn-stroked-blue:active,
.btn-stroked-blue:focus,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue:active,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue:focus {
  color: #274360;
  background-color: #e9eff7;
  border-color: #274360
}

.btn-stroked-blue.disabled,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-stroked-blue {
  background-color: #e9eff7
}

.btn-stroked-red.disabled,
.btn-stroked-red:hover {
  background-color: #f8e9e6
}

.btn-red,
.btn-stroked-red {
  border-color: #ff5018;
  color: #ff5018
}

.btn-stroked-red:hover {
  border-color: #ff7d68;
  color: #ff7d68
}

.btn-stroked-red:active,
.btn-stroked-red:focus {
  color: #a4321f;
  background-color: #f8e9e6;
  border-color: #a4321f
}

.btn-stroked-green.disabled,
.btn-stroked-green:hover {
  background-color: #eaf6eb
}

.btn-green,
.btn-stroked-green {
  border-color: #0a9e02;
  color: #0a9e02
}

.btn-stroked-green:hover {
  border-color: #65cf6a;
  color: #65cf6a
}

.btn-stroked-green:active,
.btn-stroked-green:focus {
  color: #2e7731;
  background-color: #eaf6eb;
  border-color: #2e7731
}

.btn-orange,
.btn-stroked-orange {
  border-color: #cc6500;
  color: #cc6500
}

.btn-stroked-orange:hover {
  background-color: #f9f0e9;
  border-color: #fb9149;
  color: #fb9149
}

.btn-stroked-orange:active,
.btn-stroked-orange:focus {
  color: #8e4412;
  background-color: #f9f0e9;
  border-color: #8e4412
}

.btn-stroked-orange.disabled {
  background-color: #f9f0e9
}

.btn-stroked-yellow,
.btn-yellow {
  border-color: #fddd1c;
  color: #d89d1f
}

.btn-stroked-yellow:hover {
  background-color: #faf3e5;
  border-color: #f7d283;
  color: #f7d283
}

.btn-stroked-yellow:active,
.btn-stroked-yellow:focus {
  color: #d89d1f;
  background-color: #faf3e5;
  border-color: #d89d1f
}

.btn-stroked-yellow.disabled {
  background-color: #faf3e5
}

body {
  font-size: 1rem;
  line-height: 1.5rem;
  -webkit-animation-delay: .1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: .1s;
  -webkit-animation-iteration-count: 1
}

@-webkit-keyframes fontfix {
  from,
  to {
    opacity: 1
  }
}

h1,
h2 {
  font-weight: 300
}

h3 {
  font-weight: 400
}

h4,
h5 {
  font-weight: 500
}

h1.gray-light,
h2.gray-light,
h3.gray-light,
h4.gray-light,
h5.gray-light,
h6.gray-light {
  color: #263238
}

h1.gray-dark,
h2.gray-dark,
h3.gray-dark,
h4.gray-dark,
h5.gray-dark,
h6.gray-dark {
  color: #444
}

h1.headline-border,
h2.headline-border,
h3.headline-border,
h4.headline-border,
h5.headline-border,
h6.headline-border {
  color: #636468;
  padding-top: .3125rem;
  border-top: solid 4px #636468
}

h1.headline-border.first,
h2.headline-border.first,
h3.headline-border.first,
h4.headline-border.first,
h5.headline-border.first,
h6.headline-border.first {
  border-top-color: transparent
}

@media (min-width:768px) {
  h1.headline-border.mobile,
  h2.headline-border.mobile,
  h3.headline-border.mobile,
  h4.headline-border.mobile,
  h5.headline-border.mobile,
  h6.headline-border.mobile {
    border-top-color: transparent
  }
}

@media (max-width:767px) {
  h3 {
    font-size: 1.5rem
  }
}

@media (min-width:992px) {
  h1 {
    font-size: 3.75rem
  }
  h2 {
    font-size: 3.125rem
  }
  h3 {
    font-size: 1.875rem
  }
  h4 {
    font-size: 1.25rem
  }
  h5,
  h6 {
    font-size: 1.125rem
  }
}

h2.headline-border,
h3 {
  color: #636468;
  margin-top: 1.875rem
}

h2.headline-border a,
h2.headline-border a:hover,
h3 a,
h3 a:hover {
  color: #636468;
  font-weight: 400
}

.sectionTitle {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  color: #376caf;
  text-transform: uppercase
}

.sectionLabel {
  font-size: 2.8125rem;
  line-height: 3.125rem;
  font-weight: 100;
  color: #969696
}

ol,
p,
ul {
  font-size: 1rem;
  line-height: 1.5rem
}

p {
  font-weight: 300
}

p.p2 {
  font-family: "Roboto Slab", Arial #000, Georgia, slab-serif
}

a {
  color: #376caf;
  font-weight: 500
}

a:hover {
  color: #5da5ff
}

.footer a:hover,
a.a2 {
  text-decoration: underline
}

ol,
ul {
  font-weight: 300
}

ul.ul {
  list-style: none
}

ul.ul li:before {
  content: '\25A0';
  max-width: .4375rem;
  max-height: .4375rem;
  left: -1.25rem;
  top: .3125rem;
  color: #376caf
}

.description-list .description-list-item+.description-list-item {
  border-top: solid 1px #CCC;
  margin: .625rem 0;
  padding-top: .625rem
}

.description-list .description-list-item p {
  margin: 0 0 .625rem
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  vertical-align: middle;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga'
}

.combined-icons .material-icons {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1
}

.combined-icons .material-icons.sort {
  position: relative;
  z-index: 1
}

.combined-icons .material-icons.create {
  position: relative;
  margin-left: -.9375rem;
  z-index: 2
}

.btn-sm .material-icons {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem
}

.btn-lg .material-icons {
  font-size: 1.875rem;
  width: 1.875rem;
  height: 1.875rem
}

.info-icon {
  font-size: 1rem;
  color: #376caf
}

.rate-vendor svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  vertical-align: text-top
}

.rate-vendor.btn-lg svg {
  width: 1.5rem;
  height: 1.5rem
}

.rate-vendor.btn-sm svg {
  width: .75rem;
  height: .75rem
}

.featured-members strong {
  font-size: 1.125rem;
  font-weight: 500;
  color: #636468
}

.featured-profiles {
  margin-top: .9375rem
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0!important
}

.container,
.container-fluid {
  padding-right: 15px;
  padding-left: 15px
}

.line-clamp:after {
  content: '...';
  text-align: right;
  bottom: 0;
  right: 0;
  width: 25%;
  display: block;
  position: absolute;
  height: calc(1em * 1.2);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 75%)
}

.main-content .grid,
.primary-nav,
.primary-nav .title-area,
.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper {
  position: relative
}

@supports (-webkit-line-clamp:1) {
  .line-clamp:after {
    display: none!important
  }
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  height: calc(1em * 1.2 * 1)
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  height: calc(1em * 1.2 * 2)
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  height: calc(1em * 1.2 * 3)
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  height: calc(1em * 1.2 * 4)
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  height: calc(1em * 1.2 * 5)
}

.line-clamp-8 {
  -webkit-line-clamp: 8;
  height: calc(1em * 1.2 * 8)
}

@media (min-width:768px) and (max-width:991px) {
  .hide-on-medium {
    display: none
  }
}

@media (max-width:479px) {
  .hide-for-xsmall-only {
    display: none
  }
}
@media (min-width:768px) and (max-width:1920px) {
  .hide-for-lg-only {
    display: none !important;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  min-width: 18.125rem
}

@media (min-width:768px) {
  .container {
    width: 750px
  }
}

@media (min-width:992px) {
  .container {
    width: 970px
  }
}

@media (min-width:1200px) {
  .container {
    width: 1145px
  }
}

.container-fluid {
  width: 100%
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
  margin-right: -15px;
  margin-left: -15px
}

.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before {
  display: table;
  content: " "
}

@media (min-width:768px) {
  .container-fluid>.navbar-collapse,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container>.navbar-header {
    margin-right: 0;
    margin-left: 0
  }
  .navbar>.container .navbar-brand,
  .navbar>.container-fluid .navbar-brand {
    margin-left: -15px
  }
  .column-left {
    margin-right: 3.4%;
    float: left;
    clear: left
  }
  .column-right {
    float: right;
    clear: right
  }
  .column-one-half {
    width: 48.3%
  }
  .column-one-third {
    width: 32.2%
  }
  .column-two-thirds {
    width: 64.4%
  }
  .three-columns .column-left {
    margin-right: 0
  }
  .three-columns .column-middle {
    float: left;
    margin: auto 1.7%
  }
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .column-one-third,
  .column-two-thirds {
    width: 48.3%
  }
  .single-post .column-one-third,
  .user-blog .column-one-third {
    width: 32.2%
  }
  .single-post .column-two-thirds,
  .user-blog .column-two-thirds {
    width: 64.4%
  }
  .three-columns .column-middle {
    margin: 0 0 0 3.4%
  }
}

@media (max-width:767px) {
  .main-content .grid {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column
  }
}

@media (min-width:768px) {
  .grid {
    min-width: calc(100% - 72px)
  }
  .grid .grid:after {
    content: '';
    display: block;
    clear: both
  }
  .grid-item,
  .grid-sizer {
    width: 48.3%
  }
  .gutter-sizer {
    width: 3.4%
  }
  .grid-item {
    float: left
  }
  .grid-item.column-one-half {
    width: 48.3%
  }
  .primary-nav .title-area .header-settings .settings>li {
    line-height: 2rem
  }
}

@media (max-width:767px) {
  .grid {
    min-width: 290px
  }
  .grid:after {
    content: '';
    display: block;
    clear: both
  }
  .grid-item,
  .grid-sizer {
    width: 100%
  }
  .gutter-sizer {
    width: 0%
  }
  .grid-item {
    float: none
  }
  .primary-nav .title-area .header-settings .settings>li {
    line-height: 2rem
  }
}

.clearfix {
  clear: both
}

.primary-nav {
  background-color: #376caf;
  color: #fff
}

.primary-nav a {
  color: #fff;
  font-weight: 300
}

.primary-nav a[data-dropdown] {
  color: #fff
}

.primary-nav li,
.primary-nav ul {
  list-style: none;
  padding: 0;
  margin: 0
}

.primary-nav li.container,
.primary-nav ul.container {
  margin: 0 auto
}

.primary-nav .title-area .name {
  float: left
}

.primary-nav .title-area .name img.logo {
  padding: .75rem 0
}

.primary-nav .title-area .header-settings {
  display: table;
  height: 4.75rem
}

.primary-nav .title-area .header-settings .settings {
  display: table-row
}

.primary-nav .title-area .header-settings .settings>li {
  display: table-cell;
  vertical-align: middle;
  font-size: .875rem;
  font-weight: 300;
  padding: 0 1rem
}

.primary-nav .title-area .header-settings .settings>li:last-child {
  padding-right: 0
}

.primary-nav .title-area .header-settings .settings>li.small-avatar .avatar-status-widget {
  margin-bottom: -.5rem
}

@media (max-width:767px) {
  .primary-nav .title-area .header-settings .settings>li img.avatar {
    margin-bottom: .25rem
  }
}

@media (max-width:479px) {
  .primary-nav .title-area .header-settings .settings>li {
    line-height: 1.3125rem
  }
  .primary-nav .title-area .header-settings .settings>li.small-avatar .avatar-status-widget {
    margin-top: -.125rem;
    margin-bottom: 0;
    width: 1.625rem;
    height: 1.625rem
  }
}

.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .fa-envelope {
  font-size: 1.125rem;
  color: #fff
}

.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .fa-envelope:hover {
  color: #fff
}

.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .fa-envelope:active,
.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .fa-envelope:focus {
  color: #fff;
  outline: 0
}

.primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .notifications {
  position: absolute;
  bottom: .5rem;
  left: .625rem;
  background-color: #bf3837;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  font-size: .625rem;
  line-height: 1.125rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
  z-index: 2
}

.primary-nav .title-area .header-settings a[data-dropdown] {
  font-size: .875rem
}

.primary-nav .title-area .header-settings a[data-dropdown]:hover {
  color: #fff
}

.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] {
  margin-top: 15px;
  padding: 0;
  width: auto;
  text-align: left
}

.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] li {
  margin: 0;
  padding: 0
}

.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] li a {
  padding: .5rem
}

.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] li.admin,
.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] li.logout {
  border-top: solid 1px #999
}

.primary-nav .title-area .header-settings a[data-dropdown]+ul[data-dropdown-content] li.site-home {
  border-bottom: solid 1px #999
}

.primary-nav .title-area .menu-icon {
  position: absolute;
  top: 50%;
  right: .9375rem;
  margin-top: -2%
}

.primary-nav .title-area .menu-icon a span {
  position: relative;
  display: inline-block;
  width: 1.5625rem
}

.primary-nav .title-area .menu-icon a span::after {
  position: absolute;
  top: .3125rem;
  right: .3125rem;
  width: 16px;
  height: 0;
  box-shadow: 0 0 0 1px #FFF, 0 7px 0 1px #FFF, 0 14px 0 1px #FFF;
  content: ""
}

.primary-nav .title-area .menu-icon a span:hover:after {
  box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px ""
}

@media (max-width:767px) {
  .primary-nav .title-area {
    padding: 0 .9375rem
  }
  .primary-nav .title-area .name img.logo {
    max-width: 8.9375rem;
    padding: .625rem 0
  }
  .primary-nav .title-area .header-settings {
    max-width: 18.75rem;
    height: 30px;
    text-align: right;
    margin: 0 auto;
    padding: .625rem 0 .4375rem
  }
  .primary-nav .title-area .header-settings .settings>li {
    padding: 0 .625rem
  }
  .primary-nav .title-area .header-settings .settings>li:last-child {
    display: none
  }
  .primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .notifications {
    left: .5rem;
    font-size: .5625rem;
    line-height: 1.125rem;
    width: 1rem;
    height: 1rem
  }
}

@media (max-width:479px) {
  .primary-nav .title-area .name img.logo {
    max-width: 6.25rem
  }
  .primary-nav .title-area .header-settings .settings>li {
    padding: 0 .375rem
  }
  .primary-nav .title-area .header-settings .settings>li .material-icons {
    font-size: 1.125rem
  }
  .primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .fa-envelope {
    font-size: .875rem
  }
  .primary-nav .title-area .header-settings .settings #messages a.dropdown-toggle.dropper .notifications {
    font-size: .5rem;
    line-height: 1rem;
    width: .875rem;
    height: .875rem
  }
  .primary-nav .title-area .menu-icon {
    margin-top: -4%
  }
}

#noticemsg,
.homepage .primary-nav .title-area .header-settings .settings a[data-dropdown]+ul[data-dropdown-content] li.site-home {
  display: none
}

#noticemsg .close-reveal-modal {
  color: #AAA;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1;
  position: absolute;
  top: 0;
  right: .625rem
}

.notification-container {
  position: absolute;
  overflow-y: scroll;
  max-height: 90vh;
  z-index: 87;
  background-color: #fff;
  color: #444;
  text-align: left;
  cursor: default;
  box-shadow: 0 0 5px 0 rgba(50, 50, 50, .75)
}

.notification-container:focus {
  outline: 0
}

@media (min-width:768px) {
  .primary-nav .title-area .header-settings {
    float: right
  }
  .primary-nav .title-area .menu-icon {
    display: none
  }
  .notification-container {
    top: 1.25rem;
    right: 0;
    width: 28.125rem
  }
  .notification-container:after,
  .notification-container:before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute
  }
  .notification-container:before {
    border: 6px inset;
    border-color: transparent transparent #FFF;
    border-bottom-style: solid;
    top: -12px;
    right: 4px;
    z-index: 89
  }
  .notification-container:after {
    border: 7px inset;
    border-color: transparent transparent #ccc;
    border-bottom-style: solid;
    top: -.875rem;
    right: .1875rem;
    z-index: 88
  }
}

@media (max-width:767px) {
  .notification-container {
    position: fixed;
    top: 2.0625rem;
    left: .5rem;
    right: .5rem;
    margin: 0 auto;
    min-width: 18.125rem;
    width: 100%;
    max-width: 95%
  }
}

.notification-container .notification-heading {
  margin-top: 0;
  padding: .4375rem .625rem 0;
  color: #444
}

.notification-container .notification-heading .btn.js-notifications-mark-read,
.notification-container .notification-heading .reveal-modal .js-notifications-mark-read.close-reveal-modal.btn-close,
.reveal-modal .notification-container .notification-heading .js-notifications-mark-read.close-reveal-modal.btn-close {
  color: #fff;
  float: right;
  margin: .5rem 1.875rem .75rem 0
}

.notification-container a {
  color: #376caf
}

.notification-container a:hover {
  color: #5da5ff
}

.notification-container .notification {
  position: relative;
  width: 100%;
  clear: both;
  padding: .4375rem .625rem;
  border-top: solid 1px #CCC
}

.notification-container .notification .avatar-holder {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 3.75rem;
  height: 100%;
  padding: .3125rem .625rem 0 0
}

.notification-container .notification .avatar-holder .avatar {
  position: relative;
  max-width: 3.125rem;
  max-height: 3.125rem;
  z-index: 1
}

.notification-container .notification .avatar-holder .notice-icon {
  position: absolute;
  border-radius: 50%;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1.3125rem;
  text-align: center;
  color: #fff;
  bottom: 0;
  left: 1.5625rem;
  z-index: 2;
  background-color: #376caf
}

.notification-container .notification .avatar-holder .notice-icon.connection-accepted,
.notification-container .notification .avatar-holder .notice-icon.connection-request,
.notification-container .notification .avatar-holder .notice-icon.private-message,
.notification-container .notification .avatar-holder .notice-icon.status-increased {
  background-color: #376caf
}

.notification-container .notification .avatar-holder .notice-icon.post-comments,
.notification-container .notification .avatar-holder .notice-icon.post-like {
  background-color: #48a54c
}

.notification-container .notification .avatar-holder .notice-icon.status-warning {
  background-color: #f3bf50
}

.notification-container .notification .avatar-holder .notice-icon.status-decreased {
  background-color: #c93e27
}

.notification-container .notification .msg-text {
  display: inline-block;
  left: 70px;
  max-width: 85%
}

@media (max-width:479px) {
  .notification-container .notification .msg-text {
    max-width: 75%
  }
}

.notification-container .notification .msg-text span.message-author {
  font-weight: 700
}

.notification-container .view-all {
  display: block;
  clear: both;
  width: 100%;
  padding: .4375rem .625rem;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: #fff;
  background-color: #376caf
}

.notification-container.f-dropdown.content {
  padding: 0
}

@media (min-width:768px) {
  .notification-container.f-dropdown.content {
    max-width: 28.125rem
  }
}

.hcm-tagline {
  display: none
}

.hcm-products .hcm-tagline,
.hcm-takeover .hcm-tagline {
  display: block
}

.top-nav {
  background-color: #636468;
  height: 1px;
}

.top-nav a {
  font-family: Roboto, Helvetica Neue, Arial Narrow, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 100
}

.top-nav li,
.top-nav ul {
  list-style: none;
  padding: 0;
  margin: 0
}

.top-nav li .dd-menu,
.top-nav ul .dd-menu {
  margin-left: 0
}

.top-nav .secondary-nav>li>a {
  color: #fff
}

.top-nav .secondary-nav>li>a:hover {
  font-weight: 300
}

.top-nav #department-dd li {
  padding: .3125rem;
  margin: 0
}

.top-nav #department-dd li a {
  text-align: left
}

.top-nav #department-dd li:hover a {
  color: #fff;
  font-weight: 300
}

@media (max-width:767px) {
  .notification-container.f-dropdown.content {
    max-width: 18.75rem
  }
  .top-nav .container {
    padding: 0
  }
  .top-nav .secondary-nav {
    display: none
  }
  .top-nav .secondary-nav.menu-opened {
    display: block
  }
  .top-nav .secondary-nav a,
  .top-nav .secondary-nav ul a {
    font-size: 1.125rem;
    line-height: 2.375rem;
    font-weight: 300
  }
  .top-nav .secondary-nav .tertiary-nav>li #department-dd {
    max-width: 18.75rem
  }
  .top-nav .secondary-nav .tertiary-nav>li #department-dd li {
    border-bottom: solid 1px #CCC
  }
  .top-nav .secondary-nav .tertiary-nav>li #department-dd li:last-child {
    border: none
  }
  .top-nav .secondary-nav .tertiary-nav>li .mobile-home {
    display: inherit
  }
  .top-nav .has-dd {
    width: 100%;
    display: block
  }
  .top-nav .has-dd+.dd-menu {
    display: none
  }
  .top-nav .has-dd.dd-active+.dd-menu {
    display: block;
    background-color: #fff;
    padding: 0 1.25rem
  }
  .top-nav .has-dd.dd-active+.dd-menu a {
    color: #6b6563
  }
  .top-nav .has-dd.dd-active+.dd-menu a:hover {
    font-weight: 400
  }
}

@media (min-width:768px) {
  .top-nav .secondary-nav {
    position: relative;
    margin: 0 auto 3.125rem;
    padding: .625rem .5rem
  }
  .top-nav .secondary-nav>li {
    float: left
  }
  .top-nav .secondary-nav>li:first-of-type {
    margin-left: 0
  }
  .top-nav .secondary-nav>li a {
    position: relative;
    font-size: 1rem;
    line-height: 1.875rem;
    margin: 0;
    display: inline-block;
    text-align: center;
    letter-spacing: .0078125em
  }
  .top-nav .secondary-nav>li .tertiary-nav>li .mobile-home,
  .top-nav .secondary-nav>li a.nav-about {
    display: none
  }
  .top-nav .secondary-nav>li a.nav-community {
    width: 5.375rem
  }
  .top-nav .secondary-nav>li a.nav-ratings {
    width: 7rem
  }
  .top-nav .secondary-nav>li a.nav-news {
    width: 2.75rem
  }
  .top-nav .secondary-nav>li a.nav-events {
    width: 3.1875rem
  }
  .top-nav .secondary-nav>li a.nav-uni {
    width: 4.5625rem
  }
  .top-nav .secondary-nav>li a.nav-magazine {
    width: 5.125rem
  }
  .top-nav .secondary-nav>li a.nav-data,
  .top-nav .secondary-nav>li a.nav-hcm {
    width: 2.375rem
  }
  .top-nav .secondary-nav>li a.nav-data+.tertiary-nav>li,
  .top-nav .secondary-nav>li a.nav-hcm+.tertiary-nav>li {
    min-width: 190px
  }
  .top-nav .secondary-nav>li a.dd-active,
  .top-nav .secondary-nav>li a:hover {
    letter-spacing: 0
  }
  .top-nav .secondary-nav>li .tertiary-nav>li {
    float: left;
    text-align: center;
    min-width: 6.3125rem;
    margin: 0 .3125rem
  }
  .top-nav .secondary-nav>li .tertiary-nav>li:first-child {
    margin-left: 0
  }
  .top-nav .secondary-nav>li .tertiary-nav>li:last-child {
    margin-right: 0
  }
  .top-nav .secondary-nav .topnav-search {
    position: absolute;
    display: inline-block;
    right: 0;
    margin: 0;
    max-height: 1.875rem;
    z-index: 1
  }
  .top-nav .secondary-nav .topnav-search .input-group {
    max-width: 10.625rem
  }
  .top-nav .secondary-nav .topnav-search input {
    float: right;
    border: 1px solid #acacac;
    border-right: transparent;
    border-collapse: collapse;
    box-shadow: none;
    height: 1.875rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-appearance: textfield;
    -webkit-box-sizing: border-box;
    transition: all .5s
  }
  .top-nav .secondary-nav .topnav-search button {
    margin-bottom: 0;
    height: 1.875rem;
    font-size: .875rem;
    padding: 0 .25rem
  }
  .top-nav .secondary-nav .topnav-search form input[type=search] {
    margin: 0
  }
  .top-nav .secondary-nav .has-dd {
    width: 100%;
    display: block
  }
  .top-nav .secondary-nav .has-dd+.dd-menu.tertiary-nav {
    display: none
  }
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav {
    display: block;
    position: absolute;
    top: .125rem;
    left: 0;
    width: 100%;
    line-height: 2.5rem
  }
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav {
    min-width: 46.125rem;
    left: 0
  }
}

@media (min-width:768px) {
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav>li {
    float: left
  }
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav>li a {
    color: #444;
    font-weight: 300;
    text-align: center
  }
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav>li a:hover{
    font-weight: 500
  }


  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav>li a.dd-active,
  .top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav>li a:active {
    position: relative;
    font-weight: 400
  }
  .top-nav .secondary-nav .has-dd.dd-active {
    position: relative;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .top-nav .secondary-nav .has-dd.dd-active .dd-toggle {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 2.5rem;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #6b6563
  }
}

.footer,
.footer a {
  color: #fff
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .top-nav .secondary-nav {
    padding: .625rem 0
  }
  .top-nav .secondary-nav>li {
    margin: 0 .375rem
  }
}

@media (min-width:992px) {
  .top-nav .secondary-nav>li {
    margin: 0 1.125rem
  }
  .top-nav .secondary-nav .topnav-search .search-box {
    width: 7.8125rem
  }
}

@media (min-width:1200px) {
  .top-nav .secondary-nav>li {
    margin: 0 1.375rem
  }
  .top-nav .secondary-nav .topnav-search .search-box {
    width: 15.3125rem
  }
}

@media (max-width:767px) {
  .nav-bar .has-dd.nav-events,
  .nav-bar .off-site {
    width: 100%;
    padding: .375rem .9375rem;
    display: block
  }
  .nav-bar .has-dd.nav-events .dd-toggle,
  .nav-bar .off-site .dd-toggle {
    float: right;
    font-weight: 100
  }
  .nav-bar .has-dd.nav-events .dd-toggle:after,
  .nav-bar .off-site .dd-toggle:after {
    content: "\203a"
  }
}

.footer {
  background-color: #636468;
  clear: both;
  margin-top: 2.5rem
}

.footer .container {
  padding: 2.875rem .9375rem
}

.footer li,
.footer ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.footer .logo-tagline {
  margin-bottom: 1.5rem
}

.footer .logo-tagline .logo {
  width: 12.3125rem;
  height: 2.6875rem
}

.footer .logo-tagline .tagline {
  font-size: .9375rem;
  font-weight: 100;
  font-style: italic
}

@media (max-width:767px) {
  .footer .container {
    padding: .9375rem 0
  }
  .footer a:hover {
    text-decoration: none
  }
  .footer .logo-tagline {
    max-width: 18.75rem;
    margin: 0 0 .9375rem 1.125rem
  }
  .footer .logo-tagline .tagline {
    display: none;
    font-size: .875rem;
    margin-left: 3.125rem
  }
  .footer .bottomnav>li {
    float: none;
    clear: both;
    width: 100%
  }
  .footer .bottomnav>li .has-dd {
    border-top: solid 1px #fff;
    padding: .625rem 1.25rem;
    width: 100%
  }
  .footer .bottomnav>li .has-dd.dd-active {
    padding: .625rem 1.25rem 0
  }
}

.footer .bottomnav>li>a {
  font-size: 1rem;
  font-weight: 500
}

.footer .bottomnav .bottomsubnav {
  margin-left: 0
}

.footer .bottomnav .bottomsubnav li a {
  font-size: .9375rem;
  line-height: 1.5rem;
  font-weight: 100
}

.footer .bottomnav .bottomsubnav li a:hover {
  font-weight: 400
}

.footer .bottomnav.nav-left {
  margin: 0
}

@media (min-width:768px) {
  .footer .bottomnav.nav-left>li {
    float: left;
    width: 11.25rem
  }
  .footer .bottomnav.nav-left>li:last-child {
    width: 10.625rem;
    margin-left: .625rem
  }
}

@media (min-width:992px) {
  .footer .bottomnav.nav-left>li {
    width: 11.25rem
  }
}

@media (min-width:1200px) {
  .footer .bottomnav.nav-left>li {
    width: 11.875rem
  }
}

.footer .bottomnav.nav-right>li {
  margin: 0 auto
}

.footer .bottomnav.nav-right .social {
  display: block;
  max-width: 114px
}

.footer .bottomnav.nav-right .social>ul {
  height: 1.3125rem;
  margin-left: 0
}

.footer .bottomnav.nav-right .social .social-logo {
  float: left;
  background-color: #fff;
  color: #636468;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1.125rem;
  text-align: center;
  margin-left: .625rem
}

.footer .bottomnav.nav-right .social .social-logo i.fa {
  color: #636468;
  font-size: .8125rem
}

.footer .bottomnav.nav-right .social .social-logo i.fa.fa-twitter {
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding-left: .125rem
}

.footer .bottomnav.nav-right .social .social-logo:first-child {
  margin-left: 0
}

.footer .bottomnav.nav-right .getInvolved {
  margin: 0 0 1.25rem
}

.footer .bottomnav.nav-right .legal {
  font-size: .6875rem;
  line-height: 1rem;
  font-weight: 100;
  margin: 1.25rem 0 0
}

.footer .bottomnav.nav-right .legal a {
  font-size: .6875rem;
  font-weight: 100
}

@media (max-width:767px) {
  .footer .bottomnav.nav-right {
    clear: both;
    margin: 0;
    padding: 0
  }
  .footer .bottomnav.nav-right .getInvolved {
    margin: 0 0 1.25rem;
    border-bottom: solid 1px #fff
  }
  .footer .bottomnav.nav-right .social {
    margin-left: 1.25rem
  }
  .footer .bottomnav.nav-right .legal {
    border-top: solid 1px #fff;
    padding: .625rem 1.25rem
  }
  .footer .bottomnav.nav-right .legal .line-break {
    display: none
  }
}

@media (min-width:768px) and (max-width:991px) {
  .footer .bottomnav.nav-right {
    clear: both;
    text-align: center;
    padding-top: 1.25rem
  }
  .footer .bottomnav.nav-right .legal .line-break {
    display: none
  }
}

@media (min-width:992px) {
  .footer .bottomnav.nav-right {
    float: right;
    margin-top: -2.5625rem;
    padding-left: 2.3125rem;
    border-left: solid 1px #fff;
    position: relative
  }
  .footer .bottomnav.nav-right .getInvolved {
    position: relative;
    top: 2.5625rem;
    margin-bottom: 3.25rem
  }
  .footer .bottomnav.nav-right .social {
    position: absolute;
    top: 0;
    left: 2.375rem
  }
  .footer .bottomnav.nav-right .legal {
    position: relative;
    clear: both
  }
  .footer .bottomnav.nav-right .legal .link-break {
    display: none
  }
}

.nagbar {
  bottom: 0;
  position: fixed;
  z-index: 150;
  width: 100%;
  background: rgba(0, 0, 0, .7);
  display: none
}

.nag {
  padding: 4.375rem 0
}

.nag h2 {
  color: #fff;
  font-size: 2.25rem;
  line-height: 93px;
  vertical-align: middle
}

.nag a {
  float: right;
  margin: 24px 0
}

@media (min-width:768px) and (max-width:991px) {
  .nag h2 {
    font-size: 1.5rem
  }
}

@media (max-width:767px) {
  .nag h2 {
    font-size: 1.25rem;
    text-align: center
  }
  .nag a {
    float: none;
    margin: 10px auto
  }
}

@media (max-width:479px) {
  .nag {
    padding: .625rem 0
  }
  .nag h2 {
    font-size: 1rem;
    text-align: center;
    line-height: 50px;
    font-weight: 400
  }
  .nag .reveal-modal a.btn-lg.close-reveal-modal.btn-close,
  .nag a.btn.btn-lg,
  .reveal-modal .nag a.btn-lg.close-reveal-modal.btn-close {
    float: none;
    margin: 10px auto 0;
    font-size: 1rem;
    padding: .625rem 1.5rem
  }
  .nag img.avatar.large {
    margin: 10px calc(50% - 46px);
    width: 4rem;
    height: 4rem
  }
}

form label {
  display: inline-block;
  color: #444
}

form input[type=text],
form input[type=search],
form input[type=textarea],
form textarea[type=text],
form textarea[type=search],
form textarea[type=textarea] {
  display: inline-block;
  border: 1px solid #acacac;
  border-radius: 3px;
  padding: 0 .625rem;
  margin: .3125rem 0;
  font-size: 1rem;
  font-weight: 300
}

form input[type=text][disabled],
form input[type=search][disabled],
form input[type=textarea][disabled],
form textarea[type=text][disabled],
form textarea[type=search][disabled],
form textarea[type=textarea][disabled] {
  background-color: #f2f2f2
}

form input[type=text].has-warning,
form input[type=search].has-warning,
form input[type=textarea].has-warning,
form textarea[type=text].has-warning,
form textarea[type=search].has-warning,
form textarea[type=textarea].has-warning {
  border-color: #fddd1c
}

form input[type=text].has-error,
form input[type=search].has-error,
form input[type=textarea].has-error,
form textarea[type=text].has-error,
form textarea[type=search].has-error,
form textarea[type=textarea].has-error {
  border-color: #c93e27
}

form input[type=text].has-success,
form input[type=search].has-success,
form input[type=textarea].has-success,
form textarea[type=text].has-success,
form textarea[type=search].has-success,
form textarea[type=textarea].has-success {
  border-color: #0a9e02
}

form input[type=text][readonly],
form input[type=search][readonly],
form input[type=textarea][readonly],
form textarea[type=text][readonly],
form textarea[type=search][readonly],
form textarea[type=textarea][readonly] {
  color: #444;
  border: 0;
  background-color: #fff;
  outline: 0;
  box-shadow: none
}

form input[type=text][readonly]::-webkit-input-placeholder,
form input[type=search][readonly]::-webkit-input-placeholder,
form input[type=textarea][readonly]::-webkit-input-placeholder,
form textarea[type=text][readonly]::-webkit-input-placeholder,
form textarea[type=search][readonly]::-webkit-input-placeholder,
form textarea[type=textarea][readonly]::-webkit-input-placeholder {
  color: #444
}

form input[type=text][readonly] :-moz-placeholder,
form input[type=search][readonly] :-moz-placeholder,
form input[type=textarea][readonly] :-moz-placeholder,
form textarea[type=text][readonly] :-moz-placeholder,
form textarea[type=search][readonly] :-moz-placeholder,
form textarea[type=textarea][readonly] :-moz-placeholder {
  color: #444
}

form input[type=text][readonly] ::-moz-placeholder,
form input[type=search][readonly] ::-moz-placeholder,
form input[type=textarea][readonly] ::-moz-placeholder,
form textarea[type=text][readonly] ::-moz-placeholder,
form textarea[type=search][readonly] ::-moz-placeholder,
form textarea[type=textarea][readonly] ::-moz-placeholder {
  color: #444
}

form input[type=text][readonly] :-ms-input-placeholder,
form input[type=search][readonly] :-ms-input-placeholder,
form input[type=textarea][readonly] :-ms-input-placeholder,
form textarea[type=text][readonly] :-ms-input-placeholder,
form textarea[type=search][readonly] :-ms-input-placeholder,
form textarea[type=textarea][readonly] :-ms-input-placeholder {
  color: #444
}

form input[type=text]::-webkit-input-placeholder,
form input[type=search]::-webkit-input-placeholder,
form input[type=textarea]::-webkit-input-placeholder,
form textarea[type=text]::-webkit-input-placeholder,
form textarea[type=search]::-webkit-input-placeholder,
form textarea[type=textarea]::-webkit-input-placeholder {
  color: #9b9b9b
}

form input[type=text] :-moz-placeholder,
form input[type=search] :-moz-placeholder,
form input[type=textarea] :-moz-placeholder,
form textarea[type=text] :-moz-placeholder,
form textarea[type=search] :-moz-placeholder,
form textarea[type=textarea] :-moz-placeholder {
  color: #9b9b9b
}

form input[type=text] ::-moz-placeholder,
form input[type=search] ::-moz-placeholder,
form input[type=textarea] ::-moz-placeholder,
form textarea[type=text] ::-moz-placeholder,
form textarea[type=search] ::-moz-placeholder,
form textarea[type=textarea] ::-moz-placeholder {
  color: #9b9b9b
}

form input[type=text] :-ms-input-placeholder,
form input[type=search] :-ms-input-placeholder,
form input[type=textarea] :-ms-input-placeholder,
form textarea[type=text] :-ms-input-placeholder,
form textarea[type=search] :-ms-input-placeholder,
form textarea[type=textarea] :-ms-input-placeholder {
  color: #9b9b9b
}

form input textarea,
form input[type=textarea],
form textarea textarea,
form textarea[type=textarea] {
  display: block
}

form .form-element-icon {
  display: inline-block;
  position: relative
}

form .form-element-icon .material-icons {
  position: absolute;
  margin: .5rem .625rem 0;
  font-size: 1.875rem;
  color: #CCC;
  pointer-events: none
}

form .form-element-icon input {
  padding-left: 2.5rem
}

form .form-element-required {
  display: inline-block;
  position: relative
}

form .form-element-required .required-sticker {
  position: absolute;
  top: .3125rem;
  right: 0;
  z-index: 2;
  width: 0;
  height: 0;
  border-top: 1.875rem solid #c93e27;
  border-left: 1.875rem solid transparent
}

form .form-element-required .required-sticker span {
  position: absolute;
  z-index: 3;
  top: -1.625rem;
  right: .1875rem;
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem
}

form .description {
  height: 7.5rem
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate
}

.input-group input.form-control {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin: 0;
  border-radius: 3px 0 0 3px
}

.input-group .input-group-btn {
  position: relative;
  display: table-cell;
  font-size: 0;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle
}

.input-group .input-group-btn .reveal-modal button.close-reveal-modal.btn-close,
.input-group .input-group-btn button.btn,
.reveal-modal .input-group .input-group-btn button.close-reveal-modal.btn-close {
  position: relative;
  padding: .3125rem .625rem;
  border: 1px solid #acacac;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #acacac;
  background-color: #fff
}

.input-group .input-group-btn .reveal-modal button.close-reveal-modal.btn-close:hover,
.input-group .input-group-btn button.btn:hover,
.reveal-modal .input-group .input-group-btn button.close-reveal-modal.btn-close:hover {
  color: #fff;
  background-color: #acacac
}

@media (min-width:768px) {
  .reveal-modal .topnav-search .input-group .input-group-btn button.close-reveal-modal.btn-close,
  .topnav-search .input-group .input-group-btn .reveal-modal button.close-reveal-modal.btn-close,
  .topnav-search .input-group .input-group-btn button.btn {
    padding: 0 .25rem
  }
}

@media (max-width:767px) {
  .topnav-search .input-group {
    max-width: 96%;
    margin: 0 auto
  }
  .topnav-search .input-group .search-box {
    max-height: 2.25rem
  }
}

input[type=checkbox] {
  margin: 0
}

p:not(#ie8)>input[type=checkbox] {
  opacity: 0;
  float: left;
  width: 1.375rem;
  height: 1.375rem
}

p:not(#ie8)>input[type=checkbox]+label {
  margin: 0 0 .3125rem -1.5625rem;
  clear: none;
  padding: 0 0 0 1.875rem;
  cursor: pointer;
  background: url(../images/components/ds_custom-checkboxes.png) 0 -25px no-repeat
}

p:not(#ie8)>input[type=checkbox]:checked+label {
  background-position: 0 0
}

p:not(#ie8)>input[type=checkbox]:disabled+label,
p:not(#ie8)>input[type=checkbox]:disabled+label:hover,
p:not(#ie8)>input[type=checkbox]:disabled+label:hover:active,
p:not(#ie8)>input[type=checkbox]:focus:disabled+label,
p:not(#ie8)>input[type=checkbox]:hover:disabled+label {
  background-position: 0 -75px
}

p:not(#ie8)>input[type=checkbox]:disabled:checked+label,
p:not(#ie8)>input[type=checkbox]:disabled:checked+label:hover,
p:not(#ie8)>input[type=checkbox]:disabled:checked+label:hover:active,
p:not(#ie8)>input[type=checkbox]:focus:disabled:checked+label,
p:not(#ie8)>input[type=checkbox]:hover:disabled:checked+label {
  background-position: 0 -50px
}

p:not(#ie8)>input[type=checkbox].indeterminate+label,
p:not(#ie8)>input[type=checkbox].indeterminate+label:hover,
p:not(#ie8)>input[type=checkbox].indeterminate+label:hover:active,
p:not(#ie8)>input[type=checkbox]:focus.indeterminate+label,
p:not(#ie8)>input[type=checkbox]:focus:indeterminate+label,
p:not(#ie8)>input[type=checkbox]:hover.indeterminate+label,
p:not(#ie8)>input[type=checkbox]:hover:indeterminate+label,
p:not(#ie8)>input[type=checkbox]:indeterminate+label,
p:not(#ie8)>input[type=checkbox]:indeterminate+label:hover,
p:not(#ie8)>input[type=checkbox]:indeterminate+label:hover:active {
  background-position: 0 -100px
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox]+label {
  padding: 0 0 0 1.5rem;
  background: url(../images/components/ds_custom-checkboxes_h.png) -25px 0 no-repeat
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox]+label span {
  background-color: #fff;
  display: inline-block
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:checked+label {
  background-position: 0 0
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled+label:hover,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled+label:hover:active,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:focus:disabled+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:hover:disabled+label {
  background-position: 0 -75px
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled:checked+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled:checked+label:hover,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:disabled:checked+label:hover:active,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:focus:disabled:checked+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:hover:disabled:checked+label {
  background-position: 0 -50px
}

p:not(#ie8).horizontal-checkboxes>input[type=checkbox].indeterminate+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox].indeterminate+label:hover,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox].indeterminate+label:hover:active,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:focus.indeterminate+label,
p:not(#ie8).horizontal-checkboxes>input[type=checkbox]:hover.indeterminate+label {
  background-position: 0 -100px
}

p:not(#ie8).horizontal-wrap {
  display: inline-block;
  padding: 0 8px 0 0;
  margin: 0
}

p:not(#ie8).horizontal-wrap>input[type=checkbox]+label {
  padding: 0 0 0 1.375rem
}

input[type=radio] {
  margin: 0
}

p:not(#ie8)>input[type=radio] {
  opacity: 0;
  float: left;
  width: 1.5rem;
  height: 1.5rem
}

p:not(#ie8)>input[type=radio]+label {
  margin: 0 0 .3125rem -1.75rem;
  clear: none;
  line-height: 1.5rem;
  padding: 0 0 0 1.875rem;
  cursor: pointer;
  background: url(../images/components/ds_custom-radios.png) 0 -25px no-repeat
}

p:not(#ie8)>input[type=radio]:checked+label {
  background-position: 0 0
}

p:not(#ie8)>input[type=radio]:disabled+label,
p:not(#ie8)>input[type=radio]:disabled+label:hover,
p:not(#ie8)>input[type=radio]:disabled+label:hover:active,
p:not(#ie8)>input[type=radio]:focus:disabled+label,
p:not(#ie8)>input[type=radio]:hover:disabled+label {
  background-position: 0 -75px
}

p:not(#ie8)>input[type=radio]:disabled:checked+label,
p:not(#ie8)>input[type=radio]:disabled:checked+label:hover,
p:not(#ie8)>input[type=radio]:disabled:checked+label:hover:active,
p:not(#ie8)>input[type=radio]:focus:disabled:checked+label,
p:not(#ie8)>input[type=radio]:hover:disabled:checked+label {
  background-position: 0 -50px
}

.switch label {
  color: transparent;
  background: #b6b6b6
}

.switch input:checked+label {
  background: #376caf
}

.range-slider,
.range-slider.round {
  background-color: #eceff1
}

.switch label:before {
  content: "OFF";
  color: #fff;
  font-size: .875rem;
  font-weight: 300;
  display: block;
  position: relative;
  top: .375rem;
  left: -2rem;
  max-width: 0;
  transition: left .15s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.switch input:checked+label:before {
  content: "ON";
  left: -3.5rem
}

.switch.large label:before {
  font-size: 1rem;
  left: -2.5rem;
  top: .5rem
}

.switch.large input:checked+label:before {
  left: -4.25rem
}

.switch.small label:before {
  font-size: .75rem;
  left: -1.75rem
}

.switch.small input:checked+label:before {
  left: -3rem
}

.switch.tiny label:before {
  font-size: .625rem;
  left: -1.5rem;
  top: .3125rem
}

.switch.tiny input:checked+label:before {
  left: -2.625rem
}

.range-slider {
  height: .4375rem;
  border-color: #b4c1c8
}

.range-slider.round .range-slider-handle {
  border-radius: 50%;
  width: 1.375rem;
  height: 1.375rem;
  top: -.53rem
}

.range-slider.round .range-slider-handle:active,
.range-slider.round .range-slider-handle:focus {
  outline: 0
}

.range-slider .range-slider-handle,
.range-slider.round .range-slider-handle {
  background-color: #376caf;
  opacity: 1
}

.range-slider .range-slider-handle:hover,
.range-slider.round .range-slider-handle:hover {
  background-color: #376caf
}

.range-slider .range-slider-active-segment {
  height: .375rem;
  background-color: #376caf
}

.range-slider.disabled .range-slider-handle,
.range-slider[disabled] .range-slider-handle {
  background: #b6b6b6;
  cursor: not-allowed;
  opacity: 1
}

.range-slider.disabled .range-slider-handle:hover,
.range-slider[disabled] .range-slider-handle:hover {
  background-color: #b6b6b6
}

.round.range-slider[data-slider='0'] .range-slider-handle {
  left: -.5625rem
}

.round.range-slider[data-slider='100'] .range-slider-handle {
  left: .5625rem
}

.progress.recommend-rating {
  margin-left: .1875rem;
  max-width: 7.8125rem
}

.progress.recommend-rating .meter {
  background-color: #cc6500
}

.vendors-home.content {
  margin-top: 0
}

.vendors-home .hero-banner {
  background-color: rgba(0, 0, 0, .35);
  background-repeat: no-repeat;
  background-position: center top
}

.vendors-home .hero-banner .container {
  height: auto
}

@media (max-width:767px) {
  .vendors-home .hero-banner {
    background-image: url(../images/category-banners/vendor-ratings-home_sm.jpg)
  }
}

@media (min-width:768px) and (max-width:991px) {
  .vendors-home .hero-banner {
    background-image: url(../images/category-banners/vendor-ratings-home_md.jpg)
  }
  .vendors-home .hero-banner .container {
    padding: 0
  }
}

@media (min-width:992px) {
  .vendors-home .hero-banner {
    background-image: url(../images/category-banners/vendor-ratings-home_lg.jpg)
  }
}

@media (min-width:1200px) {
  .vendors-home .hero-banner {
    background-image: url(../images/category-banners/vendor-ratings-home_xl.jpg);
    background-repeat: no-repeat;
    background-position: center top
  }
}

@media (min-width:1920px) {
  .vendors-home .hero-banner {
    background: url(../images/category-banners/vendor-ratings-home_xl.jpg), #656160;
    background: url(../images/category-banners/vendor-ratings-home_xl.jpg), linear-gradient(to right, #95958b 49%, #656160 51%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95958b', endColorstr='#656160', GradientType=1);
    background-repeat: no-repeat;
    background-position: center top
  }
}

.vendors-home .hero-banner .container h2 {
  color: #fff;
  font-weight: 300
}

.vendors-home .hero-banner .container .search-verified {
  color: #fff;
  float: left
}

.vendors-home .hero-banner .container .search-verified span {
  font-size: 1.125rem;
  font-weight: 100
}

.vendors-home .hero-banner .container .search-verified span strong,
.vendors-home .hero-banner .container .search-verified span.verified {
  font-weight: 500
}

.vendors-home .hero-banner .container .search-verified span.bullet {
  margin: 0 .9375rem
}

.vendors-home .hero-banner .container .search-verified span.verified img {
  max-width: 1.875rem
}

.vendors-home .hero-banner .container .search-verified .input-group {
  margin-top: 1.25rem;
  max-width: 29.8125rem
}

.vendors-home .hero-banner .container .btn-orange {
  float: right;
  margin-top: 2.1875rem
}

@media (min-width:992px) {
  .vendors-home .hero-banner .container h2 {
    margin: 5.3125rem 0 1.875rem;
    line-height: 3.125rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .vendors-home .hero-banner .container h2 {
    margin: 5rem 0;
    line-height: 2.1875rem
  }
}

@media (max-width:767px) {
  .vendors-home .hero-banner .container h2 {
    margin: 2.8125rem 0 1.875rem;
    line-height: 1.6875rem
  }
  .vendors-home .hero-banner .container .search-verified {
    max-width: 50%;
    text-align: center
  }
  .vendors-home .hero-banner .container .search-verified span {
    font-weight: 300
  }
  .vendors-home .hero-banner .container .search-verified span.ratings,
  .vendors-home .hero-banner .container .search-verified span.vendors,
  .vendors-home .hero-banner .container .search-verified span.verified {
    display: block;
    margin: 0 auto
  }
  .vendors-home .hero-banner .container .search-verified .bullet {
    display: none
  }
  .vendors-home .hero-banner .container .search-verified .input-group {
    margin-top: .3125rem
  }
  .vendors-home .hero-banner .container .btn-orange {
    margin-top: 5rem;
    padding: .3125rem .625rem .3125rem .3125rem;
    font-size: .875rem
  }
  .vendors-home .hero-banner .container .btn-orange svg {
    width: 1rem;
    height: 1rem
  }
}

@media (max-width:479px) {
  .vendors-home .hero-banner .container h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1rem 0
  }
  .vendors-home .hero-banner .container h2 br {
    display: none
  }
  .vendors-home .hero-banner .container .search-verified {
    max-width: 100%;
    text-align: left
  }
  .vendors-home .hero-banner .container .search-verified span.ratings,
  .vendors-home .hero-banner .container .search-verified span.vendors {
    display: inline-block
  }
  .vendors-home .hero-banner .container .search-verified span.verified {
    display: block
  }
  .vendors-home .hero-banner .container .search-verified .bullet.one {
    display: inline-block;
    margin: 0 .3125rem
  }
  .vendors-home .hero-banner .container .search-verified .input-group {
    margin-top: .625rem;
    width: 80%
  }
  .vendors-home .hero-banner .container .search-verified .input-group .input-group-btn button {
    margin-bottom: 0
  }
  .vendors-home .hero-banner .container .btn-orange {
    margin-top: .9375rem;
    float: none
  }
}

.vendor-home.main-content .how-it-works {
  background-color: #cbd9ea;
  padding: 2.8125rem 2rem 2.1875rem;
  text-align: center
}

.vendor-home.main-content .how-it-works .btn-stroked-orange {
  clear: both;
  margin: 0 auto;
  background-color: transparent;
  border-width: 2px
}

.vendor-home.main-content .how-it-works .btn-stroked-orange:active,
.vendor-home.main-content .how-it-works .btn-stroked-orange:focus,
.vendor-home.main-content .how-it-works .btn-stroked-orange:hover {
  background-color: #f9f0e9
}

.vendor-home.main-content .how-it-works .title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
  margin-top: 0
}

.vendor-home.main-content .how-it-works ol {
  position: relative;
  list-style: none;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0
}

.vendor-home.main-content .how-it-works ol li {
  position: relative;
  padding: 0;
  margin: 0 auto 1.25rem;
  text-align: center
}

.vendor-home.main-content .how-it-works ol li img {
  display: block;
  margin: 0 auto;
  max-width: 6.9375rem;
  max-height: 6.9375rem
}

.vendor-home.main-content .how-it-works ol li strong {
  display: block;
  font-size: 1.25rem;
  font-weight: 300;
  color: #444
}

.vendor-home.main-content .how-it-works ol li .step {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #444
}

@media (min-width:768px) {
  .vendor-home.main-content .how-it-works ol li {
    float: left;
    width: 30%
  }
  .vendor-home.main-content .how-it-works ol li:nth-child(2) {
    margin: auto 5%
  }
  .vendor-home.main-content .how-it-works ol li strong {
    margin: 1.25rem 0 .75rem
  }
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .vendor-home.main-content .how-it-works ol li .line-break {
    display: none
  }
}

@media (max-width:767px) {
  .vendor-home.main-content .how-it-works ol li {
    clear: both;
    min-height: 5rem;
    margin-bottom: 3.125rem;
    text-align: left
  }
  .vendor-home.main-content .how-it-works ol li img {
    float: left;
    margin-right: .625rem
  }
  .vendor-home.main-content .how-it-works ol li strong {
    display: inline-block;
    margin: .9375rem 0 .3125rem
  }
  .allVendors .hero-banner,
  .byCategory .hero-banner {
    background-image: url(../images/category-banners/allvendors_sm.jpg)
  }
  .allVendors .hero-banner .container,
  .byCategory .hero-banner .container {
    height: 13.3125rem
  }
}

@media (max-width:767px) and (min-width:480px) {
  .vendor-home.main-content .how-it-works ol li .line-break {
    display: none
  }
}

@media (max-width:479px) {
  .vendor-home.main-content .how-it-works {
    padding: 2.1875rem 1.375rem 1.5625rem
  }
  .vendor-home.main-content .how-it-works ol li {
    text-align: center
  }
  .vendor-home.main-content .how-it-works ol li img {
    float: none;
    margin: 0 auto
  }
  .vendor-home.main-content .how-it-works ol li .step,
  .vendor-home.main-content .how-it-works ol li strong {
    display: block
  }
}

.allVendors .hero-banner,
.byCategory .hero-banner {
  background-color: #0a2554;
  background-repeat: no-repeat;
  background-position: center top
}

@media (min-width:768px) and (max-width:991px) {
  .allVendors .hero-banner,
  .byCategory .hero-banner {
    background-image: url(../images/category-banners/allvendors_md.jpg)
  }
  .allVendors .hero-banner .container,
  .byCategory .hero-banner .container {
    height: 13.3125rem
  }
}

@media (min-width:992px) {
  .allVendors .hero-banner,
  .byCategory .hero-banner {
    background-image: url(../images/category-banners/allvendors_lg.jpg)
  }
  .allVendors .hero-banner .container,
  .byCategory .hero-banner .container {
    height: 13.3125rem
  }
}

@media (min-width:1200px) {
  .allVendors .hero-banner,
  .byCategory .hero-banner {
    background-image: url(../images/category-banners/allvendors_xl.jpg);
    background-repeat: no-repeat;
    background-position: center top
  }
}

.allVendors .hero-banner .container .search-verified,
.byCategory .hero-banner .container .search-verified {
  color: #fff;
  float: left
}

@media (min-width:992px) and (max-width:1199px),
(min-width:768px) and (max-width:991px) {
  .allVendors .hero-banner .container .search-verified,
  .byCategory .hero-banner .container .search-verified {
    max-width: 70%
  }
}

.allVendors .hero-banner .container .search-verified span,
.byCategory .hero-banner .container .search-verified span {
  font-size: 1.125rem;
  font-weight: 100
}

.allVendors .hero-banner .container .search-verified span .linebreak,
.byCategory .hero-banner .container .search-verified span .linebreak {
  display: none
}

.allVendors .hero-banner .container .search-verified span.hero-description,
.byCategory .hero-banner .container .search-verified span.hero-description {
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 75%
}

@media (max-width:479px) {
  .allVendors .hero-banner .container .search-verified span.hero-description,
  .byCategory .hero-banner .container .search-verified span.hero-description {
    font-size: 1rem;
    line-height: 1.125rem
  }
}

.allVendors .hero-banner .container .search-verified .input-group,
.byCategory .hero-banner .container .search-verified .input-group {
  margin-top: 1.25rem;
  max-width: 29.8125rem
}

.allVendors .hero-banner .container .btn-ghost,
.allVendors .hero-banner .container .btn-orange,
.byCategory .hero-banner .container .btn-ghost,
.byCategory .hero-banner .container .btn-orange {
  float: right;
  margin-top: .9375rem
}

@media (min-width:992px) {
  .allVendors .hero-banner .container h2,
  .byCategory .hero-banner .container h2 {
    margin: 2.1875rem 0 1.25rem;
    line-height: 3.125rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .allVendors .hero-banner .container h2,
  .byCategory .hero-banner .container h2 {
    margin: 1.875rem 0 .9375rem;
    line-height: 2.1875rem
  }
  .allVendors .hero-banner .container .search-verified .instructions .linebreak,
  .byCategory .hero-banner .container .search-verified .instructions .linebreak {
    display: inherit
  }
  .allVendors .hero-banner .container .search-verified .input-group,
  .byCategory .hero-banner .container .search-verified .input-group {
    margin-top: 0
  }
}

@media (max-width:767px) {
  .allVendors .hero-banner .container h2,
  .byCategory .hero-banner .container h2 {
    margin: .9375rem 0 0;
    line-height: 1.6875rem
  }
  .allVendors .hero-banner .container .search-verified,
  .byCategory .hero-banner .container .search-verified {
    max-width: 100%
  }
  .allVendors .hero-banner .container .search-verified .instructions,
  .byCategory .hero-banner .container .search-verified .instructions {
    display: inline-block;
    margin: .625rem 0 .3125rem;
    font-weight: 300;
    font-size: .75rem;
    line-height: 1rem
  }
  .allVendors .hero-banner .container .search-verified .input-group,
  .byCategory .hero-banner .container .search-verified .input-group {
    margin: 0
  }
  .allVendors .hero-banner .container .btn-orange,
  .byCategory .hero-banner .container .btn-orange {
    margin-top: .9375rem;
    padding: .3125rem .625rem .3125rem .3125rem;
    font-size: .875rem
  }
  .allVendors .hero-banner .container .btn-orange svg,
  .byCategory .hero-banner .container .btn-orange svg {
    width: 1rem;
    height: 1rem
  }
}

@media (max-width:767px) and (min-width:480px) and (max-width:555px) {
  .allVendors .hero-banner .container h2,
  .byCategory .hero-banner .container h2 {
    margin: .3125rem 0 0
  }
  .allVendors .hero-banner .container .search-verified .instructions,
  .byCategory .hero-banner .container .search-verified .instructions {
    margin: .3125rem 0
  }
  .allVendors .hero-banner .container .btn-orange,
  .byCategory .hero-banner .container .btn-orange {
    margin-top: .3125rem
  }
}

@media (max-width:479px) {
  .allVendors .hero-banner .container h2,
  .byCategory .hero-banner .container h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: .9375rem .625rem .625rem 0
  }
  .allVendors .hero-banner .container .search-verified,
  .byCategory .hero-banner .container .search-verified {
    line-height: 1.125rem
  }
  .allVendors .hero-banner .container .search-verified .input-group,
  .byCategory .hero-banner .container .search-verified .input-group {
    margin-top: .625rem;
    width: 80%
  }
  .allVendors .hero-banner .container .search-verified .instructions,
  .byCategory .hero-banner .container .search-verified .instructions {
    margin: .3125rem 0 0
  }
  .allVendors .hero-banner .container .btn-orange,
  .byCategory .hero-banner .container .btn-orange {
    float: none
  }
}

@media (min-width:768px) {
  .allVendors.productsView .hero-content .hero-description,
  .byCategory.productsView .hero-content .hero-description {
    max-width: 65%;
    float: left;
    clear: left
  }
  .allVendors.productsView .hero-content .hero-description+.btn-orange,
  .byCategory.productsView .hero-content .hero-description+.btn-orange {
    margin-top: 0
  }
}

.allVendors .category-ad,
.byCategory .category-ad {
  margin: 1rem auto 0
}

@media (min-width:992px) {
  .allVendors .category-ad,
  .byCategory .category-ad {
    width: 1145px;
    height: 94px
  }
}

.allVendors .category-ad>a>img,
.byCategory .category-ad>a>img {
  max-width: 100%
}

@media (max-width:479px) {
  .allVendors .hero-description {
    font-size: 1rem;
    line-height: 1.125rem;
    margin-bottom: .625rem
  }
  .allVendors .input-group-btn button {
    margin-bottom: 0
  }
}

.category-page.byCategory .main-content .vendor-cat {
  display: block;
  clear: both;
  margin: 1.5625rem 0
}

.category-page.byCategory .main-content .vendor-cat strong.parent-cat-title {
  display: block;
  clear: both;
  color: #444;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 300
}

.category-page.byCategory .main-content .vendor-cat .sub-cat {
  display: inline-block;
  width: 30%;
  margin: .9375rem 2% .9375rem 0
}

.category-page.byCategory .main-content .vendor-cat .sub-cat strong.sub-cat-title {
  color: #376caf;
  font-size: 1.25rem;
  font-weight: 300
}

.category-page.byCategory .main-content .vendor-cat .sub-cat span {
  color: #444;
  display: block;
  font-size: .875rem
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .allVendors.productsView .pagination-content .filter-container+.headline-border.first,
  .byCategory.productsView .pagination-content .filter-container+.headline-border.first {
    clear: both
  }
  .category-page.byCategory .main-content .vendor-cat .sub-cat {
    width: 46%
  }
}

.category-page.byCategory .main-content .arrow-cta {
  clear: both;
  float: right;
  font-weight: 300
}

.vendor-home.main-content .column-left h3,
.vendor-home.main-content .column-right h3 {
  margin-top: 1.25rem
}

.vendor-home.main-content a.arrow-cta .material-icons {
  color: #7e7877
}

.allVendors .main-content .column-left.byName,
.allVendors .main-content .pagination-centered {
  margin-top: 1.875rem
}

h3 .verified-reviews {
  display: block;
  clear: both;
  font-size: 14px;
  color: #376caf
}

.recentVRs h3 .btn,
.recentVRs h3 .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .recentVRs h3 .close-reveal-modal.btn-close {
  float: right
}

.recentVRs .review {
  display: block;
  clear: both;
  border-bottom: solid 1px #ccc;
  margin-top: 20px
}

.recentVRs .pagination-centered {
  width: 100%;
  position: relative;
  margin-top: 1.25rem
}

@media (min-width:768px) and (max-width:991px) {
  .recentVRs .verified {
    display: none
  }
}

.buyers-guide.column-two-thirds {
  text-align: center
}

.buyers-guide.column-two-thirds h3 {
  text-align: left
}

.buyers-guide.column-two-thirds img {
  width: 6.8125rem;
  margin: 0 1.125rem 0 0;
  float: left
}

.buyers-guide.column-two-thirds p {
  float: left;
  max-width: 79%;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem
}

.buyers-guide.column-two-thirds .get-guides {
  display: block;
  clear: both;
  margin: 0 auto
}

.buyers-guide.column-two-thirds .get-guides ul li {
  white-space: nowrap;
  text-align: right
}

.buyers-guide.column-two-thirds .get-guides a.buyer-guide {
  white-space: nowrap;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin: 0 1.25rem
}

@media (min-width:768px) and (max-width:991px) {
  .buyers-guide.column-two-thirds img {
    float: left;
    display: inline-block;
    margin-right: .4375rem
  }
  .buyers-guide.column-two-thirds p {
    float: none;
    max-width: 100%;
    font-size: .875rem
  }
  .buyers-guide.column-two-thirds .get-guides a {
    display: block
  }
}

@media (max-width:767px) {
  .vendor-home.main-content .column-left h3,
  .vendor-home.main-content .column-right h3 {
    clear: both
  }
  .buyers-guide.column-two-thirds img {
    margin: .625rem .625rem .625rem 0
  }
  .buyers-guide.column-two-thirds p {
    max-width: 73%
  }
  .buyers-guide.column-two-thirds .get-guides a.buyer-guide {
    margin: 0
  }
  .buyers-guide.column-two-thirds .get-guides a.buyer-guide:first-child {
    margin: 0 .625rem 0 0
  }
}

@media (max-width:479px) {
  .buyers-guide.column-two-thirds img {
    float: none;
    max-width: 100%;
    margin: .625rem 0
  }
  .buyers-guide.column-two-thirds p {
    float: none;
    max-width: 100%
  }
  .buyers-guide.column-two-thirds .get-guides a {
    display: block
  }
}

.buyers-guide.column-one-third img {
  max-width: 5.3125rem;
  margin: 0 1.125rem 0 0;
  float: left
}

.buyers-guide.column-one-third strong {
  color: #376caf;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem
}

.buyers-guide.column-one-third p {
  font-size: 1rem;
  line-height: 1.375rem
}

.buyers-guide.column-one-third .get-guides {
  list-style: none;
  margin: 0;
  padding: 0
}

.buyers-guide.column-one-third .get-guides li {
  width: 75%;
  float: right;
  white-space: nowrap;
  margin: .0625rem 0;
  text-align: right
}

.buyers-guide.column-one-third .get-guides li:nth-child(odd) {
  margin-right: 0
}

.buyers-guide.column-one-third .get-guides li a {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem
}

.buyers-guide.column-one-third .get-guides li a .material-icons {
  color: #636468
}

@media (min-width:768px) and (max-width:991px) {
  .buyers-guide.column-one-third .get-guides li {
    width: 47%
  }
  .buyers-guide.column-one-third .get-guides li:nth-child(odd) {
    margin-right: 6%
  }
}

@media (max-width:767px) {
  .buyers-guide.column-one-third .get-guides {
    display: block;
    -webkit-box-flex: 0;
    flex: none;
    clear: both
  }
  .buyers-guide.column-one-third .get-guides li {
    float: left
  }
  .buyers-guide.column-one-third+div {
    clear: both;
    padding-top: .0625rem
  }
}

@media (max-width:479px) {
  .buyers-guide.column-one-third .get-guides li {
    float: none;
    width: 100%
  }
}

.vendor-categories .categories-table.categories-list {
  display: table;
  border: 0;
  margin-top: 1.875rem
}

.vendor-categories .categories-table.categories-list .table-header {
  background-color: #376caf;
  color: #fff;
  font-size: 1rem
}

.vendor-categories .categories-table.categories-list .row {
  display: table-row
}

.vendor-categories .categories-table.categories-list .row .cell {
  padding: .75rem .9375rem;
  vertical-align: middle
}

.vendor-categories .categories-table.categories-list .row .cell.label-title {
  text-align: left
}

.vendor-categories .categories-table.categories-list .row .cell.count {
  width: 20%;
  text-align: center;
  border-left: solid 0 #89a9d0;
  color: #7e7877;
  font-weight: 400
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .vendor-categories .categories-table.categories-list .row .cell {
    font-size: .875rem;
    padding: .5rem .625rem
  }
}

.vendor-categories .categories-table.categories-list .row .center {
  text-align: center
}

.vendor-categories .categories-table.categories-list .row .categories-title {
  font-size: 1.125rem;
  font-weight: 400
}

.vendor-categories .categories-table.categories-list .row .meta-icons {
  font-size: .875rem;
  font-weight: 500
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .vendor-categories .categories-table.categories-list .row .categories-title {
    float: left;
    font-size: .875rem;
    font-weight: 400
  }
  .vendor-categories .categories-table.categories-list .row .meta-icons,
  .vendor-categories .categories-table.categories-list .row .meta-icons a {
    font-weight: 400
  }
  .vendor-categories .categories-table.categories-list .row .meta-icons .comments,
  .vendor-categories .categories-table.categories-list .row .meta-icons .views {
    display: block
  }
}

.load-more {
  margin-top: .625rem
}

.recognize-excellence .excellence.award {
  margin: 1.25rem auto
}

.recognize-excellence .award.description {
  display: block;
  text-align: left;
  font-style: italic;
  font-weight: 300
}

.recognize-excellence .award+.btn-midi {
  float: right;
  margin-top: 1.25rem
}

@media (max-width:767px) {
  .vendor-categories .categories-table.categories-list .table-striped {
    width: 100%;
    border-bottom: none
  }
  .vendor-categories .categories-table.categories-list .table-striped:last-child {
    border-bottom: solid 1px #89a9d0;
    border-top: none
  }
  .vendor-categories .categories-table.categories-list .table-striped:last-child .row:nth-of-type(odd) {
    background: #f1f4f8
  }
  .vendor-categories .categories-table.categories-list .table-striped:last-child .row:nth-of-type(even) {
    background: #fff
  }
  .recognize-excellence .award {
    display: inline-block;
    float: left;
    max-width: 49%
  }
  .recognize-excellence .award.excellence {
    margin-right: 2%
  }
  .recognize-excellence .award+.btn-midi {
    float: none;
    clear: both;
    width: 100%;
    margin: 1.25rem auto
  }
}

@media (max-width:479px) {
  .recognize-excellence .award {
    display: block;
    clear: both;
    float: none;
    max-width: 100%;
    margin: 1.25rem auto
  }
  .recognize-excellence .award.excellence {
    width: 100%
  }
  .recognize-excellence .award+.btn-midi {
    margin: 0
  }
}

.byName .arrow-cta,
.vendor-category+.arrow-cta {
  float: right;
  clear: both
}

.vendor-category {
  margin-top: 1.25rem
}

.vendor-category:first-child {
  margin-top: 0
}

.vendor-category .category-title {
  color: #444;
  font-weight: 300;
  font-size: 1.125rem
}

.vendor-category a.vendor-subcat {
  display: block;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem
}

@media (min-width:992px),
(max-width:767px) and (min-width:615px) {
  .column-container {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 3%;
    column-gap: 3%;
    -webkit-column-width: 30%;
    column-width: 30%
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:614px) and (min-width:480px) {
  .column-container {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 2%;
    column-gap: 2%;
    -webkit-column-width: 49%;
    column-width: 49%
  }
}

.column-container .vendor-category {
  display: inline-block;
  margin: 0 0 .3125rem;
  width: 100%
}

@media (min-width:992px) {
  .byName .search-vendors {
    max-width: 50%;
    float: right;
    margin-top: -2.8125rem
  }
}

.byName .tabs.horizontal {
  clear: both;
  margin: 0;
  width: 100%
}

.byName .tabs.horizontal li.tab-title {
  position: relative;
  width: 3.75%;
  z-index: 1
}

.byName .tabs.horizontal li.tab-title a {
  font-family: monospace;
  position: relative;
  background-color: #f2f2f2;
  color: #376caf;
  font-weight: 100;
  font-size: 1rem;
  padding: .3125rem 0;
  text-align: center;
  max-height: 2.1875rem;
  min-height: 1rem;
  border-top: 5px solid transparent
}

.byName .tabs.horizontal li.tab-title a:hover {
  color: #5da5ff
}

.byName .tabs.horizontal li.tab-title.active {
  overflow: hidden;
  background-color: transparent;
  height: 2.1875rem;
  padding: 0 .3125rem;
  margin: 0 -.3125rem;
  z-index: 2
}

.byName .tabs.horizontal li.tab-title.active:last-child {
  margin: 0 -.25rem 0 -.375rem
}

.byName .tabs.horizontal li.tab-title.active a {
  border-top-color: #376caf;
  background-color: #fff;
  color: #636468;
  font-weight: 500;
  padding: .3125rem
}

.byName .tabs.horizontal li.tab-title.active a:hover {
  background-color: #fff
}

@media (min-width:992px) and (max-width:1199px) {
  .byName .tabs.horizontal li.tab-title.active {
    padding: 0 .3125rem 0 .0625rem
  }
  .byName .tabs.horizontal li.tab-title.active:first-child {
    margin: 0 -.4375rem 0 -.0625rem
  }
  .byName .tabs.horizontal li.tab-title.active:last-child {
    margin: remc-calc(0 -4px)
  }
}

@media (max-width:767px) {
  .byName .tabs.horizontal li.tab-title {
    width: 3.73%
  }
  .byName .tabs.horizontal li.tab-title a {
    font-size: .75rem;
    font-weight: 300
  }
  .byName .tabs.horizontal li.tab-title.active {
    width: 4.75%;
    text-align: center;
    padding: 0 .3125rem;
    margin: 0 -.4375rem
  }
  .byName .tabs.horizontal li.tab-title.active:first-child,
  .byName .tabs.horizontal li.tab-title.active:last-child {
    margin: 0 -.4375rem 0 -.375rem
  }
  .byName .tabs.horizontal li.tab-title.active a {
    padding: .3125rem .125rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .36)
  }
  .byName .tabs.horizontal li.tab-title.active:first-child a,
  .byName .tabs.horizontal li.tab-title.active:last-child a {
    padding: .3125rem .4375rem
  }
}

@media (max-width:767px) and (max-width:600px) {
  .byName .tabs.horizontal li.tab-title.active:first-child a,
  .byName .tabs.horizontal li.tab-title.active:last-child a {
    padding: .3125rem .1875rem
  }
}

.byName .tabs.horizontal+.tabs-content .content {
  padding: 1.875rem
}

.byName .tabs.horizontal+.tabs-content .content.active {
  margin-top: 0
}

.byName .tabs.horizontal+.tabs-content .content .vendor-subcat {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: .3125rem 0
}

.byName .tabs.horizontal+.tabs-content .content ul {
  padding: 0;
  margin: 0
}

.byName .tabs.horizontal+.tabs-content .content ul li {
  list-style: disc;
  margin-left: 10px;
  color: #376caf
}

.byName .tabs.horizontal+.tabs-content .content ul li:hover {
  color: #5da5ff
}

@media (min-width:992px),
(max-width:767px) and (min-width:480px) {
  .byName .tabs.horizontal+.tabs-content .content ul {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 2%;
    column-gap: 2%;
    -webkit-column-width: 49%;
    column-width: 49%
  }
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .byName {
    height: 100%
  }
  .byName .tabs.horizontal {
    float: left;
    width: 10%;
    margin-right: 5%;
    height: 75%
  }
  .byName .tabs.horizontal li.tab-title {
    float: left;
    clear: left;
    width: 100%;
    height: 4%;
    padding: 0;
    margin: 0
  }
  .byName .tabs.horizontal li.tab-title a {
    padding: .3125rem;
    margin: 0;
    width: 100%;
    height: 100%;
    line-height: 1.0625rem;
    vertical-align: middle;
    border-top: none;
    border-left: 5px solid #fff;
    box-shadow: inset -5px 0 5px -4px rgba(0, 0, 0, .36)
  }
  .byName .tabs.horizontal li.tab-title.active {
    width: 100%;
    padding: 0;
    margin: 0
  }
  .byName .tabs.horizontal li.tab-title.active a {
    line-height: 2.1875rem;
    font-size: 1rem;
    font-weight: 500;
    color: #444;
    width: 100%;
    padding: 0;
    margin: 0;
    border-left-color: #376caf
  }
  .byName .tabs.horizontal li.tab-title.active:first-child,
  .byName .tabs.horizontal li.tab-title.active:first-child a,
  .byName .tabs.horizontal li.tab-title.active:last-child,
  .byName .tabs.horizontal li.tab-title.active:last-child a {
    padding: 0;
    margin: 0
  }
  .byName .tabs.horizontal li.tab-title.active:first-child a {
    box-shadow: inset 0 9px 5px -9px rgba(0, 0, 0, .75)
  }
  .byName .tabs.horizontal li.tab-title.active:last-child a {
    box-shadow: inset 0 -9px 5px -9px rgba(0, 0, 0, .75)
  }
  .byName .tabs.horizontal+.tabs-content {
    float: left;
    max-width: 85%
  }
  .byName .tabs.horizontal+.tabs-content .active {
    padding: 0
  }
}

@media (max-width:479px) {
  .byName .arrow-cta {
    display: block;
    clear: both;
    margin-top: 1.5625rem
  }
  .byName+div {
    clear: both;
    padding-top: 1px
  }
}

.column-one-third.column-left #panelCategory,
.column-one-third.column-left #panelName {
  padding: 0
}

.column-one-third.column-left .vendor-category {
  margin-top: 0;
  margin-bottom: .3125rem
}

.column-one-third.column-left .vendor-category:first-child {
  margin-top: .9375rem
}

.column-one-third.column-left .vendor-category:last-child {
  margin-bottom: 0
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical {
  float: left;
  width: 10%;
  margin-bottom: 0;
  margin-left: -10px
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title {
  height: 4%;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title a {
  padding: .3125rem;
  margin: 0;
  width: 100%;
  max-height: 100%;
  border-top: none;
  border-left: 5px solid #fff;
  box-shadow: inset -5px 0 5px -4px rgba(0, 0, 0, .36);
  background-color: #f2f2f2;
  color: #376caf;
  font-family: monospace;
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.0625rem;
  text-align: center;
  vertical-align: middle
}

.date-filters input.date_start,
.product-summary .product-footer .interact-icons {
  text-align: right
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title a:hover {
  background-color: #fff
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title.active {
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  height: 2.1875rem;
  z-index: 2
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title.active a {
  color: #444;
  background-color: #fff;
  border-left-color: #376caf;
  box-shadow: none;
  height: 100%;
  line-height: 2em
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical li.tab-title.active:first-child a {
  box-shadow: inset 0 9px 5px -9px rgba(0, 0, 0, .75)
}

.column-one-third.column-left #panelName.byName ul.tabs.vertical+.tabs-content {
  max-width: 90%;
  margin-left: 10%;
  margin-bottom: 0
}

.column-one-third.column-left #panelName.byName #byName {
  height: 100%
}

.column-one-third.column-left #panelName.byName .content ul li {
  list-style: disc;
  margin-left: 10px;
  color: #376caf
}

.column-one-third.column-left #panelName.byName .content.active {
  height: 100%;
  padding: .3125rem 0 0
}

.column-one-third.column-left #panelName.byName .content.active a {
  font-weight: 300
}

#vendorTabs .reveal-modal a.close-reveal-modal.btn-close,
#vendorTabs a.btn,
.reveal-modal #vendorTabs a.close-reveal-modal.btn-close {
  float: right;
  margin-top: 5px
}

@media (min-width:992px) and (max-width:1199px) {
  #vendorTabs .reveal-modal a.close-reveal-modal.btn-close,
  #vendorTabs a.btn,
  .reveal-modal #vendorTabs a.close-reveal-modal.btn-close {
    display: none
  }
}

#vendorTabs ul li.tab-title a {
  line-height: .9375rem
}

#vendorTabs ul li.tab-title a .products {
  display: block;
  font-size: .8125rem;
  font-weight: 300
}

#vendorTabs .tabs-content {
  margin-bottom: 0
}

#vendorTabs .tabs-content .content.active {
  padding-top: 0;
  padding-bottom: 0
}

#vendorTabs .tabs-content .content .product-info {
  border-bottom: solid 1px #CCC;
  clear: both;
  height: 5.1875rem;
  padding: .5rem 0 .625rem
}

#vendorTabs .tabs-content .content .product-info:last-child {
  border-bottom: none
}

#vendorTabs .tabs-content .content .product-info .company-logo {
  float: left;
  margin-right: .625rem
}

#vendorTabs .tabs-content .content .product-info .company-name {
  color: #376caf;
  font-size: 1rem;
  font-weight: 300;
  margin-top: .625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

#vendorTabs .tabs-content .content .product-info .product-rating-summary {
  color: #444;
  font-size: .6875rem;
  margin-top: -.3125rem
}

@media (max-width:767px) {
  #vendorTabs .tabs-content .content .product-info {
    height: 3.9375rem
  }
  #vendorTabs .tabs-content .content .product-info .company-logo {
    margin-right: .3125rem
  }
  #vendorTabs .tabs-content .content .product-info .company-logo img {
    width: 2.8125rem;
    height: 2.8125rem
  }
  #vendorTabs .tabs-content .content .product-info .company-name {
    font-size: .875rem;
    margin-top: .1875rem;
    width: 73%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
  #vendorTabs .tabs-content .content .product-info .product-rating-summary {
    font-size: .625rem
  }
  .company-profile-header .modal-cropper.editProfilePic,
  .profile-blog .modal-cropper.editProfilePic {
    bottom: -1.6875rem
  }
}

#vendorTabs .view-all {
  font-size: .875rem
}

@media (min-width:768px) {
  #vendorTabs .view-all {
    float: left;
    margin: -1.25rem 0 1.25rem
  }
}

@media (min-width:992px) {
  .column-two-thirds.column-right #vendorTabs .tabs.vertical+.tabs-content .content.active {
    padding-left: .3125rem
  }
  .column-two-thirds.column-right #vendorTabs .tabs.vertical+.tabs-content .content .product-info {
    float: left;
    clear: none;
    min-width: calc(50% - 5px);
    max-width: 3.125rem;
    margin-right: .3125rem
  }
}

.star-ratings-sprite {
  background: url(../images/components/rate-star-sprite.png) repeat-x;
  font-size: 0;
  height: 25px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 125px;
  margin: 0 3px
}

.star-ratings-sprite .rating {
  background: url(../images/components/rate-star-sprite.png) 0 100% repeat-x;
  float: left;
  height: 25px;
  display: block
}

.star-ratings-sprite.small,
.star-ratings-sprite.small .rating {
  background-image: url(../images/components/rate-star-sprite_sm.png);
  height: 14px
}

.star-ratings-sprite.small {
  width: 70px
}

.star-ratings-sprite.large,
.star-ratings-sprite.large .rating {
  background-image: url(../images/components/rate-star-sprite_lg.png);
  height: 57px
}

.star-ratings-sprite.large {
  width: 285px
}

.star-ratings-sprite.xlarge,
.star-ratings-sprite.xlarge .rating {
  background-image: url(../images/components/rate-star-sprite_xl.png);
  height: 70px
}

.star-ratings-sprite.xlarge {
  width: 350px
}

@media (max-width:479px) {
  .star-ratings-sprite.xlarge,
  .star-ratings-sprite.xlarge .rating {
    background-image: url(../images/components/rate-star-sprite_lg.png);
    height: 57px
  }
  .star-ratings-sprite.xlarge {
    width: 285px
  }
}

.product-ratings .product-info {
  border-bottom: solid 1px #CCC;
  clear: right;
  line-height: 1.25rem;
  padding: .625rem 0 .75rem
}

.product-ratings .product-info .department-name {
  color: #376caf;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin-top: .3125rem
}

.product-ratings .product-info .product-rating-summary {
  color: #444;
  font-size: .6875rem;
  margin: 0
}

.product-ratings .product-info .product-rating-summary .star-rating {
  float: left;
  margin: .125rem 0 0
}

.product-ratings .product-info .product-rating-summary .star-ratings-sprite {
  margin-left: 0
}

.product-ratings .product-info .product-rating-summary .recommended .full {
  display: inherit
}

.product-ratings .product-info .product-rating-summary .recommended .abbrev {
  display: none
}

@media (max-width:479px),
(min-width:992px) and (max-width:1199px) {
  .product-ratings .product-info .product-rating-summary .recommended .full {
    display: none
  }
  .product-ratings .product-info .product-rating-summary .recommended .abbrev {
    display: inherit
  }
}

.product-ratings .product-info .company-name {
  color: #444;
  font-size: .75rem;
  margin: 0
}

.product-ratings .product-info .company-name a {
  color: #376caf;
  font-weight: 300
}

.product-ratings.vrCatProd .company-logo {
  float: left;
  margin: 0 .625rem 0 0
}

.review .review-header .vendor-reviewed,
.review .review-header .vendor-reviewed a {
  font-size: 1.3125rem;
  line-height: 1.3125rem;
  font-weight: 400;
  color: #376caf;
  padding-bottom: .375rem
}

.review .review-header .company-avatar {
  float: left;
  padding: 0 .75rem .375rem 0
}

.review .review-header .review-title {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #444
}

.review .review-header .reviewed-by {
  font-size: .82875rem;
  font-weight: 300;
  font-style: italic;
  color: #444
}

.review .review-header .time-reviewed {
  font-size: 12px;
  line-height: 22px;
  font-weight: 100;
  color: #636468;
  float: right
}

.review .review-header .review-subhead {
  display: block;
  clear: both
}

.review .review-header .review-subhead .star-rating {
  float: left;
  margin: .125rem 0 0
}

.review .review-header .review-subhead .recommended {
  float: left;
  font-size: .8125rem;
  line-height: 1.875rem;
  color: #444
}

.review .review-header .review-subhead .recommended .answer {
  color: #7e7877;
  font-size: .9375rem;
  font-weight: 700
}

.review .review-header .review-subhead .recommended .answer .fa-thumbs-up {
  font-size: 1.125rem
}

.review .review-header img.verified-badge {
  clear: right;
  float: right;
  margin-bottom: .9375rem;
  color: #0a9e02
}

.review .review-content {
  clear: both;
  margin: 1.125rem 0
}

.review .review-content p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: #444;
  margin: 0
}

.review .review-content p strong {
  font-weight: 500
}

.review .review-footer {
  clear: both
}

.review .review-footer .who-likes {
  display: block;
  clear: both
}

@media (min-width:992px) {
  .review .review-footer .who-likes {
    display: inline;
    clear: none
  }
  .commentSection.onReviews {
    margin-left: 3rem
  }
  .commentSection.onReviews .post-comment,
  .commentSection.onReviews .post-comment .commenter.profile {
    min-width: 100%
  }
  .commentSection.onReviews .post-comment .commenter.profile .avatar-status-widget {
    float: left;
    width: 4.6875rem;
    height: 4.6875rem;
    margin-right: .625rem
  }
  .commentSection.onReviews .post-comment .comment-content {
    max-width: 100%;
    clear: both
  }
  .commentSection.onReviews .post-comment .comment-footer {
    margin-left: 0
  }
}

.commentSection.onReviews {
  display: none;
  background-color: #d5e0e9;
  margin-top: 0
}

.commentSection.onReviews .pagination-controls {
  display: none
}

.commentSection.onReviews .post-comment {
  margin-bottom: 0;
  padding: .9375rem
}

.commentSection.onReviews .number-of-comments,
.commentSection.onReviews .showMore {
  font-weight: 300;
  font-size: .875rem;
  padding: 0 .9375rem
}

.commentSection.onReviews .number-of-comments {
  float: right
}

.premium-listing-header {
  background: #d5e0e9;
  color: #636468;
  font-weight: 500;
  font-size: .875rem;
  padding: .375rem .625rem 0;
  margin-top: .75rem;
  text-transform: uppercase
}

.product-summary {
  position: relative;
  width: 100%;
  border-bottom: solid 1px #e6e6e6;
  padding: 1.75rem 0 1.25rem
}

.product-summary .company-avatar.profile {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 25%;
  min-width: 7.3125rem;
  margin-right: .625rem;
  font-size: .625rem;
  position: relative;
  z-index: 2
}

.product-summary .company-avatar.profile .company-featured-info {
  margin-top: .625rem;
  font-size: .75rem;
  line-height: 1.5rem;
  font-weight: 400
}

.product-summary .company-avatar.profile .company-featured-info .label-info,
.product-summary .company-avatar.profile .company-featured-info a {
  font-weight: 300
}

.product-summary .company-avatar.profile .company-featured-info a .material-icons {
  font-size: 1.125rem;
  color: #999
}

.product-summary .summary-content {
  display: inline-block;
  width: 100%;
  max-width: calc(90% - 127px);
  height: 100%;
  font-weight: 300;
  margin-top: .625rem;
  position: relative;
  z-index: 1
}

.product-summary .summary-content .product-summary-header {
  width: 100%
}

.product-summary .summary-content .product-summary-header .product-name {
  font-size: 1.5rem;
  font-weight: 500
}

.product-summary .summary-content .product-summary-header .by-line {
  display: block;
  font-size: 1rem;
  margin-bottom: .875rem
}

.product-summary .summary-content .product-summary-header .company-averages {
  font-size: .875rem;
  line-height: 1.25rem
}

.product-summary .summary-content .product-summary-header .company-averages .star-ratings-sprite {
  margin: 0 0 .3125rem
}

.product-summary .summary-content .product-summary-header .company-averages .dealerships,
.product-summary .summary-content .product-summary-header .company-averages .ratings-number,
.product-summary .summary-content .product-summary-header .company-averages .recommended {
  display: inline-block
}

@media (min-width:992px) {
  .product-summary .summary-content .product-summary-header .company-averages .dealerships,
  .product-summary .summary-content .product-summary-header .company-averages .ratings-number,
  .product-summary .summary-content .product-summary-header .company-averages .recommended {
    width: 30%
  }
}

.product-summary .summary-content .product-summary-header .company-averages .dealerships strong,
.product-summary .summary-content .product-summary-header .company-averages .ratings-number strong,
.product-summary .summary-content .product-summary-header .company-averages .recommended strong {
  font-weight: 500
}

.product-summary .summary-content .product-summary-header .company-averages .recommended .full {
  display: inherit
}

.product-summary .summary-content .product-summary-header .company-averages .recommended .abbrev {
  display: none
}

.product-summary .summary-content .product-summary-header .category-name a {
  font-weight: 300
}

.product-summary .summary-content .product-content {
  width: 100%;
  margin: .625rem 0;
  font-size: 14px;
  line-height: 1.5rem;
  height: 4.5rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}

.product-summary .summary-content .product-content p {
  font-size: 14px
}

.product-summary .product-footer {
  clear: both
}

.product-summary.premium-listing {
  border: solid #d5e0e9;
  border-width: 0 .625rem .75rem;
  background: #fff;
  padding: 1.5rem .625rem
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .product-summary .summary-content .product-content {
    max-width: 85%
  }
  .product-summary .company-avatar.profile {
    float: left;
    margin: 0 .3125rem .625rem 0
  }
  .product-summary .summary-content {
    display: block;
    margin-top: 0;
    max-width: 100%
  }
  .product-summary .summary-content .product-summary-header {
    float: left;
    display: inline-block;
    max-width: calc(100% - 136px)
  }
  .product-summary .summary-content .product-summary-header .product-name {
    font-size: 1rem
  }
  .product-summary .summary-content .product-summary-header .company-averages {
    margin-left: 0;
    font-size: .875rem;
    line-height: 1.25rem
  }
  .product-summary .summary-content .product-summary-header .company-averages .star-ratings-sprite {
    margin-bottom: .3125rem
  }
  .product-summary .summary-content .product-summary-header .company-averages .recommended .full {
    display: none
  }
  .product-summary .summary-content .product-summary-header .company-averages .recommended .abbrev {
    display: inherit
  }
  .product-summary .summary-content .product-summary-header .company-averages .ratings-number .verified {
    display: none
  }
  .product-summary .summary-content .product-content {
    width: 100%;
    clear: both;
    margin: .625rem 0;
    font-size: 12px;
    line-height: 1.5em;
    height: 4.5em
  }
  .product-summary .product-footer {
    clear: both
  }
}

.media-header .not-provided div {
  width: 43%;
  margin: 20px 4% 20px 0;
  display: inline-block
}

.media-header .not-provided div:nth-child(2) {
  margin-right: 0
}

.media-header .not-provided div:nth-child(3) {
  display: none
}

.media-header a.left:nth-child(even) {
  clear: left
}

@media (min-width:992px) {
  .media-header .not-provided div {
    width: 30%;
    margin-right: 4%
  }
  .media-header .not-provided div:nth-child(2) {
    margin-right: 4%
  }
  .media-header .not-provided div:nth-child(3) {
    display: inline-block;
    margin-right: 0
  }
}

.media-header .media-img {
  height: 160px;
  overflow: hidden
}

.pagination-container {
  position: relative
}

.filter-container {
  float: right;
  font-weight: 700;
  color: #376caf
}

.date-filters input {
  border: none;
  background: 0 0;
  color: #376caf;
  cursor: pointer;
  width: 4.5rem;
  font-size: .75rem;
  font-weight: 700;
  outline: 0
}

.date-filters input:hover {
  text-decoration: underline
}

.date-filters .date-range-datepicker-load {
  font-size: 1.125rem;
  cursor: pointer
}

.date-filters .date-range-datepicker-load:hover {
  color: #0a9e02
}

.company-profile-header,
.profile-blog {
  position: relative
}

.company-profile-header .modal-cropper,
.profile-blog .modal-cropper {
  position: absolute;
  opacity: .7;
  z-index: 4;
  cursor: pointer
}

.company-profile-header .modal-cropper.editCoverPhoto,
.profile-blog .modal-cropper.editCoverPhoto {
  color: #eee;
  top: .3125rem;
  left: .3125rem;
  text-shadow: 0 0 4px #000
}

.company-profile-header .modal-cropper.editProfilePic,
.profile-blog .modal-cropper.editProfilePic {
  color: #999;
  left: .8125rem
}

@media (max-width:479px) {
  .product-summary .product-footer .btn,
  .product-summary .product-footer .reveal-modal .close-reveal-modal.btn-close,
  .reveal-modal .product-summary .product-footer .close-reveal-modal.btn-close {
    padding: .3125rem .9375rem
  }
  .product-summary .summary-content .product-summary-header {
    max-width: calc(100% - 146px)
  }
  .company-profile-header .modal-cropper.editProfilePic,
  .profile-blog .modal-cropper.editProfilePic {
    left: .5rem;
    bottom: -18px
  }
}

@media (min-width:768px) and (max-width:991px) {
  .company-profile-header .modal-cropper.editProfilePic,
  .profile-blog .modal-cropper.editProfilePic {
    bottom: -1.8125rem
  }
}

@media (min-width:992px) {
  .company-profile-header .modal-cropper.editProfilePic,
  .profile-blog .modal-cropper.editProfilePic {
    left: 1.4375rem;
    bottom: -1rem
  }
}

@media (min-width:1200px) {
  .byName .tabs.horizontal+.tabs-content .content ul {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 3%;
    column-gap: 3%;
    -webkit-column-width: 30%;
    column-width: 30%
  }
  .company-profile-header .modal-cropper.editProfilePic,
  .profile-blog .modal-cropper.editProfilePic {
    bottom: -1.9375rem
  }
}

.company-profile-header .editProfilePicWrapper .editProfilePicText,
.profile-blog .editProfilePicWrapper .editProfilePicText {
  display: none
}

.company-profile-header .editProfilePicWrapper:hover,
.profile-blog .editProfilePicWrapper:hover {
  position: absolute;
  z-index: 3;
  left: .8125rem;
  color: #fff;
  text-shadow: none;
  background-color: #000;
  border: 1px solid #eee;
  margin: 0 0 0 -3px;
  padding: 2px 5px 0 2px
}

@media (max-width:767px) {
  .company-profile-header .editProfilePicWrapper:hover,
  .profile-blog .editProfilePicWrapper:hover {
    bottom: -1.75rem
  }
}

@media (max-width:479px) {
  .company-profile-header .editProfilePicWrapper:hover,
  .profile-blog .editProfilePicWrapper:hover {
    left: .5rem;
    bottom: -19px
  }
}

@media (min-width:768px) and (max-width:991px) {
  .company-profile-header .editProfilePicWrapper:hover,
  .profile-blog .editProfilePicWrapper:hover {
    bottom: -1.875rem
  }
}

@media (min-width:992px) {
  .company-profile-header .editProfilePicWrapper:hover,
  .profile-blog .editProfilePicWrapper:hover {
    left: 1.4375rem;
    bottom: -1.0625rem
  }
}

@media (min-width:1200px) {
  .company-profile-header .editProfilePicWrapper:hover,
  .profile-blog .editProfilePicWrapper:hover {
    bottom: -2rem
  }
}

.company-profile-header .editProfilePicWrapper:hover .modal-cropper.editProfilePic,
.profile-blog .editProfilePicWrapper:hover .modal-cropper.editProfilePic {
  opacity: 1;
  color: #fff;
  position: relative;
  display: inline;
  left: 0;
  bottom: 0
}

.company-profile-header .editProfilePicWrapper:hover .editProfilePicText,
.profile-blog .editProfilePicWrapper:hover .editProfilePicText {
  display: inline;
  font-size: smaller
}

.company-profile-header .editCoverPicWrapper,
.profile-blog .editCoverPicWrapper {
  position: absolute;
  top: .3125rem;
  left: .3125rem
}

.company-profile-header .editCoverPicWrapper .modal-cropper,
.profile-blog .editCoverPicWrapper .modal-cropper {
  position: relative
}

.company-profile-header .editCoverPicWrapper .modal-cropper.editCoverPhoto,
.profile-blog .editCoverPicWrapper .modal-cropper.editCoverPhoto {
  color: #fff;
  top: 0;
  left: 0;
  text-shadow: 0 0 4px #000
}

.company-profile-header .editCoverPicWrapper .editCoverPicText,
.profile-blog .editCoverPicWrapper .editCoverPicText {
  display: none
}

.company-profile-header .editCoverPicWrapper:hover,
.profile-blog .editCoverPicWrapper:hover {
  color: #fff;
  text-shadow: none;
  background-color: #000;
  border: 1px solid #eee;
  margin: -3px 0 0 -3px;
  padding: 2px 5px 0 2px
}

.company-profile-header .editCoverPicWrapper:hover .editCoverPicText,
.profile-blog .editCoverPicWrapper:hover .editCoverPicText {
  display: inline;
  font-size: smaller
}

.company-profile-header .editCoverPicWrapper:hover .modal-cropper.editCoverPhoto,
.profile-blog .editCoverPicWrapper:hover .modal-cropper.editCoverPhoto {
  opacity: 1;
  color: fff;
  text-shadow: none
}

.company-profile-header .holdProfilePic,
.profile-blog .holdProfilePic {
  position: static
}

.company-profile-header .cover-photo,
.profile-blog .cover-photo {
  position: relative;
  height: 13.75rem;
  background-repeat: no-repeat;
  background-position: center top;
  border: 1px solid #e6e6e6;
  color: #fff;
  margin-bottom: 3.125rem
}

.company-profile-header .cover-photo .profile-content,
.profile-blog .cover-photo .profile-content {
  position: relative;
  width: 100%;
  height: 100%
}

.company-profile-header .cover-photo .profile-content .avatar-status-widget,
.profile-blog .cover-photo .profile-content .avatar-status-widget {
  float: left;
  margin-left: .625rem;
  width: 100%;
  height: 100%;
  max-width: 10.9375rem;
  max-height: 10.9375rem;
  min-width: 6.5rem;
  min-height: 6.5rem
}

.company-profile-header .cover-photo .profile-content .avatar-status-widget img.avatar,
.profile-blog .cover-photo .profile-content .avatar-status-widget img.avatar {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  padding: 0;
  background-color: #fff
}

.company-profile-header .cover-photo .profile-content .avatar-status-widget.contributor img.avatar,
.company-profile-header .cover-photo .profile-content .avatar-status-widget.master img.avatar,
.company-profile-header .cover-photo .profile-content .avatar-status-widget.senior-contributor img.avatar,
.profile-blog .cover-photo .profile-content .avatar-status-widget.contributor img.avatar,
.profile-blog .cover-photo .profile-content .avatar-status-widget.master img.avatar,
.profile-blog .cover-photo .profile-content .avatar-status-widget.senior-contributor img.avatar {
  border: 0;
  box-shadow: none
}

@media (min-width:768px) {
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.contributor .status-level,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.master .status-level,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.senior-contributor .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.contributor .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.master .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.senior-contributor .status-level {
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    margin: -10px
  }
}

@media (max-width:767px) and (max-width:479px) {
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.contributor,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.master,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.senior-contributor,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.contributor,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.master,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.senior-contributor {
    max-width: 8.125rem
  }
}

.company-profile-header .cover-photo .profile-content h1,
.profile-blog .cover-photo .profile-content h1 {
  position: absolute;
  bottom: 10px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, .5)
}

.company-profile-header .cover-photo .profile-content h1 .job-title,
.profile-blog .cover-photo .profile-content h1 .job-title {
  display: block;
  font-style: italic;
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 1.5rem
}

@media (max-width:767px) {
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.contributor .status-level,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.master .status-level,
  .company-profile-header .cover-photo .profile-content .avatar-status-widget.senior-contributor .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.contributor .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.master .status-level,
  .profile-blog .cover-photo .profile-content .avatar-status-widget.senior-contributor .status-level {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    margin: -6px
  }
  .company-profile-header .cover-photo .profile-content h1,
  .profile-blog .cover-photo .profile-content h1 {
    margin: 0 2.5rem 0 29%;
    max-width: 70%;
    max-height: 12.5rem;
    line-height: 2.125rem;
    overflow-y: hidden
  }
  .company-profile-header .cover-photo .profile-content h1 .job-title,
  .profile-blog .cover-photo .profile-content h1 .job-title {
    font-size: 1.25rem;
    line-height: 1.5rem
  }
}

@media (max-width:479px) {
  .company-profile-header .cover-photo .profile-content h1,
  .profile-blog .cover-photo .profile-content h1 {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
    max-width: 50%;
    margin: 0 2.1875rem 0 40%;
    bottom: 5px
  }
  .company-profile-header .cover-photo .profile-content h1 .job-title,
  .profile-blog .cover-photo .profile-content h1 .job-title {
    font-size: 1.125rem;
    line-height: 1.25rem
  }
}

@media (min-width:768px) {
  .company-profile-header .cover-photo .profile-content h1,
  .profile-blog .cover-photo .profile-content h1 {
    max-width: 23.75rem;
    line-height: 2.75rem;
    left: 9.375rem;
    margin-left: 1.875rem
  }
}

@media (min-width:992px) {
  .company-profile-header .cover-photo .profile-content h1,
  .profile-blog .cover-photo .profile-content h1 {
    max-width: 34.375rem;
    font-size: 3.125rem;
    line-height: 3.125rem;
    left: 11.875rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .company-profile-header .cover-photo .profile-content,
  .profile-blog .cover-photo .profile-content {
    padding-top: 6.25rem
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget,
  .profile-blog .cover-photo .profile-content .avatar-status-widget {
    width: 9.375rem;
    height: 9.375rem
  }
}

@media (min-width:992px) {
  .company-profile-header .cover-photo .profile-content,
  .profile-blog .cover-photo .profile-content {
    padding-top: 4.8125rem
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget,
  .profile-blog .cover-photo .profile-content .avatar-status-widget {
    margin-left: 1.25rem;
    width: 10rem;
    height: 10rem
  }
}

@media (min-width:1200px) {
  .company-profile-header .cover-photo .profile-content h1,
  .profile-blog .cover-photo .profile-content h1 {
    max-width: 43.75rem;
    font-size: 3.75rem;
    line-height: 3.75rem;
    left: 11.875rem
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget,
  .profile-blog .cover-photo .profile-content .avatar-status-widget {
    width: 10.9375rem;
    height: 10.9375rem
  }
}

.company-profile-header .cover-photo .profile-content .btns-connect,
.profile-blog .cover-photo .profile-content .btns-connect {
  float: right
}

.company-profile-header .cover-photo .profile-content .btns-connect .connect,
.profile-blog .cover-photo .profile-content .btns-connect .connect {
  float: right;
  clear: right
}

.company-profile-header .cover-photo .profile-content .btns-connect .connect svg,
.profile-blog .cover-photo .profile-content .btns-connect .connect svg {
  vertical-align: text-top
}

@media (max-width:767px) {
  .company-profile-header .cover-photo .profile-content .btns-connect .connect.friend,
  .profile-blog .cover-photo .profile-content .btns-connect .connect.friend {
    padding: .0625rem .3125rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect.follow,
  .profile-blog .cover-photo .profile-content .btns-connect .connect.follow {
    padding: .0625rem .375rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect svg,
  .profile-blog .cover-photo .profile-content .btns-connect .connect svg {
    fill: #fff
  }
  .company-profile-header .cover-photo .profile-content .btns-connect.company,
  .profile-blog .cover-photo .profile-content .btns-connect.company {
    margin-right: 10px
  }
}

@media (max-width:767px) and (max-width:479px) {
  .company-profile-header .cover-photo .profile-content .btns-connect.company,
  .profile-blog .cover-photo .profile-content .btns-connect.company {
    margin-right: .0625rem
  }
}

@media (min-width:768px) {
  .company-profile-header .cover-photo .profile-content .btns-connect,
  .profile-blog .cover-photo .profile-content .btns-connect {
    margin: .75rem .625rem 0 0
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect,
  .profile-blog .cover-photo .profile-content .btns-connect .connect {
    font-size: 16px;
    font-weight: 500;
    min-width: 9.375rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect.friend,
  .profile-blog .cover-photo .profile-content .btns-connect .connect.friend {
    margin-bottom: .625rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect svg,
  .profile-blog .cover-photo .profile-content .btns-connect .connect svg {
    margin-right: .3125rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect.company,
  .profile-blog .cover-photo .profile-content .btns-connect.company {
    margin: 4.125rem .625rem 0 0
  }
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .company-profile-header .cover-photo .profile-content .btns-connect.company .connect.follow,
  .profile-blog .cover-photo .profile-content .btns-connect.company .connect.follow {
    min-width: 8.125rem
  }
}

@media (min-width:992px) {
  .company-profile-header .cover-photo .profile-content .btns-connect,
  .profile-blog .cover-photo .profile-content .btns-connect {
    margin: 2.1875rem .625rem 0 0
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .connect,
  .profile-blog .cover-photo .profile-content .btns-connect .connect {
    font-size: 18px;
    min-width: 10rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect.company,
  .profile-blog .cover-photo .profile-content .btns-connect.company {
    margin: 5.5rem .625rem 0 0
  }
}

.company-profile-header .breadcrumb,
.profile-blog .breadcrumb {
  float: right;
  position: relative;
  top: -40px;
  font-size: .875rem;
  text-transform: uppercase;
  color: #636468;
  height: 0;
  display: inline-block
}

.company-profile-header .breadcrumb .breadcrumb-truncate,
.profile-blog .breadcrumb .breadcrumb-truncate {
  white-space: nowrap;
  max-width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: .75rem;
  position: relative;
  top: 1px
}

@media (max-width:479px) {
  .company-profile-header .breadcrumb,
  .profile-blog .breadcrumb {
    font-size: .75rem
  }
  .company-profile-header .breadcrumb .breadcrumb-truncate,
  .profile-blog .breadcrumb .breadcrumb-truncate {
    max-width: 5.3125rem;
    line-height: .65rem
  }
}

.company-profile-header .breadcrumb-product .categ,
.profile-blog .breadcrumb-product .categ {
  display: none
}

.company-profile-header .breadcrumb-product .breadcrumb-truncate,
.profile-blog .breadcrumb-product .breadcrumb-truncate {
  max-width: 11.25rem
}

@media (max-width:767px) {
  .company-profile-header .breadcrumb-product .categ,
  .profile-blog .breadcrumb-product .categ {
    display: inline-block
  }
  .company-profile-header .breadcrumb-product .categories,
  .profile-blog .breadcrumb-product .categories {
    display: none
  }
  .company-profile-header .breadcrumb-product .breadcrumb-truncate,
  .profile-blog .breadcrumb-product .breadcrumb-truncate {
    max-width: 7.5rem
  }
  .company-profile-header,
  .profile-blog {
    padding-top: .9375rem
  }
  .company-profile-header .cover-photo,
  .profile-blog .cover-photo {
    background-size: auto 100%
  }
  .company-profile-header .cover-photo .profile-content,
  .profile-blog .cover-photo .profile-content {
    position: absolute;
    top: 0;
    max-height: 100%
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget,
  .profile-blog .cover-photo .profile-content .avatar-status-widget {
    width: 25%;
    height: auto;
    margin-left: 0;
    position: absolute;
    bottom: -1.75rem;
    left: .625rem
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget img.avatar,
  .profile-blog .cover-photo .profile-content .avatar-status-widget img.avatar {
    width: 100%
  }
  .company-profile-header .cover-photo .profile-content .btns-connect,
  .profile-blog .cover-photo .profile-content .btns-connect {
    position: absolute;
    right: 0;
    bottom: 0
  }
}

@media (max-width:479px) {
  .company-profile-header .breadcrumb-product .categ,
  .profile-blog .breadcrumb-product .categ {
    display: inline-block
  }
  .company-profile-header .breadcrumb-product .categories,
  .profile-blog .breadcrumb-product .categories {
    display: none
  }
  .company-profile-header .breadcrumb-product .breadcrumb-truncate,
  .profile-blog .breadcrumb-product .breadcrumb-truncate {
    max-width: 3.125rem
  }
}

@media (max-width:767px) and (max-width:767px) {
  .company-profile-header .cover-photo,
  .profile-blog .cover-photo {
    height: 8.75rem
  }
  .company-profile-header .cover-photo .profile-content .avatar-status-widget,
  .profile-blog .cover-photo .profile-content .avatar-status-widget {
    width: 36%;
    max-width: 8.125rem;
    left: .3125rem;
    bottom: -1.125rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .btn,
  .company-profile-header .cover-photo .profile-content .btns-connect .reveal-modal .close-reveal-modal.btn-close,
  .profile-blog .cover-photo .profile-content .btns-connect .btn,
  .profile-blog .cover-photo .profile-content .btns-connect .reveal-modal .close-reveal-modal.btn-close,
  .reveal-modal .company-profile-header .cover-photo .profile-content .btns-connect .close-reveal-modal.btn-close,
  .reveal-modal .profile-blog .cover-photo .profile-content .btns-connect .close-reveal-modal.btn-close {
    padding: .375rem .375rem 0
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .btn:last-child,
  .company-profile-header .cover-photo .profile-content .btns-connect .reveal-modal .close-reveal-modal.btn-close:last-child,
  .profile-blog .cover-photo .profile-content .btns-connect .btn:last-child,
  .profile-blog .cover-photo .profile-content .btns-connect .reveal-modal .close-reveal-modal.btn-close:last-child,
  .reveal-modal .company-profile-header .cover-photo .profile-content .btns-connect .close-reveal-modal.btn-close:last-child,
  .reveal-modal .profile-blog .cover-photo .profile-content .btns-connect .close-reveal-modal.btn-close:last-child {
    margin-top: 5px
  }
  .company-profile-header .cover-photo .profile-content .btns-connect svg,
  .profile-blog .cover-photo .profile-content .btns-connect svg {
    width: 1.375rem;
    height: 1.375rem
  }
}

@media (max-width:767px) and (max-width:767px) and (max-width:479px) {
  .company-profile-header .cover-photo,
  .profile-blog .cover-photo {
    height: 7.5rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect,
  .profile-blog .cover-photo .profile-content .btns-connect {
    width: 1.875rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect .material-icons,
  .profile-blog .cover-photo .profile-content .btns-connect .material-icons {
    font-size: 1rem
  }
  .company-profile-header .cover-photo .profile-content .btns-connect svg,
  .profile-blog .cover-photo .profile-content .btns-connect svg {
    width: 1rem;
    height: 1rem
  }
}

@media (min-width:768px) {
  .companyPages .container.profile-nav {
    max-width: 45rem
  }
}

@media (min-width:992px) {
  .company-profile-header .btns-desktop .btn.connect.follow,
  .company-profile-header .btns-desktop .reveal-modal .connect.follow.close-reveal-modal.btn-close,
  .reveal-modal .company-profile-header .btns-desktop .connect.follow.close-reveal-modal.btn-close {
    margin-top: 2.8125rem
  }
  .companyPages .container.profile-nav {
    max-width: 58.75rem
  }
}

@media (min-width:1200px) {
  .companyPages .container.profile-nav {
    max-width: 69.6875rem
  }
}

.profile-nav.tab-nav .top-line {
  max-width: 100%;
  height: .875rem;
  margin-top: 0;
  box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, .36)
}

.profile-nav.tab-nav ul.tabs li.tab-title.inbox .tab-word,
.profile-nav.tab-nav ul.tabs li.tab-title.settings .tab-word {
  display: inherit
}

.profile-nav.tab-nav ul.tabs li.tab-title.inbox .tab-icon,
.profile-nav.tab-nav ul.tabs li.tab-title.settings .tab-icon {
  display: none
}

@media (min-width:768px) and (max-width:991px) {
  .companyPages .container {
    padding: 0
  }
  .profile-nav.tab-nav ul.tabs {
    margin-left: 0
  }
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .875rem;
    padding: 1rem 1.5625rem
  }
}

@media (max-width:767px) {
  .profile-nav.tab-nav ul.tabs {
    margin-left: 0
  }
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .875rem;
    padding: 1rem .5625rem
  }
  .profile-nav.tab-nav ul.tabs li.tab-title.active a {
    padding: .625rem .5625rem 1rem
  }
  .profile-nav.tab-nav ul.tabs li.tab-title .product-nav-list a {
    padding: .125rem 0 0 .75rem
  }
}

@media (max-width:767px) and (max-width:540px) {
  .profile-nav.tab-nav ul.tabs li.tab-title.inbox .tab-word,
  .profile-nav.tab-nav ul.tabs li.tab-title.settings .tab-word {
    display: none
  }
  .profile-nav.tab-nav ul.tabs li.tab-title.inbox .tab-icon,
  .profile-nav.tab-nav ul.tabs li.tab-title.settings .tab-icon {
    display: inherit;
    padding-top: 7px
  }
}

@media (max-width:479px) and (max-width:420px) {
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .75rem
  }
}

@media (max-width:479px) and (max-width:375px) {
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .6875rem
  }
}

@media (max-width:479px) and (max-width:360px) {
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .625rem
  }
}

@media (max-width:479px) and (max-width:330px) {
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    font-size: .5625rem
  }
}

@media (max-width:479px) {
  .profile-nav.tab-nav ul.tabs {
    margin-left: 0
  }
  .profile-nav.tab-nav ul.tabs li.tab-title a {
    padding: .3125rem .3125rem 0
  }
  .profile-nav.tab-nav ul.tabs li.tab-title.active {
    height: 2.125rem
  }
  .profile-nav.tab-nav ul.tabs li.tab-title.active a {
    padding: 0 .3125rem
  }
  .profile-nav.tab-nav .top-line {
    margin: 0
  }
}

.data-point {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  padding-bottom: .625rem
}

.data-point .info-label {
  color: #7e7877;
  padding-right: .3125rem
}

.more-arrow,
.profilePage h3 {
  color: #636468
}

.data-point.about {
  clear: both
}

.data-point.about .info-data .editable-container,
.data-point.about .info-data .editable-input,
.data-point.about .info-data .input-large {
  width: 100%
}

.data-point .edu-wrap,
.data-point .wh-wrap {
  margin: 4px 0 12px
}

.profilePage h3.headline-border.interests {
  margin-top: 1.875rem
}

.more-arrow {
  clear: both;
  margin-top: .9375rem
}

.more-arrow a {
  font-size: .875rem;
  font-weight: 300
}

.more-arrow .material-icons {
  font-size: 1.25rem
}

.connections.column-one-third,
.followers.column-one-third {
  margin-top: 1.25rem
}

.connections .people .avatar,
.followers .people .avatar {
  float: left;
  margin: .625rem
}

.editable {
  font-weight: 400
}

div.editable {
  display: inline-block
}

.profiles-landing.content {
  margin-top: 0
}

.profiles-landing .hero-banner {
  background-color: rgba(0, 0, 0, .35);
  background-repeat: no-repeat;
  background-position: center top
}

@media (max-width:767px) {
  .profiles-landing .hero-banner {
    background-image: url(../images/category-banners/profiles-home_sm.jpg)
  }
  .profiles-landing .hero-banner .container {
    height: 16.1875rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .profiles-landing .hero-banner {
    background-image: url(../images/category-banners/profiles-home_md.jpg)
  }
  .profiles-landing .hero-banner .container {
    height: 20.9375rem
  }
}

@media (min-width:992px) {
  .profiles-landing .hero-banner {
    background-image: url(../images/category-banners/profiles-home_lg.jpg)
  }
  .profiles-landing .hero-banner .container {
    height: 25.3125rem
  }
}

@media (min-width:1200px) {
  .profiles-landing .hero-banner {
    background-image: url(../images/category-banners/profiles-home_xl.jpg);
    background-repeat: no-repeat;
    background-position: center top
  }
}

@media (min-width:1920px) {
  .profiles-landing .hero-banner {
    background: url(../images/category-banners/profiles-home_xl.jpg), #b8b8b6;
    background: url(../images/category-banners/profiles-home_xl.jpg), linear-gradient(to right, #b8b8b6 49%, #babec1 51%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b8b8b6', endColorstr='#babec1', GradientType=1);
    background-repeat: no-repeat;
    background-position: center top
  }
}

.profiles-landing .hero-banner .container {
  position: relative
}

.profiles-landing .hero-banner .container h2 {
  color: #fff;
  font-weight: 300
}

.profiles-landing .hero-banner .container .search-blogs {
  position: absolute;
  color: #fff;
  left: 0;
  bottom: 50px
}

.profiles-landing .hero-banner .container .search-blogs .input-group {
  max-width: 29.8125rem
}

.profiles-landing .hero-banner .container .search-blogs .input-group button {
  margin-bottom: 0
}

.profiles-landing .hero-banner .container .write-post {
  position: absolute;
  right: 0;
  bottom: 45px
}

@media (min-width:992px) {
  .profiles-landing .hero-banner .container h2 {
    margin: 5.3125rem 0 6.25rem;
    line-height: 3.125rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .profiles-landing .hero-banner .container h2 {
    margin: 5rem 0;
    line-height: 2.1875rem
  }
}

@media (max-width:767px) {
  .profiles-landing .hero-banner .container h2 {
    margin: 2.8125rem 0 1.875rem;
    line-height: 1.6875rem
  }
  .profiles-landing .hero-banner .container .search-blogs {
    position: static;
    float: left;
    clear: left;
    max-width: 50%;
    text-align: center
  }
  .profiles-landing .hero-banner .container .write-post {
    position: relative;
    float: right;
    margin-top: 3.125rem;
    padding: .3125rem .625rem;
    font-size: .75rem
  }
}

@media (max-width:479px) {
  .profiles-landing .hero-banner .container h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1.875rem .625rem 1.25rem 0
  }
  .profiles-landing .hero-banner .container h2 br {
    display: none
  }
  .profiles-landing .hero-banner .container .search-blogs {
    max-width: 80%;
    text-align: left
  }
  .profiles-landing .hero-banner .container .search-blogs .input-group {
    margin-top: .3125rem;
    width: 100%
  }
  .profiles-landing .hero-banner .container .write-post {
    float: none;
    width: 100%
  }
}

.timeline.recent-activity ul,
.timeline.resume ul {
  list-style: none;
  border-left: solid 1px #636468;
  padding: .0625rem 0 .25rem;
  margin: 0 0 0 .9375rem
}

.timeline.recent-activity ul li,
.timeline.resume ul li {
  position: relative;
  line-height: 1.875rem;
  margin: .9375rem 0 .9375rem -.9375rem;
  padding: 0 0 0 2.625rem;
  width: 100%;
  clear: both
}

.timeline.recent-activity ul li .avatar,
.timeline.resume ul li .avatar {
  position: absolute;
  top: 0;
  left: 0
}

.timeline.recent-activity ul li .event-description,
.timeline.resume ul li .event-description {
  float: right;
  width: calc(100% - 40px)
}

.timeline.recent-activity ul li:first-child,
.timeline.resume ul li:first-child {
  margin-top: .3125rem
}

.timeline.recent-activity ul li .time-stamp,
.timeline.resume ul li .time-stamp {
  float: right;
  font-style: italic
}

.timeline .more-arrow {
  display: none
}

.profilePage .section.timeline.recent-activity ul li .avatar.xsmall .combined-icons .material-icons {
  line-height: 1rem
}

.profilePage .section.timeline.recent-activity ul li .time-stamp {
  float: right;
  font-style: italic;
  line-height: 1.35rem
}

.profile-about .section.timeline.resume ul li .job-info {
  display: block;
  width: calc(100% - rem-calc(40px))
}

.profile-about .section.timeline.resume ul li .job-info .company-name {
  font-weight: 300;
  display: block
}

.profile-user-connections .connections-header {
  padding-bottom: .625rem
}

.profile-user-connections h3 {
  color: #636468
}

.profile-user-connections .connections-types {
  line-height: 2.3125rem
}

.profile-user-connections .connections-types a {
  font-weight: 300
}

.profile-user-connections .input-group button,
.profile-user-connections .input-group input {
  margin-bottom: 0
}

@media (max-width:767px) {
  .profile-user-connections .connections-types {
    display: block;
    text-align: center
  }
  .profile-user-connections .connections-types .type-connections {
    margin: 0 10%
  }
  .profile-user-connections .connections-types .type-followers {
    float: right
  }
  .profile-user-connections .connections-types .type-all {
    float: left
  }
}

@media (max-width:479px) {
  .profile-user-connections .connections-types a {
    font-size: .8125rem
  }
  .profile-user-connections .connections-types a.type-connections {
    margin: 0 1%
  }
}

@media (min-width:768px) and (max-width:991px) {
  .profile-user-connections .connections-header {
    width: 100%
  }
}

@media (min-width:768px) {
  .profile-user-connections .connections-types {
    float: left;
    width: 60%
  }
  .profile-user-connections .connections-types .type-connections {
    margin: 0 10%
  }
  .profile-user-connections .input-group {
    float: right;
    width: 40%
  }
}

@media (min-width:992px) {
  .profile-user-connections .connections-types {
    max-width: 22.6875rem
  }
  .profile-user-connections .connections-types .type-connections {
    margin: 0 .625rem
  }
  .profile-user-connections .input-group {
    max-width: 15.125rem
  }
}

@media (min-width:1200px) {
  .profile-user-connections .connections-types {
    max-width: 28.125rem
  }
  .profile-user-connections .connections-types .type-connections {
    margin: 0 6%
  }
  .profile-user-connections .input-group {
    max-width: 18.75rem
  }
}

.user-info .user-company,
.user-info .user-job,
.user-info .user-location,
.user-info .user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.user-info .user-name {
  color: #376caf;
  font-size: 1.25rem;
  font-weight: 500
}

.user-info .user-company,
.user-info .user-job,
.user-info .user-location {
  font-weight: 300;
  font-size: .875rem;
  line-height: 1.125rem
}

.user-info .user-job {
  color: #444
}

.user-info .user-company,
.user-info .user-location {
  color: #999
}

.peoples {
  clear: both
}

@media (min-width:768px) and (max-width:991px) {
  .peoples {
    width: 100%
  }
}

.peoples .user-info {
  display: block;
  border-top: solid 1px #CCC;
  width: 100%;
  clear: both;
  height: 8.75rem;
  padding: 1.25rem 0 1.1875rem
}

.peoples .user-info .user-avatar {
  float: left;
  margin-right: .625rem
}

.peoples .user-info .user-name {
  margin-top: .9375rem
}

.peoples .user-info .btn-dropdown {
  float: right;
  min-width: 8.4375rem;
  margin-top: -3.125rem;
  font-size: .875rem
}

@media (max-width:767px) {
  .peoples .user-info .user-job {
    margin-right: 8.4375rem
  }
  .peoples .user-info .btn-dropdown {
    width: 7.8125rem;
    padding: .0625rem .3125rem;
    font-size: .75rem
  }
}

@media (max-width:479px) {
  .peoples .user-info .user-name {
    margin-top: 0
  }
  .peoples .user-info .user-job {
    margin-right: 0
  }
  .peoples .user-info .btn-dropdown {
    float: left;
    clear: left;
    margin: 0 0 1.25rem 6.4375rem
  }
}

.all-peoples {
  clear: both;
  padding-top: 1px
}

@media (min-width:768px) and (max-width:991px) {
  .all-peoples {
    width: 100%
  }
}

.all-peoples .user-info {
  float: left;
  width: 9.0625rem;
  text-align: center;
  margin: 0 1.25rem 1.25rem 0
}

.all-peoples .user-info:nth-child(3) {
  clear: left
}

.all-peoples .user-info .user-name {
  margin-top: .5rem
}

.all-peoples .user-info .user-location {
  margin-bottom: .9375rem
}

@media (max-width:479px) {
  .all-peoples .user-info {
    margin: 0 .625rem 1.25rem 0
  }
  .all-peoples .user-info:nth-of-type(odd) {
    margin-right: 0
  }
}

.statuses p {
  text-align: center;
  font-size: 1.25rem;
  max-width: 27.1875rem;
  margin: 1.25rem auto
}

.statuses .grand-total {
  text-align: center;
  color: #376caf;
  font-size: 4.375rem;
  line-height: 4.5rem;
  font-weight: 100
}

@media (max-width:767px) {
  .profile-user-activity .column-one-half {
    width: 100%
  }
}

@media (min-width:992px) {
  .manageProfile .settings_sliders {
    width: 76.6%
  }
}

@media (min-width:1200px) {
  .manageProfile .settings_sliders {
    width: 64.4%
  }
}

.manageProfile .settings_sliders .settings_group {
  width: 100%;
  border-bottom: solid 1px #e6e6e6
}

.manageProfile .settings_sliders .settings_group .group_content .checkbox.setting {
  position: relative;
  clear: left
}

.manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .help_text {
  color: #999;
  font-size: .75rem
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_label.accordion_trigger .btn-edit {
  float: right;
  color: #999;
  background-color: transparent
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_label.accordion_trigger .btn-edit:hover {
  color: #444
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_content {
  display: none
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_content input[type=text] {
  display: block;
  max-width: 34.6875rem
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_content .btn,
.manageProfile .settings_sliders .settings_group .group_content .accordion_content .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .manageProfile .settings_sliders .settings_group .group_content .accordion_content .close-reveal-modal.btn-close {
  width: 8.4375rem
}

.manageProfile .settings_sliders .settings_group .group_content .accordion_content .btn.btn-submit,
.manageProfile .settings_sliders .settings_group .group_content .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
.reveal-modal .manageProfile .settings_sliders .settings_group .group_content .accordion_content .btn-submit.close-reveal-modal.btn-close {
  margin-right: .625rem
}

.manageProfile .settings_sliders .settings_group+.btn-submit {
  margin-top: .625rem
}

@media (min-width:768px) {
  .manageProfile .settings_sliders .settings_group {
    display: table;
    padding: 1.25rem 0
  }
  .manageProfile .settings_sliders .settings_group .group_label {
    display: table-cell;
    vertical-align: top;
    width: 9.0625rem;
    padding: 0 .625rem;
    font-weight: 700
  }
  .manageProfile .settings_sliders .settings_group .group_label .settings_subheading {
    font-weight: 500;
    font-size: .875rem
  }
  .manageProfile .settings_sliders .settings_group .group_content {
    display: table-cell;
    vertical-align: top;
    padding: 0 .625rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting {
    margin-right: 3.125rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch {
    float: left;
    margin: 0 .625rem 0 0
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch.notification-label {
    color: #999;
    font-size: .75rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch.notification-label.system {
    margin-left: 5px
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch.notification-label.email {
    margin-left: 13px
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch+label {
    display: block;
    margin-left: 7.25rem;
    font-size: 1rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .help_text {
    margin: 0 0 1.25rem 7.25rem
  }
}

@media (max-width:767px) {
  .manageProfile .settings_sliders .settings_group {
    padding: .625rem 0
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting {
    position: relative;
    clear: left
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch {
    float: right;
    margin: 0 0 0 .625rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch:before {
    display: block;
    color: #999;
    font-size: .75rem;
    text-align: center
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch.system:before {
    content: 'System'
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch.email:before {
    content: 'Email'
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .switch+label {
    display: block;
    margin-right: 7.25rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .checkbox.setting .help_text {
    clear: left;
    margin-right: 7.25rem;
    line-height: 1rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .accordion_label.accordion_trigger .btn-edit {
    float: right;
    color: #999;
    background-color: transparent;
    margin-bottom: 0
  }
  .manageProfile .settings_sliders .settings_group .group_content .accordion_label.accordion_trigger .btn-edit:hover {
    color: #444
  }
  .manageProfile .settings_sliders .settings_group .group_content .accordion_label.accordion_trigger .trigger_value {
    position: relative;
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word
  }
  .manageProfile .settings_sliders .settings_group .group_content .accordion_content {
    font-size: .875rem;
    margin: .3125rem 0 .625rem
  }
  .manageProfile .settings_sliders .settings_group.email_group .trigger_value {
    font-size: .875rem
  }
}

@media (max-width:479px) {
  .manageProfile .settings_sliders .settings_group {
    display: block;
    clear: both
  }
  .manageProfile .settings_sliders .settings_group .trigger_value {
    max-width: 15.625rem
  }
  .manageProfile .settings_sliders .settings_group.email_group .trigger_value {
    font-size: .75rem
  }
  .manageProfile .settings_sliders .settings_group .group_content .accordion_content .btn.btn-submit,
  .manageProfile .settings_sliders .settings_group .group_content .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
  .reveal-modal .manageProfile .settings_sliders .settings_group .group_content .accordion_content .btn-submit.close-reveal-modal.btn-close {
    margin-right: 1rem
  }
}

.manageProfile .settings_form .settings_group {
  width: 100%
}

.manageProfile .settings_form .settings_group.email_group {
  border-bottom: solid 1px #e6e6e6
}

.manageProfile .settings_form .settings_group .group_content .checkbox.setting {
  position: relative;
  clear: left
}

.manageProfile .settings_form .settings_group .group_content .checkbox.setting .help_text {
  color: #999;
  font-size: .75rem
}

.manageProfile .settings_form .settings_group .group_content .accordion_label.accordion_trigger .btn-edit {
  float: right;
  color: #999;
  background-color: transparent
}

.manageProfile .settings_form .settings_group .group_content .accordion_label.accordion_trigger .btn-edit:hover {
  color: #444
}

.manageProfile .settings_form .settings_group .group_content .accordion_content {
  display: none
}

.manageProfile .settings_form .settings_group .group_content .accordion_content input[type=text] {
  display: block;
  max-width: 34.6875rem
}

.manageProfile .settings_form .settings_group .group_content .accordion_content .btn,
.manageProfile .settings_form .settings_group .group_content .accordion_content .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .manageProfile .settings_form .settings_group .group_content .accordion_content .close-reveal-modal.btn-close {
  width: 8.4375rem
}

.manageProfile .settings_form .settings_group .group_content .accordion_content .btn.btn-submit,
.manageProfile .settings_form .settings_group .group_content .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
.reveal-modal .manageProfile .settings_form .settings_group .group_content .accordion_content .btn-submit.close-reveal-modal.btn-close {
  margin-right: .625rem
}

.manageProfile .settings_form .settings_group+.btn-submit {
  margin-top: .625rem
}

@media (min-width:768px) {
  .manageProfile .settings_form .settings_group {
    display: table;
    padding: 1.25rem 0
  }
  .manageProfile .settings_form .settings_group .group_label {
    display: table-cell;
    vertical-align: top;
    width: 9.0625rem;
    padding: 0 .625rem 0 0;
    font-weight: 700
  }
  .manageProfile .settings_form .settings_group.newsletters_group,
  .manageProfile .settings_form .settings_group.notifications_group {
    padding-top: 0
  }
  .manageProfile .settings_form .settings_group .group_label .settings_subheading {
    font-weight: 500;
    font-size: .875rem
  }
  .manageProfile .settings_form .settings_group .group_content {
    display: table-cell;
    vertical-align: top
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch {
    float: left;
    margin: 0 .625rem 0 0
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch.label {
    color: #999;
    font-size: .75rem;
    padding-bottom: .625rem
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch.label.system {
    margin-left: 5px
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch.label.email {
    margin-left: 13px
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch+label {
    display: block;
    font-size: 1rem
  }
  .manageProfile .settings_form .settings_group.password_group {
    padding-bottom: 0
  }
  .manageProfile .settings_form .settings_group.notifications_group .group_content .checkbox.setting .switch+label {
    margin-left: 7.25rem
  }
  .manageProfile .settings_form .settings_group.notifications_group .group_content .checkbox.setting .help_text {
    margin: 0 0 1.25rem 7.25rem
  }
  .manageProfile .settings_form .settings_group.newsletters_group .group_content .checkbox.setting .switch+label {
    margin-left: 3.625rem
  }
  .manageProfile .settings_form .settings_group.newsletters_group .group_content .checkbox.setting .help_text {
    margin: 0 0 1.25rem 3.625rem
  }
}

@media (max-width:767px) {
  .manageProfile .settings_form .settings_group {
    padding: .625rem 0
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting {
    position: relative;
    clear: left
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch {
    float: right;
    margin: 0 0 0 .625rem
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch:before {
    display: block;
    color: #999;
    font-size: .75rem;
    text-align: center
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch.system:before {
    content: 'System'
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch.email:before {
    content: 'Email'
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .switch+label {
    display: block;
    margin-right: 7.25rem
  }
  .manageProfile .settings_form .settings_group .group_content .checkbox.setting .help_text {
    clear: left;
    margin-right: 7.25rem;
    line-height: 1rem
  }
  .manageProfile .settings_form .settings_group .group_content .accordion_label.accordion_trigger .btn-edit {
    float: right;
    color: #999;
    background-color: transparent;
    margin-bottom: 0
  }
  .manageProfile .settings_form .settings_group .group_content .accordion_label.accordion_trigger .btn-edit:hover {
    color: #444
  }
  .manageProfile .settings_form .settings_group .group_content .accordion_label.accordion_trigger .trigger_value {
    position: relative;
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word
  }
  .manageProfile .settings_form .settings_group .group_content .accordion_content {
    font-size: .875rem;
    margin: .3125rem 0 .625rem
  }
  .manageProfile .settings_form .settings_group.email_group .trigger_value {
    font-size: .875rem
  }
}

@media (max-width:479px) {
  .manageProfile .settings_form .settings_group {
    display: block;
    clear: both
  }
  .manageProfile .settings_form .settings_group .trigger_value {
    max-width: 15.625rem
  }
  .manageProfile .settings_form .settings_group.email_group .trigger_value {
    font-size: .75rem
  }
  .manageProfile .settings_form .settings_group .group_content .accordion_content .btn.btn-submit,
  .manageProfile .settings_form .settings_group .group_content .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
  .reveal-modal .manageProfile .settings_form .settings_group .group_content .accordion_content .btn-submit.close-reveal-modal.btn-close {
    margin-right: 1rem
  }
}

.companyPages .company-at-a-glance {
  margin-bottom: 1.25rem
}

.companyPages .company-at-a-glance p:nth-last-of-type(1) {
  margin-bottom: 0
}

.companyPages .company-at-a-glance h2 {
  color: #636468;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.25rem
}

.companyPages .company-at-a-glance h2 .has-products {
  font-weight: 400;
  font-size: .67em
}

.companyPages .company-at-a-glance .company-averages {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem
}

.companyPages .company-at-a-glance .company-averages .label {
  font-size: 1.375rem
}

.companyPages .company-at-a-glance .company-averages .star-ratings-sprite {
  display: inline-block;
  margin-bottom: -3px
}

.companyPages .company-at-a-glance .company-averages .recommended .full {
  display: inherit
}

.companyPages .company-at-a-glance .company-averages .recommended .abbrev {
  display: none
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .companyPages .company-at-a-glance h2 .has-products {
    display: block
  }
  .companyPages .company-at-a-glance .recommended {
    display: inline-block;
    clear: left
  }
}

@media (max-width:767px) {
  .companyPages .company-at-a-glance .company-averages {
    font-size: 1rem;
    line-height: 1.5rem
  }
  .companyPages .company-at-a-glance .company-averages .recommended .full {
    display: none
  }
  .companyPages .company-at-a-glance .company-averages .recommended .abbrev {
    display: inherit
  }
}

@media (min-width:1200px) {
  .companyPages .company-at-a-glance .company-averages {
    font-size: 1.125rem
  }
  .companyPages .company-at-a-glance .company-averages .label {
    font-size: 1.5rem
  }
}

.companyPages .company-blurb {
  font-weight: 300
}

@media (max-width:767px),
(min-width:768px) and (max-width:991px) {
  .companyPages .company-blurb {
    display: block;
    display: -webkit-box;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
  }
  .companyPages .company-blurb.long {
    height: auto;
    -webkit-line-clamp: initial;
    overflow: auto;
    text-overflow: initial
  }
}

.companyPages .company-blurb .inline-ad {
  float: right;
  margin-left: .9375rem
}

@media (min-width:768px) and (max-width:991px),
(max-width:550px) {
  .companyPages .company-blurb .inline-ad {
    float: none;
    margin-left: 0;
    margin-bottom: .9375rem
  }
}

.companyPages .company-blurb p:nth-last-of-type(1) {
  margin-bottom: 0
}

.companyPages .company-blurb .side-column-ad {
  margin-bottom: 1.25rem;
  margin-top: 0
}

.companyPages .expand-description {
  display: none
}

@media (max-width:767px) {
  .companyPages .expand-description {
    display: block
  }
}

.companyPages .edit-company,
.companyPages .edit-product {
  color: #6b6563;
  font-weight: 300;
  font-size: .875rem;
  margin-top: 1.25rem
}

.companyPages .edit-company .last-edit,
.companyPages .edit-product .last-edit {
  font-style: italic
}

.companyPages .edit-company .edit-this,
.companyPages .edit-product .edit-this {
  display: inline-block;
  float: right
}

.companyPages .edit-company a,
.companyPages .edit-product a {
  font-weight: 400;
  font-style: normal
}

.companyPages .edit-company .load-more,
.companyPages .edit-company .more-arrow,
.companyPages .edit-product .load-more,
.companyPages .edit-product .more-arrow {
  width: 40%;
  display: inline-block;
  float: right;
  vertical-align: middle;
  margin-top: 0
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .companyPages .edit-company .load-more,
  .companyPages .edit-company .more-arrow,
  .companyPages .edit-product .load-more,
  .companyPages .edit-product .more-arrow {
    width: 100%
  }
  .companyPages .edit-company .last-edit,
  .companyPages .edit-product .last-edit {
    float: right
  }
}

@media (max-width:767px) {
  .companyPages .edit-company .load-more,
  .companyPages .edit-company .more-arrow,
  .companyPages .edit-product .load-more,
  .companyPages .edit-product .more-arrow {
    width: 100%
  }
  .companyPages .edit-company .last-edit,
  .companyPages .edit-product .last-edit {
    float: right
  }
}

@media (min-width:992px) {
  .companyPages .company-blurb .side-column-ad {
    float: right;
    margin-left: 1.25rem
  }
  .companyPages .product-listing .left-content {
    float: left;
    display: block;
    width: 48.3%;
    margin-right: 3.4%
  }
  .companyPages .product-listing .right-content {
    margin: -20px 0 0
  }
}

.companyPages .contact-form {
  position: relative
}

.companyPages .contact-form .form-element-required,
.companyPages .contact-form input[type=text],
.companyPages .contact-form textarea {
  width: 100%
}

.companyPages .contact-form .btn-align-right {
  width: 100%;
  text-align: right
}

.companyPages .contact-form .btn-align-right .btn-submit {
  min-width: 8.5rem
}

.companyPages .contact-block {
  margin-bottom: 1.875rem
}

.companyPages .claim-yourself {
  display: inline-block;
  clear: right;
  border: none;
  font-style: italic;
  font-size: 1rem
}

.companyPages .claim-yourself.alert-box.info {
  color: #444
}

.companyPages .claim-yourself a {
  font-weight: 300
}

@media (max-width:479px) {
  .companyPages .claim-yourself {
    display: none!important
  }
}

@media (max-width:767px) {
  .companyPages .claim-yourself {
    display: none!important
  }
  .companyPages .company-contact .left-content {
    float: left;
    display: block;
    width: 48.3%;
    margin-right: 3.4%
  }
  .companyPages .company-contact .right-content {
    display: inline-block;
    width: 48.3%
  }
  .companyPages .company-contact .contact-form .btn-align-right,
  .companyPages .company-contact .contact-form .form-element-required,
  .companyPages .company-contact .contact-form input[type=text],
  .companyPages .company-contact .contact-form textarea {
    max-width: 100%
  }
  .companyPages.company-products.gold ul#productTabs+.tabs-content.productTabs .content.active,
  .companyPages.company-products.silver ul#productTabs+.tabs-content.productTabs .content.active {
    padding: .9375rem
  }
}

@media (max-width:767px) and (max-width:479px) {
  .companyPages .company-contact .btn-submit,
  .companyPages .company-contact .left-content,
  .companyPages .company-contact .right-content {
    float: none;
    display: block;
    width: 100%
  }
}

.companyPages .headline-border .recent {
  display: none
}

@media (min-width:992px) {
  .companyPages .headline-border .recent {
    display: inline-block
  }
}

.companyPages .company-banner-ad {
  margin-top: 1.875rem;
  text-align: center
}

.companyPages.gold .show-silver,
.companyPages.gold .show-wiki {
  display: none
}

.companyPages.gold .show-gold {
  display: inherit
}

.companyPages.silver .show-gold,
.companyPages.silver .show-wiki {
  display: none
}

.companyPages.silver .show-silver {
  display: inherit
}

.companyPages.wiki .hide-wiki,
.companyPages.wiki .show-gold,
.companyPages.wiki .show-silver {
  display: none
}

.companyPages.wiki .show-wiki {
  display: inherit
}

.claim-page {
  text-align: right;
  font-size: .8125rem;
  font-style: italic
}

.claim-page a {
  font-style: normal;
  font-weight: 400
}

@media (min-width:768px) and (max-width:991px) {
  .claim-page {
    text-align: left
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:550px) {
  .claim-page {
    text-align: left
  }
}

@media (max-width:479px) {
  .claim-page {
    text-align: left
  }
}

.expand-description {
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1
}

.expand-description:before {
  border-top: 1px solid #e6e6e6;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.expand-description .expand-less,
.expand-description .expand-more {
  cursor: pointer;
  font-size: .75rem;
  background-color: #fff;
  color: #636468
}

.expand-description .expand-less .blue,
.expand-description .expand-more .blue {
  color: #376caf
}

.expand-description .expand-more {
  display: inline
}

.expand-description .expand-less,
.expand-description.advanced .expand-more {
  display: none
}

.expand-description.advanced .expand-less {
  display: inline
}

.companyPages.company-products.gold .headline-border.product-summary-tab+.headline-border,
.companyPages.company-products.silver .headline-border.product-summary-tab+.headline-border,
.companyPages.company-products.wiki .headline-border.product-summary-tab+.headline-border {
  display: none
}

.companyPages.company-products.wiki .headline-border.product-summary-tab {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
  border-bottom: solid 1px #444
}

.companyPages.company-products #panel-ratings h3.headline-border {
  border-top-color: transparent
}

.companyPages.company-products .noVendor {
  display: block;
  margin-top: .3125rem;
  text-align: right;
  font-size: .875rem;
  line-height: 1.5rem;
  font-weight: 300
}

.companyPages.company-products .noVendor a {
  font-weight: 400
}

#productTabs {
  margin-top: 1.25rem
}

.reveal-modal ul#productTabs+.tabs-content.productTabs h3 .close-reveal-modal.btn-close,
ul#productTabs+.tabs-content.productTabs h3 .btn,
ul#productTabs+.tabs-content.productTabs h3 .reveal-modal .close-reveal-modal.btn-close {
  float: right
}

ul#productTabs+.tabs-content.productTabs h3.headline-border {
  clear: both
}

h4.by-line {
  margin-top: 1.875rem
}

.company-at-a-glance.review,
.ratings-breakdown {
  display: block;
  clear: both
}

.company-at-a-glance.review .read-more,
.ratings-breakdown .read-more {
  clear: both;
  font-weight: 300
}

.company-at-a-glance.review .average-stars,
.ratings-breakdown .average-stars {
  clear: left;
  float: left;
  color: #cc6500;
  font-size: 3.75rem;
  line-height: 3.75rem
}

.company-at-a-glance.review .star-rating,
.ratings-breakdown .star-rating {
  float: left
}

.company-at-a-glance.review .stars-breakdown,
.ratings-breakdown .stars-breakdown {
  clear: left;
  float: left;
  padding-top: 10px
}

.company-at-a-glance.review .more-arrow,
.ratings-breakdown .more-arrow {
  margin-top: 0;
  padding-top: .9375rem
}

.company-at-a-glance.review .recommended-stars,
.ratings-breakdown .recommended-stars {
  clear: left
}

@media (min-width:992px) {
  .company-at-a-glance.review .star-rating,
  .ratings-breakdown .star-rating {
    margin: .625rem 1.25rem 0
  }
  .company-at-a-glance.review .byWho,
  .ratings-breakdown .byWho {
    float: right;
    max-width: 50%
  }
  .company-at-a-glance.review .btn-orange,
  .ratings-breakdown .btn-orange {
    float: right;
    margin-top: .9375rem
  }
  .company-at-a-glance.review .btn-orange.mobile,
  .ratings-breakdown .btn-orange.mobile {
    display: none
  }
  .company-at-a-glance.review .stars-breakdown,
  .ratings-breakdown .stars-breakdown {
    margin-left: 90px;
    max-width: calc(50% - 90px)
  }
}

@media (min-width:1200px) {
  .company-at-a-glance.review .stars-breakdown,
  .ratings-breakdown .stars-breakdown {
    margin-left: 100px;
    max-width: calc(50% - 100px)
  }
}

@media (max-width:767px) {
  .company-at-a-glance.review .star-rating,
  .ratings-breakdown .star-rating {
    margin: .625rem 1.25rem 0
  }
}

@media (max-width:767px) and (min-width:611px) {
  .company-at-a-glance.review .btn-orange,
  .ratings-breakdown .btn-orange {
    float: right;
    margin-top: .9375rem
  }
  .company-at-a-glance.review .btn-orange.mobile,
  .ratings-breakdown .btn-orange.mobile {
    display: none
  }
  .company-at-a-glance.review .stars-breakdown,
  .ratings-breakdown .stars-breakdown {
    margin-left: 90px;
    max-width: calc(50% - 90px)
  }
  .company-at-a-glance.review .byWho,
  .ratings-breakdown .byWho {
    float: right;
    max-width: 50%
  }
}

@media (max-width:767px) and (max-width:610px) and (min-width:480px) {
  .company-at-a-glance.review #reviewChart,
  .company-at-a-glance.review .recommended-stars,
  .ratings-breakdown #reviewChart,
  .ratings-breakdown .recommended-stars {
    text-align: center
  }
  .company-at-a-glance.review .btn-orange,
  .ratings-breakdown .btn-orange {
    float: left
  }
  .company-at-a-glance.review .btn-orange.desktop,
  .ratings-breakdown .btn-orange.desktop {
    display: none
  }
  .company-at-a-glance.review .stars-breakdown,
  .ratings-breakdown .stars-breakdown {
    margin-left: 100px;
    max-width: calc(100% - 100px)
  }
  .company-at-a-glance.review .byWho,
  .ratings-breakdown .byWho {
    float: none;
    clear: left;
    max-width: 100%
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .company-at-a-glance.review #reviewChart,
  .company-at-a-glance.review .recommended-stars,
  .ratings-breakdown #reviewChart,
  .ratings-breakdown .recommended-stars {
    text-align: center
  }
  .company-at-a-glance.review .btn-orange,
  .ratings-breakdown .btn-orange {
    float: left
  }
  .company-at-a-glance.review .btn-orange.desktop,
  .ratings-breakdown .btn-orange.desktop {
    display: none
  }
  .company-at-a-glance.review .average-stars,
  .ratings-breakdown .average-stars {
    float: none;
    display: inline-block
  }
  .company-at-a-glance.review .average-stars+.star-rating,
  .ratings-breakdown .average-stars+.star-rating {
    float: none;
    display: inline-block;
    margin: .625rem 0 0 .625rem
  }
  .company-at-a-glance.review .average-stars+.star-rating+.star-rating,
  .ratings-breakdown .average-stars+.star-rating+.star-rating {
    clear: both;
    width: 100%;
    margin: .3125rem 0 0
  }
  .company-at-a-glance.review .average-stars+.star-rating+.star-rating br,
  .ratings-breakdown .average-stars+.star-rating+.star-rating br {
    display: none
  }
  .company-at-a-glance.review .average-stars+.star-rating+.star-rating .dealerships-ratings,
  .ratings-breakdown .average-stars+.star-rating+.star-rating .dealerships-ratings {
    display: inline-block;
    width: 49%
  }
  .company-at-a-glance.review .average-stars+.star-rating+.star-rating .dealerships-ratings:last-child,
  .ratings-breakdown .average-stars+.star-rating+.star-rating .dealerships-ratings:last-child {
    text-align: right
  }
  .company-at-a-glance.review .stars-breakdown,
  .ratings-breakdown .stars-breakdown {
    margin-left: 90px;
    width: calc(100% - 90px)
  }
  .company-at-a-glance.review .more-arrow .cview-pagination,
  .ratings-breakdown .more-arrow .cview-pagination {
    font-size: .75rem
  }
  .company-at-a-glance.review .more-arrow .btn-orange,
  .ratings-breakdown .more-arrow .btn-orange {
    margin-top: 0
  }
  .company-at-a-glance.review .more-arrow .btn-orange svg,
  .ratings-breakdown .more-arrow .btn-orange svg {
    width: .75rem;
    height: .75rem
  }
}

.rate-summary {
  position: relative;
  font-size: 1rem;
  font-weight: 300
}

.rate-summary .title {
  width: 100%;
  text-align: center;
  font-weight: 400
}

.rate-summary.stars-breakdown .title {
  margin: .625rem 0 .625rem -100px;
  width: calc(100% + 100px)
}

.rate-summary.stars-breakdown .star-number {
  text-align: right;
  font-size: .8125rem
}

.rate-summary.byWho .title {
  margin: 1.25rem 0 .625rem
}

.rate-summary.byWho .p0_title {
  font-weight: 500
}

.star-bar {
  display: block;
  position: relative;
  height: .9375rem;
  margin: 0 0 5px;
  background: #CE6702;
  line-height: .9375rem
}

div.star-bar>div.star-number {
  font-size: .75rem
}

.star-number {
  display: block;
  position: absolute;
  left: 10px
}

.star-count {
  position: absolute;
  left: -92px;
  top: -1px;
  height: 17px;
  width: 85px;
  background: url(/images/components/rate-star-gray_xs.png) repeat-x
}

.star-4 {
  background: #DD7901
}

.star-4 .star-count {
  left: -75px;
  width: 68px
}

.star-3 {
  background: #E48A2A
}

.star-3 .star-count {
  left: -58px;
  width: 51px
}

.star-2 {
  background: #ED9D4C
}

.star-2 .star-count {
  left: -41px;
  width: 34px
}

.star-1 {
  background: #F2C190
}

.star-1 .star-count {
  left: -24px;
  width: 17px
}

.summary-header h3 {
  margin-top: 0
}

.summary-header .prod-overview .prod-title {
  display: block
}

.summary-header .prod-overview .prod-title img {
  float: left;
  margin: 0 .9375rem 1.25rem 0
}

.summary-header .prod-overview .prod-title .title-by {
  margin-bottom: 1.25rem
}

.summary-header .prod-overview .prod-title .title-by .overview-title {
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: #376caf
}

.summary-header .prod-overview .prod-title .title-by .by-line {
  display: block;
  color: #444;
  font-size: .875rem;
  line-height: 1.5rem
}

.summary-header .prod-overview .prod-content {
  display: block;
  clear: both;
  border-top: solid 1px #CCC;
  padding-top: 15px;
  margin-bottom: 20px;
  color: #444;
  font-size: .9375rem;
  line-height: 1.375rem
}

.summary-header .prod-overview .prod-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: .625rem
}

.summary-header .prod-overview .prod-content a.read-more {
  display: block;
  text-align: right
}

.silver-award,
.silver-video {
  width: 50%;
  float: left
}

.silver-award .view-more,
.silver-video .view-more {
  display: block;
  text-align: right;
  font-size: .875rem;
  font-weight: 300
}

#rulesModal h1,
#rulesModal h2,
.bar {
  text-align: center
}

@media (min-width:992px) and (max-width:1199px) {
  #productTabs .tab-title a {
    padding: 1rem 1.5rem
  }
  #productTabs .tab-title.active a {
    margin-top: -.3125rem;
    padding: 1rem 1.5rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars,
  ul#productTabs+.tabs-content.productTabs .rate-summary.stars-breakdown {
    margin-right: 1%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .prod-overview {
    max-width: 39%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.recommended-stars {
    max-width: 26%;
    margin: 0 1%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.stars-breakdown {
    margin-right: 0;
    margin-left: 0
  }
}

@media (min-width:768px) and (max-width:991px) {
  #productTabs .tab-title a,
  #productTabs .tab-title.active a {
    font-size: .875rem;
    font-weight: 500;
    padding: 1rem .4375rem
  }
  #productTabs .tab-title.active a {
    margin-top: -.3125rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary {
    clear: both
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars {
    text-align: center;
    margin: 2.25rem auto 0;
    padding-top: 1.25rem;
    max-width: 100%
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars .average-stars {
    max-width: 49%;
    float: left;
    margin-right: 1%
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars .average-recommended {
    max-width: 49%;
    float: right;
    margin-left: 1%
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.stars-breakdown {
    margin: 2.5rem auto 1.25rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.byWho {
    max-width: 100%;
    margin: auto;
    text-align: center
  }
}

@media (max-width:767px) {
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars,
  ul#productTabs+.tabs-content.productTabs .rate-summary.stars-breakdown {
    float: left;
    max-width: 25%;
    margin-right: 5%
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.byWho {
    float: right;
    max-width: 40%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .prod-overview {
    float: left;
    max-width: 40%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.recommended-stars {
    max-width: 25%;
    margin: 0 0 0 1%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.stars-breakdown {
    float: right;
    margin-right: 0;
    margin-left: 0
  }
}

@media (max-width:767px) and (max-width:620px) {
  ul#productTabs+.tabs-content.productTabs .rate-summary.byWho {
    float: none;
    max-width: 100%;
    margin: auto
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .prod-overview {
    float: left;
    max-width: 50%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary {
    float: right
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.recommended-stars {
    max-width: 35%
  }
}

@media (max-width:767px) {
  #productTabs .tab-title a,
  #productTabs .tab-title.active a {
    font-size: .875rem;
    font-weight: 500;
    padding: .75rem .4375rem
  }
  #productTabs .tab-title.active a {
    margin-top: -.3125rem
  }
}

@media (max-width:479px) {
  ul#productTabs+.tabs-content.productTabs .content.active {
    padding: .3125rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.byWho,
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars,
  ul#productTabs+.tabs-content.productTabs .rate-summary.stars-breakdown {
    float: none;
    clear: both;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 16.25rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars {
    clear: both
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars .average-stars {
    padding: 1.25rem 0
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars .progress,
  ul#productTabs+.tabs-content.productTabs .rate-summary.recommended-stars .star-rating {
    max-width: 7.8125rem;
    margin: auto
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.stars-breakdown {
    margin: 2.5rem auto 1.25rem
  }
  ul#productTabs+.tabs-content.productTabs .rate-summary.byWho #reviewChart {
    margin-left: -.625rem
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .prod-overview {
    float: none;
    max-width: 100%
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.recommended-stars,
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.stars-breakdown {
    float: left;
    max-width: 45%;
    clear: none;
    margin: 0 auto 5%
  }
  #productTabs .tab-title a,
  #productTabs .tab-title.active a {
    font-size: .625rem
  }
}

@media (max-width:479px) and (max-width:413px) {
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary {
    float: none;
    margin: auto
  }
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.recommended-stars,
  ul#productTabs+.tabs-content.productTabs .summary-header .rate-summary.stars-breakdown {
    float: none;
    display: block;
    clear: both;
    max-width: 11.25rem;
    width: auto
  }
}

.tabs.horizontal.shadow-tabs+.tabs-content .content>h3:first-child {
  margin-top: 0
}

.recentVRs+.pagination-centered {
  margin: 3.125rem 0 0
}

.recentVRs+.more-arrow {
  margin-top: 1.25rem
}

.overview {
  background: #fff;
  padding: 30px
}

.overview h1,
.overview h2,
.overview h3 {
  font-weight: 300;
  color: #376caf;
  line-height: 1.2
}

.overview h1 {
  border-bottom: 4px solid #376caf;
  width: 100%;
  margin-bottom: .5em;
  font-size: 3rem
}

.overview h2 {
  font-size: 2.5rem
}

.overview ol,
.overview ul {
  margin-left: 2.5rem
}

.overview li {
  margin-bottom: .5em;
  color: #444;
  font-weight: 300;
  font-size: 1em
}

.overview table {
  width: 46%;
  margin-right: 3%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px
}

.overview table thead {
  background: #376caf
}

.overview table thead td {
  color: #fff
}

.overview table tbody,
.overview table td,
.overview table tr {
  width: 100%
}

.overview table td:nth-child(2) {
  width: 20%;
  text-align: center
}

.overview .table-of-contents {
  -webkit-columns: 300px 3;
  columns: 300px 3;
  margin-bottom: 30px
}

.overview .table-of-contents ul {
  border-left: 1px solid #CCC;
  padding-left: 30px;
  margin-left: 10px
}

.overview .table-of-contents ul ul {
  border-left: none
}

@media (max-width:767px) {
  .overview .table-of-contents ul li ul {
    display: none
  }
}

.overview .overview-bluebox {
  background: #376caf;
  padding: 1.5rem 2rem 1rem;
  color: #fff;
  font-weight: 300;
  margin-bottom: 1.5rem
}

.overview .overview-bluebox div {
  margin-bottom: .75em
}

.overview .overview-bluebox h2 {
  color: #fff;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 1.5rem
}

.overview .overview-bluebox li {
  color: #fff
}

.overview .overview-bluebox p {
  margin-bottom: .5em
}

.overview .overview-bluebox .title {
  display: inline-block;
  width: 20%;
  font-weight: 600;
  vertical-align: top
}

.icon-no:before,
.icon-yes:before {
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit
}

.overview .overview-bluebox .prof-value {
  display: inline-block;
  width: 75%
}

.icon-no:before {
  content: "\f068";
  color: #b00
}

.icon-yes:before {
  content: "\f00c";
  color: #0a0
}

.claim-company_modal .hs-form-required,
.progress {
  display: none
}

@media (max-width:767px) {
  .overview {
    padding: 30px 15px
  }
  .overview table {
    width: 100%;
    margin-right: 0
  }
  .overview .overview-bluebox {
    padding: 15px
  }
  .overview .overview-bluebox .prof-value,
  .overview .overview-bluebox .title {
    width: 45%
  }
}

.claim-company_modal .blue-header {
  background-color: #cc6500!important;
  font-size: 1.875rem!important;
  height: 70px!important;
  padding-top: .75rem!important
}

.claim-company_modal fieldset {
  padding: 0!important;
  width: 100%!important
}

.claim-company_modal fieldset .form-columns-2 .hs-form-field {
  width: 50%!important;
  margin: 0!important
}

.claim-company_modal .hs-form-field {
  margin-bottom: 10px!important
}

.claim-company_modal .hs-input {
  padding: 8px 8px 9px;
  font-size: 14px;
  border-radius: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, .75);
  width: calc(100% - 16px)!important;
  box-sizing: content-box!important;
  border: 1px solid #ccc!important;
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif!important
}

.blog-title,
.post-header .post-headline,
.rate-vendor-start {
  font-family: Roboto, Helvetica Neue, Arial Narrow, Helvetica, Arial, sans-serif
}

.claim-company_modal .hs-form .hs-button {
  text-shadow: none!important;
  background-color: #cc6500!important;
  border: none!important;
  background-image: none!important;
  box-shadow: none!important;
  font-weight: 400!important;
  font-size: .875rem!important;
  transition: none!important;
  border-radius: 4px;
  height: 25px;
  width: 100%;
  padding: 7px 0!important
}

.claim-company_modal .actions {
  padding: 0!important;
  margin: 0!important
}

.claim-company_modal ul {
  margin: 4px 0!important
}

.claim-company_modal label {
  color: #bf3837!important;
  line-height: 14px!important;
  margin: 0
}

.progress-bar-area {
  margin-top: 0
}

.progress {
  position: relative;
  width: 100%;
  height: 30px;
  background: #C7DA9F;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 10px
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 30px;
  background: #85C220;
  color: #fff;
  font-weight: 700;
  font-size: .7em
}

.bar.red {
  background: tomato
}

@media (min-width:768px) {
  .claim-company_modal {
    max-width: 31.25rem
  }
  #rulesModal {
    max-height: 80vh
  }
  #rulesModal[style] {
    top: 10%!important
  }
}

.load-more,
.more-arrow {
  display: block;
  width: 100%;
  text-align: right;
  font-size: .75rem;
  text-transform: uppercase
}

.badge {
  font-size: .875rem;
  font-style: italic
}

.badge.featured {
  color: #d11d00
}

.badge.new {
  color: #159c04
}

.meta-icons {
  float: right;
  color: #999;
  font-size: .875rem
}

.meta-icons .material-icons {
  font-size: 1.5rem
}

.meta-icons .fa {
  font-size: 1.25rem
}

.meta-icons .comments,
.meta-icons .flag {
  color: #999;
  display: inline-block
}

.interact-icons {
  color: #376caf
}

.interact-icons .vote-down,
.interact-icons .vote-up {
  color: #999
}

.interact-icons .vote-down.active,
.interact-icons .vote-up.active {
  color: #376caf
}

.interact-icons .vote-down.material-icons,
.interact-icons .vote-up.material-icons {
  font-size: 1.5rem
}

.interact-icons .vote-down.fa,
.interact-icons .vote-up.fa {
  font-size: 1.25rem
}

.interact-icons .rating-moderation {
  color: #999;
  font-size: .75rem
}

.interact-icons .rating-moderation .vote-down,
.interact-icons .rating-moderation .vote-up {
  font-size: .75rem;
  color: #376caf
}

.interact-icons .rating-moderation .vote-down.active,
.interact-icons .rating-moderation .vote-up.active,
.interact-icons .stat-count {
  color: #999
}

.interact-icons .who-likes {
  font-size: .8125rem
}

@media (max-width:767px) {
  #rulesModal {
    max-height: 90vh
  }
  #rulesModal[style] {
    top: 1%!important
  }
  .interact-icons .who-likes {
    max-width: 63%
  }
}

@media (max-width:767px) and (max-width:479px) {
  .interact-icons .who-likes {
    max-width: 100%;
    display: block;
    clear: left
  }
}

.blog-title {
  color: #444;
  margin: 0 0 1.25rem
}

.blog-title .blog-name,
.blog-title .blog-tagline {
  font-size: .875rem;
  margin: 0 0 .625rem;
  color: #7e7877
}

.blog-title .manage-user-access {
  margin-bottom: 1.25rem
}

.blog-title h4 {
  font-size: .875rem;
  color: #444;
  margin: .1875rem 0 .3125rem
}

.blog-title h4.headline-subline {
  font-size: 1.5rem;
  margin-bottom: .625rem;
  margin-top: 0;
  font-weight: 300;
  color: #7e7877
}

.blog-title .blog-url {
  font-size: .875rem;
  color: #7e7877;
  margin: 0 0 .625rem
}

.blog-title table {
  width: 100%
}

@media (max-width:479px) {
  .ifcanedit .btn,
  .ifcanedit .reveal-modal .close-reveal-modal.btn-close,
  .reveal-modal .ifcanedit .close-reveal-modal.btn-close {
    font-size: .75rem;
    line-height: 1.125rem;
    padding: .1875rem .8125rem .1875rem .5rem
  }
  .ifcanedit .btn:last-child,
  .ifcanedit .reveal-modal .close-reveal-modal.btn-close:last-child,
  .reveal-modal .ifcanedit .close-reveal-modal.btn-close:last-child {
    float: right
  }
}

#blogForm .form-element-required {
  width: 100%
}

#blogForm .form-element-required input[type=text] {
  display: inline-block;
  width: 100%;
  float: left
}

@media (min-width:992px) {
  #blogForm .form-element-required {
    display: block
  }
  #blogForm .form-element-required input[type=text] {
    width: 49%;
    margin-right: 1%
  }
  #blogForm .form-group div:nth-child(2) input {
    margin-right: 0;
    margin-left: 1%
  }
}

#blogForm .save-blog {
  width: 6.25rem;
  margin: .3125rem 0 0 12.5rem
}

#blogForm button {
  margin: 1.25rem 0 0
}

@media (min-width:768px) {
  #blogForm button {
    float: right
  }
}

.blog-claim {
  margin-bottom: 1.25rem
}

.blog.add-post .has-tip,
.blog.edit-post .has-tip {
  border: none
}

.blog.add-post .hide-adv,
.blog.edit-post .hide-adv {
  display: none
}

.blog.add-post #postForm,
.blog.edit-post #postForm {
  margin-top: 30px
}

.blog.add-post .advanced-switch,
.blog.edit-post .advanced-switch {
  float: right
}

.blog.add-post .advanced-switch .advanced-editor,
.blog.edit-post .advanced-switch .advanced-editor {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 300
}

.blog.add-post .advanced-switch .switch,
.blog.edit-post .advanced-switch .switch {
  float: right;
  margin: .625rem .3125rem 0 0
}

.blog.add-post .section .headline-border,
.blog.edit-post .section .headline-border {
  margin-top: 0;
  font-weight: 300
}

.blog.add-post .section .featured-img,
.blog.edit-post .section .featured-img {
  margin: .625rem 0
}

.blog.add-post .section .featured-img .modal-cropper,
.blog.edit-post .section .featured-img .modal-cropper {
  position: relative;
  opacity: 1;
  font-family: Roboto, Helvetica Neue, Arial Narrow, Helvetica, Arial, sans-serif
}

.blog.add-post .section .featured-img button,
.blog.edit-post .section .featured-img button {
  width: 100%
}

.blog.add-post .section.three,
.blog.edit-post .section.three {
  -webkit-columns: 2 140px;
  columns: 2 140px;
  -webkit-column-gap: 1em;
  column-gap: 1em
}

.blog.add-post .section.three h3,
.blog.edit-post .section.three h3 {
  -webkit-column-span: all;
  column-span: all
}

.blog.add-post .section.three .category-checkboxes,
.blog.edit-post .section.three .category-checkboxes {
  display: block;
  margin-bottom: .0625rem
}

.blog.add-post .section.four,
.blog.edit-post .section.four {
  clear: both;
  padding-top: 1px
}

@media (min-width:992px) and (max-width:1199px) {
  .blog.add-post .section.three .category-checkboxes.Finance.Insurance label,
  .blog.add-post .section.three .category-checkboxes.Miscellaneous.Industry label,
  .blog.edit-post .section.three .category-checkboxes.Finance.Insurance label,
  .blog.edit-post .section.three .category-checkboxes.Miscellaneous.Industry label {
    max-width: 140px
  }
  .blog.add-post .section.four h3 small,
  .blog.edit-post .section.four h3 small {
    font-size: .875rem
  }
}

.blog.add-post .section.one input,
.blog.add-post .section.one select,
.blog.add-post .section.one textarea,
.blog.add-post .section.two input,
.blog.add-post .section.two select,
.blog.add-post .section.two textarea,
.blog.edit-post .section.one input,
.blog.edit-post .section.one select,
.blog.edit-post .section.one textarea,
.blog.edit-post .section.two input,
.blog.edit-post .section.two select,
.blog.edit-post .section.two textarea {
  margin: 0
}

.blog.add-post .section.one .section-label,
.blog.add-post .section.two .section-label,
.blog.edit-post .section.one .section-label,
.blog.edit-post .section.two .section-label {
  margin: 1.25rem 0 .625rem;
  font-weight: 400
}

.blog.add-post .section.one .section-label .select-elements,
.blog.add-post .section.two .section-label .select-elements,
.blog.edit-post .section.one .section-label .select-elements,
.blog.edit-post .section.two .section-label .select-elements {
  float: right
}

.blog.add-post .section.one .section-label .select-elements p,
.blog.add-post .section.two .section-label .select-elements p,
.blog.edit-post .section.one .section-label .select-elements p,
.blog.edit-post .section.two .section-label .select-elements p {
  display: inline-block;
  padding: 0 .75rem 0 0
}

.blog.add-post .section.one .seo-settings label,
.blog.add-post .section.two .seo-settings label,
.blog.edit-post .section.one .seo-settings label,
.blog.edit-post .section.two .seo-settings label {
  margin: .625rem 0 .3125rem;
  font-weight: 500
}

.blog.add-post .section.one .seo-settings .section-label,
.blog.add-post .section.two .seo-settings .section-label,
.blog.edit-post .section.one .seo-settings .section-label,
.blog.edit-post .section.two .seo-settings .section-label {
  margin-bottom: 0
}

.blog.add-post .section.five input,
.blog.add-post .section.five select,
.blog.add-post .section.five textarea,
.blog.edit-post .section.five input,
.blog.edit-post .section.five select,
.blog.edit-post .section.five textarea {
  margin: 0
}

.blog.add-post .section.five .hide-adv,
.blog.edit-post .section.five .hide-adv {
  margin-bottom: .9375rem
}

.blog.add-post .section.five .hide-adv label,
.blog.edit-post .section.five .hide-adv label {
  margin: .625rem 0 .3125rem;
  font-weight: 500
}

.blog.add-post .section.five .hide-adv.publish-settings,
.blog.edit-post .section.five .hide-adv.publish-settings {
  margin-bottom: 0
}

.blog.add-post .section.five .accordion_label.accordion_trigger .btn-edit,
.blog.edit-post .section.five .accordion_label.accordion_trigger .btn-edit {
  float: right;
  color: #999;
  background-color: transparent
}

.blog.add-post .section.five .accordion_label.accordion_trigger .btn-edit:hover,
.blog.edit-post .section.five .accordion_label.accordion_trigger .btn-edit:hover {
  color: #444
}

.blog.add-post .section.five .accordion_content,
.blog.edit-post .section.five .accordion_content {
  display: none
}

.blog.add-post .section.five .accordion_content input[type=text],
.blog.add-post .section.five .accordion_content select,
.blog.edit-post .section.five .accordion_content input[type=text],
.blog.edit-post .section.five .accordion_content select {
  display: block;
  max-width: 22.4375rem
}

.blog.add-post .section.five .accordion_content .pub-status label,
.blog.edit-post .section.five .accordion_content .pub-status label {
  width: 17%;
  min-width: 3.75rem;
  font-size: 1rem;
  font-weight: 700;
  display: inline-block
}

.blog.add-post .section.five .accordion_content .pub-status select,
.blog.edit-post .section.five .accordion_content .pub-status select {
  display: inline-block;
  width: 83%;
  max-width: 18.125rem
}

@media (min-width:992px) and (max-width:1199px) {
  .blog.add-post .section.five .accordion_content .pub-status select,
  .blog.edit-post .section.five .accordion_content .pub-status select {
    width: 78%;
    max-width: 14.875rem
  }
}

@media (max-width:479px) {
  .blog.add-post .section.five .accordion_content .pub-status select,
  .blog.edit-post .section.five .accordion_content .pub-status select {
    width: 76%;
    max-width: 12.875rem
  }
}

.blog.add-post .section.five .accordion_content .pub-when,
.blog.edit-post .section.five .accordion_content .pub-when {
  margin: .3125rem 0
}

.blog.add-post .section.five .accordion_content .pub-when .set-date,
.blog.edit-post .section.five .accordion_content .pub-when .set-date {
  display: block;
  margin-top: .3125rem
}

.blog.add-post .section.five .accordion_content .row,
.blog.edit-post .section.five .accordion_content .row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row
}

.blog.add-post .section.five .accordion_content .row input,
.blog.edit-post .section.five .accordion_content .row input {
  -webkit-box-flex: 2;
  flex-grow: 2;
  flex-shrink: 1
}

.blog.add-post .section.five .accordion_content .row select,
.blog.edit-post .section.five .accordion_content .row select {
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 2
}

.blog.add-post .section.five .accordion_content .timezone,
.blog.edit-post .section.five .accordion_content .timezone {
  display: block;
  font-style: italic;
  font-size: .75rem
}

.blog.add-post .section.five .accordion_content .btn,
.blog.add-post .section.five .accordion_content .reveal-modal .close-reveal-modal.btn-close,
.blog.edit-post .section.five .accordion_content .btn,
.blog.edit-post .section.five .accordion_content .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .blog.add-post .section.five .accordion_content .close-reveal-modal.btn-close,
.reveal-modal .blog.edit-post .section.five .accordion_content .close-reveal-modal.btn-close {
  width: 8.4375rem;
  margin: .3125rem 0 .625rem
}

.blog.add-post .section.five .accordion_content .btn.btn-submit,
.blog.add-post .section.five .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
.blog.edit-post .section.five .accordion_content .btn.btn-submit,
.blog.edit-post .section.five .accordion_content .reveal-modal .btn-submit.close-reveal-modal.btn-close,
.reveal-modal .blog.add-post .section.five .accordion_content .btn-submit.close-reveal-modal.btn-close,
.reveal-modal .blog.edit-post .section.five .accordion_content .btn-submit.close-reveal-modal.btn-close {
  margin-right: .625rem
}

.blog.add-post .section.five .btn.save-blog,
.blog.add-post .section.five .reveal-modal .save-blog.close-reveal-modal.btn-close,
.blog.edit-post .section.five .btn.save-blog,
.blog.edit-post .section.five .reveal-modal .save-blog.close-reveal-modal.btn-close,
.reveal-modal .blog.add-post .section.five .save-blog.close-reveal-modal.btn-close,
.reveal-modal .blog.edit-post .section.five .save-blog.close-reveal-modal.btn-close {
  clear: both;
  width: 100%;
  margin-top: 1.25rem
}

@media (min-width:992px) and (max-width:1199px) {
  .blog.add-post .section.five .check-group label,
  .blog.edit-post .section.five .check-group label {
    max-width: 300px
  }
  .blog.add-post .section.five .check-group label .info-icon,
  .blog.edit-post .section.five .check-group label .info-icon {
    float: right;
    margin-right: -12px
  }
}

@media (max-width:479px) {
  .blog.add-post .section.five .check-group label,
  .blog.edit-post .section.five .check-group label {
    max-width: 270px
  }
}

.about-author {
  display: block;
  color: #444
}

.about-author strong {
  font-size: 1.5rem;
  font-weight: 400
}

.about-author strong+p {
  margin-top: .625rem
}

.about-author .avatar-block.avatar-status-widget {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 1.25rem
}

.about-author .avatar-block.avatar-status-widget .status-level {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  margin: -10px
}

.about-author .avatar-block.avatar-status-widget img.avatar {
  padding: 0;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .15)
}

@media (max-width:767px) {
  .about-author .avatar-block.avatar-status-widget {
    width: 9.375rem;
    height: 9.375rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .about-author .avatar-block.avatar-status-widget {
    width: 9.375rem;
    height: 9.375rem
  }
}

@media (min-width:992px) {
  .about-author .avatar-block.avatar-status-widget {
    width: 10rem;
    height: 10rem
  }
}

@media (min-width:1200px) {
  .about-author .avatar-block.avatar-status-widget {
    width: 10.9375rem;
    height: 10.9375rem
  }
}

@media (max-width:767px) {
  .about-author {
    margin-top: 2.5rem;
    height: 100%
  }
  .about-author .avatar-block {
    float: left;
    max-width: 9.375rem;
    height: 100%;
    margin: 0 1.25rem
  }
}

@media (max-width:767px) and (max-width:767px) {
  .about-author .avatar-block {
    float: none;
    margin: 0 auto
  }
}

.post-header {
  display: block;
  clear: both;
  margin-top: 20px
}

.post-header .post-headline {
  display: table-cell;
  color: #376caf;
  font-size: 1.875rem;
  font-weight: 700
}

@media (min-width:992px) {
  .post-header .post-headline {
    font-size: 2.5rem;
    line-height: 2.75rem
  }
}

@media (max-width:767px) {
  .post-header .post-headline {
    font-size: 1.25rem;
    line-height: 1.5rem
  }
}

.post-header .by-line,
.post-header .by-line a {
  font-size: .9375rem;
  font-weight: 300
}

.post-header .by-line {
  clear: left;
  float: left;
  color: #636468
}

@media (max-width:479px) {
  .post-header .by-line {
    max-width: 100%;
    float: none
  }
}

.post-content {
  display: block;
  padding-top: 1.25rem
}

.blog-post .post-content {
  clear: both
}

.blog-post .post-content img[style*="float: left;"],
.blog-post .post-content img[style*="float:left;"] {
  margin: 0 .625rem .625rem 0
}

.blog-post .post-content img[style*="float: right;"],
.blog-post .post-content img[style*="float:right;"] {
  margin: 0 0 .625rem .625rem
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .blog-post .post-content img[style*="float: left;"],
  .blog-post .post-content img[style*="float:left;"],
  .blog-post .post-content img[style*="float: right;"],
  .blog-post .post-content img[style*="float:right;"] {
    float: none!important;
    margin: .625rem auto 0!important;
    width: 100%!important;
    height: 100%!important
  }
}

@media (max-width:767px) {
  .blog-post .post-content>p:first-of-type {
    clear: right
  }
}

.post-author {
  display: block;
  clear: both;
  border: 1px solid #e6e6e6;
  padding: .9375rem;
  margin-bottom: 1.25rem;
  min-height: 12.5rem
}

.post-comment,
.post-preview {
  border-bottom: solid 1px #e6e6e6
}

.post-author .connect-desktop {
  display: inherit
}

.post-author .connect-desktop .btn-blue,
.post-author .connect-desktop .reveal-modal .close-reveal-modal.btn-close.btn-blue,
.reveal-modal .post-author .connect-desktop .close-reveal-modal.btn-close.btn-blue {
  display: block;
  margin: 0 auto 7px
}

.post-author .connect-mobile {
  display: none
}

.post-author .author-connect {
  float: left;
  margin-right: 1.875rem
}

.post-author .author-about {
  color: #444;
  font-weight: 300;
  padding-top: .625rem
}

.post-author .author-about .profile-name {
  font-weight: 300
}

.post-author .author-credentials {
  font-style: italic;
  padding-bottom: .3125rem
}

.post-author .author-blurb,
.post-author .author-credentials {
  color: #666;
  font-weight: 100;
  margin-bottom: 0
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .post-author {
    padding: .625rem;
    min-height: 100%
  }
  .post-author .author-about,
  .post-author .author-blurb,
  .post-author .author-credentials {
    font-size: .875rem
  }
  .post-author .author-connect {
    margin-right: .625rem
  }
  .post-author .connect-desktop {
    display: none
  }
  .post-author .connect-mobile {
    display: inherit
  }
  .post-author .connect-mobile .btn-blue,
  .post-author .connect-mobile .reveal-modal .close-reveal-modal.btn-close.btn-blue,
  .reveal-modal .post-author .connect-mobile .close-reveal-modal.btn-close.btn-blue {
    min-width: 48%
  }
  .post-author .connect-mobile .btn-blue:last-child {
    float: right
  }
  .post-author .author-blurb {
    clear: both
  }
}

.post-footer .post-bottom {
  display: block;
  clear: both;
  border-top: solid 1px #e6e6e6
}

.post-footer .post-bottom .meta-icons {
  margin-top: .3125rem
}

.post-footer .post-bottom .addthis_sharing_toolbox {
  float: right;
  margin: .625rem .3125rem 0
}

@media (max-width:767px) {
  .post-footer .post-bottom .addthis_sharing_toolbox {
    clear: right
  }
}

.comment-footer,
.post-preview .post-bottom {
  clear: both
}

.commentSection {
  clear: both;
  margin-top: 2.5rem
}

.commentSection .sectionLabel {
  font-size: 1.875rem;
  font-weight: 500
}

.commentSection .addComment {
  background-color: #d5e0e9;
  padding: .3125rem .625rem .625rem
}

.commentSection .addComment .avatar-status-widget {
  float: left;
  margin: .3125rem .3125rem 0 0
}

.commentSection .addComment input {
  font-size: .875rem;
  max-width: 100%;
  width: calc(100% - 135px)
}

.commentSection .addComment .btn-submit {
  margin-bottom: 0
}

.commentSection .addComment .cke_editor_addComment {
  font-size: .875rem;
  margin-left: 2.8125rem;
  max-width: calc(100% - 45px);
  width: auto;
  margin-bottom: 3.125rem
}

.commentSection .addComment .cke_editor_addComment+.btn-submit {
  margin-top: -2.5rem
}

.commentSection .addComment .cke_wysiwyg_div {
  min-height: 100px
}

.post-comment .comment-content {
  width: 100%
}

.post-comment .comment-content iframe {
  max-width: 100%
}

.post-comment .commenter.profile {
  position: relative;
  max-width: 10.625rem;
  min-width: 6.25rem;
  padding-right: 1.5625rem
}

.post-comment .commenter.profile .profile-name {
  color: #376caf;
  font-size: 1.25rem;
  font-weight: 500
}

.post-comment .commenter.profile .profile-company,
.post-comment .commenter.profile .profile-job {
  font-weight: 300;
  font-size: .875rem;
  line-height: 1.125rem
}

.post-comment .commenter.profile .profile-job {
  color: #444
}

.comment-footer .meta .flag,
.post-comment .commenter.profile .profile-company {
  color: #999
}

@media (max-width:767px),
(min-width:768px) and (max-width:991px) {
  .post-comment .commenter.profile .avatar-status-widget {
    float: left;
    width: 4.6875rem;
    height: 4.6875rem;
    margin-right: .625rem
  }
}

.comment-footer .meta {
  float: right
}

.comment-footer .meta .comment-time {
  color: #999;
  font-size: .8125rem;
  font-weight: 300
}

.comment-footer .meta .flag .fa {
  font-size: 1.125rem
}

@media (max-width:767px) {
  .comment-footer {
    position: relative;
    margin-top: .625rem
  }
  .comment-footer .meta {
    float: left
  }
  .comment-footer .interact-icons {
    margin-left: 35%
  }
}

@media (max-width:767px) and (max-width:479px) {
  .comment-footer .interact-icons .who-likes {
    max-width: 100%;
    display: block
  }
}

.post-comment {
  position: relative;
  padding-bottom: .375rem;
  margin-bottom: 1.25rem;
  height: auto
}

@media (min-width:992px) {
  .post-comment .commenter.profile {
    float: left;
    height: 100%
  }
  .post-comment .comment-content {
    position: relative;
    float: left;
    max-width: 65%;
    height: auto
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .post-comment .commenter.profile {
    width: 18%
  }
  .post-comment .comment-content {
    max-width: 82%
  }
  .post-comment .comment-footer {
    margin-left: 18%
  }
}

@media (min-width:1200px) {
  .post-comment .commenter.profile {
    width: 15%
  }
  .post-comment .comment-content {
    max-width: 85%
  }
  .post-comment .comment-footer {
    margin-left: 15%
  }
}

@media (min-width:992px) {
  .column-two-thirds .post-comment .commenter.profile {
    width: 28%
  }
  .column-two-thirds .post-comment .comment-content {
    max-width: 72%
  }
  .column-two-thirds .post-comment .comment-footer {
    margin-left: 28%
  }
}

@media (min-width:1200px) {
  .column-two-thirds .post-comment .commenter.profile {
    width: 23%
  }
  .column-two-thirds .post-comment .comment-content {
    max-width: 77%
  }
  .column-two-thirds .post-comment .comment-footer {
    margin-left: 23%
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .post-comment .commenter.profile {
    max-width: 100%;
    width: 100%;
    min-height: 4.0625rem;
    padding-right: 0
  }
  .post-comment .commenter.profile img.avatar {
    float: left;
    margin-right: 1.25rem
  }
  .post-comment .commenter.profile .profile-job {
    font-size: .75rem;
    line-height: 1.125rem
  }
  .post-comment .comment-content {
    clear: both;
    max-width: 100%;
    width: 100%;
    margin-top: .9375rem
  }
  .post-comment .comment-footer {
    position: relative
  }
  .post-comment .comment-footer .meta {
    display: block;
    width: 100%
  }
  .post-comment .comment-footer .meta .flag {
    position: absolute;
    right: 0;
    bottom: 0
  }
  .post-comment .comment-footer .interact-icons {
    clear: both;
    width: 100%;
    margin-left: 0
  }
  .post-comment .comment-footer .interact-icons a {
    display: inline
  }
  .post-comment .comment-footer .interact-icons .who-likes {
    display: inline;
    width: calc(100% - 40px)
  }
}

.blogs-landing .hero-banner .container,
.post-preview {
  position: relative
}

.post-preview {
  margin-bottom: 1rem
}

.post-preview .post-content,
.post-preview .post-header {
  font-size: .8125rem
}

.post-preview .post-content .post-headline,
.post-preview .post-header .post-headline {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500
}

.post-preview .post-content .post-headline.mobile,
.post-preview .post-header .post-headline.mobile {
  display: none
}

.post-preview .post-content .post-headline.desktop,
.post-preview .post-header .post-headline.desktop {
  display: inherit
}

@media (max-width:479px) {
  .post-preview .post-content .post-headline,
  .post-preview .post-header .post-headline {
    clear: both
  }
  .post-preview .post-content .post-headline.mobile,
  .post-preview .post-header .post-headline.mobile {
    display: inherit
  }
  .post-preview .post-content .post-headline.desktop,
  .post-preview .post-header .post-headline.desktop {
    display: none
  }
}

.post-preview .post-content .by-line,
.post-preview .post-header .by-line {
  width: 100%;
  color: #444;
  font-weight: 300
}

.post-preview .post-content .by-line a,
.post-preview .post-header .by-line a {
  font-weight: 300
}

.post-preview .post-content .by-line .post-time,
.post-preview .post-header .by-line .post-time {
  float: right;
  color: #999;
  font-weight: 300
}

.post-preview .post-content .by-line.desktop,
.post-preview .post-header .by-line.desktop {
  display: inherit
}

.post-preview .post-content .by-line.mobile,
.post-preview .post-header .by-line.mobile {
  display: none
}

@media (min-width:768px) and (max-width:991px) {
  .post-preview .post-content .by-line.desktop,
  .post-preview .post-header .by-line.desktop {
    display: none
  }
  .post-preview .post-content .by-line.mobile,
  .post-preview .post-header .by-line.mobile {
    display: block
  }
}

.post-preview .post-content .sponsored,
.post-preview .post-header .sponsored {
  color: #444;
  font-weight: 200
}

.post-preview .post-content {
  padding-top: .3125rem;
  font-size: .9375rem
}

.post-preview .post-content .post-featured-image {
  display: inline-block;
  width: 50%;
  max-width: 200px;
  float: left;
  margin-right: 15px;
  margin-top: 5px;
  border: 1px solid #e6e6e6;
  min-height: 4.6875rem
}

.post-preview .post-content .post-article-clickable {
  font-weight: 400;
  color: #222
}

.post-preview .post-content .native-ad-img {
  display: inline-block;
  width: 50%;
  max-width: 200px;
  float: right;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 10px
}

.post-preview .post-content .native-ad-img img {
  border: 1px solid #e6e6e6
}

@media (max-width:479px) {
  .post-preview .post-content .post-featured-image {
    max-width: none;
    width: 100%;
    margin-bottom: .9375rem
  }
}

.post-preview .post-content .read-more {
  font-size: .625rem
}

.post-preview .post-content .comment {
  margin: .3125rem 0;
  background-color: #e9eff7;
  padding: .3125rem .625rem;
  font-size: .75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.post-preview .post-img {
  float: left;
  width: 12.8125rem;
  height: auto;
  border: 1px solid #999;
  max-width: 38%;
  margin-top: .3125rem
}

.post-preview .img-float {
  float: left;
  max-width: 60%;
  margin-left: 2%
}

.post-preview .img-float .post-header .post-headline {
  margin: 0
}

.post-preview .img-float .post-content {
  padding-top: 0;
  font-size: .9375rem;
  line-height: 18px
}

.post-preview .img-float .post-content .read-more {
  display: block
}

.post-preview.sponsored .img-float {
  margin-left: 0;
  margin-right: 2%
}

.post-preview.sponsored .img-float .sponsored {
  color: #636468;
  font-size: .6875rem;
  line-height: 1.125rem
}

.post-preview.sponsored .img-float .post-bottom {
  padding: .3125rem
}

.blogs-landing.content {
  margin-top: 0
}

.blogs-landing .hero-banner {
  background-color: rgba(0, 0, 0, .35);
  background-repeat: no-repeat;
  background-position: center top
}

@media (max-width:767px) {
  .blogs-landing .hero-banner {
    background-image: url(../images/category-banners/blogs-home_sm.jpg)
  }
  .blogs-landing .hero-banner .container {
    height: 16.1875rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .blogs-landing .hero-banner {
    background-image: url(../images/category-banners/blogs-home_md.jpg)
  }
  .blogs-landing .hero-banner .container {
    height: 20.9375rem
  }
}

@media (min-width:992px) {
  .blogs-landing .hero-banner {
    background-image: url(../images/category-banners/blogs-home_lg.jpg)
  }
  .blogs-landing .hero-banner .container {
    height: 25.3125rem
  }
}

@media (min-width:1200px) {
  .blogs-landing .hero-banner {
    background-image: url(../images/category-banners/blogs-home_xl.jpg);
    background-repeat: no-repeat;
    background-position: center top
  }
}

@media (min-width:1920px) {
  .blogs-landing .hero-banner {
    background: url(../images/category-banners/blogs-home_xl.jpg), #8e8e8e;
    background: url(../images/category-banners/blogs-home_xl.jpg), linear-gradient(to right, #8e8e8e 49%, #8c9099 51%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8e8e8e', endColorstr='#8c9099', GradientType=1);
    background-repeat: no-repeat;
    background-position: center top
  }
}

.blogs-landing .hero-banner .container h2 {
  color: #fff;
  font-weight: 300
}

.blogs-landing .hero-banner .container .search-blogs {
  position: absolute;
  color: #fff;
  left: 0;
  bottom: 50px
}

.blogs-landing .hero-banner .container .search-blogs .input-group {
  max-width: 29.8125rem
}

.blogs-landing .hero-banner .container .search-blogs .input-group button {
  margin-bottom: 0
}

.blogs-landing .hero-banner .container .write-post {
  position: absolute;
  right: 0;
  bottom: 45px
}

@media (min-width:992px) {
  .blogs-landing .hero-banner .container h2 {
    margin: 5.3125rem 0 6.25rem;
    line-height: 3.125rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .blogs-landing .hero-banner .container h2 {
    margin: 5rem 0;
    line-height: 2.1875rem
  }
}

@media (max-width:767px) {
  .blogs-landing .hero-banner .container h2 {
    margin: 2.8125rem 0 1.875rem;
    line-height: 1.6875rem
  }
  .blogs-landing .hero-banner .container .search-blogs {
    position: static;
    float: left;
    clear: left;
    max-width: 50%;
    text-align: center
  }
  .blogs-landing .hero-banner .container .write-post {
    position: relative;
    float: right;
    margin-top: 3.125rem;
    padding: .3125rem .625rem;
    font-size: .75rem
  }
}

.comment-feed,
.forums-landing .hero-banner .container {
  position: relative
}

@media (max-width:479px) {
  .blogs-landing .hero-banner .container h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1.875rem .625rem 1.25rem 0
  }
  .blogs-landing .hero-banner .container h2 br {
    display: none
  }
  .blogs-landing .hero-banner .container .search-blogs {
    max-width: 80%;
    text-align: left;
    margin-bottom: 10px
  }
  .blogs-landing .hero-banner .container .search-blogs .input-group {
    margin-top: .3125rem;
    width: 100%
  }
  .blogs-landing .hero-banner .container .write-post {
    float: none
  }
}

.comment-feed {
  border-bottom: solid 1px #e6e6e6;
  padding-bottom: .375rem;
  margin-bottom: .625rem
}

.comment-feed .commenter.profile {
  float: left;
  position: relative;
  width: 3.125rem
}

.comment-feed .commenter.profile img.avatar {
  display: block;
  margin: 0 0 .1875rem
}

.comment-feed .post-headline {
  display: block;
  font-size: 1.125rem;
  line-height: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.comment-feed .comment-content {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 300;
  margin-bottom: .3125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.comment-feed .comment-content a {
  font-weight: 400
}

.comment-feed .post-img {
  float: right;
  margin: .3125rem 0 0 .3125rem
}

.comment-feed .comment-footer {
  clear: both;
  padding-left: 3.125rem
}

.comment-feed .comment-footer .interact-icons {
  margin-left: 0
}

.featured-post .post-preview {
  border-bottom: none
}

.featured-post .post-preview .post-headline {
  font-size: 1.125rem;
  font-weight: 300
}

.featured-post .about-author {
  background-color: #e9eff7;
  border: 1px solid #e6e6e6;
  padding: .9375rem;
  font-weight: 300
}

.featured-post .about-author .author-block .by-line {
  font-size: .875rem
}

.featured-post .about-author .author-block .author-bio {
  line-height: 1rem;
  font-size: .75rem
}

@media (min-width:992px) {
  .blogs-landing .featured-post .post-preview,
  .department-feed .featured-post .post-preview,
  .ds-home .featured-post .post-preview {
    float: left;
    max-width: 66%
  }
  .blogs-landing .featured-post .post-preview .featuredimg,
  .department-feed .featured-post .post-preview .featuredimg,
  .ds-home .featured-post .post-preview .featuredimg {
    max-width: 57%;
    float: left;
    margin: .4375rem .9375rem .3125rem 0;
    border: 1px solid #e6e6e6
  }
  .blogs-landing .featured-post .about-author,
  .department-feed .featured-post .about-author,
  .ds-home .featured-post .about-author {
    float: right;
    max-width: 30%;
    height: 100%;
    display: table
  }
  .blogs-landing .featured-post .about-author .author-avatar,
  .department-feed .featured-post .about-author .author-avatar,
  .ds-home .featured-post .about-author .author-avatar {
    text-align: center;
    margin: 0 auto 1.25rem
  }
  .blogs-landing .featured-post .about-author .author-avatar .avatar,
  .department-feed .featured-post .about-author .author-avatar .avatar,
  .ds-home .featured-post .about-author .author-avatar .avatar {
    margin: 0 auto
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .blogs-landing .featured-post .post-content .featuredimg,
  .department-feed .featured-post .post-content .featuredimg,
  .ds-home .featured-post .post-content .featuredimg {
    float: left;
    max-height: 6.875rem;
    margin: .4375rem .9375rem 0 0;
    border: 1px solid #e6e6e6
  }
  .blogs-landing .featured-post .about-author,
  .department-feed .featured-post .about-author,
  .ds-home .featured-post .about-author {
    min-height: 7.8125rem;
    display: table;
    width: 100%
  }
  .blogs-landing .featured-post .about-author .author-avatar,
  .department-feed .featured-post .about-author .author-avatar,
  .ds-home .featured-post .about-author .author-avatar {
    display: table-cell;
    width: 40%
  }
  .blogs-landing .featured-post .about-author .author-block,
  .department-feed .featured-post .about-author .author-block,
  .ds-home .featured-post .about-author .author-block {
    display: table-cell;
    vertical-align: middle;
    max-width: 60%
  }
}

.blogs-landing .featured-post+.exclusive-blogs,
.department-feed .featured-post+.exclusive-blogs,
.ds-home .featured-post+.exclusive-blogs {
  clear: both;
  padding-top: 1px
}

.ds-feed .featured-post .post-content .featuredimg {
  float: left;
  max-height: 6.875rem;
  margin: .4375rem .9375rem 0 0
}

.ds-feed .featured-post .about-author {
  min-height: 7.8125rem;
  display: table;
  width: 100%
}

.ds-feed .featured-post .about-author .author-avatar {
  display: table-cell;
  width: 40%
}

.ds-feed .featured-post .about-author .author-block {
  display: table-cell;
  vertical-align: middle;
  max-width: 60%
}

.forums-landing.content {
  margin-top: 0
}

.forums-landing .hero-banner {
  background-color: rgba(0, 0, 0, .35);
  background-repeat: no-repeat;
  background-position: center top
}

@media (max-width:767px) {
  .forums-landing .hero-banner {
    background-image: url(../images/category-banners/forums-home_sm.jpg)
  }
  .forums-landing .hero-banner .container {
    height: 16.1875rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .forums-landing .hero-banner {
    background-image: url(../images/category-banners/forums-home_md.jpg)
  }
  .forums-landing .hero-banner .container {
    height: 20.9375rem
  }
}

@media (min-width:992px) {
  .forums-landing .hero-banner {
    background-image: url(../images/category-banners/forums-home_lg.jpg)
  }
  .forums-landing .hero-banner .container {
    height: 25.3125rem
  }
}

@media (min-width:1200px) {
  .forums-landing .hero-banner {
    background-image: url(../images/category-banners/forums-home_xl.jpg);
    background-repeat: no-repeat;
    background-position: center top
  }
}

@media (min-width:1920px) {
  .forums-landing .hero-banner {
    background: url(../images/category-banners/forums-home_xl.jpg), #b4b4b4;
    background: url(../images/category-banners/forums-home_xl.jpg), linear-gradient(to right, #b4b4b4 49%, #9d9ea0 51%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4b4b4', endColorstr='#9d9ea0', GradientType=1);
    background-repeat: no-repeat;
    background-position: center top
  }
}

.forums-landing .hero-banner .container h2 {
  color: #fff;
  font-weight: 300
}

.forums-landing .hero-banner .container .search-blogs {
  position: absolute;
  color: #fff;
  left: 0;
  bottom: 50px
}

.forums-landing .hero-banner .container .search-blogs .input-group {
  max-width: 29.8125rem
}

.forums-landing .hero-banner .container .search-blogs .input-group button {
  margin-bottom: 0
}

.forums-landing .hero-banner .container .write-post {
  position: absolute;
  right: 0;
  bottom: 45px
}

@media (min-width:992px) {
  .forums-landing .hero-banner .container h2 {
    margin: 5.3125rem 0 6.25rem;
    line-height: 3.125rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .forums-landing .hero-banner .container h2 {
    margin: 5rem 0;
    line-height: 2.1875rem
  }
  .forums-landing .hero-banner .container .search-blogs {
    max-width: 50%
  }
}

@media (max-width:767px) {
  .forums-landing .hero-banner .container h2 {
    margin: 2.8125rem 0 1.875rem;
    line-height: 1.6875rem
  }
  .forums-landing .hero-banner .container .search-blogs {
    position: static;
    float: left;
    clear: left;
    max-width: 50%;
    text-align: center
  }
  .forums-landing .hero-banner .container .write-post {
    position: relative;
    float: right;
    margin-top: 3.125rem;
    padding: .3125rem .625rem;
    font-size: .75rem
  }
}

@media (max-width:479px) {
  .forums-landing .hero-banner .container h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1.875rem .625rem 1.25rem 0
  }
  .forums-landing .hero-banner .container h2 br {
    display: none
  }
  .forums-landing .hero-banner .container .search-blogs {
    max-width: 80%;
    text-align: left;
    margin-bottom: 10px
  }
  .forums-landing .hero-banner .container .search-blogs .input-group {
    margin-top: .3125rem;
    width: 100%
  }
  .forums-landing .hero-banner .container .write-post {
    float: none
  }
}

.forums-landing .forum-boards .forum-table.forum-list {
  display: table;
  border: 0;
  border-bottom: solid 1px #89a9d0
}

.forums-landing .forum-boards .forum-table.forum-list .table-header {
  background-color: #376caf;
  color: #fff;
  font-size: 1rem
}

.forums-landing .forum-boards .forum-table.forum-list .row {
  display: table-row
}

.forums-landing .forum-boards .forum-table.forum-list .row .cell {
  padding: .75rem .9375rem;
  vertical-align: middle
}

.forums-landing .forum-boards .forum-table.forum-list .row .cell.board-label {
  width: 60%;
  text-align: left
}

.forums-landing .forum-boards .forum-table.forum-list .row .cell.count {
  width: 20%;
  text-align: center;
  border-left: solid 1px #89a9d0
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .forums-landing .forum-boards .forum-table.forum-list .row .cell {
    font-size: .875rem;
    padding: .5rem .625rem
  }
}

.forums-landing .forum-boards .forum-table.forum-list .row .board-title {
  font-size: 1.125rem;
  font-weight: 300
}

.forums-landing .forum-boards .forum-table.forum-list .row .meta-icons {
  font-size: .875rem;
  font-weight: 500
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .forums-landing .forum-boards .forum-table.forum-list .row .board-title {
    float: left;
    font-size: .875rem
  }
  .forums-landing .forum-boards .forum-table.forum-list .row .meta-icons,
  .forums-landing .forum-boards .forum-table.forum-list .row .meta-icons a {
    font-weight: 400
  }
  .forums-landing .forum-boards .forum-table.forum-list .row .meta-icons .comments,
  .forums-landing .forum-boards .forum-table.forum-list .row .meta-icons .views {
    display: block
  }
}

@media (max-width:767px) {
  .forums-landing .forum-boards .forum-table.forum-list .table-striped {
    width: 100%;
    border: none
  }
  .forums-landing .forum-boards .forum-table.forum-list .table-striped:last-child {
    border-bottom: solid 1px #89a9d0;
    border-top: none
  }
  .forums-landing .forum-boards .forum-table.forum-list .table-striped:last-child .row:nth-of-type(odd) {
    background: #f1f4f8
  }
  .forums-landing .forum-boards .forum-table.forum-list .table-striped:last-child .row:nth-of-type(even) {
    background: #fff
  }
}

.forums-landing .load-more {
  margin-top: .625rem
}

.forum-board.cover-photo,
.forum-topic.cover-photo {
  position: relative;
  height: 12.5rem;
  background-repeat: no-repeat;
  background-position: center top;
  color: #fff;
  margin-bottom: 1.25rem
}

@media (max-width:767px) {
  .forum-board.cover-photo,
  .forum-topic.cover-photo {
    margin: 0 -15px 20px
  }
}

.forum-board.cover-photo .hero-content,
.forum-topic.cover-photo .hero-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .625rem .9375rem 0
}

.forum-board.cover-photo .hero-content .hero-description,
.forum-topic.cover-photo .hero-content .hero-description {
  display: block;
  max-width: 100%;
  margin: .3125rem 0 .625rem
}

@media (min-width:768px) {
  .forum-board.cover-photo .hero-content,
  .forum-topic.cover-photo .hero-content {
    padding: .9375rem 1.875rem
  }
  .forum-board.cover-photo .hero-content .hero-description,
  .forum-topic.cover-photo .hero-content .hero-description {
    display: inline-block;
    max-width: 55%
  }
  .forum-board.cover-photo .hero-content .btn,
  .forum-board.cover-photo .hero-content .reveal-modal .close-reveal-modal.btn-close,
  .forum-topic.cover-photo .hero-content .btn,
  .forum-topic.cover-photo .hero-content .reveal-modal .close-reveal-modal.btn-close,
  .reveal-modal .forum-board.cover-photo .hero-content .close-reveal-modal.btn-close,
  .reveal-modal .forum-topic.cover-photo .hero-content .close-reveal-modal.btn-close {
    float: right
  }
}

@media (max-width:479px),
(min-width:768px) and (max-width:991px) {
  .forum-board.cover-photo .hero-content .btn,
  .forum-board.cover-photo .hero-content .reveal-modal .close-reveal-modal.btn-close,
  .forum-topic.cover-photo .hero-content .btn,
  .forum-topic.cover-photo .hero-content .reveal-modal .close-reveal-modal.btn-close,
  .reveal-modal .forum-board.cover-photo .hero-content .close-reveal-modal.btn-close,
  .reveal-modal .forum-topic.cover-photo .hero-content .close-reveal-modal.btn-close {
    font-size: .875rem;
    line-height: 24px;
    padding: .375rem .9375rem
  }
}

.cview-container .forum-table,
.forum-table {
  width: 100%;
  color: #7e7877
}

.cview-container .forum-table:last-child,
.forum-table:last-child {
  border-bottom: solid 1px #89a9d0
}

.cview-container .forum-table .forum-topic,
.forum-table .forum-topic {
  font-size: .875rem
}

.cview-container .forum-table .forum-topic .forum-topic-title,
.forum-table .forum-topic .forum-topic-title {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.cview-container .forum-table .forum-topic .author.avatar,
.forum-table .forum-topic .author.avatar {
  float: left;
  margin-right: .625rem
}

.cview-container .forum-table .topic-interaction ul,
.forum-table .topic-interaction ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: .875rem
}

.cview-container .forum-table .topic-interaction a,
.forum-table .topic-interaction a {
  color: #999
}

.cview-container .forum-table .last-reply,
.forum-table .last-reply {
  font-size: .875rem
}

.cview-container .forum-table .last-reply .post-date,
.forum-table .last-reply .post-date {
  font-size: .75rem
}

.cview-container .forum-table .forum-table.sponsored,
.forum-table .forum-table.sponsored {
  color: #636468;
  font-size: .6875rem
}

.cview-container .forum-table .forum-table.sponsored .row,
.forum-table .forum-table.sponsored .row {
  padding-top: .25rem
}

.cview-container .forum-table .forum-table.sponsored .row .cell,
.forum-table .forum-table.sponsored .row .cell {
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem
}

@media (max-width:991px) {
  .cview-container .forum-table .forum-table.sponsored .row .cell,
  .forum-table .forum-table.sponsored .row .cell {
    font-size: .8125rem;
    line-height: 1.125rem
  }
}

.cview-container .forum-table .forum-table.sponsored .row .cell .forum-topic-title,
.forum-table .forum-table.sponsored .row .cell .forum-topic-title {
  font-size: .9375rem;
  font-weight: 500
}

@media (max-width:991px) {
  .cview-container .forum-table .forum-table.sponsored .row .cell .forum-topic-title,
  .forum-table .forum-table.sponsored .row .cell .forum-topic-title {
    font-size: .875rem;
    font-weight: 400
  }
}

.cview-container .forum-table .forum-table.sponsored .row .cell .sponsored,
.forum-table .forum-table.sponsored .row .cell .sponsored {
  font-size: .6875rem;
  color: #999
}

@media (max-width:991px) {
  .cview-container .forum-table .forum-table.sponsored .row .cell .sponsored,
  .forum-table .forum-table.sponsored .row .cell .sponsored {
    font-size: .625rem
  }
}

.cview-container .forum-table .forum-table.sponsored .row .cell .last-reply,
.forum-table .forum-table.sponsored .row .cell .last-reply {
  font-weight: 300
}

.cview-container .forum-table .forum-table.sponsored .row .cell .last-reply a,
.forum-table .forum-table.sponsored .row .cell .last-reply a {
  font-size: .9375rem;
  font-weight: 500
}

@media (max-width:991px) {
  .cview-container .forum-table .forum-table.sponsored .row .cell .last-reply a,
  .forum-table .forum-table.sponsored .row .cell .last-reply a {
    font-size: .875rem;
    font-weight: 400
  }
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .post-header,
.forum-table .forum-table.sponsored .row .cell.forum-topic .post-header {
  margin: 0
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .post-header .post-headline,
.forum-table .forum-table.sponsored .row .cell.forum-topic .post-header .post-headline {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .post-content,
.forum-table .forum-table.sponsored .row .cell.forum-topic .post-content {
  padding: 0
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .post-content p,
.forum-table .forum-table.sponsored .row .cell.forum-topic .post-content p {
  margin: 0;
  font-size: .875rem;
  line-height: 1.125rem
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .post-content p a,
.forum-table .forum-table.sponsored .row .cell.forum-topic .post-content p a {
  font-size: .75rem
}

.cview-container .forum-table .forum-table.sponsored .row .cell.forum-topic .native-ad-img,
.forum-table .forum-table.sponsored .row .cell.forum-topic .native-ad-img {
  display: none
}

@media (max-width:479px) {
  .cview-container .forum-table .forum-topic,
  .forum-table .forum-topic {
    font-size: .75rem
  }
  .cview-container .forum-table .forum-topic .forum-topic-title,
  .forum-table .forum-topic .forum-topic-title {
    font-size: .875rem
  }
  .cview-container .forum-table .forum-topic .post-date,
  .forum-table .forum-topic .post-date {
    display: none
  }
  .cview-container .forum-table .last-reply,
  .cview-container .forum-table .topic-interaction ul,
  .forum-table .last-reply,
  .forum-table .topic-interaction ul {
    font-size: .75rem
  }
  .cview-container .forum-table .last-reply .post-date,
  .forum-table .last-reply .post-date {
    font-size: .625rem
  }
}

@media (min-width:992px) {
  .cview-container .forum-table.long .cell,
  .forum-table.long .cell {
    vertical-align: middle
  }
  .cview-container .forum-table.long .forum-topic,
  .forum-table.long .forum-topic {
    width: 53%
  }
  .cview-container .forum-table.long .last-reply,
  .forum-table.long .last-reply {
    width: 32%;
    text-align: right
  }
  .cview-container .forum-table.long .last-reply .mobile-dot,
  .forum-table.long .last-reply .mobile-dot {
    display: none
  }
  .cview-container .forum-table.long .topic-interaction,
  .forum-table.long .topic-interaction {
    width: 14%;
    border-right: solid 1px #89a9d0;
    border-left: solid 1px #89a9d0;
    padding: .75rem .9375rem
  }
}

@media (min-width:992px) and (min-width:992px) and (max-width:1199px) {
  .cview-container .forum-table.long .cell,
  .forum-table.long .cell {
    padding: .625rem
  }
  .cview-container .forum-table.long .forum-topic-title,
  .forum-table.long .forum-topic-title {
    max-width: 250px
  }
}

@media (min-width:992px) and (min-width:1200px) {
  .cview-container .forum-table.long .cell,
  .forum-table.long .cell {
    padding: .75rem 1.25rem
  }
  .cview-container .forum-table.long .forum-topic-title,
  .forum-table.long .forum-topic-title {
    max-width: 300px
  }
}

@media (max-width:767px),
(min-width:768px) and (max-width:991px) {
  .cview-container .forum-table.long,
  .forum-table.long {
    width: 100%
  }
  .cview-container .forum-table.long .cell,
  .cview-container .forum-table.long .row,
  .cview-container .forum-table.long.table,
  .cview-container .forum-table.long.table .row .cell,
  .forum-table.long .cell,
  .forum-table.long .row,
  .forum-table.long.table,
  .forum-table.long.table .row .cell {
    display: block
  }
  .cview-container .forum-table.long .row,
  .forum-table.long .row {
    padding: .75rem .625rem
  }
  .cview-container .forum-table.long .forum-topic,
  .forum-table.long .forum-topic {
    float: left;
    width: 80%
  }
  .cview-container .forum-table.long .topic-interaction,
  .forum-table.long .topic-interaction {
    float: right;
    width: 20%
  }
  .cview-container .forum-table.long .last-reply,
  .forum-table.long .last-reply {
    clear: both;
    text-align: left;
    border-top: solid 1px #CCC;
    padding-top: .3125rem;
    margin-top: .3125rem
  }
  .cview-container .forum-table.long .last-reply .line-break,
  .forum-table.long .last-reply .line-break {
    display: none
  }
  .cview-container .forum-table.long .last-reply .mobile-dot,
  .forum-table.long .last-reply .mobile-dot {
    display: inline
  }
}

.cview-container .forum-table.long+.pagination-centered,
.forum-table.long+.pagination-centered {
  margin-top: 2.5rem
}

.cview-container .forum-table.short,
.forum-table.short {
  width: 100%
}

.cview-container .forum-table.short .cell,
.cview-container .forum-table.short .row,
.cview-container .forum-table.short.table,
.cview-container .forum-table.short.table .row .cell,
.forum-table.short .cell,
.forum-table.short .row,
.forum-table.short.table,
.forum-table.short.table .row .cell {
  display: block
}

.cview-container .forum-table.short .row,
.forum-table.short .row {
  padding: .75rem .625rem
}

.cview-container .forum-table.short .forum-topic,
.forum-table.short .forum-topic {
  float: left;
  width: 75%;
  padding-bottom: .3125rem;
  margin-bottom: .3125rem
}

.cview-container .forum-table.short .topic-interaction,
.forum-table.short .topic-interaction {
  float: left;
  width: 25%
}

.cview-container .forum-table.short .topic-interaction .inline-list,
.forum-table.short .topic-interaction .inline-list {
  float: right
}

.cview-container .forum-table.short .last-reply,
.forum-table.short .last-reply {
  float: none;
  clear: both;
  max-width: 100%;
  margin-left: 3.125rem;
  border-top: solid 1px #CCC
}

.cview-container .forum-table.short .last-reply br,
.forum-table.short .last-reply br {
  display: none
}

.cview-container .forum-table.short.mini,
.forum-table.short.mini {
  border: none
}

.cview-container .forum-table.short.mini .forum-topic,
.forum-table.short.mini .forum-topic {
  padding-bottom: 0;
  margin-bottom: 0
}

.cview-container .forum-table.short.mini .last-reply,
.forum-table.short.mini .last-reply {
  border-top: none;
  clear: none
}

.forum-topic .forum-post .post-headline {
  text-align: left;
  color: #376caf;
  font-size: 1.5rem
}

.forum-topic .forum-post .row.post-comment {
  border-top: solid 1px #CCC
}

@media (min-width:768px) {
  .forum-topic .forum-post .post-headline {
    font-size: 2rem
  }
  .forum-topic .forum-post .row.post-comment {
    padding-top: 1.5rem
  }
}

.forum-topic .forum-post .row.post-comment .date {
  display: block;
  text-align: right;
  margin: 1.375rem 0 1rem;
  font-size: .875rem;
  color: #999
}

.forum-topic .replies.comment-section h3 .pager,
.searchResults .expand-search .expand-more {
  display: inline
}

.forum-topic .forum-post .row.post-comment .commenter.profile .profile-name {
  color: #376caf;
  font-size: 1.25rem;
  font-weight: 500;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto
}

.forum-topic .forum-post .row.post-comment .commenter.profile .profile-company,
.forum-topic .forum-post .row.post-comment .commenter.profile .profile-job {
  font-weight: 300;
  font-size: .875rem;
  line-height: 1.125rem
}

.forum-topic .forum-post .row.post-comment .commenter.profile .profile-job {
  color: #444
}

.forum-topic .forum-post .row.post-comment .commenter.profile .profile-company {
  color: #999
}

@media (max-width:767px),
(min-width:768px) and (max-width:991px) {
  .forum-topic .forum-post .row.post-comment .commenter.profile .avatar-status-widget {
    float: left;
    width: 4.6875rem;
    height: 4.6875rem;
    margin-right: .625rem
  }
}

.forum-topic .forum-post .row.post-comment .comment-content {
  margin-top: .3125rem
}

.forum-topic .replies.comment-section {
  border-top: solid 1px #e6e6e6
}

.forum-topic .replies.comment-section h3 .pager ul.pagination {
  float: right
}

.forum-topic .commentSection .pagination-centered {
  margin-top: 1.875rem
}

.searchResults h3 .results-number {
  font-weight: 300;
  font-size: 1.25rem
}

.searchResults h3 .basic-search {
  float: right;
  max-width: 50%;
  margin-top: .3125rem
}

@media (max-width:767px) {
  .forum-topic .forum-post .row.post-comment,
  .forum-topic .forum-post .row.post-comment .user-info {
    padding-top: .3125rem
  }
  .searchResults h3 .results-number {
    font-size: smaller
  }
  .searchResults h3 .basic-search {
    float: none;
    clear: both;
    max-width: 100%
  }
  .reveal-modal .searchResults .advanced-search .adv-search.close-reveal-modal.btn-close,
  .searchResults .advanced-search .btn.adv-search,
  .searchResults .advanced-search .reveal-modal .adv-search.close-reveal-modal.btn-close {
    width: 100%
  }
}

.searchResults h3 .basic-search button {
  margin-bottom: 0
}

.searchResults h3 .basic-search input {
  border: 1px solid #acacac;
  border-right: transparent;
  border-collapse: collapse;
  box-shadow: none;
  height: 2.25rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.searchResults .refine-search h3 {
  margin-top: 0
}

.searchResults .expand-search {
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1
}

.searchResults .expand-search:before {
  border-top: 1px solid #e6e6e6;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.searchResults .expand-search .expand-less,
.searchResults .expand-search .expand-more {
  cursor: pointer;
  font-size: .75rem;
  background-color: #fff;
  color: #636468
}

.btn-blue-login.disabled,
.btn-green-login.disabled,
.btn-green.disabled,
.btn-orange.disabled,
.btn-red.disabled,
.btn-yellow.disabled {
  cursor: not-allowed
}

.searchResults .expand-search .expand-less .blue,
.searchResults .expand-search .expand-more .blue {
  color: #376caf
}

.searchResults .expand-search .expand-less,
.searchResults .expand-search+.advanced-search,
.searchResults .expand-search.advanced .expand-more {
  display: none
}

.searchResults .expand-search.advanced .expand-less {
  display: inline
}

.searchResults .expand-search.advanced+.advanced-search {
  display: inherit
}

.searchResults .advanced-search {
  width: 100%;
  min-height: 150px
}

.reveal-modal .searchResults .advanced-search .adv-search.close-reveal-modal.btn-close,
.searchResults .advanced-search .btn.adv-search,
.searchResults .advanced-search .reveal-modal .adv-search.close-reveal-modal.btn-close {
  margin: 1.25rem 0
}

@media (min-width:768px) {
  .reveal-modal .searchResults .advanced-search .adv-search.close-reveal-modal.btn-close,
  .searchResults .advanced-search .btn.adv-search,
  .searchResults .advanced-search .reveal-modal .adv-search.close-reveal-modal.btn-close {
    float: right
  }
  .searchResults .advanced-search input {
    max-width: 47%;
    float: left
  }
  .searchResults .advanced-search input:nth-child(odd) {
    margin-right: 6%
  }
}

@media (min-width:768px) and (min-width:992px) {
  .searchResults .advanced-search input {
    margin: 1.25rem 0
  }
}

.searchResults .search-results .total-results {
  clear: both;
  color: #636468;
  font-weight: 300;
  font-size: 1.25rem;
  margin-top: 1.25rem
}

.searchResults .search-results .total-results+h3 {
  margin-top: .625rem
}

.searchResults .search-results .total-results strong {
  font-weight: 400
}

.searchResults .user-result {
  display: block;
  border-bottom: solid 1px #e6e6e6;
  padding: .625rem 0
}

.searchResults .user-result .user-info {
  display: inline-block;
  min-height: 93px
}

.searchResults .user-result .user-info .user-employment {
  font-weight: 300
}

.searchResults .user-result .user-info .user-social .followers,
.searchResults .user-result .user-info .user-social .friends {
  float: left;
  font-weight: 300
}

.searchResults .user-result .user-info .user-social .followers strong,
.searchResults .user-result .user-info .user-social .friends strong {
  font-weight: 400
}

.searchResults .user-result .user-info .user-social .friends {
  margin-right: 1.5rem
}

@media (min-width:768px) {
  .searchResults .user-result .user-avatar {
    float: left;
    margin-right: 1.875rem
  }
  .searchResults .user-result .user-info {
    max-width: calc(100% - 255px);
    padding: .625rem 0 0
  }
  .searchResults .user-result .user-connect {
    line-height: 93px;
    float: right
  }
}

@media (max-width:767px) {
  .searchResults .user-result .user-avatar {
    float: left;
    margin-right: 1.0625rem
  }
  .searchResults .user-result .user-info {
    max-width: calc(100% - 240px)
  }
  .searchResults .user-result .user-connect {
    float: right;
    line-height: 5.8125rem
  }
}

@media (max-width:767px) and (max-width:479px) {
  .searchResults .user-result .user-info {
    max-width: calc(100% - 140px)
  }
  .searchResults .user-result .user-connect {
    float: none;
    line-height: 26px;
    margin-left: 6.875rem
  }
}

.searchResults .content-result {
  display: block;
  clear: both;
  border-bottom: solid 1px #e6e6e6;
  padding: .625rem 0
}

.searchResults .content-result .content-icon {
  float: left
}

.departmentpage .cover-photo .hero-content .btns-connect,
.departmentpage h3.headline-border .btn,
.departmentpage h3.headline-border .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .departmentpage h3.headline-border .close-reveal-modal.btn-close {
  float: right
}

.searchResults .content-result .content-info {
  display: block;
  min-height: 4.0625rem;
  max-width: calc(100% - 100px);
  padding: .625rem 0 0;
  overflow: hidden
}

.searchResults .content-result .post-preview {
  display: inline-block;
  max-width: calc(100% - 125px);
  border-bottom: none
}

@media (min-width:768px) {
  .searchResults .content-result .content-icon {
    margin-right: 1.875rem
  }
  .hero-banner .hero-description {
    font-size: 1.25rem;
    line-height: 1.25rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .searchResults .column-two-thirds {
    width: 100%
  }
  .searchResults .hide-mobile {
    display: none
  }
}

.responsive-ad-container {
  position: relative;
  height: 0;
  overflow: hidden
}

.responsive-ad-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.side-column-ad {
  margin-top: 30px;
  text-align: center
}

.takeover_1145x94 {
  padding-bottom: 8.2096069869%
}

.house_150x300 {
  padding-bottom: 200%
}

.exclusive_300x250,
.ros_250x300 {
  padding-bottom: 83.3333333333%
}

.ros_100x300 {
  padding-bottom: 33.3333333333%
}

.takeover-bg {
  margin-top: 0;
  background-repeat: repeat-x;
  background-position: center top
}

@media (max-width:767px) {
  .searchResults .content-result .content-icon {
    margin-right: 1.0625rem
  }
  .searchResults .hide-mobile {
    display: none
  }
  .takeover-bg {
    background: #fff
  }
}

@media (min-width:1200px) {
  .takeover-bg .hero-content {
    padding: 2.25rem
  }
  .takeover-bg .main-content {
    padding: 0 2.25rem
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .takeover-bg .hero-content {
    padding: 1.4375rem
  }
  .takeover-bg .main-content {
    padding: 0 1.4375rem
  }
}

.takeover-bg .takeover-ad {
  padding: 1.25rem 0
}

.takeover-bg .takeover-ad+.container {
  background-color: #fff
}

.takeover-bg .takeover-ad+.container.department-page {
  padding-top: .9375rem
}

.takeover-bg .main-content,
.takeover-bg .profile-blog,
.takeover-bg .profile-container,
.takeover-bg .profile-nav {
  background-color: #fff;
  padding: 0 .9375rem
}

@media (min-width:992px) and (max-width:1199px) {
  .takeover-bg .main-content,
  .takeover-bg .profile-blog,
  .takeover-bg .profile-container,
  .takeover-bg .profile-nav {
    padding: 0 1.4375rem
  }
  .takeover-bg .main-content.home-nav,
  .takeover-bg .profile-blog.home-nav,
  .takeover-bg .profile-container.home-nav,
  .takeover-bg .profile-nav.home-nav {
    padding: 0
  }
}

@media (min-width:1200px) {
  .takeover-bg .main-content,
  .takeover-bg .profile-blog,
  .takeover-bg .profile-container,
  .takeover-bg .profile-nav {
    padding: 0 2.25rem
  }
  .takeover-bg .main-content.home-nav,
  .takeover-bg .profile-blog.home-nav,
  .takeover-bg .profile-container.home-nav,
  .takeover-bg .profile-nav.home-nav {
    padding: 0
  }
}

.takeover-bg .profile-blog {
  padding-top: .9375rem
}

@media (min-width:992px) and (max-width:1199px) {
  .takeover-bg .profile-blog {
    padding-top: 1.4375rem
  }
}

@media (min-width:1200px) {
  .takeover-bg .profile-blog {
    padding-top: 2.25rem
  }
}

@media (min-width:992px) {
  .takeover-bg .hero-banner.fluid-container {
    max-width: 60.625rem;
    padding: 0 .9375rem;
    margin: 0 auto
  }
  .takeover-bg .hero-banner.fluid-container .container {
    padding: 0
  }
  .takeover-bg .hero-banner.fluid-container .btn-lg.btn-blue {
    right: 1.875rem
  }
  .takeover-bg .home.hero-content .sidekick-ads {
    margin: 0 0 0 .375rem
  }
  .takeover-bg .home.hero-content .sidekick-ads img {
    border: 1px solid #e6e6e6
  }
}

@media (min-width:1200px) {
  .takeover-bg .hero-banner.fluid-container {
    max-width: 71.5625rem
  }
}

.forum-dep .takeover-bg .main-content {
  margin-left: -.9375rem
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .house_150x300,
  .takeover_1145x94 {
    display: none
  }
  .takeover-bg {
    background: 0 0!important
  }
  .takeover-bg .takeover-ad {
    display: none
  }
}

.panel {
  width: 18.75rem;
  height: 13.75rem;
  padding: 1.875rem 2.1875rem;
  background: #fff;
  border-radius: .3125rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36);
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36)
}

.departmentpage .cover-photo,
.hero-banner.solution {
  background-repeat: no-repeat;
  background-position: center top
}

.panel h5 {
  color: #376caf
}

.panel h5 i {
  color: #909090
}

.panel.has-img {
  padding: 0 0 1.875rem
}

.panel.has-img img {
  width: 18.75rem;
  height: 5.1875rem;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.panel.has-img h5,
.panel.has-img p {
  padding: 0 1.875rem
}

.clear {
  clear: both
}

.loginpage .content.loginModal {
  width: 100%;
  max-width: 31.25rem;
  margin: auto
}

.loginpage .content.loginModal .close-reveal-modal {
  display: none
}

.hero-banner {
  color: #fff;
  min-height: 13.3125rem
}

.hero-banner .hero-content,
.hero-banner .hero-description,
.hero-banner h2,
.hero-banner h4,
.hero-banner p {
  color: #fff
}

.hero-banner .hero-description {
  font-weight: 300
}

.hero-banner.solution {
  background-color: #8ca3c3
}

@media (min-width:768px) {
  .hero-banner.solution .btn-orange {
    float: right;
    margin-top: 2.1875rem
  }
}

.nomargintop {
  margin-top: 0
}

.nomarginbottom {
  margin-bottom: 0
}

.departmentpage .cover-photo {
  position: relative;
  height: 12.5rem;
  background-color: #1e365d;
  color: #fff;
  margin-bottom: 1.25rem
}

@media (max-width:767px) {
  .hero-banner .hero-description {
    font-size: 1rem;
    line-height: 1.125rem
  }
  .departmentpage .cover-photo {
    margin: 0 -15px 20px
  }
  ul.tabs.vertical {
    float: left
  }
}

.departmentpage .cover-photo .hero-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .625rem .9375rem 0
}

.departmentpage h3.headline-border .verified-reviews {
  display: inline;
  font-size: 14px;
  color: #376caf
}

@media (min-width:992px) and (max-width:1199px) {
  .departmentpage h3.headline-border .verified-reviews .verified {
    display: none
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .departmentpage h3.headline-border .mobile-icon-btn,
  .departmentpage h3.headline-border .verified-reviews .verified {
    display: none
  }
}

.departmentpage .rate-pod {
  display: block;
  width: 100%;
  margin: .3125rem auto 0;
  text-align: center
}

.dataTables_paginate.paging_simple_numbers ul.pagination li,
ul.pagination li {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0 0 -1px;
  text-align: center;
  background-color: #fff;
  color: #376caf;
  border: 1px solid #d5e0e9
}

.dataTables_paginate.paging_simple_numbers ul.pagination li a,
.dataTables_paginate.paging_simple_numbers ul.pagination li button,
ul.pagination li a,
ul.pagination li button {
  text-decoration: none;
  color: #376caf;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.25rem;
  border-radius: 0
}

.dataTables_paginate.paging_simple_numbers ul.pagination li a:hover,
.dataTables_paginate.paging_simple_numbers ul.pagination li button:hover,
ul.pagination li a:hover,
ul.pagination li button:hover {
  color: #5da5ff;
  background-color: #fff
}

.dataTables_paginate.paging_simple_numbers ul.pagination li.current a,
.dataTables_paginate.paging_simple_numbers ul.pagination li.current button,
ul.pagination li.current a,
ul.pagination li.current button {
  color: #000;
  font-weight: 500;
  background-color: #d5e0e9
}

.dataTables_paginate.paging_simple_numbers ul.pagination li.current a:focus,
.dataTables_paginate.paging_simple_numbers ul.pagination li.current a:hover,
.dataTables_paginate.paging_simple_numbers ul.pagination li.current button:focus,
.dataTables_paginate.paging_simple_numbers ul.pagination li.current button:hover,
ul.pagination li.current a:focus,
ul.pagination li.current a:hover,
ul.pagination li.current button:focus,
ul.pagination li.current button:hover {
  background-color: #b3c0d5
}

.dataTables_paginate.paging_simple_numbers ul.pagination li.unavailable a,
.dataTables_paginate.paging_simple_numbers ul.pagination li.unavailable button,
ul.pagination li.unavailable a,
ul.pagination li.unavailable button {
  color: #376caf
}

.dataTables_paginate.paging_simple_numbers ul.pagination.page-numbers li,
ul.pagination.page-numbers li {
  color: #7e7877;
  border: none;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0
}

.dataTables_paginate.paging_simple_numbers ul.pagination.page-numbers li.current,
ul.pagination.page-numbers li.current {
  border: 1px solid #7e7877
}

.pagination-centered ul.pagination li {
  margin: 0 -3px
}

.dataTables_paginate.paging_simple_numbers ul.pagination li {
  min-width: 2.5rem;
  width: auto
}

ul.tabs {
  outline: 0
}

ul.tabs li.navtab-title,
ul.tabs li.tab-title {
  outline: 0;
  position: relative;
  z-index: 2
}

ul.tabs li.navtab-title a,
ul.tabs li.tab-title a {
  outline: 0;
  text-decoration: none
}

ul.tabs li.navtab-title.active,
ul.tabs li.tab-title.active {
  outline: 0;
  background-color: #fff;
  position: relative;
  z-index: 2
}

.tabs-content {
  position: relative;
  z-index: 1
}

.tabs-content .content {
  background-color: #fff
}

div.tab-nav {
  position: relative;
  z-index: 5
}

ul.tabs.horizontal {
  padding-left: 0;
  margin-left: -5px
}

ul.tabs.horizontal li.nav-tab-title a,
ul.tabs.horizontal li.tab-title a {
  background-color: transparent;
  color: #444;
  max-height: 3.125rem;
  min-height: 1rem;
  border-top: 5px solid transparent;
  box-shadow: inset 0 -5px 5px -4px rgba(0, 0, 0, .36)
}

ul.tabs.horizontal li.nav-tab-title a:hover,
ul.tabs.horizontal li.tab-title a:hover {
  color: #5da5ff;
  background-color: transparent
}

ul.tabs.horizontal li.nav-tab-title.active,
ul.tabs.horizontal li.tab-title.active {
  overflow: hidden;
  height: 3.125rem;
  padding: .3125rem .3125rem 0;
  background-color: transparent
}

ul.tabs.horizontal li.nav-tab-title.active a,
ul.tabs.horizontal li.tab-title.active a {
  border-top-color: #376caf;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36);
  color: #376caf;
  padding: .625rem 2rem 1rem;
  display: inline-block;
  background-color: #fff
}

ul.tabs.horizontal li.nav-tab-title.active a:hover,
ul.tabs.horizontal li.tab-title.active a:hover {
  background-color: #fff
}

ul.tabs.horizontal.section-nav-tabs li.nav-tab-title a,
ul.tabs.horizontal.section-nav-tabs li.tab-title a {
  box-shadow: none
}

.tabs.horizontal+.tabs-content .content.active.shadow,
ul.tabs.horizontal.section-nav-tabs li.nav-tab-title.active a,
ul.tabs.horizontal.section-nav-tabs li.tab-title.active a {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36)
}

ul.tabs.horizontal li.nav-tab-title .product-nav-list,
ul.tabs.horizontal li.tab-title .product-nav-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 328px;
  background: #fff
}

ul.tabs.horizontal li.nav-tab-title .product-nav-list a,
ul.tabs.horizontal li.tab-title .product-nav-list a {
  display: block;
  clear: both;
  font-weight: 400;
  border: none;
  color: #263238;
  padding: .125rem 0 0 .75rem;
  margin: 0
}

ul.tabs.horizontal li.nav-tab-title .product-nav-list a:hover,
ul.tabs.horizontal li.tab-title .product-nav-list a:hover {
  color: #fff;
  background-color: #376caf
}

.tabs.horizontal+.tabs-content .content {
  padding: 1.875rem
}

.tabs.horizontal+.tabs-content .content.active {
  margin-top: 0
}

ul.tabs.vertical {
  position: relative;
  width: 100%;
  max-width: 30%;
  height: 21.875rem;
  padding-top: .25rem;
  overflow-x: hidden;
  overflow-y: auto
}

ul.tabs.vertical li.nav-tab-title,
ul.tabs.vertical li.tab-title {
  width: 100%;
  height: 3rem;
  z-index: 1
}

ul.tabs.vertical li.nav-tab-title a,
ul.tabs.vertical li.tab-title a {
  color: #636468;
  padding: .5625rem 1.0625rem .5625rem .625rem;
  border-left: 5px solid transparent;
  font-size: .875rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

ul.tabs.vertical li.nav-tab-title a:hover,
ul.tabs.vertical li.tab-title a:hover {
  color: #444
}

ul.tabs.vertical li.nav-tab-title.active,
ul.tabs.vertical li.nav-tab-title.sticky,
ul.tabs.vertical li.tab-title.active,
ul.tabs.vertical li.tab-title.sticky {
  background-color: #fff;
  z-index: 2
}

ul.tabs.vertical li.nav-tab-title.active a,
ul.tabs.vertical li.nav-tab-title.sticky a,
ul.tabs.vertical li.tab-title.active a,
ul.tabs.vertical li.tab-title.sticky a {
  background-color: #fff;
  border-left-color: #376caf;
  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, .36);
  color: #444;
  display: inline-block
}

ul.tabs.vertical li.nav-tab-title.active a:hover,
ul.tabs.vertical li.nav-tab-title.sticky a:hover,
ul.tabs.vertical li.tab-title.active a:hover,
ul.tabs.vertical li.tab-title.sticky a:hover {
  background-color: #fff
}

ul.tabs.vertical li.nav-tab-title.sticky,
ul.tabs.vertical li.tab-title.sticky {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 4px;
  z-index: 3
}

.btn-facebook,
.btn-facebook span,
.top-bar section.top-bar-section,
.top-bar section.top-bar-section ul.right {
  position: relative
}

.tabs.vertical+.tabs-content {
  max-width: 70%;
  margin-left: 30%;
  padding-top: .25rem
}

.tabs.vertical+.tabs-content .content {
  padding-left: .9375rem;
  background-color: #fff
}

.tabs.vertical+.tabs-content .content.active {
  margin-top: 0;
  height: 20.9375rem;
  overflow-y: auto
}

dl.sub-nav dd {
  background-color: transparent;
  color: #444
}

dl.sub-nav dd.active a,
dl.sub-nav dd.active a:hover {
  background-color: #376caf
}

a[data-dropdown].btn-dropdown,
dl.sub-nav dd.active a,
dl.sub-nav dd:hover a,
ul[data-dropdown-content] li:hover a {
  color: #fff
}

dl.sub-nav dd:hover a {
  background-color: #5da5ff
}

.reveal-modal dl.sub-nav dd .close-reveal-modal.btn-close,
dl.sub-nav dd .btn,
dl.sub-nav dd .reveal-modal .close-reveal-modal.btn-close {
  font-size: 1.125rem;
  padding: .625rem 1.25rem
}

a[data-dropdown] {
  color: #376caf;
  font-size: 1.125rem
}

a[data-dropdown]:hover {
  color: #5da5ff
}

ul[data-dropdown-content] {
  padding: .625rem 0;
  margin: .625rem 0 0 -.625rem;
  text-align: center;
  list-style: none;
  outline: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36);
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .36)
}

ul[data-dropdown-content] li {
  background-color: #fff;
  padding: .4375rem 0;
  margin: .625rem 0
}

ul[data-dropdown-content] li a {
  text-decoration: none;
  color: #444;
  font-weight: 300
}

ul[data-dropdown-content] li:hover {
  background-color: #376caf
}

ul[data-dropdown-content]:active,
ul[data-dropdown-content]:focus {
  outline: 0
}

ul[data-dropdown-content].flag-comment {
  text-align: left;
  padding: 0
}

ul[data-dropdown-content].flag-comment li {
  margin: 0;
  padding: 0
}

ul[data-dropdown-content].flag-comment li a {
  padding: .5rem
}

ul[data-dropdown-content].flag-comment li.spam {
  border-bottom: solid 1px #999
}

ul[data-dropdown-content].flag-comment li.ban {
  border-top: solid 1px #999
}

.top-bar {
  background-color: #444
}

.top-bar ul li a {
  text-decoration: none
}

.top-bar-section li:not(.has-form) a:not(.button):hover,
.top-bar-section ul li a:hover,
.top-bar-section ul li:hover,
.top-bar-section ul li:hover:not(.has-form)>a:hover {
  background: #7e7877
}

.top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button) {
  background: #444
}

.top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button):hover {
  background: #7e7877
}

.sticky .top-bar.container {
  padding-left: 0;
  padding-right: 0
}

.sticky.fixed {
  background-color: #444
}

.breadcrumbs {
  background: 0 0;
  border: none;
  padding: .5625rem 0
}

.btn-stroked-blue,
.btn-stroked-green,
.btn-stroked-orange,
.btn-stroked-red,
.btn-stroked-yellow,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue {
  background-color: #fff
}

.breadcrumbs>* {
  font-size: 1rem;
  font-weight: 400
}

.breadcrumbs>* a,
.breadcrumbs>:before {
  color: #376caf
}

.breadcrumbs>* a:hover {
  color: #5da5ff
}

.btn-blue,
.btn-blue:active,
.btn-blue:focus,
.btn-blue:hover,
.nav-bar .menu-icon,
.reveal-modal .close-reveal-modal.btn-close.btn-blue,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:active,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:focus,
.reveal-modal .close-reveal-modal.btn-close.btn-blue:hover {
  color: #fff
}

.breadcrumbs>:focus q,
.breadcrumbs>:hover a {
  text-decoration: none
}

.nav-bar li,
.nav-bar ul {
  list-style: none
}

.nav-bar .has-dd ul.dd-menu {
  margin-left: 0
}

@media (max-width:767px) {
  .nav-bar .has-dd {
    width: 100%;
    padding: .375rem .9375rem;
    display: block
  }
  .nav-bar .has-dd+.dd-menu {
    display: none
  }
  .nav-bar .has-dd.dd-active+.dd-menu {
    display: block;
    padding: 0 1.25rem .375rem
  }
  .nav-bar .has-dd .dd-toggle {
    float: right;
    font-weight: 100
  }
  .nav-bar .has-dd .dd-toggle:after {
    content: "+"
  }
  .nav-bar .has-dd.dd-active .dd-toggle:after {
    content: "-"
  }
}

.btn,
.reveal-modal .close-reveal-modal.btn-close,
.reveal-modal input.close-reveal-modal.btn-close,
input.btn {
  display: inline-block;
  font-size: .875rem;
  font-weight: 400;
  text-align: center;
  border-radius: .1875rem;
  line-height: 24px;
  padding: .375rem 1.25rem
}

.btn:focus,
.reveal-modal .close-reveal-modal.btn-close:focus,
.reveal-modal input.close-reveal-modal.btn-close:focus,
input.btn:focus {
  outline: 0
}

.btn.icon-left,
.reveal-modal .icon-left.close-reveal-modal.btn-close,
.reveal-modal input.icon-left.close-reveal-modal.btn-close,
input.btn.icon-left {
  padding: .375rem 1.25rem .375rem .9375rem
}

.btn.icon-right,
.reveal-modal .icon-right.close-reveal-modal.btn-close,
.reveal-modal input.icon-right.close-reveal-modal.btn-close,
input.btn.icon-right {
  padding: .375rem .9375rem .375rem 1.25rem
}

.btn.icon-both,
.reveal-modal .icon-both.close-reveal-modal.btn-close,
.reveal-modal input.icon-both.close-reveal-modal.btn-close,
input.btn.icon-both {
  padding: .375rem .9375rem
}

.btn svg,
.reveal-modal .close-reveal-modal.btn-close svg,
.reveal-modal input.close-reveal-modal.btn-close svg,
input.btn svg {
  width: 1.375rem;
  height: 1.375rem;
  fill: currentColor;
  vertical-align: middle
}

@media (max-width:767px) {
  .btn svg,
  .reveal-modal .close-reveal-modal.btn-close svg,
  .reveal-modal input.close-reveal-modal.btn-close svg,
  input.btn svg {
    width: .875rem;
    height: .875rem
  }
}

.btn.btn-sm svg,
.reveal-modal .btn-sm.close-reveal-modal.btn-close svg,
.reveal-modal input.btn-sm.close-reveal-modal.btn-close svg,
input.btn.btn-sm svg {
  width: 1.125rem;
  height: 1.125rem
}

.btn.rounded,
.reveal-modal .rounded.close-reveal-modal.btn-close,
.reveal-modal input.rounded.close-reveal-modal.btn-close,
input.btn.rounded {
  border-radius: 500px
}

.reveal-modal div.close-reveal-modal.btn-close,
div.btn {
  display: inline-block
}

.reveal-modal a.close-reveal-modal.btn-close,
a.btn {
  text-decoration: none;
  margin-top: .3125rem
}

.reveal-modal input.close-reveal-modal.btn-close,
input.btn {
  outline: 0;
  border: none
}

.btn-blue,
.btn-green,
.btn-green-login,
.btn-orange,
.btn-red,
.btn-stroked-blue,
.btn-stroked-green,
.btn-stroked-orange,
.btn-stroked-red,
.btn-stroked-yellow,
.btn-yellow,
.reveal-modal .close-reveal-modal.btn-close.btn-blue,
.reveal-modal .close-reveal-modal.btn-close.btn-stroked-blue {
  border-style: solid;
  border-width: 1px
}

.btn.btn-mini,
.reveal-modal .btn-mini.close-reveal-modal.btn-close {
  font-size: .5625rem;
  line-height: .875rem;
  padding: .0625rem .875rem
}

.btn.btn-mini.icon-left,
.reveal-modal .btn-mini.icon-left.close-reveal-modal.btn-close {
  padding: .0625rem .25rem .0625rem .5625rem
}

.btn.btn-mini.icon-right,
.reveal-modal .btn-mini.icon-right.close-reveal-modal.btn-close {
  padding: .0625rem .5625rem .0625rem .25rem
}

.btn.btn-midi,
.reveal-modal .btn-midi.close-reveal-modal.btn-close {
  font-size: .75rem;
  line-height: 1.125rem;
  padding: .3125rem 1.1875rem
}

.btn.btn-midi.icon-left,
.reveal-modal .btn-midi.icon-left.close-reveal-modal.btn-close {
  padding: .3125rem .9375rem .3125rem .625rem
}

.btn.btn-midi.icon-right,
.reveal-modal .btn-midi.icon-right.close-reveal-modal.btn-close {
  padding: .3125rem .625rem .3125rem .9375rem
}

.btn.btn-sm,
.reveal-modal .btn-sm.close-reveal-modal.btn-close {
  font-size: .6875rem;
  line-height: 1.25rem;
  padding: .1875rem 1rem
}

.btn.btn-sm.icon-left,
.reveal-modal .btn-sm.icon-left.close-reveal-modal.btn-close {
  padding: .1875rem 1rem .1875rem .6875rem
}

.btn.btn-sm.icon-right,
.reveal-modal .btn-sm.icon-right.close-reveal-modal.btn-close {
  padding: .1875rem .6875rem .1875rem 1rem
}

.btn.btn-sm.icon-both,
.reveal-modal .btn-sm.icon-both.close-reveal-modal.btn-close {
  padding: .1875rem .6875rem
}

.btn.btn-lg,
.reveal-modal .btn-lg.close-reveal-modal.btn-close {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: .625rem 1.625rem
}

.btn.btn-lg.icon-left,
.reveal-modal .btn-lg.icon-left.close-reveal-modal.btn-close {
  padding: .625rem 1.625rem .625rem 1.3125rem
}

.btn.btn-lg.icon-right,
.reveal-modal .btn-lg.icon-right.close-reveal-modal.btn-close {
  padding: .625rem 1.3125rem .625rem 1.625rem
}

.btn.btn-lg.icon-both,
.reveal-modal .btn-lg.icon-both.close-reveal-modal.btn-close {
  padding: .625rem 1.3125rem
}

.btn.btn-xl,
.reveal-modal .btn-xl.close-reveal-modal.btn-close {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: .75rem 1.75rem
}

.btn.btn-xl.icon-left,
.reveal-modal .btn-xl.icon-left.close-reveal-modal.btn-close {
  padding: .75rem 1.75rem .75rem 1.4375rem
}

.btn.btn-xl.icon-right,
.reveal-modal .btn-xl.icon-right.close-reveal-modal.btn-close {
  padding: .75rem 1.4375rem .75rem 1.75rem
}

.btn.btn-xl.icon-both,
.reveal-modal .btn-xl.icon-both.close-reveal-modal.btn-close {
  padding: .75rem 1.4375rem
}

.input-group-btn .reveal-modal button.close-reveal-modal.btn-close,
.input-group-btn button.btn,
.reveal-modal .input-group-btn button.close-reveal-modal.btn-close {
  line-height: .875rem
}

.btn-next,
.btn-prev {
  min-width: 6.25rem;
  max-height: 2.5rem
}

.btn-blue.disabled,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-blue {
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed
}

.btn-blue.disabled:active,
.btn-blue.disabled:focus,
.btn-blue.disabled:hover,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-blue:active,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-blue:focus,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-blue:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-red,
.btn-red:active,
.btn-red:focus,
.btn-red:hover {
  color: #fff
}

.btn-blue.faded:active,
.btn-blue.faded:focus,
.btn-blue.faded:hover,
.reveal-modal .faded.close-reveal-modal.btn-close.btn-blue:active,
.reveal-modal .faded.close-reveal-modal.btn-close.btn-blue:focus,
.reveal-modal .faded.close-reveal-modal.btn-close.btn-blue:hover {
  border-color: transparent
}

.btn-red.disabled,
.btn-red.disabled:active,
.btn-red.disabled:focus,
.btn-red.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-green,
.btn-green:active,
.btn-green:focus,
.btn-green:hover {
  color: #fff
}

.btn-red.faded:active,
.btn-red.faded:focus,
.btn-red.faded:hover {
  border-color: transparent
}

.btn-green.disabled,
.btn-green.disabled:active,
.btn-green.disabled:focus,
.btn-green.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-green.faded:active,
.btn-green.faded:focus,
.btn-green.faded:hover {
  border-color: transparent
}

.btn-orange,
.btn-orange:active,
.btn-orange:focus,
.btn-orange:hover {
  color: #fff
}

.btn-orange.disabled,
.btn-orange.disabled:active,
.btn-orange.disabled:focus,
.btn-orange.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-orange.faded:active,
.btn-orange.faded:focus,
.btn-orange.faded:hover {
  border-color: transparent
}

.btn-yellow,
.btn-yellow:active,
.btn-yellow:focus,
.btn-yellow:hover {
  color: #fff
}

.btn-yellow.disabled,
.btn-yellow.disabled:active,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-yellow.faded:active,
.btn-yellow.faded:focus,
.btn-yellow.faded:hover {
  border-color: transparent
}

.btn-blue-login {
  border-style: solid;
  border-width: 1px;
  color: #fff
}

.btn-blue-login:active,
.btn-blue-login:focus,
.btn-blue-login:hover {
  color: #fff
}

.btn-blue-login.disabled,
.btn-blue-login.disabled:active,
.btn-blue-login.disabled:focus,
.btn-blue-login.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-blue-login.faded:active,
.btn-blue-login.faded:focus,
.btn-blue-login.faded:hover {
  border-color: transparent
}

.btn-green-login,
.btn-green-login:active,
.btn-green-login:focus,
.btn-green-login:hover {
  color: #fff
}

.btn-green-login.disabled,
.btn-green-login.disabled:active,
.btn-green-login.disabled:focus,
.btn-green-login.disabled:hover {
  color: #ccc;
  border-color: #ccc
}

.btn-green-login.faded:active,
.btn-green-login.faded:focus,
.btn-green-login.faded:hover {
  border-color: transparent
}

.btn-stroked-blue.disabled,
.btn-stroked-green.disabled,
.btn-stroked-orange.disabled,
.btn-stroked-red.disabled,
.btn-stroked-yellow.disabled,
.reveal-modal .disabled.close-reveal-modal.btn-close.btn-stroked-blue {
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed
}

.btn-ghost {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff
}

.btn-ghost:active,
.btn-ghost:hover {
  color: #376caf;
  border-color: #fff;
  background-color: #fff
}

.btn-facebook {
  background-color: #3b5998;
  color: #fff;
  line-height: 24px
}

.btn-facebook span {
  display: inline-block;
  margin: -.375rem .625rem -.8125rem 0;
  width: 1.5rem;
  height: 2.25rem;
  border-right: solid 1px #1b2b71
}

.btn-facebook span .fa-facebook {
  float: left;
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  line-height: 2.25rem
}

.btn-facebook:hover {
  color: #fff
}

.btn-facebook.btn-lg span {
  width: 1.875rem;
  height: 2.75rem;
  margin: -.625rem .625rem -1.3125rem 0
}

.btn-facebook.btn-lg span .fa-facebook {
  font-size: 1.875rem;
  line-height: 2.75rem
}

.reveal-modal .close-reveal-modal.btn-close {
  position: static
}

.btn-loader:not(.disabled):before {
  content: '';
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-left-color: transparent;
  border-radius: 50%;
  filter: alpha(Opacity=0);
  opacity: 0;
  transition-duration: .25s;
  transition-property: opacity;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  animation-timing-function: linear
}

.btn-loader:not(.disabled):after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  transition-delay: .25s;
  transition-duration: .75s;
  transition-property: width
}

.btn-loader:not(.disabled).sending {
  pointer-events: none;
  cursor: not-allowed
}

.btn-loader:not(.disabled).sending:not(.expand) {
  text-align: left
}

.btn-loader:not(.disabled).sending:before {
  transition-delay: .25s;
  transition-duration: 1s;
  filter: alpha(enabled=false);
  opacity: 1
}

.btn-loader:not(.disabled).sending:after {
  transition-delay: 0s;
  width: 30px
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.alert-box {
  font-size: 18px;
  font-weight: 300
}

.cview-container .forum-table tfoot tr td,
.no-table tfoot tr td,
.table tfoot tr td {
  font-style: italic;
  font-weight: 400
}

.alert-box .close {
  opacity: 1
}

.alert-box.info {
  color: #376caf;
  border-color: #376caf;
  background-color: #e9eff7
}

.alert-box.info .close {
  color: #376caf
}

.alert-box.success {
  color: #48a54c;
  border-color: #48a54c;
  background-color: #eaf6eb
}

.alert-box.success .close {
  color: #48a54c
}

.alert-box.alert {
  color: #c93e27;
  border-color: #c93e27;
  background-color: #f8e9e6
}

.alert-box.alert .close {
  color: #c93e27
}

.alert-box.warning {
  color: #f3bf50;
  border-color: #f3bf50;
  background-color: #faf3e5
}

.alert-box.warning .close {
  color: #f3bf50
}

.system-notificaions {
  margin: 0 auto;
  max-width: 60.625rem
}

.cview-container .forum-table,
.table {
  border: none;
  font-size: 1.125rem;
  line-height: 1.5rem
}

.cview-container .forum-table td,
.cview-container .forum-table th,
.table td,
.table th {
  padding: .75rem 1.5rem
}

.cview-container .forum-table tr.alt,
.cview-container .forum-table tr.even,
.cview-container .forum-table tr:nth-of-type(even),
.table tr.alt,
.table tr.even,
.table tr:nth-of-type(even) {
  background: 0 0;
  border-top: solid 1px rgba(0, 0, 0, .1);
  border-bottom: solid 1px rgba(0, 0, 0, .1)
}

.cview-container .forum-table tfoot,
.table tfoot {
  background: 0 0
}

.cview-container .forum-table tr.alt,
.cview-container .forum-table tr.even,
.cview-container .forum-table tr:nth-of-type(even),
.table-striped .forum-table:nth-of-type(even),
.table-striped tr.alt,
.table-striped tr.even,
.table-striped tr:nth-of-type(even) {
  background: #f1f4f8;
  border: none
}

.cview-container .forum-table tfoot tr,
.table tfoot tr {
  border-bottom: none
}

.cview-container .forum-table,
.table-striped {
  border: 1px solid #89a9d0
}

.table-striped .forum-table {
  border: none
}

.cview-container .table-striped:nth-of-type(even) {
  background: #f1f4f8;
  border-top: none;
  border-bottom: none
}

.cview-container .table-striped:nth-last-of-type(2) {
  border-bottom: solid 1px #89a9d0
}

.no-table {
  border: none;
  font-size: 1.125rem;
  line-height: 1.5rem
}

.no-table td,
.no-table th {
  padding: .0625rem .3125rem
}

.no-table tr.alt,
.no-table tr.even,
.no-table tr:nth-of-type(even) {
  background: 0 0;
  border: none
}

.no-table tfoot {
  background: 0 0
}

.no-table tfoot tr {
  border-bottom: none
}

.table {
  display: table
}

.table .row {
  display: table-row
}

.table .row .cell {
  display: table-cell
}

.table.table-striped .row:nth-of-type(even) {
  background: #f1f4f8
}

@media (max-width:767px),
(min-width:768px) and (max-width:991px) {
  .table .table {
    display: block
  }
}

.cview-container .table,
.forums-landing .table {
  display: block
}

.table-responsive-wrapper {
  position: relative;
  width: 100%;
  overflow: auto
}

.reveal-modal {
  position: fixed;
  overflow-y: auto;
  border: none
}

@media (min-width:768px) {
  .reveal-modal {
    max-height: 90vh
  }
  .reveal-modal[style] {
    top: 5%!important
  }
}

.reveal-modal.open {
  border-radius: 4px
}

@media (max-width:767px) {
  .reveal-modal {
    min-height: auto;
    max-height: 98vh
  }
  .reveal-modal[style] {
    top: 1%!important
  }
  .reveal-modal.open {
    border-radius: 0
  }
}

.reveal-modal:focus {
  outline: 0
}

.reveal-modal .close-reveal-modal {
  font-weight: 100
}

.reveal-modal-bg {
  background: rgba(255, 255, 255, .75)
}

#cke_topicBody {
  margin: .3125rem 0 .625rem
}

@media (max-width:479px) {
  #theModal.reveal-modal .start-discussion .blue-header {
    font-size: 1.875rem;
    padding: .75rem 0
  }
}

#theModal.reveal-modal .close-register-modal {
  color: #aaa;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: 1.375rem;
  top: .625rem
}

#theModal.reveal-modal .blue-header {
  background-color: #376caf;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 100;
  padding: .3125rem 0;
  text-align: center;
  margin: -1.875rem -1.875rem 2.1875rem;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0
}

@media (max-width:767px) {
  #theModal.reveal-modal .blue-header {
    border-radius: 0;
    margin-bottom: .9375rem
  }
  #theModal.reveal-modal .blue-header .logo {
    padding: 0 0 .3125rem
  }
}

@media (min-width:768px) {
  #theModal.reveal-modal .blue-header .logo {
    padding: 1.5625rem 0 2.5rem
  }
}

#theModal.reveal-modal .blue-header .subhead {
  display: block;
  font-size: 1.25rem
}

#theModal.reveal-modal hr.or-line-break {
  padding: 0;
  border: none;
  border-top: solid 1px #7e7877;
  color: #7e7877;
  text-align: center
}

#theModal.reveal-modal hr.or-line-break:after {
  content: "or";
  display: inline-block;
  position: relative;
  top: -.875rem;
  font-size: 1.125rem;
  padding: 0 .625rem;
  background: #fff
}

#theModal.reveal-modal .alreadyExist {
  display: block
}

#theModal.reveal-modal .alreadyExist .btn-green-login {
  max-width: 50%;
  float: right
}

#theModal.reveal-modal button {
  margin-bottom: 0
}

#theModal.reveal-modal .content .email-updates-group ul {
  display: inline-block;
  width: 100%
}

#theModal.reveal-modal .content .expand-newsletter {
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1
}

#theModal.reveal-modal .content .expand-newsletter:before {
  border-top: 1px solid #e6e6e6;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

#theModal.reveal-modal .content .expand-newsletter .expand-less,
#theModal.reveal-modal .content .expand-newsletter .expand-more {
  cursor: pointer;
  font-size: .75rem;
  background-color: #fff;
  color: #636468
}

#theModal.reveal-modal .content .expand-newsletter .expand-less .blue,
#theModal.reveal-modal .content .expand-newsletter .expand-more .blue {
  color: #376caf
}

#theModal.reveal-modal .content .expand-newsletter .expand-more {
  display: inline
}

#theModal.reveal-modal .content .expand-newsletter .expand-less,
#theModal.reveal-modal .content .expand-newsletter+.advanced-newsletter,
#theModal.reveal-modal .content .expand-newsletter.advanced .expand-more {
  display: none
}

#theModal.reveal-modal .content .expand-newsletter.advanced .expand-less {
  display: inline
}

#theModal.reveal-modal .content .expand-newsletter.advanced+.advanced-newsletter {
  display: inherit
}

#theModal.reveal-modal .content .advanced-newsletter {
  width: 100%;
  min-height: 150px
}

@media (max-width:479px) {
  #theModal.reveal-modal .content .advanced-newsletter p:not(#ie8)>input[type=checkbox]+label {
    width: 100%;
    margin-left: 0
  }
  #theModal.reveal-modal .content .advanced-newsletter p {
    margin-bottom: 0
  }
  #theModal.reveal-modal .content #NagNewsletter .move-along .btn,
  #theModal.reveal-modal .content #NagNewsletter .move-along .close-reveal-modal.btn-close {
    font-size: .75rem;
    line-height: 1.125rem;
    padding: 6px 16px
  }
  #theModal.reveal-modal .content #NagNewsletter .move-along .btn-green-login.close-reveal-modal.btn-close,
  #theModal.reveal-modal .content #NagNewsletter .move-along .btn.btn-green-login {
    padding: 3px 11px 3px 16px
  }
}

#theModal.reveal-modal .content .pagination-header {
  width: 100%;
  clear: both;
  margin: .625rem 0 0;
  list-style: none;
  text-align: center
}

#theModal.reveal-modal .content .pagination-header li {
  float: left;
  width: 33%;
  position: relative;
  margin: .625rem 0;
  border: 1px solid #376caf;
  color: #376caf
}

#theModal.reveal-modal .content .pagination-header li.current {
  background-color: #376caf;
  color: #fff
}

#theModal.reveal-modal .content .pagination-header li:nth-child(2) {
  border-left: 0;
  border-right: 0
}

#theModal.reveal-modal .content .tos {
  height: 300px;
  overflow-y: auto;
  width: 100%
}

#theModal.reveal-modal .content h2 {
  color: #48a54c;
  text-align: center
}

#theModal.reveal-modal .content .instructions {
  color: #444;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 100;
  line-height: 1.5rem
}

#theModal.reveal-modal .content .description {
  text-align: center;
  font-size: 1.0625rem;
  color: #135489
}

#theModal.reveal-modal .content fieldset {
  border: 0;
  margin: 0;
  padding: 0 10px
}

#theModal.reveal-modal .content ul {
  float: left;
  margin: 0 0 10px;
  list-style: none;
  padding: 0
}

#theModal.reveal-modal .content .columns {
  -webkit-columns: 2;
  columns: 2
}

#theModal.reveal-modal .content .progress-dots {
  float: none;
  display: block;
  max-width: 7.1875rem;
  margin: 20px auto 0;
  text-align: center
}

#theModal.reveal-modal .content .progress-dots li {
  float: left;
  border-radius: 50%;
  border: 1px solid #376caf;
  height: .9375rem;
  width: .9375rem;
  margin: 0 .0625rem;
  line-height: 2.25rem
}

#theModal.reveal-modal .content .progress-dots li.filled {
  background-color: #376caf
}

@media (max-width:767px) {
  #theModal.reveal-modal .content .progress-dots {
    padding-top: .75rem
  }
}

#theModal.reveal-modal .move-along {
  clear: both;
  padding-top: 1.875rem
}

#theModal.reveal-modal .move-along .page-numbers {
  display: inline-block;
  margin: .6875rem 0
}

#theModal.reveal-modal .move-along .btn-green-login {
  width: 100%
}

#theModal.reveal-modal .move-along .btn-blue-login {
  width: 100%;
  background-color: #376caf
}

#theModal.reveal-modal .move-along .close-modal {
  display: block;
  text-align: center;
  margin-top: 10px
}

#theModal.reveal-modal .move-along a {
  display: block;
  text-align: center;
  width: 100%
}

#theModal.reveal-modal .move-along .btn-stroked-blue {
  width: 100%;
  margin-right: .625rem
}

.loginModal .btn-green-login,
.loginModal .btn-link {
  width: 12.25rem
}

#theModal.reveal-modal .move-along .dont-ask {
  color: #7e7877;
  font-weight: 300;
  margin: 1.25rem 0 0
}

#theModal.reveal-modal .modal-footer {
  margin-top: 1.25rem
}

#theModal.reveal-modal .modal-footer .btn-stroked-blue {
  color: #376caf
}

#theModal.reveal-modal .modal-footer .btn-stroked-blue.register-back {
  float: left
}

.loginModal .need2signup {
  float: left;
  margin-right: 20px
}

.loginModal .btn-facebook {
  float: right;
  margin: -4.5rem 0 0
}

@media (max-width:479px) {
  #theModal.reveal-modal .content .columns {
    -webkit-columns: 1;
    columns: 1
  }
  #theModal.reveal-modal .content .progress-dots {
    clear: both
  }
  .loginModal .btn-link {
    width: 100%
  }
  .loginModal .need2signup {
    float: none;
    width: 100%;
    text-align: center;
    margin: 0 auto
  }
  .loginModal .btn-green-login {
    width: 100%
  }
  .loginModal .btn-facebook {
    float: none;
    clear: both;
    width: 100%;
    margin: 10px auto 0
  }
}

.register-email .join-us {
  font-weight: 100;
  font-size: 2.3125rem;
  line-height: 2.25rem
}

.register-email .join-us .join {
  font-weight: 700;
  color: #48a54c
}

.register-email .description {
  font-size: 1.25rem
}

.register-email .btn-green-login {
  width: 100%;
  margin-bottom: 0
}

.register-email .btn-facebook {
  width: 100%
}

.register-email .btn-facebook span {
  float: left
}

@media (min-width:768px) {
  #theModal.reveal-modal.login-reg_modal {
    max-width: 31.25rem
  }
  .register-email .join-us {
    text-align: center
  }
}

@media (max-width:767px) {
  .register-email .join-us {
    font-size: 1.8125rem;
    line-height: 1.875rem
  }
  .register-email .description {
    font-size: 1rem;
    line-height: 1.25rem
  }
}

@media (max-width:479px) {
  .register-email .join-us {
    font-size: 1.3125rem;
    line-height: 1.5rem
  }
}

.register-welcome .form-element-required {
  width: 100%
}

.register-welcome .required-fyi {
  float: right;
  color: #c93e27;
  font-size: .625rem
}

.move-along .btn-link {
  float: right
}

.rate-vendor-start h3.rate-vendor {
  margin: 3.125rem auto 0;
  width: 100%;
  padding: .9375rem 1.25rem;
  color: #fff;
  font-size: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  font-weight: 100;
  background-color: #cc6500;
  border-top-left-radius: .3125rem;
  border-top-right-radius: .3125rem
}

.rate-vendor-start h3.rate-vendor svg {
  width: 1.875rem;
  height: 1.875rem
}

.rate-vendor-start h3.rate-vendor .subhead {
  display: block;
  font-size: 1rem;
  font-weight: 100
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .rate-vendor-start h3.rate-vendor .subhead {
    font-size: .875rem
  }
}

@media (max-width:479px) {
  .rate-vendor-start h3.rate-vendor .subhead {
    text-align: right;
    margin-right: 1.875rem
  }
}

.rate-vendor-start .rate-vendor-steps {
  display: none;
  border: 1px solid #CCC;
  border-top: none;
  padding: 1.25rem .9375rem
}

.rate-vendor-start .rate-vendor-steps.active {
  display: block
}

.rate-vendor-start .rate-vendor-steps .pagination-header {
  width: 100%;
  height: 3.125rem;
  clear: both;
  margin: -.625rem 0 1.875rem;
  list-style: none;
  text-align: center
}

.rate-vendor-start .rate-vendor-steps .pagination-header li {
  float: left;
  position: relative;
  margin: .625rem 0;
  padding: .625rem;
  border: 1px solid #acacac;
  color: #969696
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:first-child {
  width: 45%;
  border-right: 0
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:nth-child(2) {
  width: 29%;
  border-left: 0;
  border-right: 0
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:last-child {
  width: 20%;
  border-left: 0
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:after,
.rate-vendor-start .rate-vendor-steps .pagination-header li:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:after {
  border-color: transparent transparent transparent #fff;
  border-width: 22px;
  margin-top: -22px;
  z-index: 2
}

.rate-vendor-start .rate-vendor-steps .pagination-header li:before {
  border-color: transparent transparent transparent #acacac;
  border-width: 23px;
  margin-top: -23px
}

.rate-vendor-start .rate-vendor-steps .pagination-header li.current {
  background-color: #cc6500;
  color: #fff
}

.rate-vendor-start .rate-vendor-steps .pagination-header li.current:after {
  border-left-color: #cc6500
}

@media (max-width:767px) {
  .rate-vendor-start .rate-vendor-steps .pagination-header li {
    font-size: .875rem
  }
  .rate-vendor-start .rate-vendor-steps .pagination-header li:last-child,
  .rate-vendor-start .rate-vendor-steps .pagination-header li:nth-child(2) {
    padding: .625rem .3125rem .625rem 1.5625rem
  }
}

@media (max-width:479px) {
  .rate-vendor-start .rate-vendor-steps .pagination-header {
    display: none;
    height: auto;
    width: 100%
  }
  .rate-vendor-start .rate-vendor-steps .pagination-header li {
    float: none;
    clear: both;
    font-size: 1rem;
    font-weight: 300;
    margin: 0
  }
  .rate-vendor-start .rate-vendor-steps .pagination-header li:first-child,
  .rate-vendor-start .rate-vendor-steps .pagination-header li:last-child,
  .rate-vendor-start .rate-vendor-steps .pagination-header li:nth-child(2) {
    width: 90%;
    border-left: solid 1px #acacac
  }
}

.rate-vendor-start .rate-vendor-steps .step-title {
  display: block;
  text-align: center;
  margin-bottom: .625rem;
  font-size: 1.5rem;
  font-weight: 300
}

.rate-vendor-start .rate-vendor-steps .step-title strong {
  color: #cc6500;
  font-weight: 500
}

.rate-vendor-start .rate-vendor-steps .step-title.success strong {
  color: #0a9e02;
  font-weight: 300
}

.rate-vendor-start .rate-vendor-steps .step-title.rate-another {
  clear: both;
  margin-top: 1.25rem
}

@media (max-width:479px) {
  .rate-vendor-start .rate-vendor-steps .step-title {
    font-size: 1.5625rem
  }
}

.rate-vendor-start .rate-vendor-steps .step-instructions {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 300;
  color: #444
}

.rate-vendor-start .rate-vendor-steps .step-instructions strong {
  font-weight: 500
}

.rate-vendor-start .rate-vendor-steps .step-instructions .text-link {
  display: block;
  text-align: right
}

.rate-vendor-start .rate-vendor-steps .productStats {
  clear: both;
  width: 100%;
  margin: .625rem 0
}

.rate-vendor-start .rate-vendor-steps select {
  display: block;
  margin: .625rem auto
}

.rate-vendor-start .rate-vendor-steps textarea {
  min-height: 4.375rem;
  line-height: 1.25rem
}

.rate-vendor-start .rate-vendor-steps .noVendor {
  text-align: center;
  font-size: .875rem;
  line-height: 1.5rem;
  font-weight: 300
}

.rate-vendor-start .rate-vendor-steps .noVendor a {
  font-weight: 400
}

@media (min-width:768px) {
  .rate-vendor-start .rate-vendor-steps .noVendor .line-break {
    display: none
  }
}

.rate-vendor-start .rate-vendor-steps .recommend {
  font-weight: 300
}

.rate-vendor-start .rate-vendor-steps .recommend .recommend-radio {
  max-width: 100%;
  margin: .3125rem auto
}

.rate-vendor-start .rate-vendor-steps .recommend .recommend-radio p:not(#ie8) {
  float: left;
  width: 50%
}

.rate-vendor-start .rate-vendor-steps .recommend .recommend-radio p:not(#ie8)>input[type=radio]+label {
  display: inline-block
}

.rate-vendor-start .rate-vendor-steps .next-nav {
  margin-top: 1.25rem;
  min-height: 40px
}

.rate-vendor-start .rate-vendor-steps .next-nav .btn,
.rate-vendor-start .rate-vendor-steps .next-nav .reveal-modal .close-reveal-modal.btn-close,
.reveal-modal .rate-vendor-start .rate-vendor-steps .next-nav .close-reveal-modal.btn-close {
  width: 100%;
  min-width: 6.875rem
}

.rate-vendor-start .rate-vendor-steps .next-nav .btn-left {
  width: 48%;
  float: left
}

.rate-vendor-start .rate-vendor-steps .next-nav .btn-right {
  width: 48%;
  float: right
}

.rate-vendor-start .rate-vendor-steps .step-progress {
  margin: 3.125rem auto 0;
  list-style: none;
  max-width: 6.25rem;
  text-align: center
}

.rate-vendor-start .rate-vendor-steps .step-progress li {
  display: inline-block;
  margin: 0 .0625rem;
  border-radius: 50%;
  border: 1px solid #cc6500;
  width: 10px;
  height: 11px
}

.rate-vendor-start .rate-vendor-steps .step-progress li.active {
  background-color: #cc6500
}

.rate-vendor-start .rate-vendor-steps .btn-back {
  float: left;
  min-width: 6.875rem;
  margin-top: 2.1875rem
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .rating-description,
.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .step-instructions {
  margin: 1.25rem 0;
  font-size: 1.25rem;
  position: relative;
  text-align: center
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .star-ratings-sprite {
  margin: auto
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .rating-description {
  display: block;
  text-align: center;
  margin: .9375rem 0 1.25rem
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 #step2RateProductStarText {
  display: none
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .write-review {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.25rem 0
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .write-review input[type=text],
.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .write-review textarea {
  width: 100%
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .write-review textarea {
  padding-top: 7px;
  min-height: 4.5rem;
  margin-bottom: 0
}

.rate-vendor-start .rate-vendor-steps.rateVendorStep2 .step-instructions {
  margin-top: 0
}

.rate-vendor-start .rate-vendor-steps.addVendorStep2 .btn-orange.addVendor {
  float: right;
  margin-bottom: 1.25rem
}

@media (max-width:479px) {
  .rate-vendor-start h3.rate-vendor {
    margin-top: 1.875rem
  }
  .rate-vendor-start .rate-vendor-step1 .noVendor a {
    display: block
  }
  .rate-vendor-start .rate-vendor-step1 .next-nav a.btn-green {
    width: 100%;
    padding: .3125rem .625rem
  }
}

.rate-vendor-start .vrModal-step1 .rateVendorStep1 {
  border: none
}

.rate-vendor-start .vrModal-step1 .how-it-works {
  display: none
}

.rate-vendor-start .vrModal-step1 .rateVendorStep1.active+.how-it-works {
  display: block
}

.rate-vendor-start .how-it-works {
  background-color: #e9eff7;
  margin: .625rem 0 0;
  padding: .0625rem 0 .9375rem;
  text-align: center
}

.rate-vendor-start .how-it-works h3 {
  color: #cc6500;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: .625rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  padding: 0 .625rem
}

.rate-vendor-start .how-it-works ol {
  position: relative;
  list-style: none;
  counter-reset: li;
  text-align: center;
  width: 100%;
  clear: both;
  margin: 0;
  padding: 0
}

.rate-vendor-start .how-it-works ol li {
  position: relative;
  counter-increment: step;
  display: inline-block;
  max-width: 30%;
  margin: 0 auto 1.25rem
}

.rate-vendor-start .how-it-works ol li:before {
  content: counter(step, decimal);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: .9375rem;
  background: #cc6500;
  color: #fff;
  position: absolute;
  top: .875rem;
  left: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.5625rem;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle
}

.rate-vendor-start .how-it-works ol li span {
  display: inline-block;
  max-width: 35%;
  text-align: left;
  font-size: .625rem;
  line-height: .75rem;
  font-weight: 700;
  color: #444;
  margin-top: 4%
}

.rate-vendor-start .how-it-works ol li img {
  display: inline-block;
  float: left;
  max-height: 3.375rem;
  width: auto;
  margin: 0 3% 7% 16%
}

.rate-vendor-start .how-it-works ol li:nth-child(2) img {
  min-height: 3.75rem
}

.rate-vendor-start .how-it-works ol li:nth-child(2) span {
  max-width: 42%
}

@media (max-width:479px) {
  .rate-vendor-start .how-it-works ol {
    padding: 1.25rem
  }
  .rate-vendor-start .how-it-works ol li {
    float: none;
    display: block;
    clear: both;
    max-width: 100%
  }
  .rate-vendor-start .how-it-works ol li:nth-child(2) span {
    margin-left: 2.375rem
  }
}

.write-review .avPhone {
  position: relative;
  z-index: 1;
  margin-bottom: 5px
}

.write-review textarea {
  padding-top: 7px
}

.addVendorProduct {
  text-align: center
}

select.step1AboutProductSelect {
  margin-top: 0
}

.onPage.rate-vendor-start .rate-vendor-steps .step-progress {
  line-height: 16px;
  margin: 1.875rem auto 0
}

.company-about .onPage .noVendor .addVendor,
.company-about .onPage .noVendor .or,
.onPage.rate-vendor-start .close-reveal-modal {
  display: none
}

#theModal.vrModal .close-reveal-modal {
  color: #fff
}

@media (min-width:768px) {
  #theModal.vrModal {
    max-width: 31.25rem;
    padding: 0
  }
  .welcomeModal {
    max-width: 36.25rem
  }
}

#theModal.vrModal h3.active {
  margin-top: 0
}

#theModal.vrModal label {
  position: relative;
  display: inline-block;
  font-weight: 500;
  margin-top: .625rem
}

#theModal.vrModal input[type=text],
#theModal.vrModal select {
  display: block;
  width: 100%
}

#theModal.vrModal .noVendor {
  display: block;
  clear: both
}

@media (max-width:767px) {
  #theModal.vrModal {
    padding: 0;
    margin: auto
  }
  #theModal.vrModal .write-review label {
    margin-top: 0
  }
  #theModal.vrModal.reveal-modal {
    min-height: auto
  }
}

@media (max-height:540px) and (min-width:768px) {
  #theModal.vrModal {
    box-sizing: border-box;
    padding-bottom: 60px
  }
  @supports (-ms-accelerator:true) {
    #theModal.vrModal .step-progress {
      padding-bottom: 50px
    }
  }
}

@media all and (max-height:540px) and (min-width:768px) and (-ms-high-contrast:none),
(max-height:540px) and (min-width:768px) and (-ms-high-contrast:active) {
  #theModal.vrModal .step-progress {
    padding-bottom: 50px
  }
}

.addAProduct,
.addProductHeading,
.addVendorHeading,
.addVendorStep1,
.addVendorStep2,
.addVendorStep3,
.rateVendorHeading,
.rateVendorStep1,
.rateVendorStep2,
.rateVendorStep3 {
  display: none
}

.addAProduct.active,
.addProductHeading.active,
.addVendorHeading.active,
.addVendorStep1.active,
.addVendorStep2.active,
.addVendorStep3.active,
.rateVendorHeading.active,
.rateVendorStep1.active,
.rateVendorStep2.active,
.rateVendorStep3.active {
  display: block
}

.minMaxWrapper {
  position: relative
}

.minMaxOutput {
  position: absolute;
  bottom: 0;
  right: .25rem;
  opacity: .75;
  font-size: .5625rem;
  line-height: .75rem;
  height: .75rem;
  padding: 0 .25rem;
  background: rgba(255, 255, 255, .5);
  border-radius: .25rem
}

.minMaxOutput.textareaType {
  bottom: .4375rem;
  right: 1.25rem
}

.addProductStep3 .minMaxOutput.textareaType {
  bottom: 0
}

.welcomeModal {
  overflow-y: visible;
  padding: 0
}

.welcomeModal .modal-content {
  position: relative;
  padding: 1.5625rem 1.875rem;
  color: #fff;
  background-color: #376caf;
  text-align: center
}

.welcomeModal .modal-content h3 {
  color: #fff;
  font-weight: 300
}

.welcomeModal .modal-content p,
.welcomeModal .modal-content ul {
  font-size: 1.25rem;
  font-weight: 100
}

.welcomeModal .modal-content .modalNav {
  position: absolute;
  top: 11.75rem;
  background-color: #fff;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4)
}

.welcomeModal .modal-content .modalNav.next {
  right: -3.75rem
}

.welcomeModal .modal-content .modalNav.next .fa {
  margin-left: .3125rem
}

.welcomeModal .modal-content .modalNav.prev {
  left: -3.75rem
}

.welcomeModal .modal-content .modalNav.prev .fa {
  margin-right: .3125rem
}

.welcomeModal .modal-content .modalNav .fa {
  color: #376caf;
  font-size: 1.875rem;
  line-height: 2.5rem
}

.welcomeModal .modal-footer {
  position: relative;
  padding: 1.25rem 1.875rem;
  background-color: #fff
}

.welcomeModal .modal-footer .progress-dots {
  float: none;
  display: block;
  max-width: 4.375rem;
  min-height: .625rem;
  margin: 0 auto;
  text-align: center;
  list-style: none
}

.welcomeModal .modal-footer .progress-dots li {
  float: left;
  border-radius: 50%;
  border: 1px solid #376caf;
  height: .625rem;
  width: .625rem;
  margin: 0 .125rem;
  line-height: 2.25rem
}

.welcomeModal .modal-footer .progress-dots li.filled {
  background-color: #376caf
}

.welcomeModal#welcomeOne .modal-content {
  color: #999;
  background-color: #fff;
  padding: 3.75rem 1.875rem 0
}

.welcomeModal#welcomeOne .modal-content h3 {
  color: #444
}

.welcomeModal#welcomeOne .modal-footer {
  padding: .625rem 1.875rem 1.875rem
}

.welcomeModal#welcomeThree p,
.welcomeModal#welcomeThree ul {
  text-align: left
}

.welcomeModal#welcomeThree ul {
  padding-left: 16px
}

.welcomeModal#welcomeThree ul.progress-dots {
  padding: 0
}

@media (max-width:767px) {
  .welcomeModal.reveal-modal {
    min-height: auto
  }
  .welcomeModal .modal-content {
    padding: 1.25rem 1.25rem .0625rem
  }
  .welcomeModal .modal-content .slide-icon {
    max-height: 3.4375rem
  }
  .welcomeModal .modal-content h3 {
    margin-top: .9375rem
  }
  .welcomeModal .modal-content p,
  .welcomeModal .modal-content ul {
    font-size: 1rem;
    font-weight: 300
  }
  .welcomeModal .modal-content .modalNav {
    top: auto;
    bottom: -55px;
    z-index: 2;
    background-color: #376caf
  }
  .welcomeModal .modal-content .modalNav.next {
    right: 1.25rem
  }
  .welcomeModal .modal-content .modalNav.prev {
    left: 1.25rem
  }
  .welcomeModal .modal-content .modalNav .fa {
    color: #fff
  }
  .welcomeModal .modal-footer {
    z-index: 1;
    padding: 1.875rem
  }
  .welcomeModal#welcomeOne .modal-content {
    padding: 1.5625rem 1.25rem 0
  }
  .welcomeModal#welcomeThree p {
    margin-bottom: 0
  }
}

.voteSummary.has-tip {
  border-bottom: 0;
  color: #376caf;
  cursor: pointer
}

.voteSummary.has-tip:hover {
  border-bottom: 0;
  color: #5da5ff;
  cursor: pointer
}

.who-likes .blue-header i.header-icon {
  font-size: 3rem
}

.who-likes .ppl-who-liked .person {
  clear: both;
  min-height: 1.875rem;
  line-height: 1.875rem;
  margin: .3125rem 0
}

.who-likes .ppl-who-liked .person:last-child {
  margin-bottom: 0
}

.who-likes .ppl-who-liked .person .pwl-photo {
  float: left;
  margin-right: .625rem
}

.who-likes .ppl-who-liked .person .pwl-name {
  float: left;
  font-weight: 400;
  font-size: 1.25rem
}

.who-likes .ppl-who-liked .person .pwl-button {
  float: right
}

@media (max-width:767px) and (max-width:479px) {
  .who-likes .ppl-who-liked .person .pwl-button {
    clear: both;
    float: none;
    width: 100%;
    text-align: right
  }
}

.who-likes .has-tip,
.who-likes .has-tip:hover {
  color: #fff;
  cursor: pointer
}

#theModal.reveal-modal .who-likes .blue-header {
  text-align: left;
  padding-left: 35px
}

.avatar.generic,
.docs-cropped .modal-body,
.img-container,
.img-preview,
.video-list {
  text-align: center
}

.orbit-container .orbit-next,
.orbit-container .orbit-prev {
  top: auto;
  bottom: 2px
}

.orbit-container .orbit-next {
  right: 2px
}

.orbit-container .orbit-prev {
  left: auto
}

@media (max-width:767px) {
  .who-likes .ppl-who-liked .person {
    margin: .9375rem 0
  }
  .orbit-container .orbit-prev {
    right: 40.32%
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption {
    max-width: 48%
  }
}

@media (max-width:479px) {
  .orbit-container .orbit-prev {
    right: 37%
  }
}

@media (min-width:768px) {
  .orbit-container .orbit-prev {
    right: 22.6%
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption {
    max-width: 28%
  }
}

@media (min-width:992px) {
  .orbit-container .orbit-prev {
    right: 22.5%
  }
}

@media (min-width:1200px) {
  .orbit-container .orbit-prev {
    right: 23.3%
  }
}

.orbit-container .orbit-bullets-container ol.orbit-bullets {
  margin-bottom: 1.6875rem
}

.orbit-container .orbit-bullets-container ol.orbit-bullets li {
  border: 1px solid #fff;
  background: 0 0
}

.orbit-container .orbit-bullets-container ol.orbit-bullets li.active {
  background: #fff
}

.owl-carousel {
  position: relative;
  z-index: 1
}

.owl-carousel .owl-controls .owl-nav {
  width: 100%;
  position: absolute;
  top: 25%;
  z-index: 2
}

.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-nav .owl-prev {
  font-size: 75px;
  font-weight: 100
}

.owl-carousel .owl-controls .owl-nav .owl-prev {
  float: left
}

.inline-video,
.owl-carousel .owl-controls .owl-nav .owl-next {
  float: right
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: auto;
  background-color: rgba(0, 0, 0, .5);
  font-size: 18px;
  font-weight: 300
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption h4,
.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption h4:hover {
  color: #fff
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption h4.exclusive {
  font-size: 14px;
  line-height: 18px;
  border-bottom: solid 2px rgba(255, 255, 255, .5)
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .post-tagline {
  font-size: smaller;
  color: #fff
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .post-tagline:hover {
  color: #fff
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain {
  overflow: hidden
}

@media (min-width:768px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain {
    max-height: 75%
  }
}

@media (min-width:1200px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain {
    max-height: 80%
  }
}

@media (max-width:767px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain {
    max-height: 64%
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain h4 {
    font-size: 16px
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain .post-tagline {
    font-size: 14px;
    line-height: 1.5em
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
    min-height: 15.9375rem
  }
}

@media (max-width:479px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain {
    max-height: 62%
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain h4 {
    font-size: 14px
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain h4.line-clamp {
    -webkit-line-clamp: 2;
    height: calc(1em * 1.2 * 2)
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain .post-tagline {
    font-size: 12px
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .orbit-contain .post-tagline.line-clamp {
    -webkit-line-clamp: 3;
    height: calc(1em * 1.5 * 3)
  }
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
    min-height: 11.25rem
  }
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .cta {
  display: block;
  clear: both;
  font-size: 11px;
  padding: .625rem 0 0;
  text-transform: uppercase
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .cta a {
  color: #fff
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li .orbit-caption .cta a:hover {
  color: #5da5ff
}

.home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
  border: 1px solid #e6e6e6;
  min-width: 100%
}

@media (min-width:768px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
    min-height: 24.875rem
  }
}

@media (min-width:992px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
    min-height: 22.25rem
  }
}

.home.hero-content .orbit-container .orbit-bullets-container {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  width: 100%
}

@media (max-width:767px) {
  .home.hero-content .orbit-container .orbit-bullets-container {
    max-width: 48%
  }
  .home.hero-content .orbit-container .orbit-bullets-container .orbit-bullets {
    display: block
  }
}

@media (min-width:768px) {
  .home.hero-content .orbit-container .orbit-bullets-container {
    max-width: 28%
  }
  .home.hero-content .orbit-container .orbit-bullets-container li {
    width: .75rem;
    height: .75rem
  }
}

@media (min-width:992px) {
  .home.hero-content .orbit-container .orbit-bullets-container li {
    width: .875rem;
    height: .875rem;
    margin-right: .5rem
  }
}

@media (min-width:1200px) {
  .home.hero-content .orbit-container ul.orbit-carousel.orbit-slides-container li.active img {
    min-height: 25.375rem
  }
  .home.hero-content .orbit-container .orbit-bullets-container li {
    width: 1rem;
    height: 1rem;
    margin-right: .625rem
  }
}

.home.hero-content .orbit-container .orbit-slide-number,
.home.hero-content .orbit-container .orbit-timer {
  display: none
}

@media (max-width:767px) and (max-width:479px) {
  .home.hero-content .orbit-container .orbit-bullets-container {
    bottom: -10px
  }
  .home.hero-content .orbit-container a.orbit-next,
  .home.hero-content .orbit-container a.orbit-prev {
    max-height: 2.5rem
  }
}

.featured-profiles {
  max-width: 85%;
  margin: 0 auto
}

.featured-profiles .owl-carousel .owl-nav .owl-prev {
  margin-left: -7%
}

.featured-profiles .owl-carousel .owl-nav .owl-next {
  margin-right: -7%
}

.featured-profiles .profile {
  text-align: center;
  line-height: 1.125rem
}

.featured-profiles .profile div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.featured-profiles .profile .profile-name {
  color: #376caf;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.featured-profiles .profile .profile-company,
.featured-profiles .profile .profile-job {
  font-weight: 300;
  font-size: .875rem;
  line-height: 1.125rem
}

.featured-profiles .profile .profile-job {
  color: #444
}

.featured-profiles .profile .profile-company {
  color: #999
}

.featured-profiles .profile .btn.btn-connect,
.featured-profiles .profile .reveal-modal .btn-connect.close-reveal-modal.btn-close,
.reveal-modal .featured-profiles .profile .btn-connect.close-reveal-modal.btn-close {
  margin-top: .625rem
}

.gold-company-slider .owl-carousel,
.vr-cat-slider.owl-carousel {
  margin: 2.25rem auto .875rem;
  max-width: 90%;
  position: relative
}

@media (max-width:479px),
(max-width:767px) {
  .gold-company-slider .owl-carousel,
  .vr-cat-slider.owl-carousel {
    margin: 1.25rem auto
  }
}

.gold-company-slider .owl-carousel .category-caption,
.gold-company-slider .owl-carousel .vr-cat,
.vr-cat-slider.owl-carousel .category-caption,
.vr-cat-slider.owl-carousel .vr-cat {
  position: relative
}

.gold-company-slider .owl-carousel .category-caption img,
.gold-company-slider .owl-carousel .vr-cat img,
.vr-cat-slider.owl-carousel .category-caption img,
.vr-cat-slider.owl-carousel .vr-cat img {
  position: relative;
  width: 100%
}

.gold-company-slider .owl-carousel .category-caption .label,
.gold-company-slider .owl-carousel .vr-cat .label,
.vr-cat-slider.owl-carousel .category-caption .label,
.vr-cat-slider.owl-carousel .vr-cat .label {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(55, 55, 55, .5);
  color: #fff;
  font-weight: 300;
  line-height: 2.8125rem;
  padding-left: .9375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

@media (max-width:767px) {
  .home.hero-content .orbit-container a.orbit-next,
  .home.hero-content .orbit-container a.orbit-prev {
    display: block
  }
  .home.hero-content .orbit-container a.orbit-next:focus,
  .home.hero-content .orbit-container a.orbit-prev:focus {
    outline: 0
  }
  .gold-company-slider .owl-carousel .category-caption .label,
  .gold-company-slider .owl-carousel .vr-cat .label,
  .vr-cat-slider.owl-carousel .category-caption .label,
  .vr-cat-slider.owl-carousel .vr-cat .label {
    font-size: .75rem;
    font-weight: 400
  }
}

@media (min-width:768px) {
  .gold-company-slider .owl-carousel .category-caption .label,
  .gold-company-slider .owl-carousel .vr-cat .label,
  .vr-cat-slider.owl-carousel .category-caption .label,
  .vr-cat-slider.owl-carousel .vr-cat .label {
    font-size: 1rem
  }
  .vr-cat-slider.owl-carousel {
    max-width: 100%
  }
}

@media (min-width:1200px) {
  .gold-company-slider .owl-carousel .category-caption .label,
  .gold-company-slider .owl-carousel .vr-cat .label,
  .vr-cat-slider.owl-carousel .category-caption .label,
  .vr-cat-slider.owl-carousel .vr-cat .label {
    font-size: 1.125rem
  }
}

@media (max-width:479px) {
  .gold-company-slider .owl-carousel .category-caption .label,
  .gold-company-slider .owl-carousel .vr-cat .label,
  .vr-cat-slider.owl-carousel .category-caption .label,
  .vr-cat-slider.owl-carousel .vr-cat .label {
    text-align: center;
    padding: 0 .3125rem;
    line-height: 1.875rem
  }
  .gold-company-slider .owl-carousel .category-caption .label .material-icons,
  .gold-company-slider .owl-carousel .vr-cat .label .material-icons,
  .vr-cat-slider.owl-carousel .category-caption .label .material-icons,
  .vr-cat-slider.owl-carousel .vr-cat .label .material-icons {
    display: none
  }
  .gold-company-slider .owl-carousel .category-caption,
  .vr-cat-slider.owl-carousel .category-caption {
    height: 13.75rem;
    overflow: hidden
  }
}

@media only screen {
  .gold-company-slider .owl-carousel .category-caption,
  .vr-cat-slider.owl-carousel .category-caption {
    height: 12.5rem;
    overflow: hidden
  }
}

@media (min-width:992px) {
  .gold-company-slider .owl-carousel .category-caption,
  .vr-cat-slider.owl-carousel .category-caption {
    height: 9.375rem;
    overflow: hidden
  }
  .gold-company-slider .owl-carousel .category-caption .label {
    padding-left: .3125rem
  }
}

.gold-company-slider .owl-carousel .owl-nav,
.vr-cat-slider.owl-carousel .owl-nav {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2
}

.gold-company-slider .owl-carousel .owl-nav div,
.vr-cat-slider.owl-carousel .owl-nav div {
  height: 100%;
  padding-top: 5%
}

.gold-company-slider .owl-carousel .owl-nav div.owl-prev,
.vr-cat-slider.owl-carousel .owl-nav div.owl-prev {
  margin-left: -4%
}

.gold-company-slider .owl-carousel .owl-nav div.owl-next,
.vr-cat-slider.owl-carousel .owl-nav div.owl-next {
  margin-right: -4%
}

@media (max-width:479px) {
  .gold-company-slider .owl-carousel .owl-nav div,
  .vr-cat-slider.owl-carousel .owl-nav div {
    padding-top: 7%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-prev,
  .vr-cat-slider.owl-carousel .owl-nav div.owl-prev {
    margin-left: -8%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-next,
  .vr-cat-slider.owl-carousel .owl-nav div.owl-next {
    margin-right: -8%
  }
}

@media (max-width:767px),
(max-width:479px) {
  .gold-company-slider {
    clear: both
  }
}

@media (min-width:768px) and (max-width:991px) {
  .gold-company-slider .owl-carousel {
    max-width: 85%
  }
  .gold-company-slider .owl-carousel .owl-nav div {
    padding-top: 20%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-prev {
    margin-left: -8%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-next {
    margin-right: -8%
  }
}

@media (max-width:767px) {
  .gold-company-slider .owl-carousel .owl-nav div {
    padding-top: 10%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-prev {
    margin-left: -5%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-next {
    margin-right: -5%
  }
}

@media (max-width:479px) {
  .gold-company-slider .owl-carousel {
    max-width: 85%
  }
  .gold-company-slider .owl-carousel .owl-nav div {
    padding-top: 20%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-prev {
    margin-left: -10%
  }
  .gold-company-slider .owl-carousel .owl-nav div.owl-next {
    margin-right: -10%
  }
}

.docs-cropped .modal-body>canvas,
.docs-cropped .modal-body>img,
.img-container>img,
.img-preview>img {
  max-width: 100%
}

.owl-stage-outer {
  z-index: 50
}

.owl-item {
  cursor: pointer
}

.reveal-modal .video-list .btn-sm.close-reveal-modal.btn-close,
.video-list .btn.btn-sm,
.video-list .reveal-modal .btn-sm.close-reveal-modal.btn-close {
  margin: auto;
  padding-right: 8px;
  padding-left: 8px
}

.wiki-company-slider {
  overflow: hidden;
  margin-top: 1.875rem
}

.wiki-company-slider .not-provided {
  width: 300%;
  display: inline-block
}

.wiki-company-slider .not-provided img {
  width: 12.5rem;
  margin-right: 1.25rem
}

.wiki-company-slider .not-provided img.last {
  margin-right: none
}

@media (min-width:768px) and (max-width:991px) {
  .wiki-company-slider .not-provided img.last {
    display: none
  }
}

.docs-options .dropdown-menu>li>label,
.docs-tooltip {
  display: block
}

.img-container,
.img-preview {
  background-color: #f7f7f7;
  width: 100%;
  overflow: hidden;
  border: 1px solid #e6e6e6
}

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin: 0 auto 20px;
  width: 100%
}

.docs-data>.input-group,
.docs-toggles>.btn,
.docs-toggles>.btn-group,
.docs-toggles>.dropdown,
.img-preview,
.reveal-modal .docs-toggles>.close-reveal-modal.btn-close {
  margin-bottom: 10px
}

.docs-preview {
  margin-right: -15px
}

.img-preview {
  float: left;
  margin-right: 10px
}

.preview-lg {
  width: 263px;
  height: 148px
}

.preview-md {
  width: 139px;
  height: 78px
}

.preview-sm {
  width: 69px;
  height: 39px
}

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0
}

.docs-data>.input-group>label {
  min-width: 80px
}

.docs-data>.input-group>span {
  min-width: 50px
}

.docs-buttons>.btn,
.docs-buttons>.btn-group,
.docs-buttons>.form-control,
.reveal-modal .docs-buttons>.close-reveal-modal.btn-close {
  margin-right: 5px;
  margin-bottom: 10px
}

.docs-tooltip {
  margin: -6px -12px;
  padding: 6px 12px
}

.docs-tooltip>.icon {
  margin: 0 -3px;
  vertical-align: top
}

.tooltip-inner {
  white-space: normal
}

.btn-toggle .tooltip-inner,
.btn-upload .tooltip-inner {
  white-space: nowrap
}

.btn-toggle {
  padding: 6px
}

.btn-toggle>.docs-tooltip {
  margin: -6px;
  padding: 6px
}

@media (max-width:400px) {
  .btn-group-crop .docs-tooltip,
  .btn-group-crop>.btn,
  .reveal-modal .btn-group-crop>.close-reveal-modal.btn-close {
    padding-left: 5px;
    padding-right: 5px
  }
  .btn-group-crop {
    margin-right: -15px!important
  }
  .btn-group-crop .docs-tooltip {
    margin-left: -5px;
    margin-right: -5px
  }
}

.docs-options .dropdown-menu {
  width: 100%
}

.docs-options .dropdown-menu>li {
  padding: 3px 20px
}

.docs-options .dropdown-menu>li:hover {
  background-color: #f7f7f7
}

.docs-diagram .modal-dialog {
  max-width: 352px
}

.docs-footer {
  margin-top: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #0066bf;
  background-color: #0074d9;
  color: #ddd
}

.docs-footer a {
  color: #eee
}

.docs-footer a:focus,
.docs-footer a:hover {
  color: #fff
}

.docs-footer a+a {
  margin-left: 10px
}

@media (min-width:768px) {
  .back-to-top {
    float: right
  }
}

.cropping-modal .tooltip {
  width: auto
}

.cropping-modal .tooltip.fade {
  background-color: transparent
}

@media (min-width:992px) {
  .cropper-modal-sm .modal-dialog {
    width: 53.125rem
  }
  .cropper-modal-sm .modal-dialog .modal-body {
    width: 100%
  }
  .cropper-modal-sm .modal-dialog .modal-body .img-container {
    float: left;
    width: 100%;
    margin-right: .625rem
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-preview {
    display: inline-block;
    max-width: 16.4375rem;
    margin: 0
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-buttons {
    display: inline-block
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-buttons .advanced-options .btn-group {
    margin-top: .3125rem
  }
  .cropper-modal-sm .modal-dialog .modal-body .preview-btns .btn-group {
    clear: left
  }
  .cropper-modal-sm .modal-dialog .modal-body .save-close {
    clear: left;
    float: right
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  .cropper-modal-sm .modal-dialog .modal-body {
    width: 100%
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-preview {
    float: left;
    max-width: 50%;
    margin: 0
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-buttons {
    display: inline-block;
    max-width: 50%
  }
  .cropper-modal-sm .modal-dialog .modal-body .docs-buttons .advanced-options .btn-group {
    margin-bottom: .3125rem
  }
  .cropper-modal-sm .modal-dialog .modal-body .preview-btns .btn-group {
    clear: left
  }
  .cropper-modal-sm .modal-dialog .modal-body .save-close {
    margin-top: 1.5625rem
  }
}

@media (min-width:768px) and (max-width:991px) and (max-width:600px),
(max-width:767px) and (max-width:600px) {
  .cropper-modal-sm .modal-dialog .modal-body .docs-buttons,
  .cropper-modal-sm .modal-dialog .modal-body .docs-preview {
    display: block;
    clear: both;
    max-width: 100%
  }
  .cropper-modal-sm .modal-dialog .modal-body .preview-btns {
    display: none
  }
}

@media (min-width:992px) {
  .cropper-modal-lg .modal-xlg {
    width: 1120px;
    height: 900px
  }
  .cropper-modal-cover .modal-dialog {
    width: 1200px;
    height: 400px
  }
  .cropper-modal-cover .modal-dialog .modal-body {
    width: 100%
  }
  .cropper-modal-cover .modal-dialog .modal-body .img-container {
    float: left;
    width: 1146px;
    margin-right: 10px;
    min-height: 300px
  }
  .cropper-modal-cover .modal-dialog .modal-body .row {
    max-width: 1146px
  }
  .cropper-modal-cover .modal-dialog .modal-body .docs-preview {
    display: inline-block;
    max-width: 16.4375rem;
    margin: 0
  }
  .cropper-modal-cover .modal-dialog .modal-body .docs-buttons {
    display: inline-block
  }
  .cropper-modal-cover .modal-dialog .modal-body .docs-buttons .advanced-options .btn-group {
    margin-top: .3125rem
  }
  .cropper-modal-cover .modal-dialog .modal-body .preview-btns .btn-group {
    clear: left
  }
  .cropper-modal-cover .modal-dialog .modal-body .save-close {
    clear: left;
    float: right
  }
}

.img-circle {
  border-radius: 50%
}

.avatar,
img.avatar {
  width: 2.5rem;
  height: 2.5rem
}

.avatar.xsmall,
img.avatar.xsmall {
  width: 1.875rem;
  height: 1.875rem
}

.avatar.small,
img.avatar.small {
  width: 2.3125rem;
  height: 2.3125rem
}

.avatar.medium,
img.avatar.medium {
  width: 4.0625rem;
  height: 4.0625rem
}

.avatar.large,
img.avatar.large {
  width: 5.8125rem;
  height: 5.8125rem
}

.avatar.silver,
img.avatar.silver {
  width: 3.25rem;
  height: 3.25rem
}

.avatar.silver.xsmall,
img.avatar.silver.xsmall {
  width: 2.625rem;
  height: 2.625rem
}

.avatar.silver.small,
img.avatar.silver.small {
  width: 3.0625rem;
  height: 3.0625rem
}

.avatar.silver.medium,
img.avatar.silver.medium {
  width: 4.8125rem;
  height: 4.8125rem
}

.avatar.silver.large,
img.avatar.silver.large {
  width: 6.5625rem;
  height: 6.5625rem
}

.avatar.gold,
img.avatar.gold {
  width: 4rem;
  height: 4rem
}

.avatar.gold.xsmall,
img.avatar.gold.xsmall {
  width: 3.375rem;
  height: 3.375rem
}

.avatar.gold.small,
img.avatar.gold.small {
  width: 3.8125rem;
  height: 3.8125rem
}

.avatar.gold.medium,
img.avatar.gold.medium {
  width: 5.5625rem;
  height: 5.5625rem
}

.avatar.gold.large,
img.avatar.gold.large {
  width: 7.3125rem;
  height: 7.3125rem
}

.company-avatar .avatar,
.company-logo .avatar {
  display: block;
  border: 1px solid #e6e6e6
}

.blogs-landing .exclusive-blogs .headline-border,
.blogs-landing .featured-post .headline-border,
.blogs-landing .featured-post .headline-border.sometimes,
.featured-members.hb-first .headline-border.sometimes,
.profile-activity .headline-border.sometimes {
  border-top-color: transparent
}

.avatar.generic {
  color: #fff
}

.avatar.generic .icon {
  font-size: 1.5rem;
  line-height: 2.5rem
}

.avatar.generic.xsmall .icon {
  font-size: 1.125rem;
  line-height: 1.875rem
}

.avatar.generic.small .icon {
  line-height: 37px
}

.avatar.generic.medium .icon {
  font-size: 2.5rem;
  line-height: 4.0625rem
}

.avatar.generic.large .icon {
  font-size: 3.75rem;
  line-height: 5.8125rem
}

.avatar.generic.red {
  background-color: #ff5018
}

.avatar.generic.orange {
  background-color: #cc6500
}

.avatar.generic.yellow {
  background-color: #fddd1c;
  color: #444
}

.avatar.generic.green {
  background-color: #0a9e02
}

.avatar.generic.blue {
  background-color: #376caf
}

.avatar.generic.gray {
  background-color: #636468
}

.avatar .combined-icons .material-icons {
  font-size: 1.5rem;
  line-height: 1.25rem;
  width: 1.5rem;
  height: 1.5rem
}

.avatar .combined-icons .material-icons.create {
  margin-left: -1.25rem
}

.avatar.xsmall .combined-icons .material-icons {
  font-size: 1rem;
  line-height: 1.25rem;
  width: 1rem;
  height: 1rem
}

.avatar.xsmall .combined-icons .material-icons.create {
  margin-left: -.625rem
}

.avatar.small .combined-icons .material-icons {
  font-size: 1.375rem;
  line-height: 1.25rem;
  width: 1.375rem;
  height: 1.375rem
}

.avatar.small .combined-icons .material-icons.create {
  margin-left: -1.25rem
}

.avatar.medium .combined-icons .material-icons {
  font-size: 2.5rem;
  line-height: 2.1875rem;
  width: 2.5rem;
  height: 2.5rem
}

.avatar.medium .combined-icons .material-icons.create {
  margin-left: -2.1875rem
}

.avatar.large .combined-icons .material-icons {
  font-size: 3.75rem;
  line-height: 3.125rem;
  width: 3.75rem;
  height: 3.75rem
}

.avatar.large .combined-icons .material-icons.create {
  margin-left: -3.4375rem
}

.avatar-status-widget {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden
}

.avatar-status-widget img.avatar {
  width: auto;
  height: 100%;
  padding: 3px;
  z-index: 1
}

.avatar-status-widget.xsmall {
  width: 1.875rem;
  height: 1.875rem
}

.avatar-status-widget.xsmall img.avatar {
  padding: 2px
}

.avatar-status-widget.small {
  width: 2.3125rem;
  height: 2.3125rem
}

.avatar-status-widget.small img.avatar {
  padding: 3px
}

.avatar-status-widget.medium {
  width: 4.0625rem;
  height: 4.0625rem
}

.avatar-status-widget.medium img.avatar {
  padding: 4px
}

.avatar-status-widget.large {
  width: 5.8125rem;
  height: 5.8125rem
}

.avatar-status-widget.large img.avatar {
  padding: 5px
}

.avatar-status-widget .status-level {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2
}

.avatar-status-widget.contributor .status-level {
  background-image: url(../images/avatars/status-bronze.png)
}

.avatar-status-widget.senior-contributor .status-level {
  background-image: url(../images/avatars/status-silver.png)
}

.avatar-status-widget.master .status-level {
  background-image: url(../images/avatars/status-gold.png)
}

.avatar-status-widget.user-avatar {
  display: block;
  margin: 0 auto
}

.avatar-status-widget.user-avatar .status-level,
.avatar-status-widget.user-avatar img.avatar {
  display: block
}

@media (min-width:768px) and (max-width:991px),
(max-width:767px) {
  span.hide-for-large-up {
    display: inline!important
  }
}

@media (min-width:768px) {
  .avatar-status-widget img.avatar {
    position: absolute
  }
  span.hide-for-small-only {
    display: inline!important
  }
  .home.main-content {
    position: relative
  }
  .home.main-content .content-column-left {
    float: left;
    clear: left;
    margin-right: 3.4%
  }
  .home.main-content .content-column-right {
    float: right;
    clear: right;
    position: relative;
    top: -250px
  }
  .home.main-content .content-column-left,
  .home.main-content .content-column-right {
    width: 48.3%
  }
  .home.main-content .content-column-left .home-content.column1,
  .home.main-content .content-column-left .home-content.column2,
  .home.main-content .content-column-right .home-content.column1,
  .home.main-content .content-column-right .home-content.column2 {
    width: 100%
  }
  .home.main-content .content-column-left .home-content.column1,
  .home.main-content .content-column-right .home-content.column1 {
    margin-right: 0
  }
  .vendor-home.main-content>.content-column-left {
    float: left;
    clear: left;
    width: 32.2%;
    margin-right: 3.4%
  }
  .vendor-home.main-content>.content-column-left .column-left {
    width: 100%
  }
  .vendor-home.main-content>.content-column-right {
    float: right;
    clear: right;
    position: relative;
    top: 0;
    width: 64.4%
  }
  .vendor-home.main-content>.content-column-right .column-right {
    width: 100%
  }
}

.vendor-home.main-content,
.vendor-home.main-content .column-left,
.vendor-home.main-content .column-right {
  position: relative
}

@media (min-width:768px) and (min-width:768px) and (max-width:991px) {
  .vendor-home.main-content>.content-column-left,
  .vendor-home.main-content>.content-column-right {
    width: 48.3%
  }
}

.forums .content-column-left {
  float: left
}

.forums .content-column-right {
  float: right
}

.checkbox+.checkbox,
.radio+.radio {
  margin-top: 0
}

.checkbox label,
.radio label {
  padding-left: 0;
  margin-bottom: auto
}

@media (min-width:768px) and (max-width:991px) {
  .companyPages .container,
  .profile-blog .container {
    max-width: 45rem
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .home.main-content .headline-border {
    font-size: 24px
  }
  .companyPages .container,
  .profile-blog .container {
    max-width: 58.75rem
  }
}

@media (min-width:1200px) {
  .companyPages .container,
  .profile-blog .container {
    max-width: 69.6875rem
  }
}

.companyPages .container .container,
.profile-blog .container .container {
  padding: 0
}

@media (min-width:768px) {
  #theModal.vrModal {
    top: 6.25rem!important
  }
  .blogs-landing .featured-members .headline-border.sometimes,
  .buyers-guide.hb-mobile .headline-border.sometimes,
  .connections.suggested .headline-border.sometimes,
  .dept .headline-border.sometimes,
  .ds-feed .featured-post .headline-border.sometimes,
  .ds-home .headline-border.sometimes,
  .featured-members.hb-mobile .headline-border.sometimes {
    border-top-color: transparent
  }
}

.blogs-landing .featured-post+.exclusive-blogs .headline-border {
  border-top-color: #636468
}

@media (min-width:768px) {
  .profiles-landing .exclusive-blogs .headline-border {
    border-top-color: transparent
  }
  .profiles-landing .featured-members.hb-mobile+.featured-members+.exclusive-blogs .headline-border {
    border-top-color: #636468
  }
}

@media (max-width:767px) {
  span.show-for-small-only {
    display: inline!important
  }
  #theModal.vrModal {
    top: 1%!important
  }
  .blog.add-post .section.four .headline-border,
  .blog.add-post .section.three .headline-border,
  .blog.edit-post .section.four .headline-border,
  .blog.edit-post .section.three .headline-border {
    margin-top: 1.875rem
  }
}

@media (min-width:992px) {
  span.show-for-large-up {
    display: inline!important
  }
  .takeover-bg .home.hero-content .sidekick-ads img {
    border: 1px solid #e6e6e6!important
  }
}

@media (min-width:768px) and (max-width:991px),
(max-width:479px) {
  .vr-company-list.pagination-content {
    margin-left: 11%
  }
  .vr-company-list.pagination-content .product-summary {
    max-width: 18.75rem
  }
  .vr-company-list.pagination-content .product-summary .product-content,
  .vr-company-list.pagination-content .product-summary .product-footer {
    clear: none
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px){

  .abtext{
    float: none !important;
    position: relative;
    top: 0px !important;
  }

  .newss {
    position: relative !important;
    right: 0;
    left: 0;
    text-align: center;
    margin: auto !important;
    display: block;
    width: auto !important;
  }
  header.top_bar li a {
    font-size: 12px !important;
    color: #fff;
  }
  #noticemsg .close-reveal-modal {
    color: #AAA !important;
    cursor: pointer;
    font-size: 2.5rem !important;
    font-weight: 100;
    line-height: 1;
    position: absolute;
    top: -11px !important;
    right: .625rem !important;
  }
  .msg-text a {
    color: #376caf !important;
  }

}



.abtext {
  float: left;
  position: relative;
  top: 23px;
}
.newss {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  margin: auto !important;
  display: block;
  width: 20%;
}
