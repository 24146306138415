/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * input-sliders.scss
 * =========================================================================
 * 
 * Read more: http://foundation.zurb.com/docs/components/range_slider.html
 *			- http://jqueryui.com/slider/#range
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



/* --- Sliders --- */
.range-slider {
	height: rem-calc(7px);
	border-color: #b4c1c8;
	background-color: #eceff1;
	// max-width: 98%;
	// margin-left: 1%;
	&.round { background-color: #eceff1; }

	// Round Handle Shape
	&.round .range-slider-handle {
		border-radius: 50%;
		width: rem-calc(22px);
		height: rem-calc(22px);
		top: rem-calc(-8.48);
		&:focus, &:active { outline: none; }
	}

	//Handle Color
	&.round .range-slider-handle,
	.range-slider-handle {
		background-color: $blue-default;
		opacity: 1;
		&:hover { background-color: $blue-default; }
	}

	//Active segment/slider-bar
	.range-slider-active-segment {
		height: rem-calc(6px);
		background-color: $blue-default;
	}

	//Disabled styles
	&.disabled .range-slider-handle,
	&[disabled] .range-slider-handle {
		background: $switch-off;
		cursor: not-allowed;
		opacity: 1;
		&:hover { background-color: $switch-off; }
	}
}
.round.range-slider[data-slider='0'] .range-slider-handle { left: rem-calc(-9px); }
.round.range-slider[data-slider='100'] .range-slider-handle { left: rem-calc(9px); }

/* --- Progress Bar --- */
.progress.recommend-rating {
	margin-left: rem-calc(3px);
	max-width: rem-calc(125px);
	.meter { background-color: $vendor-orange; }
}