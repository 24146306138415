/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * profiles-company.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Company Pages
 *   - Gold
 *   - Silver
 *   - Wiki
 * + Company Product Pages
 *   - Overview Tab (Used for Buyer's Guides, too)
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Company Pages-----------------------------------------------------------*/
.companyPages {
    .company-at-a-glance {
        margin-bottom: rem-calc(20px);
        p:nth-last-of-type(1) {
            margin-bottom: 0;
        }
        h2 {
            color: $gray3;
            font-weight: bold;
            font-size: rem-calc(36px);
            line-height: rem-calc(36px);
            .has-products {
                font-weight: 400;
                font-size: .67em;//rem-calc(24px);
            }
        }
        .company-averages {
            font-weight: 300;
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);
            .label { font-size: rem-calc(22px); }
            .star-ratings-sprite {
                display: inline-block;
                margin-bottom: -3px;
            }
            .recommended {
                .full { display: inherit; }
                .abbrev { display: none; }
            }
        }
        @media #{$medium-only}, #{$xsmall-only} {
            h2 .has-products { display: block; }
            .recommended {
                display: inline-block;
                clear: left;
            }
        }
        @media #{$small-only} {
            .company-averages {
                font-size: rem-calc(16px);
                line-height: rem-calc(24px);
                .recommended {
                    .full { display: none; }
                    .abbrev { display: inherit; }
                }
            }
        }//END media:small
        @media #{$xlarge-up} {
            .company-averages {
                font-size: rem-calc(18px);
                .label { font-size: rem-calc(24px); }
            }
        }//END media:xlarge
    }
    .company-blurb {
        font-weight: 300;
        @media #{$small-only}, #{$medium-only} {
            $font-size: 16px;
            $line-height: 1.5; //24px
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            margin: 0 auto;
            font-size: $font-size;
            line-height: $line-height;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            &.long {
                height: auto;
                -webkit-line-clamp: initial;
                overflow: auto;
                text-overflow: initial;
            }
        }//END media:small

        .inline-ad {
            float: right;
            margin-left: rem-calc(15px);
        }
        @media #{$medium-only}, (max-width: 550px) {
            .inline-ad {
                float: none;
                margin-left: 0;
                margin-bottom: rem-calc(15px);
            }
        }
        p:nth-last-of-type(1) {
            margin-bottom: 0;
        }
        .side-column-ad {
            margin-bottom: rem-calc(20px);
            margin-top: 0;
            @media #{$large-up} {
                float: right;
                margin-left: rem-calc(20px);
            }
        }
    }//END .companay-blurb
    .expand-description { display: none; }
    @media #{$small-only} {
        .expand-description { display: block; }
    }

    .edit-company, .edit-product {
        color: $gray6b;
        font-weight: 300;
        font-size: rem-calc(14px);
        margin-top: rem-calc(20px);
        .last-edit { font-style: italic; }
        .edit-this {
            display: inline-block;
            float: right;
        }
        a {
            font-weight: 400;
            font-style: normal;
        }
        .more-arrow, .load-more {
            width: 40%;
            display: inline-block;
            float: right;
            vertical-align: middle;
            margin-top: 0;
        }
        @media #{$xsmall-only}, #{$medium-only} {
            .more-arrow, .load-more {
                width: 100%;
            }
            .last-edit { float:right; }
        }
        @media #{$small-only} {
            .more-arrow, .load-more {
                width: 100%;
            }
            .last-edit { float:right; }
        }
    }

    @media #{$large-up} {
        .product-listing {
            .left-content {
                float: left;
                display: block;
                width: 48.3%;
                margin-right: 3.4%;
            }
            .right-content { margin: -20px 0 0; }
        }
    }

    //Contact Info
    .contact-form {
        position: relative;
        textarea, input[type="text"],
        .form-element-required { width: 100%; }
        .btn-align-right {
            width: 100%;
            text-align: right;
            .btn-submit { min-width: rem-calc(136px); }
        }
    }
    .contact-block {
        margin-bottom: rem-calc(30px);
    }
    .claim-yourself {
        display: inline-block;
        clear: right;
        border: none;
        font-style: italic;
        font-size: rem-calc(16px);
        &.alert-box.info { color: $gray4; }
        a { font-weight: 300; }
        @media #{$xsmall-only} {
            display: none !important;
        }
        @media #{$small-only} {
            display: none !important;
        }
    }
    @media #{$small-only} {
        .company-contact {
            .left-content {
                float: left;
                display: block;
                width: 48.3%;
                margin-right: 3.4%;
            }
            .right-content {
                display: inline-block;
                width: 48.3%;
            }
            .contact-form {
                textarea, input[type="text"],
                .form-element-required,
                .btn-align-right { max-width: 100%; }
            }
            @media #{$xsmall-only} {
                .left-content,
                .right-content,
                .btn-submit {
                    float: none;
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .headline-border .recent { display:none;}
    @media #{$large-up} {
        .headline-border .recent {display:inline-block;};
    }

    .company-banner-ad {
        margin-top: rem-calc(30px);
        text-align: center;
    }

//Gold
    &.gold {
        .show-silver, .show-wiki { display: none; }
        .show-gold { display: inherit; }
    }
//Silver
    &.silver {
        .show-gold, .show-wiki { display: none; }
        .show-silver { display: inherit; }
    }
//Wiki
    &.wiki {
        .show-gold, .show-silver, .hide-wiki { display: none; }
        .show-wiki { display: inherit; }
    }
}
.claim-page {
    text-align: right;
    font-size: rem-calc(13px);
    font-style: italic;
    a {
        font-style: normal;
        font-weight: 400;
    }
    @media #{$medium-only} {
        text-align: left;
    }
    @media #{$medium-only}, (max-width: 550px) {
        text-align: left;
    }
    @media #{$xsmall-only} {
        text-align: left;
    }
}

.expand-description {
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 1;
    &:before {
        border-top: 1px solid $grey6;
        content:"";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }
    .expand-more, .expand-less {
        cursor: pointer;
        font-size: rem-calc(12px);
        background-color: #fff;
        color: $gray3;
        .blue { color: $main-blue; }
    }

    .expand-more { display: inline; }
    .expand-less { display: none; }
    &.advanced {
        .expand-more { display: none; }
        .expand-less { display: inline; }
    }
}
/*-----------------------------------------------------------Company Pages-*/

/*-Company Product Pages---------------------------------------------------*/
.companyPages.company-products {
    &.gold, &.silver {
        .headline-border.product-summary-tab + .headline-border { display: none; }
        @media #{$small-only} {
            ul#productTabs + .tabs-content.productTabs {
                .content.active { padding: rem-calc(15); }
            }
        }
    }//END .gold .silver
    &.wiki {
        .headline-border.product-summary-tab {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
            border-bottom: solid 1px #444444;
            + .headline-border { display: none; }
        }
    }
    #panel-ratings h3.headline-border { border-top-color: transparent; }

    .noVendor {
        display: block;
        margin-top: rem-calc(5px);
        text-align: right;
        font-size: rem-calc(14px);
        line-height: rem-calc(24px);
        font-weight: 300;
        a { font-weight: 400; }
    }//END .noVendor
}

#productTabs {
    margin-top: rem-calc(20px);
}

ul#productTabs + .tabs-content.productTabs {
    h3 {
        .btn { float: right; }
        &.headline-border { clear: both; }
    }
}

/* --- Ratings Breakdown --- */
h4.by-line { margin-top: rem-calc(30px); }
.company-at-a-glance.review,
.ratings-breakdown {
    display: block;
    clear: both;
    .read-more {
        clear: both;
        font-weight: 300;
    }
    .average-stars {
        clear: left;
        float: left;
        color: $vendor-orange;
        font-size: rem-calc(60px);
        line-height: rem-calc(60px);
    }
    .star-rating { float: left; }
    .stars-breakdown {
        clear: left;
        float: left;
        padding-top: 10px;
    }
    .more-arrow {
        margin-top: 0;
        padding-top: rem-calc(15px);
    }
    .recommended-stars { clear: left; }
    @media #{$large-up} {
        .star-rating { margin: rem-calc(10px 20px 0px); }
        .byWho {
            float: right;
            max-width: 50%;
        }
    }//END media:medium
    @media #{$large-up} {
        .btn-orange {
            float: right;
            margin-top: rem-calc(15px);
            &.mobile { display: none; }
        }
        .stars-breakdown {
            margin-left: 90px;
            max-width: calc(50% - 90px);
        }
    }//END media:large-up
    @media #{$xlarge-up} {
        .stars-breakdown {
            margin-left: 100px;
            max-width: calc(50% - 100px);
        }
    }//END media:xlarge-up
    @media #{$small-only} {
        .star-rating { margin: rem-calc(10px 20px 0px); }
        @media (min-width: 611px) {
            .btn-orange {
                float: right;
                margin-top: rem-calc(15px);
                &.mobile { display: none; }
            }
            .stars-breakdown {
                margin-left: 90px;
                max-width: calc(50% - 90px);
            }
            .byWho {
                float: right;
                max-width: 50%;
            }
        }
        @media (max-width: 610px) and (min-width: 480px) {
            #reviewChart, .recommended-stars { text-align: center; }
            .btn-orange {
                float: left;
                &.desktop { display: none; }
            }
            .stars-breakdown {
                margin-left: 100px;
                max-width: calc(100% - 100px);
            }
            .byWho {
                float: none;
                clear: left;
                max-width: 100%;
            }
        }//END media

    }//END media:small
    @media #{$medium-only}, #{$xsmall-only} {
        #reviewChart,
        .recommended-stars { text-align: center; }
        .btn-orange {
            float: left;
            &.desktop { display: none; }
        }
        .average-stars {
            float: none;
            display: inline-block;
            + .star-rating {
                float: none;
                display: inline-block;
                margin: rem-calc(10px 0px 0px 10px);
                + .star-rating {
                    clear: both;
                    width: 100%;
                    margin: rem-calc(5px 0px 0px 0px);
                    br { display: none; }
                    .dealerships-ratings {
                        display: inline-block;
                        width: 49%;
                        &:last-child { text-align: right; }
                    }//END .dealerships-ratings
                }//END .star-rating .star-rating
            }//END .star-rating
        }//END .average-stars
        .stars-breakdown {
            margin-left: 90px;
            width: calc(100% - 90px);
        }
        .more-arrow {
            .cview-pagination { font-size: rem-calc(12px); }
            .btn-orange {
                margin-top: 0;
                svg {
                    width: rem-calc(12px);
                    height: rem-calc(12px);
                }
            }
        }
    }//END media:medium,xsmall
}//END .ratings-breakdown

.rate-summary {
    position:relative;
    font-size: rem-calc(16px);
    font-weight: 300;
    .title {
        width: 100%;
        text-align: center;
        font-weight: 400;
    }
    &.stars-breakdown {
        .title {
            margin: rem-calc(10px 0);
            width: calc(100% + 100px);
            margin-left: -100px;
        }
        .star-number {
            text-align: right;
            font-size: rem-calc(13px);
        }
    }
    &.byWho {
        .title { margin: rem-calc(20px 0 10px); }
        .p0_title { font-weight: 500; }
    }
}
.star-bar {
    display:block;
    position: relative;
    height: rem-calc(15px);
    margin: 0 0 5px 0;
    background: #CE6702;
    line-height: rem-calc(15px);
}
div.star-bar > div.star-number { font-size:rem-calc(12px); }
.star-number {
    display: block;
    position: absolute;
    left: 10px;
}
.star-count {
    position: absolute;
    left: -92px;
    top: -1px;
    height: 17px;
    width: 85px;
    background: url('/images/components/rate-star-gray_xs.png') repeat-x; }
.star-4 {
    background:#DD7901;
    .star-count { left: -75px; width: 68px; }
}
.star-3 {
    background:#E48A2A;
    .star-count { left: -58px; width: 51px; }
}
.star-2 {
    background:#ED9D4C;
    .star-count { left: -41px; width: 34px; }
}
.star-1 {
    background:#F2C190;
    .star-count { left: -24px; width: 17px; }
}

/* --- Summary Header --- */
.summary-header {
    h3 { margin-top: 0; }
    .prod-overview {
        .prod-title {
            display: block;
            img {
                float: left;
                margin: rem-calc(0 15px 20px 0);
            }
            .title-by {
                margin-bottom: rem-calc(20px);
                .overview-title {
                    font-size: rem-calc(20);
                    line-height: rem-calc(22);
                    color: $main-blue;
                }
                .by-line {
                    display: block;
                    color: $gray4;
                    font-size: rem-calc(14);
                    line-height: rem-calc(24);
                }
            }
        }
        .prod-content {
            display: block;
            clear: both;
            border-top: solid 1px $silver;
            padding-top: 15px;
            margin-bottom: 20px;

            color: $gray4;
            font-size: rem-calc(15);
            line-height: rem-calc(22);
            p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: rem-calc(10);
            }
            a.read-more {
                display: block;
                text-align: right;
            }
        }
    }
    .rate-summary {
        &.recommended-stars {}
        &.stars-breakdown {}
    }
}

.silver-video,
.silver-award {
    width: 50%;
    float: left;
    .view-more {
        display: block;
        text-align: right;
        font-size: rem-calc(14);
        font-weight: 300;
    }
}

/* --- Product Tabs --- */
@media #{$large-only} {
    #productTabs .tab-title {
        a { padding: rem-calc(16px 24px); }
        &.active a {
            margin-top: rem-calc(-5px);
            padding: rem-calc(16px 24px);
        }
    }
    ul#productTabs + .tabs-content.productTabs {
        .rate-summary {
            &.recommended-stars,
            &.stars-breakdown { margin-right: 1%; }
        }
        .summary-header {
            .prod-overview { max-width: 39%; }
            .rate-summary {
                &.recommended-stars {
                    max-width: 26%;
                    margin: 0 1%;
                }
                &.stars-breakdown {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }//END .summary-header
    }
}//END media:large
@media #{$medium-only} {
    #productTabs .tab-title {
        a {
            font-size: rem-calc(14px);
            font-weight: 500;
            padding: rem-calc(16px 7px);
        }
        &.active a {
            font-size: rem-calc(14px);
            font-weight: 500;
            margin-top: rem-calc(-5px);
            padding: rem-calc(16px 7px);
        }
    }
    ul#productTabs + .tabs-content.productTabs {
        .rate-summary {
            clear: both;
            &.recommended-stars {
                text-align: center;
                margin: rem-calc(36px) auto 0;
                padding-top: rem-calc(20px);
                max-width: 100%;
                .average-stars {
                    max-width: 49%;
                    float: left;
                    margin-right: 1%;
                }
                .average-recommended {
                    max-width: 49%;
                    float: right;
                    margin-left: 1%;
                }
            }
            &.stars-breakdown { margin: rem-calc(40) auto rem-calc(20); }
            &.byWho {
                max-width: 100%;
                margin: auto;
                text-align: center;
            }
        }//END .rate-summary
    }
}//END media:medium
@media #{$small-only} {
    ul#productTabs + .tabs-content.productTabs {
        .rate-summary {
            &.recommended-stars,
            &.stars-breakdown {
                float: left;
                max-width: 25%;
                margin-right: 5%;
            }
            &.byWho {
                float: right;
                max-width: 40%;
            }
        }//END .rate-summary
        .summary-header {
            .prod-overview {
                float: left;
                max-width: 40%;
            }
            .rate-summary {
                &.recommended-stars {
                    max-width: 25%;
                    margin: 0 0 0 1%;
                }
                &.stars-breakdown {
                    float: right;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }//END .summary-header
    @media (max-width: 620px) {
        .rate-summary {
            &.byWho {
                float: none;
                max-width: 100%;
                margin: auto;
            }
        }
        .summary-header {
            .prod-overview {
                float: left;
                max-width: 50%;
            }
            .rate-summary {
                float: right;
                &.recommended-stars { max-width: 35%; }
            }
        }//END .summary-header
    }//end sub-media
    }
    #productTabs .tab-title {
        a {
            font-size: rem-calc(14px);
            font-weight: 500;
            padding: rem-calc(12px 7px);
        }
        &.active a {
            font-size: rem-calc(14px);
            font-weight: 500;
            margin-top: rem-calc(-5px);
            padding: rem-calc(12px 7px); 
        }
    }
}//END media:small
@media #{$xsmall-only} {
    ul#productTabs + .tabs-content.productTabs {
        .content.active { padding: rem-calc(5); }
        .rate-summary {
            &.recommended-stars,
            &.stars-breakdown,
            &.byWho {
                float: none;
                clear: both;
                margin: 0 auto;
                text-align: center;
                width: 100%;
                max-width: rem-calc(260px);
            }
            &.recommended-stars {
                clear: both;
                .average-stars { padding: rem-calc(20 0); }
                .star-rating, .progress {
                    max-width: rem-calc(125px);
                    margin: auto;
                }
            }
            &.stars-breakdown { margin: rem-calc(40) auto rem-calc(20); }
            &.byWho #reviewChart { margin-left: rem-calc(-10); }
        }//END .rate-summary
        .summary-header {
            .prod-overview {
                float: none;
                max-width: 100%;
            }
            .rate-summary {
                &.recommended-stars,
                &.stars-breakdown {
                    float: left;
                    max-width: 45%;
                    clear: none;
                    margin: 0 auto 5%;
                }
            }
            @media (max-width: 413px) {
                .rate-summary {
                    float: none;
                    margin: auto;
                    &.recommended-stars,
                    &.stars-breakdown {
                        float: none;
                        display: block;
                        clear: both;
                        max-width: rem-calc(180px);
                        width: auto;
                    }
                }
            }//END media:320
        }//END .summary-header
    }
    #productTabs .tab-title {
        a { font-size: rem-calc(10px); }
        &.active a { font-size: rem-calc(10px); }
    }
}//END media:xsmall

.tabs.horizontal.shadow-tabs + .tabs-content {
    .content > h3:first-child { margin-top: 0; }
}

.recentVRs {
    + .pagination-centered { margin: rem-calc(50px 0 0); }
    + .more-arrow { margin-top: rem-calc(20px); }
}

/*---Overview Tab (Used for Buyer's Guides, too)---*/
.overview {
    background: #fff;
    padding: 30px;
    h1, h2, h3 {
        font-weight: 300;
        color: #376caf;
        line-height: 1.2;
    }
    h1 {
        border-bottom: 4px solid #376caf;
        width: 100%;
        margin-bottom: .5em;
        font-size: 3rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    ul, ol {
        margin-left: 2.5rem;
    }
    li {
        margin-bottom: .5em;
        color: #444;
        font-weight: 300;
        font-size: 1em;
    }
    table {
        width: 46%;
        margin-right: 3%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;
        thead {
            background: #376caf;
            td {
                color: #ffffff;
            }
        }
        tbody, tr, td {
            width:100%;
        }
        td:nth-child(2) {
            width: 20%; text-align: center;
        }
    }

    .table-of-contents {
        -webkit-columns: 300px 3;
        -moz-columns: 300px 3;
        columns: 300px 3;
        margin-bottom: 30px;
        ul { 
            border-left: 1px solid $silver;
            padding-left: 30px;
            margin-left: 10px;
        }
        ul ul {border-left: none;}
    }
    @media #{$small-only}{
        .table-of-contents ul li ul {
            display: none;
        }
    }

    /* Bluebox Styles */
    .overview-bluebox {
        background: #376caf;
        padding: 1.5rem 2rem 1rem;
        color: #fff;
        font-weight: 300;
        margin-bottom: 1.5rem;
        div {
            margin-bottom: .75em;
        }
        h2 {
            color: #fff;
            font-size: 2.2rem;
            line-height: 1;
            margin-bottom: 1.5rem;
        }
        li {
            color: #fff;
        }
        p {
            margin-bottom: .5em;
        }
        .title {
            display: inline-block;
            width: 20%;
            font-weight: 600;
            vertical-align: top;
        }
        .prof-value {
            display: inline-block;
            width: 75%;
        }
    }
}
/* Icon Styles*/
.icon-no:before {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    content: "\f068";
    color: #bb0000;
}
.icon-yes:before {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    content: "\f00c";
    color: #00aa00;
}

@media #{$small-only} {
    .overview {
        padding: 30px 15px;
        table {
            width: 100%;
            margin-right: 0;
        }
        .overview-bluebox {
            padding: 15px;
        }
        .overview-bluebox .title {
            width: 45%;
        }
        .overview-bluebox .prof-value {
            width: 45%;
        }

    }
}//END media:small

/*** Strong-arming Hubspot to look like DrivingSales ***/
.claim-company_modal {
    .blue-header {
        background-color: $vendor-orange !important;
        font-size: 1.875rem !important;
        height: 70px !important;
        padding-top: .75rem !important;
    }
    @media #{$medium-up} {
        max-width: 31.25rem;
    }
    fieldset {
        padding: 0 !important;
        width: 100% !important;
    }
    fieldset .form-columns-2 {
        .hs-form-field {
            width: 50% !important;
            margin: 0 !important;
        }
    }
    .hs-form-field {
        margin-bottom: 10px !important;
    }
    .hs-input {
        padding: 8px 8px 9px;
        font-size: 14px;
        border-radius: 0;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.75);
        width: calc(100% - 16px) !important;
        box-sizing: content-box !important;
        border: 1px solid #cccccc !important;
        font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }
    .hs-form .hs-button {
        text-shadow: none !important;
        background-color: $vendor-orange !important;
        border: none !important;
        background-image: none !important;
        box-shadow: none !important;
        font-weight: 400 !important;
        font-size: rem-calc(14px) !important;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        transition: none !important;
        border-radius: 4px;
        height: 25px;
        width: 100%;
        padding: 7px 0 !important;
    }
    .actions {
        padding: 0 !important;
        margin: 0 !important;
    }
    .hs-form-required {
        display:none;
    }
    ul {
        margin: 4px 0 !important;
    }
    label {
        color: $notice-red !important;
        line-height: 14px !important;
        margin: 0;
    }
}

/*---------------------------------------------------Company Product Pages-*/

.progress-bar-area {
    margin-top: 0px;
}
.progress {
    display: none;
    position: relative;
    width: 100%; height: 30px;
    background: #C7DA9F;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 10px;
}
.bar {
    position: absolute;
    top: 0; left: 0;
    width: 0; height: 30px;
    background: #85C220;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: .7em;
}
.bar.red { background: tomato; }