/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * blogs.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Blogs General
 * + About the Author
 * + Blog Posts
 * + Blog Footer
 * + Comments Section
 * + Post Previews
 * +
 * + Blogs Landing
 * + Featured Post
 * + 
 * - Blog - Admin
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Blogs-------------------------------------------------------------------*/
#rulesModal {
	h2, h1 { text-align: center; }
	@media #{$medium-up} {
		max-height: 80vh;
		&[style] { top: 10% !important; }
	}
	@media #{$small-only} {
		max-height: 90vh;
		&[style] { top: 1%!important; }
	}
}
.more-arrow,
.load-more {
	display: block;
	width: 100%;
	text-align: right;
	font-size: rem-calc(12px);
	text-transform: uppercase;
}

.badge {
	font-size: rem-calc(14px);
	font-style: italic;
	&.featured { color: $red-badge; }
	&.new { color: $green-badge; }
}

.meta-icons {
	float: right;
	color: $gray9;
	font-size: rem-calc(14px);
	.material-icons { font-size: rem-calc(24px); }
	.fa { font-size: rem-calc(20px); }

	.views {}
	.comments, .flag {
		color: $gray9;
		display: inline-block;
	}
}//END .meta-icons
.interact-icons {
	color: $main-blue;
	.vote-down, .vote-up {
		color: $gray9;
		&.active { color: $main-blue; }
		&.material-icons { font-size: rem-calc(24px); }
		&.fa { font-size: rem-calc(20px); }
	}
	.rating-moderation {
		color: $gray9;
		.vote-down, .vote-up {
			font-size: rem-calc(12px);
			color: $main-blue;
			&.active { color: $gray9; }
		}
		font-size: rem-calc(12px);
	}
	.stat-count {
		color: $gray9;
	}
	.who-likes {
		font-size: rem-calc(13px);
		@media #{$small-only} {
			max-width: 63%;
			@media #{$xsmall-only} {
				max-width: 100%;
				display: block;
				clear: left;
			}
		}
	}
}//END .interact-icons

/* --- Blog Header --- */
.blog-title {
	color: $gray4;
	font-family: $roboto;
	margin: rem-calc(0 0 20);
//	border-bottom: solid 1px $grey6;
	.blog-name {
		font-size: rem-calc(14px);
      margin: rem-calc(0px 0 10px 0px);
		//font-weight: 400;
      color:$gray7;
	}
	.blog-tagline {
		font-size: rem-calc(14px);
		//font-weight: 300;
		margin: rem-calc(0px 0 10px 0px);
      color: $gray7;
	}
	.manage-user-access{
		margin-bottom:rem-calc(20px);
	}
    h4{
      font-size: rem-calc(14px);
      color: $gray4;
      margin: rem-calc(3px 0px 5px 0px);
    }
    h4.headline-subline{
      font-size: rem-calc(24px);
      margin-bottom:rem-calc(10px);
      margin-top:rem-calc(0px);
      font-weight: 300;
      color: $gray7;
    }
    .blog-url {
      font-size: rem-calc(14px);
      //font-weight: 300;
      color: $gray7;
      margin: rem-calc(0px 0 10px 0px);
    }
    table { width: 100%; }

}
@media #{$xsmall-only} {
	.ifcanedit .btn {
		font-size: rem-calc(12px);
		line-height: rem-calc(18px);
		padding: rem-calc(3px 13px 3px 8px);
		&:last-child { float: right; }
	}
}

/* --- Initial Blog Setup ---- */
#blogForm {

	.form-element-required {
		width: 100%;
		input[type="text"] {
			display: inline-block;
			width: 100%;
			float: left;
		}
	}
	@media #{$large-up} {
		.form-element-required {
			display: block;
			input[type="text"] {
				width: 49%;
				margin-right: 1%;
			}
		}
		.form-group div:nth-child(2) input {
			margin-right: 0;
			margin-left: 1%;
		}
	}
	.save-blog {
		width: rem-calc(100px);
		margin: rem-calc(5px 0 0 200px);
	}
	button {
		@media #{$medium-up} { float: right; }
		margin: rem-calc(20px 0 0);
	}
}

/* --- Claim Blog --- */
.blog-claim {
	margin-bottom: rem-calc(20px);
}

/* --- Write Blog Post --- */
.blog.edit-post,
.blog.add-post {
	.has-tip { border: none; }
	.hide-adv { display: none; }
	#postForm { margin-top: 30px; }
	.advanced-switch {
		float: right;
		.advanced-editor {
			display: inline-block;
			font-size: rem-calc(18px);
			font-weight: 300;
		}
		.switch {
			float: right;
			margin: rem-calc(10 5 0 0);
		}
	}// END .advanced-switch
	.section {
		.headline-border {
			margin-top: 0;
			font-weight: 300;
		}
		.featured-img {
			margin: rem-calc(10 0);
			.modal-cropper {
				position: relative;
				opacity: 1;
				font-family: $roboto;
			}
			button {
				width: 100%;
			}
		}//END .featured-img

		&.three {
			-webkit-columns: 2 140px;
			-moz-columns: 2 140px;
			columns: 2 140px;
			-webkit-column-gap: 1em;
			-moz-column-gap: 1em;
			column-gap: 1em;
			h3 {
				-webkit-column-span: all;
				-moz-column-span: all;
				column-span: all;
			}
			.category-checkboxes {
				display: block;
				margin-bottom: rem-calc(1px);
				&.Accounting, &.Finance.Insurance, &.Fixed.Ops, &.General.Mgt, &.Marketing, &.New.Car, &.Used.Car {}
			//	&.Internet/BDC {}
				@media #{$large-only} {
					&.Miscellaneous.Industry,
					&.Finance.Insurance {
						label { max-width: 140px; }
					}
				}
			}
		}//END .three
		&.four {
			clear: both;
			padding-top: 1px;
			@media #{$large-only} {
				h3 small { font-size: rem-calc(14px); }
			}
		}//END .four
		&.one, &.two {
			input, textarea, select { margin: 0; }
			.section-label {
				margin: rem-calc(20px 0 10px);
				font-weight: 400;
				.select-elements {
					float:right;
					p {
						display: inline-block;
						padding: 0 rem-calc(12px) 0 0;
					}
				}
			}
			.seo-settings {
				label {
					margin: rem-calc(10px 0 5px);
					font-weight: 500;
				}
				.section-label { margin-bottom: 0; }
			}
		}//END .one, .two
		&.five {
			input, textarea, select { margin: 0; }
			.hide-adv {
				margin-bottom: rem-calc(15px);
				label {
					margin: rem-calc(10px 0 5px);
					font-weight: 500;
				}
				&.publish-settings { margin-bottom: 0; }
			}

			.accordion_label.accordion_trigger {
				.btn-edit {
					float: right;
					color: $gray9;
					background-color: transparent;
					&:hover { color: $gray4; }
				}
			}//END .accordion_label				
			.accordion_content {
				display: none;
				select,
				input[type="text"] {
					display: block;
					max-width: rem-calc(359px);
				}
				.pub-status {
					label {
						width: 17%; 
						min-width: rem-calc(60px);

						font-size: rem-calc(16px);
						font-weight: bold;
						display: inline-block;
					}
					select {
						display: inline-block;
						width: 83%;
						max-width: rem-calc(290px);
						@media #{$large-only} {
							width: 78%;
							max-width: rem-calc(238px);
						}//END media:large
						@media #{$xsmall-only} {
							width: 76%;
							max-width: rem-calc(206px);
						}//END media:xsmall
					}
				}
				.pub-when {
					margin: rem-calc(5px 0);
					.set-date {
						display: block;
						margin-top: rem-calc(5px);
					}
				}
				.row {
					display: flex;
					flex-direction: row;
					input {
						flex-grow: 2;
						flex-shrink: 1;
					}
					select {
						flex-grow: 1;
						flex-shrink: 2;
					//	background: transparent;
					}
				}
				.timezone {
					display: block;
				//	margin-bottom: rem-calc(10px);
					font-style: italic;
					font-size: rem-calc(12px);
				}
				.btn {
					width: rem-calc(135px);
					margin: rem-calc(5px 0 10px);
					&.btn-submit { margin-right: rem-calc(10px); }
				}
			}//END .accordion_content
			.btn.save-blog {
				clear: both;
				width: 100%;
				margin-top: rem-calc(20px);
			}
			@media #{$large-only} {
				.check-group label {
					max-width: 300px;
					.info-icon {
						float: right;
						margin-right: -12px;
					}
				}
			}
			@media #{$xsmall-only} {
				.check-group label { max-width: 270px; }
			}//END media:xsmall
		}//END .five
	}//END .section
}
/*-------------------------------------------------------------------Blogs-*/

/*-About Author------------------------------------------------------------*/
.about-author {
	display: block;
	color: $gray4;
//	text-align: justify;
	strong {
		font-size: rem-calc(24px);
		font-weight: 400;
		+ p { margin-top: rem-calc(10px); }
	}
	.avatar-block.avatar-status-widget {
		position: relative;
		display: block;
		width: 100%;
		margin: 0 auto rem-calc(20px);
		.status-level {
			width: calc(100% + 20px);
			height: calc(100% + 20px);
			margin: -10px;
		}
		img.avatar {
			padding: 0;
			-webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.15);
			-moz-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.15);
			box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.15);
		}
		@media #{$small-only} {
			width: rem-calc(150px);
			height: rem-calc(150px);
		}
		@media #{$medium-only} {
			width: rem-calc(150px);
			height: rem-calc(150px);
		}
		@media #{$large-up} {
			width: rem-calc(160px);
			height: rem-calc(160px);
		}
		@media #{$xlarge-up} {
			width: rem-calc(175px);
			height: rem-calc(175px);
		}

	}
	@media #{$small-only} {
		margin-top: rem-calc(40px);
		height: 100%;
		.avatar-block {
			float: left;
			max-width: rem-calc(150px);
			height: 100%;
			margin: rem-calc(0 20px);
		}
		@media #{$small-only} {
			.avatar-block {
				float: none;
				margin: 0 auto;
			}
		}
	}
}

/*------------------------------------------------------------About Author-*/

/*-Blog Posts--------------------------------------------------------------*/
.post-header {
	display: block;
	clear: both;
	margin-top: 20px;
	.post-headline {
		display: table-cell;
		color: $main-blue;
		font-size: rem-calc(30px);
		font-family: $roboto;
		font-weight: 700;
		@media #{$large-up} {
			font-size: rem-calc(40px);
			line-height: rem-calc(44px);
		}
		@media #{$small-only} {
			font-size: rem-calc(20px);
			line-height: rem-calc(24px);
		}
	}
	.by-line {
		clear: left;
		float: left;
		color: $gray80;
		font-size: rem-calc(15px);
		font-weight: 300;
		a {
			font-size: rem-calc(15px);
			font-weight: 300;
		}
	}
	@media #{$xsmall-only} {
		.by-line {
			max-width: 100%;
			float: none;
		}
	//	.interact-icons { margin: auto; }
	}
}

/* --- Post Content --- */
.post-content {
	display: block;
	padding-top: rem-calc(20px);
}
.blog-post .post-content {
	clear: both;
	img[style*="float: left;"],
	img[style*="float:left;"] { margin: rem-calc(0 10px 10px 0); }
	img[style*="float: right;"],
	img[style*="float:right;"] { margin: rem-calc(0 0 10px 10px); }
	@media #{$medium-only}, #{$small-only} {
		img[style*="float: right;"],
		img[style*="float:right;"],
		img[style*="float: left;"],
		img[style*="float:left;"] {
			float: none !important;
			margin: rem-calc(10px) auto 0 !important;
			width: 100%!important;
			height: 100%!important;
			//The !important is required to overwrite the inline styles
		}
		// > p:first-of-type {
		// 	clear: both;
		// 	text-align: center;
		// }
	}//END media
	@media #{$small-only} {
		> p:first-of-type { clear: right; }
	}//END media

}
.post-author {
	display: block;
	clear: both;
	border: solid 1px $grey6;
	padding: rem-calc(15px);
	margin-bottom: rem-calc(20px);
	min-height: rem-calc(200px);

	.connect-desktop {
		display: inherit;
		.btn-blue {
			display: block;
			margin: 0 auto 7px;
		}
	}
	.connect-mobile { display: none; }

	.author-connect {
		float: left;
		margin-right: rem-calc(30px);
	}
	.author-about {
		color: #444444;
		font-weight: 300;
		padding-top: rem-calc(10px);
		.profile-name { font-weight: 300; }
	}
	.author-credentials {
		font-style: italic;
		padding-bottom: rem-calc(5px);
	}
	.author-credentials,
	.author-blurb {
		color: #666666;
		font-weight: 100;
		margin-bottom: 0;
	}
}
@media #{$medium-only}, #{$xsmall-only} {
	.post-author {
		padding: rem-calc(10px);
		min-height: 100%;

		.author-about,
		.author-credentials,
		.author-blurb {
			font-size: rem-calc(14px);
		}

		.author-connect { margin-right: rem-calc(10px); }
		.connect-desktop { display: none; }
		.connect-mobile {
			display: inherit;
			.btn-blue {
				min-width: 48%;
				&:last-child { float: right; }
			}
		}
		.author-blurb { clear: both; }
	}
}
/*--------------------------------------------------------------Blog Posts-*/

/*-Blog footer-------------------------------------------------------------*/
.post-footer {
	.post-bottom {
		display: block;
		clear: both;
		border-top: solid 1px $grey6;
		.interact-icons {
/*			.who-likes {
				@media #{$small-only} {
					max-width: 63%;
					@media #{$xsmall-only} {
						max-width: 100%;
						display: block;
						clear: left;
					}
				}
			}
*/		}
		.meta-icons { margin-top: rem-calc(5px); }
		.addthis_sharing_toolbox {
		//	display: inline-block;
			float: right;
			margin: rem-calc(10px 5px 0);
			@media #{$small-only} { clear: right; }
		}
	}
}
/*-------------------------------------------------------------Blog Footer-*/

/*-Comments Section--------------------------------------------------------*/
.commentSection {
	clear: both;
	margin-top: rem-calc(40px);
	.sectionLabel {
		font-size: rem-calc(30);
		font-weight: 500;
	}
	.addComment {
//		@media #{$large-up} { display: flex; }
		background-color: $lightbluetint;
//		padding: rem-calc(10px 15px 15px);
		.avatar-status-widget {
			float: left;
			margin: rem-calc(5px 15px 0 0);
		}
/*		input {
			flex: 1;
			margin-bottom: 0;
		}
		.btn-submit {
			max-height: rem-calc(36px);
			margin: rem-calc(5px 0 0 5px);
		}
		@media #{$small-only}, #{$medium-only} {
*/
			padding: rem-calc(5px 10px 10px);
			.avatar-status-widget { margin: rem-calc(5px 5px 0 0); }
			input {
				font-size: rem-calc(14px);
			//	max-width: calc(100% - 37px);
				max-width: 100%;
				width: calc(100% - 135px);
			}
			.btn-submit { margin-bottom: 0px; }
			.cke_editor_addComment {
				font-size: rem-calc(14px);
				margin-left: rem-calc(45px);
				max-width: calc(100% - 45px);
				width: auto;
				margin-bottom: rem-calc(50px);
				+ .btn-submit { margin-top: rem-calc(-40px); }
			}
//		}//END media:small
		.cke_wysiwyg_div { min-height: 100px; }
	}
}
/* --- Comments --- */
.post-comment {
	position: relative;
	border-bottom: solid 1px $grey6;
	padding-bottom: rem-calc(6px);

	.comment-content {
	  width: 100%;

      iframe {
        max-width: 100%;
      }
    }

	.commenter.profile {
		position: relative;
		max-width: rem-calc(170px);
		min-width: rem-calc(100px);
		padding-right: rem-calc(25px);
/*		img.avatar {
			display: block;
			margin: rem-calc(0 0 3px);
		}
*/		.profile-name {
			color: $main-blue;
			font-size: rem-calc(20px);
			font-weight: 500;
		}
		.profile-job, .profile-company {
			font-weight: 300;
			font-size: rem-calc(14px);
			line-height: rem-calc(18px);}
		.profile-job { color: $gray4; }
		.profile-company { color: $gray9; }
		@media #{$small-only}, #{$medium-only} {
			.avatar-status-widget {
				float: left;
				width: rem-calc(75px);
				height: rem-calc(75px);
				margin-right: rem-calc(10px);
			}//END .avatar-status-widget
		}//END media:xsmall
	}
}

.comment-footer {
	clear: both;
	.meta {
		float: right;
		.comment-time {
			color: $gray9;
			font-size: rem-calc(13px);
			font-weight: 300;
		}
		.flag {
			color: $gray9;
			.fa { font-size: rem-calc(18px); }
		}
	}
	@media #{$small-only} {
		position: relative;
		margin-top: rem-calc(10px);
		.meta { float: left; }
		.interact-icons {
			margin-left: 35%;
			@media #{$xsmall-only} {
				.who-likes {
					max-width: 100%;
					display: block;
				}
			}
		}
	}//END media:small
}

//Full Width
.post-comment {
	margin-bottom: rem-calc(20px);
	height: auto;
//	@media #{$medium-up} {
	@media #{$large-up} {
		.commenter.profile {
			float: left;
			height: 100%;
		}
		.comment-content {
			position: relative;
			float: left;
			max-width: 65%;
			height: auto;
		}
	}
/*	@media #{$medium-only} {
		.commenter.profile { width: 24%; }
		.comment-content { max-width: 76%; }
		.comment-footer { margin-left: 24%; }
	}
*/	@media #{$large-only} {
		.commenter.profile { width: 18%; }
		.comment-content { max-width: 82%; }
		.comment-footer { margin-left: 18%; }
	}
	@media #{$xlarge-up} {
		.commenter.profile { width: 15%; }
		.comment-content { max-width: 85%; }
		.comment-footer { margin-left: 15%; }
	}//END full-width
}

// Two-Thirds Width
.column-two-thirds .post-comment {
//	.comment-content { max-width: 63%; }
	@media #{$medium-up} {
/*		.commenter.profile { width: 36%; }
		.comment-content { max-width: 64%; }
		@media #{$medium-only} {
			.comment-footer {
				position: relative;
				margin: rem-calc(10px 0 0);
				.meta { float: left; }
				.interact-icons {
					margin-left: 35%;
					.who-likes {
						max-width: 100%;
						display: block;
					}
				}
			}
		}//END media:medium
*/	}
	@media #{$large-up} {
		.commenter.profile { width: 28%; }
		.comment-content { max-width: 72%; }
		.comment-footer { margin-left: 28%; }
	}
	@media #{$xlarge-up} {
		.commenter.profile { width: 23%; }
		.comment-content { max-width: 77%; }
		.comment-footer { margin-left: 23%; }
	}
} //END two-thirds width

//Mobile Comments
@media #{$medium-only}, #{$small-only} {
	.post-comment {
		.commenter.profile {
			max-width: 100%;
			width: 100%;
			min-height: rem-calc(65px);
			padding-right: 0;
			img.avatar {
				float: left;
				margin-right: rem-calc(20px);
			}
			.profile-job {
				font-size: rem-calc(12px);
				line-height: rem-calc(18px);
			}
		}
		.comment-content {
			clear: both;
			max-width: 100%;
			width: 100%;
			margin-top: rem-calc(15px);
		}
		.comment-footer {
			position: relative;
			.meta {
				display: block;
				width: 100%;
				.flag {
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}//.meta
			.interact-icons {
				clear: both;
				width: 100%;
				margin-left: 0;
				a { display: inline; }
				.who-likes {
					display: inline;
					width: calc(100% - 40px);
				}
			}//END .interact-icons
		}
	}
}//END media:small
/*--------------------------------------------------------Comments Section-*/

/*-Post Previews-----------------------------------------------------------*/
.post-preview {
	position: relative;
	border-bottom: solid 1px $grey6;
	margin-bottom: rem-calc(16px);
	.post-header, .post-content {
		font-size: rem-calc(13px);
		.post-headline {
			font-size: rem-calc(20px);
			line-height: rem-calc(24px);
		/*	white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		*/	font-weight: 500;
			&.mobile { display: none; }
			&.desktop { display: inherit; }
		}
		@media #{$xsmall-only} {
			.post-headline {
				clear: both;
				&.mobile { display: inherit; }
				&.desktop { display: none; }
			}
		}
		.by-line {
			width: 100%;
			color: $gray4;
			font-weight: 300;
			a { font-weight: 300;}
			.post-time { 
				float: right;
				color: $gray9;
				font-weight: 300;
			}
			&.desktop { display: inherit; }
			&.mobile { display: none; }
			@media #{$medium-only} {
				&.desktop { display: none; }
				&.mobile { display: block; }
			}//END media:medium
		}
		.sponsored {
			color: $gray4;
			font-weight: 200;
		}
	}
	.post-content {
		padding-top: rem-calc(5px);
		font-size: rem-calc(15px);
		.post-featured-image {
			display: inline-block;
			width: 50%;
			max-width: 200px;
			float: left;
			margin-right: 15px;
			margin-top: 5px;
			border: 1px solid $grey6;
			min-height: rem-calc(75px);
		}
		.post-article-clickable {
			font-weight: normal;
			color: #222;
		}
		.native-ad-img {
			display: inline-block;
			width: 50%;
			max-width: 200px;
			float: right;
			margin-left: 15px;
			margin-top: 5px;
			margin-bottom: 10px;
			img {
				border: 1px solid $grey6;
			}
		}
		@media #{$xsmall-only} {
			.post-featured-image {
				max-width: none;
				width: 100%;
				margin-bottom: rem-calc(15px);
			}
		}
		.read-more { font-size: rem-calc(10px); }
		.comment {
			margin: rem-calc(5px 0);
			background-color: $blue-bg-disabled;
			padding: rem-calc(5px 10px);
			font-size: rem-calc(12px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.post-bottom { clear: both; }
/* --- Blog Feed - with Images --- */
	.post-img {
		float: left;
		width: rem-calc(205px);
	//	height: rem-calc(108px);
		height: auto;
		border: solid 1px $gray9;
		max-width: 38%;
		margin-top: rem-calc(5px);
	}
	.img-float {
		float: left;
		max-width: 60%;
		margin-left: 2%;
		.post-header .post-headline { margin: 0; }
		.post-content {
			padding-top: 0;
			font-size: rem-calc(15px);
			line-height: (18px);
			.read-more { display: block; }
		}
	}
	&.sponsored{
		.img-float {
			margin-left: 0;
			margin-right: 2%;
			.sponsored {
				color: $gray3;
				font-size: rem-calc(11px);
				line-height: rem-calc(18px);
			}
			.post-bottom { padding: rem-calc(5px); }
		}
	}


}
/*-----------------------------------------------------------Post Previews-*/

/*-Blogs Landing Page------------------------------------------------------*/
.blogs-landing {
	&.content { margin-top: 0; }
/* --- Hero --- */
	.hero-banner {
		background-color: rgba(0,0,0,0.35);
		background-repeat: no-repeat;
		background-position: center top;
		@media #{$small-only} {
		//	margin-bottom: rem-calc(20px);
			background-image: url('../images/category-banners/blogs-home_sm.jpg');
			.container { height: rem-calc(259px); }
		}
		@media #{$medium-only} {
		//	margin-bottom: rem-calc(30px);
			background-image: url('../images/category-banners/blogs-home_md.jpg');
			.container { height: rem-calc(335px); }
		}
		@media #{$large-up} {
		//	margin-bottom: rem-calc(40px);
			background-image: url('../images/category-banners/blogs-home_lg.jpg');
			.container { height: rem-calc(405px); }
		}
		@media #{$xlarge-up} {
			background-image: url('../images/category-banners/blogs-home_xl.jpg');
			background-repeat: no-repeat;
			background-position: center top;
		}
		@media #{$xxlarge-up} {
		background: url('../images/category-banners/blogs-home_xl.jpg'), #8e8e8e;
		background: url('../images/category-banners/blogs-home_xl.jpg'), -moz-linear-gradient(left,  #8e8e8e 49%, #8c9099 51%);
		background: url('../images/category-banners/blogs-home_xl.jpg'), -webkit-gradient(linear, left top, right top, color-stop(49%,#8e8e8e), color-stop(51%,#8c9099));
		background: url('../images/category-banners/blogs-home_xl.jpg'), -webkit-linear-gradient(left,  #8e8e8e 49%,#8c9099 51%);
		background: url('../images/category-banners/blogs-home_xl.jpg'), -o-linear-gradient(left,  #8e8e8e 49%,#8c9099 51%);
		background: url('../images/category-banners/blogs-home_xl.jpg'), -ms-linear-gradient(left,  #8e8e8e 49%,#8c9099 51%);
		background: url('../images/category-banners/blogs-home_xl.jpg'), linear-gradient(to right,  #8e8e8e 49%,#8c9099 51%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8e8e8e', endColorstr='#8c9099',GradientType=1 );
			background-repeat: no-repeat;
			background-position: center top;
		}
		.container {
			position: relative;
			h2 {
				color: #fff;
				font-weight: 300;
			}
			.search-blogs {
				position: absolute;
				color: #fff;
				left: 0; 
				bottom: 50px;
				.input-group {
					max-width: rem-calc(477px);
					button { margin-bottom: 0; }
				}

			}
			.write-post {
				position: absolute;
				right: 0;
				bottom: 45px;
			}
			@media #{$large-up} {
				h2 {
					margin: rem-calc(85px 0 100px);
					line-height: rem-calc(50px);
				}
			}
			@media #{$medium-only} {
				h2 {
					margin: rem-calc(80px 0);
					line-height: rem-calc(35px);
				}
			}
			@media #{$small-only} {
				h2 {
					margin: rem-calc(45px 0 30px);
					line-height: rem-calc(27px);
				}
				.search-blogs {
					position: static;
					float: left;
					clear: left;
					max-width: 50%;
					text-align: center;
				}
				.write-post {
					position: relative;
					float: right;
					margin-top: rem-calc(50px);
					padding: rem-calc(5px 10px);
					font-size: rem-calc(12px);
				}
			}
			@media #{$xsmall-only} {
				h2 {
					font-size: rem-calc(24px);
					line-height: rem-calc(24px);
					margin: rem-calc(30px 10px 20px 0);
					br { display: none; }

				}
				.search-blogs {
					max-width: 80%;
					text-align: left;
					margin-bottom: 10px;
					.input-group {
						margin-top: rem-calc(5px);
						width: 100%;
					}
				}
				.write-post {
					float: none;
				}
			}//END media:xsmall
		}
	}
}

/* --- Comments --- */
.comment-feed {
	position: relative;
	border-bottom: solid 1px $grey6;
	padding-bottom: rem-calc(6px);
	margin-bottom: rem-calc(10px);
	.commenter.profile {
		float: left;
		position: relative;
		width: rem-calc(50px);
		img.avatar {
			display: block;
			margin: rem-calc(0 0 3px);
		}
	}
	.post-headline {
		display: block;
		font-size: rem-calc(18px);
		line-height: rem-calc(28px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.comment-content {
		font-size: rem-calc(14px);
		line-height: rem-calc(20px);
		font-weight: 300;
		margin-bottom: rem-calc(5px);
		a { font-weight: 400; }
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.post-img {
		float: right;
		margin: rem-calc(5px 0 0 5px);
	}
	.comment-footer {
		clear: both;
		padding-left: rem-calc(50px);
		.interact-icons { margin-left: 0; }
	}
}

/*------------------------------------------------------Blogs Landing Page-*/

/*-Featured Post-----------------------------------------------------------*/
.featured-post {
	.post-preview {
		border-bottom: none;
		.post-headline {
			font-size: rem-calc(18px);
			font-weight: 300;
		}
	}
	.about-author {
		background-color: $blue-bg-disabled;
		border: solid 1px $grey6;
		padding: rem-calc(15px);
		font-weight: 300;
	//	font-size: 12px;
	//	line-height: 14px;
		.author-block {
			.by-line { font-size: rem-calc(14px); }
			 .author-bio {
				line-height: rem-calc(16px);
				font-size: rem-calc(12px);
			}
		}
	}
}//END .featured-post

// Alternate Styling
/* --- Home Page --- */
.blogs-landing,
.ds-home,
.department-feed {
	.featured-post {
		@media #{$large-up} {
			.post-preview {
				float: left;
				max-width: 66%;
				.featuredimg {
					max-width: 57%;
					float: left;
					margin: rem-calc(7px 15px 5px 0);
					border: 1px solid $grey6;
				}
			}
			.about-author {
				float: right;
				max-width: 30%;
				height: 100%;
				display: table;
				.author-avatar {
					text-align: center;
					margin: 0 auto rem-calc(20px);
					.avatar { margin: 0 auto; }
				}
			}
		}//END media:large
		@media #{$medium-only}, #{$small-only} {
			.post-content {
				.featuredimg {
					float: left;
					max-height: rem-calc(110px);
					margin: rem-calc(7px 15px 0 0);
					border: 1px solid $grey6;
				}
			}
			.about-author {
				min-height: rem-calc(125px);
				display: table;
				width: 100%;
				.author-avatar {
					display: table-cell;
					width: 40%;
				}
				.author-block {
					display: table-cell;
					vertical-align: middle;
					max-width: 60%;
				}
			}
		}//END media:medium
		+ .exclusive-blogs {
			clear: both;
			padding-top: 1px;
		}
	}//END .featured-post
}

/* --- Feed Page --- */
.ds-feed {
	.featured-post {
		.post-content {
			.featuredimg {
				float: left;
				max-height: rem-calc(110px);
				margin: rem-calc(7px 15px 0 0);
			}
		}
		.about-author {
			min-height: rem-calc(125px);
			display: table;
			width: 100%;
			.author-avatar {
				display: table-cell;
				width: 40%;
			}
			.author-block {
				display: table-cell;
				vertical-align: middle;
				max-width: 60%;
			}
		}
	}//END .featured-post
}
/*-----------------------------------------------------------Featured Post-*/
