/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * search.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Search Results Page
 * + Forum Boards
 * + Forum Post
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Search Results Page-----------------------------------------------------*/
.searchResults {
	h3 {
		.results-number {
			font-weight: 300;
			font-size: rem-calc(20px);
			@media #{$small-only} { font-size: smaller; }
		}//END .results-number
		.basic-search {
			float: right;
			max-width: 50%;
			margin-top: rem-calc(5px);
			@media #{$small-only} {
				float: none;
				clear: both;
				max-width: 100%;
			}//END media:xsmall

			button { margin-bottom: 0; }
			input {
				border: solid 1px #acacac;
				border-right: transparent;
				border-collapse: collapse;
				box-shadow: none;
				height: rem-calc(36px);
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}// END h3
	.refine-search h3 { margin-top: 0; }
	.expand-search {
		width: 100%;
		position: relative;
		text-align: center;
		z-index: 1;
		&:before {
			border-top: 1px solid $grey6;
			content:"";
			margin: 0 auto;
			position: absolute;
			top: 50%;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: -1;
		}
		.expand-more, .expand-less {
			cursor: pointer;
			font-size: rem-calc(12px);
			background-color: #fff;
			color: $gray3;
			.blue { color: $main-blue; }
		}

		.expand-more { display: inline; }
		.expand-less { display: none; }
		+ .advanced-search { display: none; }
		&.advanced {
			.expand-more { display: none; }
			.expand-less { display: inline; }
			+ .advanced-search { display: inherit; }
		}
	}

	.advanced-search {
		width: 100%;
		min-height: 150px;
		.btn.adv-search { margin: rem-calc(20px 0); }
		@media #{$medium-up} {
			.btn.adv-search { float: right; }
			input {
				max-width: 47%;
				float: left;
				&:nth-child(odd) { margin-right: 6%; }
				@media #{$large-up} { margin: rem-calc(20px 0); }
			}
		}//END media:medium
		@media #{$small-only} {
			.btn.adv-search { width: 100%; }
		}//END media:small
	}
	.search-results {
		.total-results {
			clear: both;
			color: $tertiary;
			font-weight: 300;
			font-size: rem-calc(20px);
			margin-top: rem-calc(20px);
			+ h3 { margin-top: rem-calc(10px); }
			strong { font-weight: 400; }
		}
	}

	.user-result {
		display: block;
		border-bottom: solid 1px $grey6;
		padding: rem-calc(10px 0);
		.user-info {
			display: inline-block;
			min-height: 93px;
			.user-employment {
				font-weight: 300;
				.user-job {}
				.user-company {}
			}//END .user-employment
			.user-social {
				.friends, .followers {
					float: left;
					font-weight: 300;
					strong { font-weight: 400; }
				}
				.friends { margin-right: rem-calc(24px); }
			}//END .user-social
		}//END .user-info
		@media #{$medium-up} {
			.user-avatar {
				float: left;
				margin-right: rem-calc(30px);
			}
			.user-info {
				max-width: calc(100% - 255px);
				padding: rem-calc(10px 0 0);
			}
			.user-connect {
				line-height: 93px;
				float: right;
			}
		}//END media:medium+
		@media #{$small-only} {
			.user-avatar {
				float: left;
				margin-right: rem-calc(17px);
			}
			.user-info { max-width: calc(100% - 240px); }
			.user-connect {
				float: right;
				line-height: rem-calc(93px);
			}
			@media #{$xsmall-only} {
				.user-info { max-width: calc(100% - 140px); }
				.user-connect {
					float: none;
					line-height: 26px;
					margin-left: rem-calc(110px);
				}
			}//END media:xsmall
		}//END media:small
	}//END .user-result
	.content-result {
		display: block;
		clear: both;
		border-bottom: solid 1px $grey6;
		padding: rem-calc(10px 0);
		.content-icon { float: left; }
		.content-info {
			display: block;
			min-height: rem-calc(65px);
			max-width: calc(100% - 100px);
			padding: rem-calc(10px 0 0);
			overflow: hidden;
		}
        .post-preview {
        	display: inline-block;
        	max-width: calc(100% - 125px);
        	border-bottom: none;
        }
		@media #{$medium-up} {
			.content-icon { margin-right: rem-calc(30px); }
		}//END media:medium+
		@media #{$small-only} {
			.content-icon { margin-right: rem-calc(17px); }
		}//END media:small
    }

	@media #{$medium-only} {
		.column-two-thirds { width: 100%; }
		.hide-mobile { display: none; }
	}//END media:medium
	@media #{$small-only} {
		.hide-mobile { display: none; }
	}
}
/*-----------------------------------------------------Search Results Page-*/