 /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * carousel.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Base Styles
 *	 - Orbit Carousel
 *	 - Qwl Carousel
 * + Home Page hero
 * + 
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Base Styles-------------------------------------------------------------*/
/* --- Orbit Carousel --- */
.orbit-container {
	.orbit-prev,
	.orbit-next {
		top: auto;
		bottom: 2px;
	}
	.orbit-next { right: 2px; }
	.orbit-prev {
		left: auto;
		@media #{$small-only} { right: 40.32%; }
		@media #{$xsmall-only} { right: 37%; }
		@media #{$medium-up} { right: 22.6%; }
		@media #{$large-up} { right: 22.5%; }
		@media #{$xlarge-up} { right: 23.3%; }
	}

	.orbit-bullets-container {
		ol.orbit-bullets {
			margin-bottom: rem-calc(27px);
			li {
				border: solid 1px #fff;
				background: transparent;
				&.active { background: #fff; }
			}
		}
	}
}
/* --- Owl Carousel --- */
.owl-carousel {
	position: relative;
	z-index: 1;
	.owl-controls {
		.owl-nav {
			width: 100%;
			position: absolute;
			top: 25%;
			z-index: 2;
			.owl-prev, .owl-next {
				font-size: 75px;
				font-weight: 100;
			}
			.owl-prev {
				float: left;
			//	margin-left: -7%;
			}
			.owl-next {
				float: right;
			//	margin-right: -7%;
			}
		}
	}
}
/*-Base Styles-------------------------------------------------------------*/

/*-Home Page Hero----------------------------------------------------------*/
.home.hero-content .orbit-container {
	ul.orbit-carousel.orbit-slides-container {
		li {
			.orbit-caption {
				top: 1px;
				right: 1px;
				bottom: 1px;
				left: auto;
				background-color: rgba(0, 0, 0, 0.5);
				@media #{$small-only} { max-width: 48%; }
				@media #{$medium-up} { max-width: 28%; }

				font-size: 18px;
				font-weight: 300;
				h4 {
					color: #fff;
					&:hover { color: #fff; }
					&.exclusive {
						font-size: 14px;
						line-height: 18px;
						border-bottom: solid 2px rgba(255,255,255,0.5);
					}
				}
				.post-tagline {
					font-size: smaller;
					color: #fff;
					&:hover { color: #fff; }
				}

				.orbit-contain {
					overflow: hidden;
					@media #{$medium-up} { max-height: 75%; }
					@media #{$xlarge-up} { max-height: 80%; }
					@media #{$small-only} {
						max-height: 64%;
						h4 { font-size: 16px; }
						.post-tagline {
							font-size: 14px;
							line-height: 1.5em;
						}
					}
					@media #{$xsmall-only} {
						max-height: 62%;
						h4 {
							font-size: 14px;
							&.line-clamp { //line-clamp-2
								-webkit-line-clamp: 2;
								height: calc(1em * 1.2 * 2);
							}
						}
						.post-tagline {
							font-size: 12px;
							&.line-clamp { //line-clamp-3
								-webkit-line-clamp: 3;
								height: calc(1em * 1.5 * 3);
							}
						}
					}//END media:xsmall
				}
				.cta {
					display: block;
					clear: both;
					font-size: 11px;
					padding: rem-calc(10px 0 0);
					text-transform: uppercase;
					a {
						color: #fff;
						&:hover { color: #5da5ff; }
					}
				}
			}
		}
		li.active img {
			border: 1px solid $grey6;
			min-width: 100%;
			@media #{$small-only} { min-height: rem-calc(255px); }
			@media #{$xsmall-only} { min-height: rem-calc(180px); }
			@media #{$medium-up} { min-height: rem-calc(398px); }
			@media #{$large-up} { min-height: rem-calc(356px); }
			@media #{$xlarge-up} { min-height: rem-calc(406px); }
		}
	}
	.orbit-bullets-container {
		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;
		width: 100%;
		@media #{$small-only} {
			max-width: 48%;
			.orbit-bullets { display: block; }
			@media #{$xsmall-only} { bottom: -10px; }
		}
		@media #{$medium-up} {
			max-width: 28%;
			li {
				width: rem-calc(12px);
				height: rem-calc(12px);
			}
		}
		@media #{$large-up} {
			li {
				width: rem-calc(14px);
				height: rem-calc(14px);
				margin-right: rem-calc(8px);
			}
		}
		@media #{$xlarge-up} {
			li {
				width: rem-calc(16px);
				height: rem-calc(16px);
				margin-right: rem-calc(10px);
			}
		}
	}

	.orbit-slide-number,
	.orbit-timer { display: none; }

	@media #{$small-only} {
		a.orbit-prev,
		a.orbit-next {
			display: block;
			&:focus { outline: none; }
			@media #{$xsmall-only} { max-height: rem-calc(40px); }
		}
	}
}
/*----------------------------------------------------------Home Page Hero-*/

/*-Home Page Featured Members----------------------------------------------*/
//.home.main-content .home-content.featured-members {
	.featured-profiles {
		//Carousel Navigation
		max-width: 85%;
		margin: 0 auto;
		//the rest of the .owl-nav styles are in component/carousel.scss
		.owl-carousel .owl-nav {
			.owl-prev { margin-left: -7%; }
			.owl-next { margin-right: -7%; }
		}
		//Carousel Content
		.profile {
			text-align: center;
			line-height: rem-calc(18px);
			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.profile-name {
				color: $main-blue;
				font-size: rem-calc(18px);
				line-height: rem-calc(20px);
				font-weight: 500;
				//need to truncate
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.profile-job, .profile-company {
				font-weight: 300;
				font-size: rem-calc(14px);
				line-height: rem-calc(18px);}
			.profile-job { color: $gray4; }
			.profile-company { color: $gray9; }

			.btn.btn-connect { margin-top: rem-calc(10px); }
		}
	}
//}
/*----------------------------------------------Home Page Featured Members-*/

/*-Square/Labeled Slider---------------------------------------------------*/
.gold-company-slider .owl-carousel,
.vr-cat-slider.owl-carousel {
	margin: rem-calc(36px) auto rem-calc(14px);
	@media #{$xsmall-only}, #{$small-only} {
		margin: rem-calc(20px) auto;
	}
	max-width: 90%;
	position: relative;
	.category-caption,
	.vr-cat {
		position: relative;
		img {
			position: relative;
			width: 100%;
		}
		.label {
			width: 100%;
			position: absolute;
			bottom: 0;
			background-color: rgba(55,55,55,0.5); //#3e3e3b
			color: #fff;
			font-weight: 300;
			line-height: rem-calc(45px);
			padding-left: rem-calc(15px);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
		}
		@media #{$small-only} {
			.label {
				font-size: rem-calc(12px);
				font-weight: 400;
			}
		}
		@media #{$xsmall-only} {
			.label {
				text-align: center;
				padding: rem-calc(0 5px);
				line-height: rem-calc(30px);
				.material-icons { display: none; }
			}
		}
		@media #{$medium-up} {
			.label { font-size: rem-calc(16px); }
		}
		@media #{$xlarge-up} {
			.label { font-size: rem-calc(18px); }
		}
	}
	.category-caption {
		@media #{$xsmall-only} {
			height: rem-calc(220px);
			overflow: hidden;
		}
		@media #{$small-up} {
			height: rem-calc(200px);
			overflow: hidden;
		}
		@media #{$large-up} {
			height: rem-calc(150px);
			overflow: hidden;
		}
	}
	.owl-nav {
	    width: 100%;
	    height: 100%;
		position: absolute;
	    top: 0;
		z-index: 2;
		div {
			height: 100%;
			padding-top: 5%;
			&.owl-prev { margin-left: -4%; }
			&.owl-next { margin-right: -4%; }
			@media #{$xsmall-only} {
				padding-top: 7%;
				&.owl-prev { margin-left: -8%; }
				&.owl-next { margin-right: -8%; }
			}
		}
	}
}
.vr-cat-slider.owl-carousel {
	@media #{$medium-up} { max-width: 100%; }
}
.gold-company-slider {
	@media #{$small-only}, #{$xsmall-only} {
		clear: both;
	}
}
.gold-company-slider .owl-carousel {
	@media #{$large-up} {
		.category-caption .label { padding-left: rem-calc(5px); }
	}
	@media #{$medium-only} {
		max-width: 85%;
		.owl-nav div {
			padding-top: 20%;
			&.owl-prev { margin-left: -8%; }
			&.owl-next { margin-right: -8%; }
		}
	}
	@media #{$small-only} {
		.owl-nav div {
			padding-top: 10%;
			&.owl-prev { margin-left: -5%; }
			&.owl-next { margin-right: -5%; }
		}
	}
	@media #{$xsmall-only} {
		max-width: 85%;
		.owl-nav div {
			padding-top: 20%;
			&.owl-prev { margin-left: -10%; }
			&.owl-next { margin-right: -10%; }
		}
	}
}

.owl-stage-outer { z-index:50; }
.owl-item { cursor:pointer; }
.inline-video { float:right; }
.video-list{ text-align:center; }
.video-list .btn.btn-sm { margin:auto; padding-right:8px; padding-left:8px; }

//END .gold-company-slider
/*---------------------------------------------------Square/Labeled Slider-*/

/*------------------Wiki Carousel (Image Not Provided------------------*/

.wiki-company-slider {
	overflow: hidden;
	margin-top: rem-calc(30px);
	.not-provided {
		width: 300%;
		display: inline-block;
		img {
			width: rem-calc(200px);
			margin-right: rem-calc(20px);
		}
		img.last { 
			margin-right: none;
		}
        @media #{$medium-only} {
			img.last {
				display: none;
			}
		}
	}
}
