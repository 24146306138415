/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * variables.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Variables
 *   - Colors and Mixins
 *   - Typography (Fonts and Icons)
 * + 
 * + 
 *
 * !! Name Your SASS Variables Modularly !!
 * Read More: http://webdesign.tutsplus.com/tutorials/quick-tip-name-your-sass-variables-modularly--webdesign-13364
 *
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Variables---------------------------------------------------------------*/
//_variables.scss file holds all global variables for the project (for typography, color schemes, and so on).
@import "colors";
@import "interpolations";
@import "typography";
