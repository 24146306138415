/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * switches.scss
 * =========================================================================
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*--- On/Off Switch ---*/
.switch label {
	color: transparent;
	background: $switch-off;
}
.switch input:checked + label { background: $blue-default; }

/*-Switch Labels-----------------------------------------------------------*/
.switch label {
	&:before {
		content: "OFF";
		color: #fff;
		font-size: rem-calc(14px);
		font-weight: 300;

		display: block;
		position: relative;
		top: rem-calc(6px);
		left: rem-calc(-32px);
		max-width: 0;

		-webkit-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
.switch input:checked + label {
	&:before {
		content: "ON";
		left: rem-calc(-56px);
	}
}

/* --- Large Switch --- */
.switch.large label {
	&:before {
		font-size: rem-calc(16px);
		left: rem-calc(-40px);
		top: rem-calc(8px);
	}
}
.switch.large input:checked + label {
	&:before { left: rem-calc(-68px); }
}

/* --- Small Switch --- */
.switch.small label {
	&:before {
		font-size: rem-calc(12px);
		left: rem-calc(-28px);
	}
}
.switch.small input:checked + label {
	&:before { left: rem-calc(-48px); }
}

/* --- Tiny Switch --- */
.switch.tiny label {
	&:before {
		font-size: rem-calc(10px);
		left: rem-calc(-24px);
		top: rem-calc(5px);
	}
}
.switch.tiny input:checked + label {
	&:before { left: rem-calc(-42px); }
}
/*-----------------------------------------------------------Switch Labels-*/