/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * header.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Header
 * + Top Navigation
 * + Tooltips
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Header Navigation-------------------------------------------------------*/
.primary-nav {
	position: relative;
	background-color: $blue-default;
	color: #fff;
	a {
		color: #fff;
		font-weight: 300;
		&[data-dropdown] { color: #fff; }
	}
	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
		&.container { margin: 0 auto; }
	}
	&.nav-bar {}
	.title-area {
		position: relative;
		.name {
			float: left;
			img.logo { padding: rem-calc(12px 0); }
		}
		.header-settings {
			display: table;
			height: rem-calc(76px);
			.settings {
				display: table-row;
				> li {
					display: table-cell;
					vertical-align: middle;
					@media #{$medium-up} { line-height: rem-calc(32px); }
					@media #{$small-only} { line-height: rem-calc(32px); }
					@media #{$xsmall-only} { line-height: rem-calc(21px); }
					font-size: rem-calc(14px);
					font-weight: 300;

					padding: rem-calc(0 16px);
					&:last-child { padding-right: 0; }
					&.small-avatar .avatar-status-widget { margin-bottom: rem-calc(-8px); }
					@media #{$small-only} {
						img.avatar { margin-bottom: rem-calc(4px); }
					}
					@media #{$xsmall-only} { 
						&.small-avatar .avatar-status-widget {
							margin-top: rem-calc(-2px);
							margin-bottom: 0;
							width: rem-calc(26px);
							height: rem-calc(26px);
						}
					}//END media
				}
				#messages {
					a.dropdown-toggle.dropper {
						position: relative;
						.fa-envelope {
							font-size: rem-calc(18px);
							color: #fff;
							&:hover { color: #fff; }
							&:active,&:focus {
								color: #fff;
								outline: none;
							}
						}
						.notifications {
							position: absolute;
							bottom: rem-calc(8px);
							left: rem-calc(10px);

							background-color: $notice-red;

							width: rem-calc(18px);
							height: rem-calc(18px);
							-moz-border-radius: 50%;
							-webkit-border-radius: 50%;
							border-radius: 50%;

							font-size: rem-calc(10px);
							line-height: rem-calc(18px);
							font-weight: 300;
							text-align: center;
							color: #fff;
							z-index: 2;
						}
					}
				}//END #messages
			}//END .settings
			a[data-dropdown] {
				font-size: rem-calc(14px);
				&:hover { color: #fff; }
				+ ul[data-dropdown-content] {
					margin-top: 15px;
					padding: 0;
					width: auto;
					text-align: left;
					li {
						margin: 0;
						padding: 0;
						a { padding: rem-calc(8); }
						&.logout, &.admin { border-top: solid 1px $gray9; }
						&.site-home { border-bottom: solid 1px $gray9; }
					}
				}//END .topnav-dd
			}
		}//END .header-settings
		.menu-icon {
			position: absolute;
			top: 50%;
			right: rem-calc(15px);
			margin-top: -2%;
			a span {
				position: relative;
				display: inline-block;
				width: rem-calc(25px);
				&::after {
					position: absolute;
					top: rem-calc(5px);
					right: rem-calc(5px);
					width: 16px;
					height: 0;
					box-shadow: 0 0 0 1px #FFFFFF, 0 7px 0 1px #FFFFFF, 0 14px 0 1px #FFFFFF;
					content: "";
				}
				&:hover:after { box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px ""; }
			}
		}
	}//END .title-area

	@media #{$small-only} {
		.title-area {
			padding: rem-calc(0 15px);
			.name img.logo {
				max-width: rem-calc(143px);
				padding: rem-calc(10px 0px);
			}
			.header-settings {
				max-width: rem-calc(300px);
				height: 30px;
				text-align: right;
				margin: 0 auto;
				padding: rem-calc(10px 0 7px);
				.settings {
					> li {
						padding: rem-calc(0 10px);
						&:last-child { display: none; }
					}
					#messages a.dropdown-toggle.dropper {
						.notifications {
							left: rem-calc(8px);
							font-size: rem-calc(9px);
							line-height: rem-calc(18px);
							width: rem-calc(16px);
							height: rem-calc(16px);
						}
					}
				}
			}
		}
	}
	@media #{$xsmall-only} {
		.title-area {
			.name img.logo { max-width: rem-calc(100px); }
			.header-settings {
				.settings {
					> li {
						padding: rem-calc(0 6px);
						.material-icons { font-size: rem-calc(18px); }
					}
					#messages a.dropdown-toggle.dropper {
						.fa-envelope { font-size: rem-calc(14px); }
						.notifications {
							font-size: rem-calc(8px);
							line-height: rem-calc(16px);
							width: rem-calc(14px);
							height: rem-calc(14px);
						}
					}
				}
			}
			.menu-icon { margin-top: -4%; }
		}
	}
	@media #{$medium-up} {
		.title-area {
			.header-settings { float: right; }
			.menu-icon { display: none; }
		}
	}

}
.homepage .primary-nav .title-area .header-settings .settings a[data-dropdown] + ul[data-dropdown-content] li.site-home { display: none; }

#noticemsg {
	display: none;
	.close-reveal-modal {
		color: $base;
		cursor: pointer;
		font-size: rem-calc(40px);
		font-weight: 100;
		line-height: 1;

		position: absolute;
		top: 0;
		right: rem-calc(10px);
	}
}//END #noticemsg

.notification-container {
	position: absolute;
	overflow-y: scroll;
	max-height: 90vh;
	z-index: 87;
	background-color: #fff;
	color: $gray4;
	text-align: left;
	cursor: default;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	&:focus { outline: none; }

	@media #{$medium-up} {
		top: rem-calc(20px);
		right: 0;
		width: rem-calc(450px);
		&:before {
			border: inset 6px;
			content: "";
			display: block;
			height: 0;
			width: 0;
			border-color: transparent transparent #FFFFFF transparent;
			border-bottom-style: solid;
			position: absolute;
			top: -12px;
			right: 4px;
			z-index: 89;
		}
		&:after {
			border: inset 7px;
			content: "";
			display: block;
			height: 0;
			width: 0;
			border-color: transparent transparent #cccccc transparent;
			border-bottom-style: solid;
			position: absolute;
			top: rem-calc(-14px);
			right: rem-calc(3px);
			z-index: 88;
		}
	}
	@media #{$small-only} {
		position: fixed;
		top: rem-calc(33px);
		left: rem-calc(8px);
		right: rem-calc(8px);

		margin: 0 auto;
		min-width: rem-calc(290px);
		width: 100%;
		max-width: 95%;
	}

	.notification-heading {
		margin-top: 0;
		padding: rem-calc(7px 10px 0);
		color: $gray4;
		.btn.js-notifications-mark-read {
			color: #fff;
			float: right;
			margin: rem-calc(8px 30px 12px 0);
		}
	}
	a {
		color:$blue-default;
		&:hover { color:$blue-hover; }
	}
	.notification {
		position: relative;
		width: 100%;
		clear: both;
		padding: rem-calc(7px 10px);
		border-top: solid 1px $silver;
		.avatar-holder {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: rem-calc(60px);
			height: 100%;
			padding: rem-calc(5px 10px 0 0);
			.avatar {
				position: relative;
				max-width: rem-calc(50px);
				max-height: rem-calc(50px);
				z-index: 1;
			}
			.notice-icon {
				position: absolute;
				border-radius: 50%;
				width: rem-calc(21px);
				height: rem-calc(21px);
				line-height: rem-calc(21px);
				text-align: center;
				color: white;
				bottom: 0;
				left: rem-calc(25px);
				z-index: 2;
				//This is for a default color
				background-color: $alert-info;
				//to be overwritten by appropriate classes
				&.connection-accepted,
				&.connection-request,
				&.status-increased,
				&.private-message {
					background-color: $alert-info;
				}
				&.post-comments,
				&.post-like {
					background-color: $alert-success;
				}
				&.status-warning { background-color: $alert-warning; }
				&.status-decreased { background-color: $alert-error; }
			}
		}
		.msg-text {
			display: inline-block;
			left: 70px;
			max-width: 85%;
			@media #{$xsmall-only} { max-width: 75%; }
	    	span.message-author { font-weight: bold; }
	    	.message-body {}
		}
	//	&.jared-intro .avatar-holder { height: rem-calc(170px); }
	}
	.view-all {
		display: block;
		clear: both;
		width: 100%;
		padding: rem-calc(7px 10px);
		margin: 0 auto;
		text-align: center;
		font-weight: 500;
		color: #fff;
		background-color: $main-blue;
	}

	&.f-dropdown.content {
		padding: rem-calc(0px);
		@media #{$medium-up} { max-width: rem-calc(450px); }
		@media #{$small-only} { max-width: rem-calc(300px); }
	}
}
.hcm-tagline { display: none; }
.hcm-products, .hcm-takeover {
	.hcm-tagline { display: block; }
}
/*--------------------------------------------------------Header Navigation*/

/*-Menu Navigation---------------------------------------------------------*/
.top-nav {
	background-color: $gray6b;
	a {
		font-family: $roboto;
		font-size: rem-calc(18px);
		font-weight: 100;
	}
	ul, li {
		list-style: none;
		padding:0;
		margin:0;
		.dd-menu { margin-left: 0; }
	}
	.secondary-nav > li > a {
		color: #fff;
		&:hover { font-weight: 300; }
	}
	#department-dd li {
		padding: rem-calc(5);
		margin: 0;
		a { text-align: left; }
		&:hover a {
			color: #fff;
			font-weight: 300;
		}
	}

	@media #{$small-only} {
		.container { padding: 0; }
		.secondary-nav {
			display: none;
			&.menu-opened { display: block; }
			a, ul a {
				font-size: rem-calc(18px);
				line-height: rem-calc(38px);
				font-weight: 300;
			}
			.tertiary-nav > li {
				#department-dd {
					max-width: rem-calc(300px);
					li {
						border-bottom: solid 1px $silver;
						&:last-child { border: none; }
					}
				}
				.mobile-home { display: inherit; }
			}
		}
		//Collapse like the top-nav
		.has-dd {
			width: 100%;
			display: block;
			+ .dd-menu { display: none; }
			&.dd-active + .dd-menu {
				display: block;
				background-color: #fff;
				padding: rem-calc(0 20px);
				a {
					color: $gray6b;
					&:hover { font-weight: 400; }
				}
			}
		}
	}//END media:small-only

	@media #{$medium-up} {
		.secondary-nav {
			position: relative;
			margin: 0 auto;
			margin-bottom: rem-calc(50px); //making space for .tertiary-nav
			padding: rem-calc(10px 8px);
			> li {
				float: left;
				&:first-of-type { margin-left: 0; }
				a {
					position: relative;
					font-size: rem-calc(16px);
					line-height: rem-calc(30px);
					
					margin: 0;
					display: inline-block;
					text-align: center;
					letter-spacing: 0.0078125em;
					&.nav-community { width: rem-calc(86px); }
					&.nav-ratings	{ width: rem-calc(112px); }
					&.nav-news		{ width: rem-calc(44px); }
					&.nav-events	{ width: rem-calc(51px); }
					&.nav-uni		{ width: rem-calc(73px); }
					&.nav-magazine	{ width: rem-calc(82px); }
					&.nav-data, &.nav-hcm {
					//	width: rem-calc(105px); // "Benchmarking"
						width: rem-calc(38px);
						+ .tertiary-nav > li { min-width: 190px; }
					}
					&.nav-about { display: none; }
					&.dd-active, &:hover { letter-spacing: 0; }
				}
				//This is up here because it relates to the width of the links
				//These styles are together for easier referencing
				.tertiary-nav > li {
					float: left;
					text-align: center;
					min-width: rem-calc(101px);
					margin: rem-calc(0 5px);
					&:first-child { margin-left: 0; }
					&:last-child { margin-right: 0; }
					.mobile-home { display: none; }
				}
			}

			.topnav-search {
				position: absolute;
				display: inline-block;
				right: 0;
				margin: 0;
				max-height: rem-calc(30px);
				z-index: 1;
				.input-group { max-width: rem-calc(170px); }

				input {
					float: right;
					border: solid 1px #acacac;
					border-right: transparent;
					border-collapse: collapse;
					box-shadow: none;
					height: rem-calc(30px);
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;

					-webkit-appearance: textfield;
					-webkit-box-sizing: border-box;

					-webkit-transition: all .5s;
					-moz-transition: all .5s;
					transition: all .5s;
				}
				button {
					margin-bottom: 0;
					height: rem-calc(30px);
					font-size: rem-calc(14px);
					padding: rem-calc(0 4px);
				}
				form input[type="search"] { margin: 0 0; }
			}//END .topnav-search

			//tertiary-nav
			.has-dd {
				width: 100%;
				display: block;
				+ .dd-menu.tertiary-nav { display: none; }
				&.dd-active + .dd-menu.tertiary-nav {
					display: block;
					position: absolute;
					top: rem-calc(50px); //placing it below the .secondary-nav
					left: 0; //rem-calc(21px);
					width: 100%;
					line-height: rem-calc(50px);
					@media #{$medium-only} {
						min-width: rem-calc(738px);
						left: 0;
					}
					> li {
						float: left;
						a {
							color: $gray4;
							font-weight: 300;
							text-align: center;
							&:hover { font-weight: 500; }
							&.dd-active,
							&:active {
								position: relative;
								font-weight: 400;
/*								&:after {
									position: absolute;
									color: #fff; //same as .teriary-nav background-color
									top: rem-calc(22px); //same as .tertiary-nav height
									left: 35%;
									font-size: rem-calc(40px);
									content: "\25be"; //try "\25be" or "\25bc"
									z-index: 9;
								}
*/							}
						}
					}
				}
			//	.dd-toggle:before { content: ""; color: $gray6b; }
				&.dd-active {
					position: relative;
					font-weight: 300;
					.dd-toggle {
						position: absolute;
						z-index: 2;
						left: 50%;
						top: rem-calc(40px); //same as .secondary-nav height
						margin-left: -10px; //same as border width
						width: 0;
						height: 0;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid $gray6b;
					}
				}
			}

		}
		@media #{$medium-only} {
			.secondary-nav {
				padding: rem-calc(10px 0px);
				> li {
					margin: rem-calc(0 6px);
				}
			}
		}//END media:medium-only
	}//END media:medium-up
	@media #{$large-up} {
		.secondary-nav {
			> li { margin: rem-calc(0 18px); }
			.topnav-search .search-box { width: rem-calc(125px); }
		}
	}//END media:large-up
	@media #{$xlarge-up} {
		.secondary-nav {
			> li { margin: rem-calc(0 22px); }
			.topnav-search .search-box { width: rem-calc(245px); }
		}
	}//END media:xlarge-up
}
/*---------------------------------------------------------Menu Navigation-*/

/*-Tooltips----------------------------------------------------------------*
.top-nav .secondary-nav .has-tip {
	border: none;
	cursor: pointer;
}
*/
@media #{$small-only} {
	.nav-bar {
		.has-dd.nav-events,
		.off-site {
			width: 100%;
			padding: 0.375rem 0.9375rem;
			display: block;

			.dd-toggle {
				float: right;
				font-weight: 100;
				&:after { content: "\203a"; }
			}
		}
	}
}
/*----------------------------------------------------------------Tooltips-*/
