/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * navigation.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Generic Modals
 * + Specific Modals
 *   - Login/Registration
 *   - Rate Vendor Modal
 *   - Min/Max Chars
 * + Welcome Slide show
 * + Who-Likes Tooltip/Modal
 * 
 * @TODO: Update TOC as necessary.
 * !! Name Your SASS Variables Modularly !!
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Modals------------------------------------------------------------------*/
.reveal-modal {
	position: fixed;
	overflow-y: auto;
	@media #{$medium-up} {
		max-height: 90vh;
		&[style] { top: 5% !important; }
	}
	@media #{$small-only} {
		min-height: auto;
		max-height: 98vh;
		&[style] { top: 1%!important; }
	}
	border: none;
	&.open {
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		@media #{$small-only} {
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
		}
	}
	&:focus { outline: none; }
	.close-reveal-modal { font-weight: 100; }
}
.reveal-modal-bg { background: rgba(255, 255, 255, 0.75); }

/* --- Start Discussion Modal --- */
#cke_topicBody { margin: rem-calc(5px 0 10px); }
@media #{$xsmall-only} {
	#theModal.reveal-modal .start-discussion .blue-header {
		font-size: rem-calc(30px);
		padding: rem-calc(12px 0px);
	}
}//END media:xsmall
/*------------------------------------------------------------------Modals-*/

/*-Login/Registration------------------------------------------------------*/
#theModal.reveal-modal {
	.close-register-modal{
		color: #aaaaaa;
		cursor: pointer;
		font-size: 2.5rem;
		font-weight: bold;
		line-height: 1;
		position: absolute;
		right: 1.375rem;
		top: 0.625rem;
	}
	.blue-header {
		background-color: $main-blue;
		color: #fff;
		font-size: rem-calc(40px);
		font-weight: 100;
		padding: rem-calc(5px 0px);
		text-align: center;

		margin: rem-calc(-30px -30px 35px);
		border-radius: 4px 4px 0px 0px;
		-moz-border-radius: 4px 4px 0px 0px;
		-webkit-border-radius: 4px 4px 0px 0px;
		@media #{$small-only} {
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			margin-bottom: rem-calc(15px);
			.logo { padding: rem-calc(0 0 5px); }
		}
		@media #{$medium-up} {
			.logo { padding: rem-calc(25px 0 40px); }
		}
		.subhead {
			display: block;
			font-size: rem-calc(20px);
		}
	}
	hr.or-line-break {
		padding: 0;
		border: none;
		border-top: solid 1px $gray7;
		color: $gray7;
		text-align: center;
		&:after {
			content: "or";
			display: inline-block;
			position: relative;
			top: rem-calc(-14px);
			font-size: rem-calc(18px);
			padding: rem-calc(0 10px);
			background: white;
		}
	}
	.alreadyExist {
		display: block;
		.btn-green-login {
			max-width: 50%;
			float: right;
		}
	}
	button { margin-bottom: 0; }
	.content {
		.email-updates-group ul{
			display: inline-block;
			width: 100%
		}
		.expand-newsletter {
			width: 100%;
			position: relative;
			text-align: center;
			z-index: 1;
			&:before {
				border-top: 1px solid $grey6;
				content:"";
				margin: 0 auto;
				position: absolute;
				top: 50%;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: -1;
			}
			.expand-more, .expand-less {
				cursor: pointer;
				font-size: rem-calc(12px);
				background-color: #fff;
				color: $gray3;
				.blue { color: $main-blue; }
			}
			.expand-more { display: inline; }
			.expand-less { display: none; }
			+ .advanced-newsletter { display: none; }
			&.advanced {
				.expand-more { display: none; }
				.expand-less { display: inline; }
				+ .advanced-newsletter { display: inherit; }
			}
		}
		.advanced-newsletter {
			width: 100%;
			min-height: 150px;
			@media #{$xsmall-only} {
				p:not(#ie8) > input[type=checkbox] + label {
					width: 100%;
					margin-left: 0;
				}
				p { margin-bottom: 0; }
			}
		}
		@media #{$xsmall-only} {
			#NagNewsletter {
				.move-along {
					.btn {
						font-size: rem-calc(12px);
						line-height: rem-calc(18px);

						padding: 6px 16px;
						&.btn-green-login { padding: 3px 11px 3px 16px; }
					}
				}//END .move-along
			}//END #NagNewsletter
		}//END media
		.pagination-header {
			width: 100%;
			clear: both;
			margin: rem-calc(10px 0 0);
			list-style: none;
			text-align: center;
			li {
				float: left;
				width: 33%;
				position: relative;
				margin: rem-calc(10px 0);
				border: solid 1px $main-blue;
				color: $main-blue;
				&.current{
					background-color: $main-blue;
					color: #fff;
				}
				&:nth-child(2) {
					border-left: 0;
					border-right: 0;
				}
			}
		}
		.tos{
			height:300px;
			overflow-y: auto;
			width:100%;
		}
		h2 {
			color:$login-green;
			text-align: center;
		}
		.instructions {
			color: $gray4;
			text-align: center;
			font-size: rem-calc(20px);
			font-weight: 100;
			line-height: rem-calc(24px);
		}
		.description {
			text-align: center;
			font-size: rem-calc(17px);
			color: $blue-text;
		}
		fieldset {
			border: 0px;
			margin: 0px;
			padding: 0 10px;
		}

		ul {
			float: left;
			margin: 0px 0px 10px;
			list-style: outside none none;
			padding: 0px;

		}
		.columns {
			-webkit-columns: 2;
			-moz-columns: 2;
			columns: 2;
			@media #{$xsmall-only} {
				-webkit-columns: 1;
				-moz-columns: 1;
				columns: 1;
			}//END media:xsmall
		}

		.progress-dots {
			float: none;
			display: block;
			max-width: rem-calc(115px);
			margin: 20px auto 0;
			text-align: center;
			li {
				float: left;
				border-radius: 50%;
				border: solid 1px $main-blue;
				height: rem-calc(15px);
				width: rem-calc(15px);
				margin: rem-calc(0 1);
				line-height: rem-calc(36px);
				&.filled { background-color: $main-blue; }
			}
			@media #{$small-only} { padding-top: rem-calc(12px); }
			@media #{$xsmall-only} { clear: both; }
		}//END .progress-dots
	}
	.move-along {
		clear: both;
		padding-top: rem-calc(30px);
		.page-numbers {
			display: inline-block;
			margin: rem-calc(11px 0);
		}
		.btn-green-login { width: 100%; }
		.btn-blue-login { width: 100%; background-color: $main-blue; }
		.close-modal{
			display: block;
			text-align: center;
			margin-top: 10px;
		}
		a {
			display:block;
			text-align:center;
			width:100%;
		}
		.btn-stroked-blue {
			width:100%;
			margin-right:rem-calc(10px);
		}
		.dont-ask {
			color: $gray7;
			font-weight:300;
			margin: rem-calc(20px 0px 0px 0px);
		}
	}
	.modal-footer {
		margin-top: rem-calc(20px);
		.btn-stroked-blue {
			color: $main-blue;
			&.register-back { float: left; }
		}
	}
	@media #{$medium-up} {
		&.login-reg_modal { max-width: rem-calc(500px); }
	}
}

/* --- Login Modal --- */
.loginModal {
	.btn-link { width: rem-calc(196px); }
	.need2signup {
		float: left;
		margin-right: 20px;
	}
	.btn-green-login { width: rem-calc(196px); }
	.btn-facebook {
		float: right;
		margin: rem-calc(-72px 0 0);
	}
	@media #{$xsmall-only} {
		.btn-link { width: 100%; }
		.need2signup {
			float: none;
			width: 100%;
			text-align: center;
			margin: 0px auto;
		}
		.btn-green-login { width: 100%; }
		.btn-facebook {
			float: none;
			clear: both;
			width: 100%;
			margin: 10px auto 0;
		}
	}
}

/* --- Register-Email Modal --- */
.register-email {
	.join-us {
		font-weight: 100;
		font-size: rem-calc(37px);
		line-height: rem-calc(36px);
		.join {
			font-weight: 700;
			color: $login-green;
		}
	}
	.description { font-size: rem-calc(20px); }
	.btn-green-login {
		width: 100%;
		margin-bottom: 0;
	}
	.btn-facebook {
		width: 100%;
		span { float: left; }
	}
}
@media #{$medium-up} {
	.register-email .join-us { text-align: center; }
}
@media #{$small-only} {
	.register-email {
		.join-us {
			font-size: rem-calc(29px);
			line-height: rem-calc(30px);
		}
		.description {
			font-size: rem-calc(16px);
			line-height: rem-calc(20px);
		}
	}
}
@media #{$xsmall-only} {
	.register-email .join-us {
		font-size: rem-calc(21px);
		line-height: rem-calc(24px);
	}
}
/* --- Register-Welcome Modal --- */
.register-welcome {
	.form-element-required { width: 100%; }
	.required-fyi {
		float: right;
		color: $error-red;
		font-size: rem-calc(10px);
	}
}
/* --- Register-Profile Modal --- */
.move-along .btn-link { float: right; }
/*------------------------------------------------------Login/Registration-*/

/*-Rate Vendor Modal-------------------------------------------------------*/
.rate-vendor-start {
	font-family: $roboto;
	h3.rate-vendor {
		margin: rem-calc(50px) auto 0;
		width: 100%;
		padding: rem-calc(15px 20px);
		color: #fff;
		font-size: rem-calc(30px);
		line-height: rem-calc(30px);
		text-align: center;
		font-weight: 100;
		background-color: $vendor-orange;
		border-top-left-radius: rem-calc(5px);
		border-top-right-radius: rem-calc(5px);
		svg {
			width: rem-calc(30px);
			height: rem-calc(30px);
		} 
		.subhead {
			display: block;
			font-size: rem-calc(16px);
			font-weight: 100;
		//	font-style: italic;
			@media #{$medium-only}, #{$small-only} {
				font-size: rem-calc(14px);
			}
			@media #{$xsmall-only} {
				text-align: right;
				margin-right: rem-calc(30px);
			}
		}
	}
	.rate-vendor-steps {
		display: none; //hide modals
		&.active { display: block; } //show modals
		border: solid 1px $silver;
		border-top: none;
		padding: rem-calc(20px 15px);

		.pagination-header {
			width: 100%;
			height: rem-calc(50px);
			clear: both;
			margin: rem-calc(-10px 0 30px);
			list-style: none;
			text-align: center;
			li {
				float: left;
				position: relative;
				margin: rem-calc(10px 0);
				padding: rem-calc(10px);
				border: solid 1px $form-border;//$vendor-orange;
				color: $gray96;//$vendor-orange;
				&:first-child {
					width: 45%;
					border-right: 0;
				}
				&:nth-child(2) {
					width: 29%;
					border-left: 0;
					border-right: 0;
				}
				&:last-child { width: 20%;
					border-left: 0;
				}
				&:before, &:after {
					left: 100%;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
				&:after {
					border-color: transparent;
					border-left-color: #fff;
					border-width: 22px;
					margin-top: -22px;
					z-index: 2;
				}
				&:before {
					border-color: transparent;
					border-left-color: $form-border;//$vendor-orange;
					border-width: 23px;
					margin-top: -23px;
				}
				&.current{
					background-color: $vendor-orange;
					color: #fff;
					&:after { border-left-color: $vendor-orange; }
				}
			}
			@media #{$small-only} {
				li { font-size: rem-calc(14px);
					&:nth-child(2) { padding: rem-calc(10px 5px 10px 25px); }
					&:last-child { padding: rem-calc(10px 5px 10px 25px); }
				}
			}
			@media #{$xsmall-only} {
				display: none;

				height: auto;
				width: 100%;
				li {
					float: none;
					clear: both;
					font-size: rem-calc(16px);
					font-weight: 300;
					margin: 0;
					&:first-child, &:last-child,
					&:nth-child(2) {
						width: 90%;
						border-left: solid 1px $form-border;
					}
				}
			}
		}//END .pagination-header

		.step-title {
			display: block;
			text-align: center;
			margin-bottom: rem-calc(10px);
			font-size: rem-calc(24px);
			font-weight: 300;
			strong {
				color: $vendor-orange;
				font-weight: 500;
			}
			&.success strong {
				color: $green-btn;
				font-weight: 300;
			}
			&.rate-another {
				clear: both;
				margin-top: rem-calc(20px);
			}
			@media #{$xsmall-only} { font-size: rem-calc(25px); }
		}
		.step-instructions {
			font-size: rem-calc(16px);
			line-height: rem-calc(22px);
			font-weight: 300;
			color: $gray4;
			strong { font-weight: 500; }
			.text-link {
				display: block;
				text-align: right;
			}
		}

		.productStats {
			clear: both;
			width: 100%;
			margin: rem-calc(10 0);
		}

		select {
			display: block;
			margin: rem-calc(10px) auto;
		}
		textarea {
			min-height: rem-calc(70px);
			line-height: rem-calc(20px);
		}
		.noVendor {
			text-align: center;
			font-size: rem-calc(14px);
			line-height: rem-calc(24px);
			font-weight: 300;
			a {
				font-weight: 400;
				//@media #{$large-only} { display: block; }
			}
			@media #{$medium-up} {
				.line-break { display: none; }
			}
		}
		.recommend {
			font-weight: 300;
			.recommend-radio {
				max-width: 100%;
				margin: rem-calc(5) auto;
				p:not(#ie8) {
					float: left;
					width: 50%;
					> input[type="radio"] + label { display: inline-block; }
				}
			}//END .recommend-radio
		}
		.next-nav {
			margin-top: rem-calc(20px);
			min-height:40px;
			.btn {
				width: 100%;
				min-width: rem-calc(110px);
			//	margin-left: rem-calc(15px);
			}
			.btn-left {
				width: 48%;
				float: left;
			}
			.btn-right {
				width: 48%;
				float: right;
			}
		}
		.step-progress {
			margin: rem-calc(50px) auto 0;
			list-style: none;
			max-width: rem-calc(100px);
			text-align: center;
			li {
				display: inline-block;
				margin: rem-calc(0 1px);

				border-radius: 50%;
				border: solid 1px $vendor-orange;
				width: 10px;
				height: 11px;
				&.active { background-color: $vendor-orange; }
			}
		}
		.btn-back {
			float: left;
			min-width: rem-calc(110px);
			margin-top: rem-calc(35px);
		}
/*	// Rate Vendor - Step 1	
		&.rateVendorStep1 {
			#productSelect,
			#vendorSelect {
				float: left;
				width: 49%;
			}
			#vendorSelect { margin-right: 2%; }
			@media #{$xsmall-only} {
				#productSelect,
				#vendorSelect {
					float: none;
					width: 100%;
					margin-right: 0;
				}
			}
		}
*/
	// Rate Vendor - Step 
		&.rateVendorStep2 {
			.rating-description,
			.step-instructions {
				margin: rem-calc(20px 0);
				font-size: rem-calc(20px);
				position: relative;
				text-align: center;
			}
			.star-ratings-sprite {
				margin: auto;
			}
			.rating-description {
				display: block;
				text-align: center;
				margin: rem-calc(15px 0 20px);
			}
			#step2RateProductStarText { display: none; }

			.write-review {
				flex-direction: row;
				flex-wrap: wrap;
				margin: rem-calc(20px 0);
				input[type="text"], textarea { 
					width: 100%;
				}
				textarea {
					padding-top: 7px;
					min-height: 4.5rem;
					margin-bottom: 0;
				}
			}//END .write-review
			.step-instructions { margin-top: 0; }
		}
	// Add Vendor - Step 2
		&.addVendorStep2 {
			.btn-orange.addVendor {
				float: right;
				margin-bottom: rem-calc(20px);
			}
		}
	}
	@media #{$xsmall-only} {
		h3.rate-vendor { margin-top: rem-calc(30px); }
		.rate-vendor-step1 {
			.noVendor a { display: block; }
			.next-nav a.btn-green {
				width: 100%;
				padding: rem-calc(5 10);
			}
		}
	}
//How it works
	.vrModal-step1 {
		.rateVendorStep1 { border: none; }
		.how-it-works { display: none; }
		.rateVendorStep1.active + .how-it-works { display: block; }
	}
	.how-it-works {
		background-color: $blue-bg-disabled;
		margin: rem-calc(10px 0 0);
		padding: rem-calc(1px 0px 15px);
		text-align: center;
		h3 {
			color: $vendor-orange;
			font-size: rem-calc(20px);
			font-weight: 300;
			letter-spacing: rem-calc(10px);
			text-align: center;
			text-transform: uppercase;
			margin-bottom: rem-calc(20px);
			padding: rem-calc(0 10px);
		}
		ol {
			position: relative;
			list-style: none;
			counter-reset: li;

			text-align: center;
			width: 100%;
			clear: both;
			margin: 0;
			padding: 0;
			li {
				position: relative;
				counter-increment: step;

				display: inline-block;
				max-width: 30%;
				margin: 0 auto rem-calc(20px);
				&:before {
					content: counter(step, decimal);
					font-family: $open-sans;
					font-size: rem-calc(15px);
					background: $vendor-orange;
					color: #fff;
					position: absolute;
					top: rem-calc(14px);
					left: 0;
					width: rem-calc(25px);
					height: rem-calc(25px);
					line-height: rem-calc(25px);
					text-align: center;
					border-radius: 50%;
					vertical-align: middle;
				}
				span {
					display: inline-block;
					max-width: 35%;
					text-align: left;
					font-size: rem-calc(10px);
					line-height: rem-calc(12px);
					font-weight: bold;
					color: $gray4;
					margin-top: 4%;
				}
				img {
					display: inline-block;
					float: left;
					max-height: rem-calc(54px);
					width: auto;
					margin: 0 3% 7% 16%;
				}
				&:nth-child(2) {
					img { min-height: rem-calc(60px); }
					span { max-width: 42%; }
				}
			}
		}
		@media #{$xsmall-only} {
			ol {
				padding: rem-calc(20px);
				li {
					float: none;
					display: block;
					clear: both;
					max-width: 100%;
					&:nth-child(2) {
						span { margin-left: rem-calc(38px); }
					}
				}
			}
		}
	}//END .how-it-works
}//END Vendor Modal
.write-review {
	.avPhone { position: relative; z-index: 1; margin-bottom: 5px; }
	textarea { padding-top: 7px; }
}

.addVendorProduct { text-align: center; }
select.step1AboutProductSelect { margin-top: 0; }
.onPage.rate-vendor-start {
	.rate-vendor-steps .step-progress {
		line-height: 16px;
		margin: rem-calc(30px) auto 0;
	}
	.close-reveal-modal { display: none; }
}

.company-about .onPage .noVendor {
	.addVendor, .or { display: none; }
}

#theModal.vrModal {
	.close-reveal-modal { color: #fff; }
	@media #{$medium-up} {
		max-width: rem-calc(500px);
		padding: 0;
	}
	h3.active { margin-top: 0; }
	label {
		position: relative;
		display: inline-block;
		font-weight: 500;
		margin-top: rem-calc(10);
	}
	input[type="text"], select {
		display: block;
		width: 100%;
	}
	.noVendor {
		display: block;
		clear: both;
	}
	@media #{$small-only} {
		padding: 0;
		margin: auto;
		.write-review label { margin-top: 0; }
		&.reveal-modal { min-height: auto; }
	}
}//END #theModal

@media (max-height: 540px) and #{$medium-up} {
	#theModal.vrModal {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding-bottom: 60px;
	}//END #theModal
}//END media:height
@media (max-height: 540px) and #{$medium-up} {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		#theModal.vrModal .step-progress { padding-bottom: 50px; }
	}//END IE
	@supports (-ms-accelerator:true) {//Target Edge
		#theModal.vrModal .step-progress { padding-bottom: 50px; }//END .step-progress
	}//END Edge
}

.addVendorStep1, .addVendorStep2, .addVendorStep3, .addVendorHeading, .addProductHeading,
.rateVendorStep1, .rateVendorStep2, .rateVendorStep3, .rateVendorHeading,
.addAProduct {
	display: none;
	&.active { display: block; }
}

/*-Min/Max Chars-----------------------------------------------------------*/
.minMaxWrapper {
	position: relative;
}
.minMaxOutput {
	position: absolute;
	bottom:0px;
	right:rem-calc(4px);
	opacity:.75;
	font-size:rem-calc(9px);
	line-height:rem-calc(12px);
	height:rem-calc(12px);
	padding:0 rem-calc(4px);
	background:rgba(255,255,255,0.5);
	border-radius:rem-calc(4px);
}
.minMaxOutput.textareaType {
	bottom:rem-calc(7px);
	right:rem-calc(20px);
}
.addProductStep3 {
	.minMaxOutput.textareaType {
		bottom: 0;
	}
}
/*-----------------------------------------------------------Min/Max Chars-*/

/*-Welcome Slide show------------------------------------------------------*/
.welcomeModal {
	overflow-y: visible;
	@media #{$medium-up} { max-width: rem-calc(580px); }
	padding: 0;
	.modal-content {
		position: relative;
		padding: rem-calc(25px 30px);
		color: #fff;
		background-color: $main-blue;
		text-align: center;

		h3 {
			color: #fff;
			font-weight: 300;
		}
		p, ul {
			font-size: rem-calc(20px);
			font-weight: 100;
		}

		.modalNav {
			position: absolute;
			top: rem-calc(188px); //50%;
			&.next {
				right: rem-calc(-60px);
				 .fa { margin-left: rem-calc(5px); }
			}
			&.prev {
				left: rem-calc(-60px);
				 .fa { margin-right: rem-calc(5px); }
			}

			background-color: #fff;
			border-radius: 50%;
			height: rem-calc(40px);
			width: rem-calc(40px);
			text-align: center;

			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
			.fa {
				color: $main-blue;
				font-size: rem-calc(30px);
				line-height: rem-calc(40px);
			}
		}
	}
	.modal-footer {
		position: relative;
		padding: rem-calc(20px 30px);
		background-color: #fff;
		.progress-dots {
			float: none;
			display: block;
			max-width: rem-calc(70px);
			min-height: rem-calc(10px);
			margin: 0 auto;
			text-align: center;
			list-style: none;
			li {
				float: left;
				border-radius: 50%;
				border: solid 1px $main-blue;
				height: rem-calc(10px);
				width: rem-calc(10px);
				margin: rem-calc(0 2);
				line-height: rem-calc(36px);
				&.filled { background-color: $main-blue; }
			}
		}//END .progress-dots
	}//END .modal-footer

	&#welcomeOne {
		.modal-content {
			color: $gray9;
			background-color: #fff;
			padding: rem-calc(60px 30px 0);
			h3 { color: $gray4; }
		}
		.modal-footer { padding: rem-calc(10px 30px 30px); }
	}//END #welcomeOne
	&#welcomeThree {
		p, ul { text-align: left; }
		ul {
			padding-left: 16px;
			&.progress-dots { padding: 0; }
		}
	}//END #welcomeThree
	@media #{$small-only} {
		&.reveal-modal { min-height: auto; }
		.modal-content {
			padding: rem-calc(20px 20px 1px);
			.slide-icon { max-height: rem-calc(55px); }
			h3 { margin-top: rem-calc(15px); }
			p, ul {
				font-size: rem-calc(16px);
				font-weight: 300;
			}
			.modalNav {
				top: auto;
				bottom: -55px;
				z-index: 2;
				&.next { right: rem-calc(20px); }
				&.prev { left: rem-calc(20px); }
				background-color: $main-blue;
				.fa { color: #fff; }
			}
		}
		.modal-footer {
			z-index: 1;
			padding: rem-calc(30px);
		}

		&#welcomeOne .modal-content { padding: rem-calc(25px 20px 0); }
		&#welcomeThree p { margin-bottom: 0; }
	}//END media:small
}//END .welcomeModal
/*------------------------------------------------------Welcome Slide show-*/

/*-Who-Likes Tooltip/Modal-------------------------------------------------*/
.voteSummary {
	&.has-tip {
		border-bottom: 0px;
		color: $main-blue;
		cursor: pointer;
		&:hover {
			border-bottom: 0px;
			color: $blue-hover;
			cursor: pointer;
		}
	}
/*
	ul[data-dropdown-content]{
		background-color: #333333;
		opacity: .9;
	}
	ul[data-dropdown-content] li {
		background-color: #ffffff;
		margin: 0.625rem 0;
		padding: 0.4375rem 0;
	}
	.mock-tip li {
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
		line-height: 16px;
		margin: 5px !important;
		padding: 0 !important;
		text-align: left;
	}
*/
}

.who-likes {
	.blue-header i.header-icon { font-size: 3rem; }

	.ppl-who-liked {
		.person {
			clear: both;
			min-height: rem-calc(30px);
			line-height: rem-calc(30px);
			margin: rem-calc(5px 0);
			&:last-child { margin-bottom: 0; }
			.pwl-photo {
				float: left;
				margin-right: rem-calc(10px);
			}
			.pwl-name {
				float: left;
				font-weight: 400;
				font-size: rem-calc(20px);
			}
			.pwl-button { float: right; }
		}//END .person
	}//END .ppl-who-liked

	@media #{$small-only} {
		.ppl-who-liked .person {
			margin: rem-calc(15px 0);
			@media #{$xsmall-only} {
				.pwl-button {
					clear: both;
					float: none;
					width: 100%;
					text-align: right;
				}
			}//END media:xsmall
		}//END .person
	}//END media:small

	.has-tip {
		color: #ffffff;
		cursor: pointer;
		&:hover {
			color: #ffffff;
			cursor: pointer;
		}
	}

}
#theModal.reveal-modal .who-likes .blue-header {
	text-align: left;
	padding-left: 35px;
}
/*-------------------------------------------------Who-Likes Tooltip/Modal-*/
