/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * profiles.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Profile Page
 * + Profiles Landing Page
 * + Import: User Profile
 * + Import: Company Pages
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Profile Page------------------------------------------------------------*/
.data-point {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    font-weight: 300;
    padding-bottom: rem-calc(10px);
    .info-label {
        color: $gray7;
        padding-right: rem-calc(5px);
    }
    &.about {
        clear: both;
        .info-label {}
        .info-data {
            .editable-container,
            .editable-input,
            .input-large { width: 100%; }
        }
    }
    .wh-wrap, .edu-wrap {
        margin:4px 0 12px 0;
    }
}

.profilePage h3 {
    color: $gray3;
    &.headline-border.interests { margin-top: rem-calc(30px); }
}

.more-arrow {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
    color: $gray80;
    margin-top: rem-calc(15px);
    a {
        font-size: rem-calc(14px);
        font-weight: 300;
    }
    .material-icons { font-size: rem-calc(20px); }
}

.connections, .followers {
    &.column-one-third { margin-top: rem-calc(20px); }
    .people .avatar {
        float: left;
        margin: rem-calc(10px);
    }
}

.editable {
    font-weight: normal;
}
div.editable {
    display: inline-block;
}
/*------------------------------------------------------------Profile Page-*/

/*-Profiles Landing Page---------------------------------------------------*/
.profiles-landing {
    &.content { margin-top: 0; }
/* --- Hero --- */
    .hero-banner {
        background-color: rgba(0,0,0,0.35);
        background-repeat: no-repeat;
        background-position: center top;
        @media #{$small-only} {
        //  margin-bottom: rem-calc(20px);
            background-image: url('../images/category-banners/profiles-home_sm.jpg');
            .container { height: rem-calc(259px); }
        }
        @media #{$medium-only} {
        //  margin-bottom: rem-calc(30px);
            background-image: url('../images/category-banners/profiles-home_md.jpg');
            .container { height: rem-calc(335px); }
        }
        @media #{$large-up} {
        //  margin-bottom: rem-calc(40px);
            background-image: url('../images/category-banners/profiles-home_lg.jpg');
            .container { height: rem-calc(405px); }
        }
        @media #{$xlarge-up} {
            background-image: url('../images/category-banners/profiles-home_xl.jpg');
            background-repeat: no-repeat;
            background-position: center top;
        }
        @media #{$xxlarge-up} {
            background: url('../images/category-banners/profiles-home_xl.jpg'), #b8b8b6;
            background: url('../images/category-banners/profiles-home_xl.jpg'), -moz-linear-gradient(left, #b8b8b6 49%, #babec1 51%);
            background: url('../images/category-banners/profiles-home_xl.jpg'), -webkit-gradient(linear, left top, right top, color-stop(49%,#b8b8b6), color-stop(51%,#babec1));
            background: url('../images/category-banners/profiles-home_xl.jpg'), -webkit-linear-gradient(left, #b8b8b6 49%,#babec1 51%);
            background: url('../images/category-banners/profiles-home_xl.jpg'), -o-linear-gradient(left, #b8b8b6 49%,#babec1 51%);
            background: url('../images/category-banners/profiles-home_xl.jpg'), -ms-linear-gradient(left, #b8b8b6 49%,#babec1 51%);
            background: url('../images/category-banners/profiles-home_xl.jpg'), linear-gradient(to right, #b8b8b6 49%,#babec1 51%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b8b8b6', endColorstr='#babec1',GradientType=1 );
            background-repeat: no-repeat;
            background-position: center top;
        }
        .container {
            position: relative;
            h2 {
                color: #fff;
                font-weight: 300;
            }
            .search-blogs {
                position: absolute;
                color: #fff;
                left: 0; 
                bottom: 50px;
                .input-group {
                    max-width: rem-calc(477px);
                    button { margin-bottom: 0; }
                }

            }
            .write-post {
                position: absolute;
                right: 0;
                bottom: 45px;
            }
            @media #{$large-up} {
                h2 {
                    margin: rem-calc(85px 0 100px);
                    line-height: rem-calc(50px);
                }
            }
            @media #{$medium-only} {
                h2 {
                    margin: rem-calc(80px 0);
                    line-height: rem-calc(35px);
                }
            }
            @media #{$small-only} {
                h2 {
                    margin: rem-calc(45px 0 30px);
                    line-height: rem-calc(27px);
                }
                .search-blogs {
                    position: static;
                    float: left;
                    clear: left;
                    max-width: 50%;
                    text-align: center;
                }
                .write-post {
                    position: relative;
                    float: right;
                    margin-top: rem-calc(50px);
                    padding: rem-calc(5px 10px);
                    font-size: rem-calc(12px);
                }
            }
            @media #{$xsmall-only} {
                h2 {
                    font-size: rem-calc(24px);
                    line-height: rem-calc(24px);
                    margin: rem-calc(30px 10px 20px 0);
                    br { display: none; }

                }
                .search-blogs {
                    max-width: 80%;
                    text-align: left;
                    .input-group {
                        margin-top: rem-calc(5px);
                        width: 100%;
                    }
                }
                .write-post {
                    float: none;
                    width: 100%;
                }
            }//END media:xsmall
        }
    }
}
/*---------------------------------------------------Profiles Landing Page-*/
@import "profiles-user.scss";
@import "profiles-company.scss";
