/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * profile-header.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Profile Header
 *	 - Cover Photo
 *	 - Profile Content
 * + Profile Navigation
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Profile Header----------------------------------------------------------*/
.company-profile-header,
.profile-blog {
	position: relative;
	.modal-cropper {
		position: absolute;
		opacity: 0.7;
		z-index: 4;
		cursor: pointer;
		&.editCoverPhoto {
			color: #eee;
			top: rem-calc(5px);
			left: rem-calc(5px);
			text-shadow: 0 0 4px #000;
		}
		&.editProfilePic {
			color: #999;
			left: rem-calc(13px);
			@media #{$small-only} { bottom: rem-calc(-27px); }
			@media #{$xsmall-only} {
				left: rem-calc(8px);
				bottom: -18px;
			}
			@media #{$medium-only} { bottom: rem-calc(-29px); }
			@media #{$large-up} {
				left: rem-calc(23px);
				bottom: rem-calc(-16px);
			}
			@media #{$xlarge-up} { bottom: rem-calc(-31px); }
		}
	}//END .modal-cropper

	.editProfilePicWrapper {
		.editProfilePicText { display: none; }
		&:hover {
			position: absolute;
			z-index: 3;
			left: rem-calc(13px);
			@media #{$small-only} { bottom: rem-calc(-28px); }
			@media #{$xsmall-only} {
				left: rem-calc(8px);
				bottom: -19px;
			}
			@media #{$medium-only} { bottom: rem-calc(-30px); }
			@media #{$large-up} {
				left: rem-calc(23px);
				bottom: rem-calc(-17px);
			}
			@media #{$xlarge-up} { bottom: rem-calc(-32px); }

			color: #fff;
			text-shadow: none;
			background-color: #000;
			border: solid 1px #eee;
			margin: 0 0 0 -3px;
			padding: 2px 5px 0 2px;

			.modal-cropper.editProfilePic  {
				opacity: 1;
				color: #fff;
				position: relative;
				display: inline;
				left: 0;
				bottom: 0;
			}
	        .editProfilePicText {
				display: inline;
				font-size: smaller;
			}
		}//END hover
    }//END .editProfilePicWrapper

	.editCoverPicWrapper {
		position: absolute;
		top: rem-calc(5px);
		left: rem-calc(5px);

		.modal-cropper {
			position: relative;
			&.editCoverPhoto {
				color: #fff;
				top: rem-calc(0px);
				left: rem-calc(0px);
				text-shadow: 0 0 4px #000;
			}
		}
		.editCoverPicText { display: none; }

		&:hover {
			color: #fff;
			text-shadow: none;
			background-color: #000;
			border: solid 1px #eee;
			margin: -3px 0 0 -3px;
			padding: 2px 5px 0 2px;

			.editCoverPicText {
				display: inline;
				font-size: smaller;
			}
			.modal-cropper.editCoverPhoto {
				opacity: 1;
				color: fff;
				text-shadow: none;
			}
		}//END hover
	}//END .editCoverPicWrapper

	.holdProfilePic { position: static; }
	.cover-photo {
		position: relative;
		height: rem-calc(220px);
		background-repeat: no-repeat;
		background-position: center top;
		border: solid 1px $grey6;
		color: #fff;
		margin-bottom: rem-calc(50px);
		/*.cover-gradient {
			position: absolute;
			width: 100%;
			height: 100%;

			background: -moz-linear-gradient(top, rgba(55,108,175,0.26) 0%, rgba(6,11,18,0.26) 100%);
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(55,108,175,0.26)), color-stop(100%,rgba(6,11,18,0.26)));
			background: -webkit-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -o-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: -ms-linear-gradient(top, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			background: linear-gradient(to bottom, rgba(55,108,175,0.26) 0%,rgba(6,11,18,0.26) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42376caf', endColorstr='#42060b12',GradientType=0 );
		}*/

		.profile-content {
			position: relative;
			width: 100%;
			height: 100%;
			.avatar-status-widget {
				float: left;
				margin-left: rem-calc(10px);

				width: 100%;
				height: 100%;
				max-width: rem-calc(175);
				max-height: rem-calc(175);
				min-width: rem-calc(104);
				min-height: rem-calc(104);
				img.avatar {
					-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
					-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
					box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);

					width: 100%;
					height: 100%;
					border: solid 2px #fff;
					padding: 0;
				//	background: #d1d2d4 url('../images/avatars/image-not-provided.jpg') no-repeat center center;
				//	background-size: contain;
					background-color: #fff;
				}

				&.contributor, &.senior-contributor, &.master {
					img.avatar {
						border: 0;
						-webkit-box-shadow: none;
						-moz-box-shadow: none;
						box-shadow: none;
					}
					@media #{$medium-up} {
						.status-level {
							width: calc(100% + 20px);
							height: calc(100% + 20px);
							margin: -10px;
						}
					}
					@media #{$small-only} {
						.status-level {
							width: calc(100% + 12px);
							height: calc(100% + 12px);
							margin: -6px;
						}
						@media #{$xsmall-only} { max-width: rem-calc(130); }
					}//END media:small
				}//END status avatar
			}

/* --- User name --- */
			h1 {
				position: absolute;
				bottom: 10px;
				color: #fff;
				text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
				.job-title {
					display: block;
					font-style: italic;
					font-weight: 100;
					font-size: rem-calc(24px);
					line-height: rem-calc(24px);
				}
			}
			@media #{$small-only} {
				h1 {
					margin: 0 rem-calc(40px) 0 29%;
					max-width: 70%;
					max-height: rem-calc(200px);
					line-height: rem-calc(34px);
					overflow-y: hidden;
					.job-title {
						font-size: rem-calc(20px);
						line-height: rem-calc(24px);
					}
				}
			}
			@media #{$xsmall-only} {
				h1 {
					font-size: rem-calc(23px);
					line-height: rem-calc(25px);
					max-width: 50%;
					margin: 0 rem-calc(35px) 0 40%;
					bottom: 5px;
					.job-title {
						font-size: rem-calc(18px);
						line-height: rem-calc(20px);
					}
				}
			}
			@media #{$medium-up} {
				h1 {
					max-width: rem-calc(380px);
					line-height: rem-calc(44px);
					left: rem-calc(150px);
					margin-left: rem-calc(30px);
				}
			}
			@media #{$large-up} {
				h1 {
					max-width: rem-calc(550px);
					font-size: rem-calc(50px);
					line-height: rem-calc(50px);
					left: rem-calc(190px);
				}
			}
			@media #{$xlarge-up} {
				h1 {
					max-width: rem-calc(700px);
					font-size: rem-calc(60px);
					line-height: rem-calc(60px);
					left: rem-calc(190px);
				}
			}//END media:xlarge

/* --- Avatar-Status-Widget --- */
			@media #{$medium-only} {
				padding-top: rem-calc(100px);
				.avatar-status-widget {
					width: rem-calc(150px);
					height: rem-calc(150px);
				}
			}
			@media #{$large-up} {
				padding-top: rem-calc(77px);
				.avatar-status-widget {
					margin-left: rem-calc(20px);
					width: rem-calc(160px);
					height: rem-calc(160px);
				}

			}//END media:large-up
			@media #{$xlarge-up} {
				.avatar-status-widget {
					width: rem-calc(175px);
					height: rem-calc(175px);
				}
			}//END media:xlarge

/* ---- Profile Connect Buttons --- */
			.btns-connect {
				float: right;
				.connect {
					float: right;
					clear: right;
					svg {
					/*	width: rem-calc(22px);
						height: rem-calc(22px);
						fill: currentColor;
					*/	vertical-align: text-top;
					}
				}
			//Mobile
				@media #{$small-only} {
					.connect {
						&.friend { padding: rem-calc(1px 5px); }
						&.follow { padding: rem-calc(1px 6px); }
						svg {
							fill: #fff;
					/*		width: rem-calc(14px);
							height: rem-calc(14px);
					*/	}
					}
					&.company {
						margin-right: 10px;
						@media #{$xsmall-only} { margin-right: rem-calc(1px); }
					}
				}//END media:small
			//Desktop
				@media #{$medium-up} {
					margin: rem-calc(12px 10px 0 0);
					.connect {
						font-size: 16px;
						font-weight: 500;
						min-width: rem-calc(150px);
						&.friend { margin-bottom: rem-calc(10px); }
						svg { margin-right: rem-calc(5px); }

					}
					&.company {
						margin: rem-calc(66px 10px 0 0);
						@media #{$medium-only} {
							.connect.follow { min-width: rem-calc(130px); }
						}
					}
				}//END media:medium
				@media #{$large-up} {
					margin: rem-calc(35px 10px 0 0);
					.connect {
						font-size: 18px;
						min-width: rem-calc(160px);
					}
					&.company { margin: rem-calc(88px 10px 0 0); }
				}//END media:large
			}//END .btns-connect

		}//END .profile-content
	}//END .cover-photo
	.breadcrumb {
		float: right;
		position: relative;
		top: -40px;
		font-size: rem-calc(14px);
		text-transform: uppercase;
		color: $gray3;
		height: 0;
		display: inline-block;
		.breadcrumb-truncate {
			white-space: nowrap;
			max-width: rem-calc(200px);
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			line-height: .75rem;
			position: relative;
			top: 1px;
		}
		@media #{$xsmall-only} {
			font-size: rem-calc(12px);
			.breadcrumb-truncate {
				max-width: rem-calc(85px);
				line-height: .65rem;
			}
		}
	}
	.breadcrumb-product {
		.categ {
			display: none;
		}
		.breadcrumb-truncate {
			max-width: rem-calc(180px);
		}
		@media #{$small-only} {
			.categ {
				display: inline-block;
			}
			.categories {
				display: none;
			}
			.breadcrumb-truncate {
				max-width: rem-calc(120px);
			}
		}
		@media #{$xsmall-only} {
			.categ {
				display: inline-block;
			}
			.categories {
				display: none;
			}
			.breadcrumb-truncate {
				max-width: rem-calc(50px);
			}
		}
	}
}//END .profile-blog

@media #{$small-only} {
	.company-profile-header,
	.profile-blog {
		padding-top: rem-calc(15px);
		.cover-photo {
			background-size: auto 100%;
			.profile-content {
				position: absolute;
				top: 0;
				max-height: 100%;
				.avatar-status-widget {
					width: 25%;
					height: auto;
					margin-left: 0;
					position: absolute;
					bottom: rem-calc(-28px);
					left: rem-calc(10px);
					img.avatar { width: 100%; }
				}
				.btns-connect {
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}
		}//END .cover-photo
		@media #{$small-only} {
			.cover-photo {
				height: rem-calc(140px);
				.profile-content {
					.avatar-status-widget {
						width: 36%;
						max-width: rem-calc(130px);
						left: rem-calc(5px);
						bottom: rem-calc(-18px);
					}
					.btns-connect {
						.btn {
							padding: rem-calc(6px 6px 0);
							&:last-child { margin-top: 5px; }
						}
						svg {
							width: rem-calc(22px);
							height: rem-calc(22px);
						}
					}
				}
				@media #{$xsmall-only} {
					height: rem-calc(120px);
					.profile-content .btns-connect {
						width: rem-calc(30px);
						.material-icons { font-size: rem-calc(16px); }
						svg {
							width: rem-calc(16px);
							height: rem-calc(16px);
						}
					}
				}//END media:xsmall
			}
		}//END media:small
	}//END .profile-blog
}//END media:small

//Company Specific
.company-profile-header {
	@media #{$large-up} {
		.btns-desktop .btn.connect.follow {
			margin-top: rem-calc(45px);
		}
	}
}
.companyPages {
	.container {
		@media #{$medium-only} { padding: 0; }
		&.profile-nav {
			@media #{$medium-up} { max-width: rem-calc(720px); }
			@media #{$large-up} { max-width: rem-calc(940px); }
			@media #{$xlarge-up} { max-width: rem-calc(1115px); }
		}//END .profile-nav
	}//END .container
}//END .companyPages
/*----------------------------------------------------------Profile Header-*/

/*-Profile Navigation------------------------------------------------------*/
.profile-nav.tab-nav {
	.top-line {
		max-width: 100%;
		height: rem-calc(14px);
		margin-top: 0;
		-webkit-box-shadow: 0px -4px 8px -4px rgba(0,0,0,0.36);
		-moz-box-shadow: 0px -4px 8px -4px rgba(0,0,0,0.36);
		box-shadow: 0px -4px 8px -4px rgba(0,0,0,0.36);
	}
	ul.tabs li.tab-title {
		&.settings,
		&.inbox {
			.tab-word { display: inherit; }
			.tab-icon{ display: none; }
		}
	}
}
@media #{$medium-only} {
	.profile-nav.tab-nav ul.tabs {
		margin-left: 0;
		li.tab-title a {
			font-size: rem-calc(14px);
			padding: rem-calc(16px 25px);
		}
	}
}//END media:medium
@media #{$small-only} {
	.profile-nav.tab-nav ul.tabs {
		margin-left: 0;
		li.tab-title {
			a {
				font-size: rem-calc(14px);
				padding: rem-calc(16px 9px);
			}
			&.active a {
				padding: rem-calc(10px 9px 16px);
			}
			.product-nav-list a {
				padding: rem-calc(2 0 0 12);
			}
			//When nav becomes too wide for "words"
			@media (max-width: 540px) {
				&.settings, &.inbox {
					.tab-word { display: none; }
					.tab-icon {
						display: inherit;
						padding-top: 7px;
					}
				}
			}//END media:500
		}//END .tab-title
	}
}//END media:small

@media #{$xsmall-only} {
	.profile-nav.tab-nav {
		ul.tabs {
			margin-left: 0;
			li.tab-title {
				a {
					padding: rem-calc(5px 5px 0);
				//	font-size: rem-calc(12px);
					@media (max-width: 420px) { font-size: rem-calc(12px); } //iphone 6 plus
					@media (max-width: 375px) { font-size: rem-calc(11px); } //iphone 6
					@media (max-width: 360px) { font-size: rem-calc(10px); } //Galaxy S
					@media (max-width: 330px) { font-size: rem-calc(9px); } //iphone 5
				}
				&.active {
					height: rem-calc(34px);
					a { padding: rem-calc(0px 5px 0px); }
				}
			}
		}
		.top-line { margin: 0; }
	}
}//END media:xsmall
/*------------------------------------------------------Profile Navigation-*/
