/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * tables.scss
 * =========================================================================
 * 
 * @TODO: Update TOC as necessary.
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*--- striped-table ---*/
.cview-container .forum-table,
.table {
	border: none;
	font-size: rem-calc(18px);
	line-height: rem-calc(24px);

	td, th { padding: rem-calc(12px 24px); }

	tr.even, tr.alt,
	tr:nth-of-type(even) {
		background: transparent;
		border-top: solid 1px rgba(0,0,0,0.1);
		border-bottom: solid 1px rgba(0,0,0,0.1);
	}
	tfoot {
		background: transparent;
		tr {
			border-bottom: none;
			td {
				font-style: italic;
				font-weight: normal;
			}
		}
	}
}
.cview-container .forum-table,
.table-striped {
	border: solid 1px $table-border;

	tr.even, tr.alt,
	tr:nth-of-type(even) {
		background: $table-background;
		border: none;
	}
}
.table-striped .forum-table {
	border: none;
	&:nth-of-type(even) {
		background: $table-background;
		border: none;
	}
}
.cview-container .table-striped:nth-of-type(even) {
	background: $table-background;
	border-top: none;
	border-bottom: none;
}
.cview-container .table-striped:nth-last-of-type(2) {
	border-bottom: solid 1px $table-border;
}

.table-hover {}

.no-table {
	border: none;
	font-size: rem-calc(18px);
	line-height: rem-calc(24px);

	td, th { padding: rem-calc(1px 5px); }

	tr.even, tr.alt,
	tr:nth-of-type(even) {
		background: transparent;
		border: none;
	}
	tfoot {
		background: transparent;
		tr {
			border-bottom: none;
			td {
				font-style: italic;
				font-weight: normal;
			}
		}
	}
}

/*--- div-table ---*/
.table {
	display: table;
	.row {
		display: table-row;
		.cell { display: table-cell; }
	}
	&.table-striped .row:nth-of-type(even) { background: $table-background; }
	@media #{$small-only}, #{$medium-only} {
		.table { display: block; }
	}
}
.forums-landing .table,
.cview-container .table { display: block; }

.table-responsive-wrapper {
	position:relative;
	width:100%;
	overflow:auto;
}
/* --- Bootstrap Tables ---
.table-hover > tbody > tr:hover {
	background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
	background-color: #e8e8e8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
	background-color: #d0e9c6;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
	background-color: #c4e3f3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
	background-color: #faf2cc;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
	background-color: #ebcccc;
}
.table-responsive {
	min-height: .01%;
	overflow-x: auto;
}
@media screen and (max-width: 767px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
	.table-responsive > .table {
		margin-bottom: 0;
	}
	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
	.table-responsive > .table-bordered {
		border: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:first-child,
	.table-responsive > .table-bordered > tbody > tr > th:first-child,
	.table-responsive > .table-bordered > tfoot > tr > th:first-child,
	.table-responsive > .table-bordered > thead > tr > td:first-child,
	.table-responsive > .table-bordered > tbody > tr > td:first-child,
	.table-responsive > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:last-child,
	.table-responsive > .table-bordered > tbody > tr > th:last-child,
	.table-responsive > .table-bordered > tfoot > tr > th:last-child,
	.table-responsive > .table-bordered > thead > tr > td:last-child,
	.table-responsive > .table-bordered > tbody > tr > td:last-child,
	.table-responsive > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}
	.table-responsive > .table-bordered > tbody > tr:last-child > th,
	.table-responsive > .table-bordered > tfoot > tr:last-child > th,
	.table-responsive > .table-bordered > tbody > tr:last-child > td,
	.table-responsive > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}
}
*/
