/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * shame.scss
 * =========================================================================
 * because hacks happen.
 *
 * be very specific about what each piece of code is doing, 
 * and how to better fix it later
 * =========================================================================
 *
 * The Rules
 * -----------------
 *
 * + If it's a hack, it goes in _shame.scss
 * + Document all hacks *fully*
 *	 - What part of the codebase does it relate to?
 *	 - Why was this needed?
 *	 - How does this fix it?
 *	 - How might you fix it properly, given more time?
 * + Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
 * + We will routinely clean shame.scss up from time to time.
 *
 * Read more: http://csswizardry.com/2013/04/shame-css/
 *
 * @TODO: Update RULES as necessary.
 *
 * @BUG:
 *
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Quick Fix Example-------------------------------------------------------*/
/* Nav specificity fix.
 *
 * Someone used an ID in the header code (`#header a {}`) which trumps the nav selectors (`.site-nav a {}`).
 * Use !important to override it until I have time to re-factor the header stuff.
 */
// .site-nav a {
//	åcolor: #BADA55 !important;
// }

/*-Quick Fix 1-------------------------------------------------------------*/
/* Visibility specificity fix.
 *
 * Foundation, while trying to be helpful, has these visibility classes set to "display: inherit !important;"
 * and for some reason these spans are displaying as a blocks...
 * It's super irritating, and I fear I may have to re-factor something in Foundation's core visibility styles (which I don't want to do)
 */
@media #{$medium-only}, #{$small-only} { //(max:991)
	span.hide-for-large-up { display: inline !important; }
}
@media #{$medium-up} { //min:768
	span.hide-for-small-only { display: inline !important; }
}
@media #{$large-up} { //(min:992)
	span.show-for-large-up { display: inline !important; }
}
@media #{$small-only} {
	span.show-for-small-only { display: inline !important; }
}

/*-Quick Fix 2-------------------------------------------------------------*/
/* Content columns on the home page
 * 
 * Divs need to float up to fill vertical space, while maintaining horizontal positioning
 * There are a few JavaScript and experimental CSS3 tricks (such as masonry.js and CSS 'column-count') to accomplish this
 * however, those solutions give you little control over which divs go into which columns
 * and even less control over how those divs shift placement on mobile
 * 
 * The quick-fix I came up with was to stack the HTML code in the order I want it to collapse to on mobile
 * and then use absolute positioning to fix the gap between the 2nd and 4th divs (that form the right-hand column)
 */

@media #{$medium-up} {
	//Home Page
	.home.main-content {
		position: relative;
		.content-column-left {
			float: left;
			clear: left;
			margin-right: 3.4%;
		}
		.content-column-right {
			float: right;
			clear: right;
			position: relative;
			top: -250px;
		}
		.content-column-right,
		.content-column-left {
			width: 48.3%;
			.home-content {
				&.column1, &.column2 { width: 100%; }
				&.column1 { margin-right: 0; }
			}
		}
	}
}
//columns not wide enough for "Start a Discussion" button AND forum titles
@media #{$large-only} {
	.home.main-content {
		.headline-border { font-size: 24px; }
	}
}

.vendor-home.main-content {
	position: relative;
	.column-left, .column-right { position: relative; }
	@media #{$medium-up} {
		> .content-column-left {
			float: left;
			clear: left;
			width: 32.2%;
			margin-right: 3.4%;
			.column-left { width: 100%; }
		}
		> .content-column-right {
			float: right;
			clear: right;
			position: relative;
			top: 0px;
			width: 64.4%;
			.column-right { width: 100%; }
		}
		@media #{$medium-only} {
			> .content-column-left,
			> .content-column-right { width: 48.3%; }

		}
	}//END media:medium-up

}

.forums {
	.content-column-left { float: left; }
	.content-column-right { float: right; }
}

/*-Quick Fix 5-------------------------------------------------------------*/
//overwriting bootstrap styles that are breaking the User Profile Settings page
.checkbox+.checkbox, .radio+.radio { margin-top: 0; }
.checkbox label, .radio label {
    padding-left: 0;
    margin-bottom: auto;
}

/*-Quick Fix 6-------------------------------------------------------------*/
//overwriting modal position for vrModal
//for some reason it's getting inline-styled with "top: 838px;"
#theModal.vrModal {
	@media #{$medium-up} { top: rem-calc(100px) !important; }
	@media #{$small-only} { top: 1% !important; }
}

/*-Quick Fix 7-------------------------------------------------------------*/
// Nested containers is causing left-indentation issues
.companyPages,
.profile-blog {
	.container {
		@media #{$medium-only} { max-width: rem-calc(720px); }
		@media #{$large-only} { max-width: rem-calc(940px); }
		@media #{$xlarge-up} { max-width: rem-calc(1115px); }
		.container { padding: 0; }
	}
}//END .container sizes

/*-Quick Fix 8-------------------------------------------------------------*/
//These components only "sometimes" needs the border on the header
//Nest under specific pages

//Never show border
.featured-members.hb-first,
.blogs-landing .featured-post,
.profile-activity {
	.headline-border.sometimes { border-top-color: transparent; }
}
//Only show border on mobile
.buyers-guide.hb-mobile,
.featured-members.hb-mobile,
.dept, .ds-home,
.ds-feed .featured-post,
.blogs-landing .featured-members,
.connections.suggested {
	@media #{$medium-up} {
		.headline-border.sometimes { border-top-color: transparent; }
	}
}

//Only show border of .exclusive-blogs if .featured-post isn't there
.blogs-landing {
	.exclusive-blogs .headline-border { border-top-color: transparent; }
	.featured-post {
		.headline-border { border-top-color: transparent; }
		+ .exclusive-blogs .headline-border { border-top-color: $gray3; }
	}
}
@media #{$medium-up} {
	.profiles-landing {
		.exclusive-blogs .headline-border { border-top-color: transparent; }
		.featured-members.hb-mobile + .featured-members + .exclusive-blogs .headline-border { border-top-color: $gray3; }
	}
}

//Adding some padding on the Create Blog Post page
@media #{$small-only} {
	.blog.add-post,
	.blog.edit-post {
		.section.three, .section.four {
			.headline-border { margin-top: rem-calc(30px); }
		}
	}
}

/*-Quick Fix 9-------------------------------------------------------------*/
// A Hubspot inline style is causing issues. Strong-arming into submission.
.takeover-bg {
	@media #{$large-up} {
		.home.hero-content .sidekick-ads {
			img {
				border: 1px solid $grey6 !important;
			}
		}
	}
}

/*-Quick Fix 10-------------------------------------------------------------*/
//Quick fix for Vendor Directory
@media #{$medium-only}, #{$xsmall-only} {
	.vr-company-list.pagination-content {
		margin-left: 11%;
		.product-summary {
			max-width: rem-calc(300px);
			.product-footer,
			.product-content { clear: none; }
		}//END .product-summary
	}
}//END media:medium,xsmall


header.top_bar nav {
	border-radius: 0;

	border: none;

	margin-bottom: 0;
}

nav {
	margin-bottom: 0;
}

.navbar {
	margin-bottom: 0px !important;
}

a.navbar-brand.logo img {
	position: relative;

	bottom: 5px;
}

ul.nav.navbar-nav.menus a {
	font-size: 19px;

	color: #636468;

	padding: 25px;
}

ul.nav.navbar-nav.menus a:hover {
	color: #636468;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent !important;

	border-color: #337ab7;
}

ul.nav.navbar-nav.navbar-right.rightmenus a {
	font-size: 19px;

	color: #fff;
}

li.tops {
	margin-top: 10px;
}

.dropss a {
	font-size: 14px !important;
}

@media screen and (min-width: 768px) {
	.navbar-brand-centered {
		position: absolute;

		left: 50%;

		display: block;

		width: 160px;

		text-align: center;

		background-color: transparent;

		top: 15px;
	}

	.navbar > .container .navbar-brand-centered,
	.navbar > .container-fluid .navbar-brand-centered {
		margin-left: -80px;
	}

	.top-nav .secondary-nav {
		display: block !important;
	}
}

li.tops.fons a {
	font-size: 18px;

	color: #fff !important;
}

.label-primary {
	background-color: #ff0000 !important;
}

section.mainmenu {
	background: #636468;
}

section.companysec {
	padding-top: 40px;

	padding-bottom: 40px;
}

.innnercom label {
	font-weight: 100;

	color: #636468;

	font-size: 16px;

	margin-bottom: 10px;
}

.innnercom select {
	border-radius: 0;

	border: 1px solid #dcdcdc;

	background: #fff;

	height: 40px;

	color: #000;
}

.tamps {
	margin-top: 10px;
}

.tamps a {
	font-size: 18px;

	text-decoration: none !important;
}

.innnercom2 {
	display: inline-block;

	width: 100%;

	background: #fff;

	border: 1px solid #ccc;

	margin-bottom: 20px;

	padding-bottom: 20px;
}

.innnercom {
	display: inline-block;

	width: 100%;

	padding-bottom: 20px;

	margin-bottom: 20px;
}

h4.lavs {
	text-align: center;

	padding-top: 15px;

	padding-bottom: 20px;

	border-bottom: 1px solid #ccc;

	font-size: 15px;

	font-weight: 600;
}

span.label.label-primary.meds {
	border-radius: 100%;
	font-size: 8px;
	position: absolute;
	top: 0px;
	right: 5px;
	height: 14px;
	width: 14px;
	padding: 3px;
}

form.navbar-form.navbar-right.foms input {
	width: 220px;

	height: 25px;

	color: #fff;

	background: #7d7e84 !important;

	border: none;
}

i.fa.fa-search.seds {
	position: absolute;

	top: 22px;

	right: 22px;

	color: #c1c2c4;
}

form.navbar-form.navbar-right.foms {
	margin-top: 17px;
}

.bannertexts h1 {
	text-align: center;
	color: #fff;
	margin-top: 0;
	font-size: 43px;
	margin-bottom: 0px;
	font-weight: 300;
}

.bannertexts h2 {
	text-align: center;
	color: #fff;
	margin-top: 0;
	font-size: 27px;
	margin-bottom: 0px;
}

.bannertexts p {
	text-align: center;
	color: #fff;
	font-size: 13px;
	margin-top: 12px;
	margin-bottom: 0;
}

.box_innnenr {
	text-align: center;
	padding: 20px 15px;
	color: #fff;
	min-height: 193px;
}
.box_innnenr .icons i {
	font-size: 40px;
}

.box_innnenr p {
	font-size: 13px;
	margin-bottom: 10px;
	min-height: 64px;
}
.readmoress a {
	padding: 6px 9px;
	border: 1px solid #fff;
	border-radius: 50px;
	color: #fff;
	font-size: 10px;
}

.readmoress {
	margin-top: 5px;

	display: inline-block;
}

.icons i {
	font-size: 24px;
}

.cusm_rowe {
	display: inline-block;

	width: 100%;
}
.cusm_rowe .div-click:hover
{
	cursor:pointer;
}

.register-button {
	width: 156px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 50px;
}

.register-button button {
	background: none;
	border: 1px solid white;
	border-radius: 25px;
	padding: 10px 46px;
	font-weight: 500;
}
.register-button .search-category {
	background: none;
	border: 1px solid white;
	border-radius: 25px;
	padding: 10px 46px;
	font-weight: 400;
	color: #FFFFFF;
}

.register-button .btn-blue {
	background: white;
	color: #376caf;
	border: 1px solid white;
	border-radius: 25px;
	padding: 10px 46px;
	font-weight: 400;
}

.register-button img {
	position: absolute;
	top: 0;
	left: 110%;
}
.register-button {
	button:hover,
	button:focus,
	button:active {
		background-color: unset !important;
	}
}

.tecys h3 {
	font-size: 18px;
	color: #fff;
}
.tecys p, .tecys a, .tecys a:hover, .tecys a:active, tecys a:visited {
	font-size: 14px;
	color: #fff;
}
header.top_bar {
	background: #376cb0;
}

.menushd li {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-right: 18px !important;
	color: #fff;
}
.menushd li a {
	color: #fff;
	font-size: 15px;
}
.content li {
	display: inline-block;
}
.contain-to-grid.custym {
	background: #636468;
}
/*.mega {
    top: 97px !important;
}*/
.mega {
	margin-top: 13px !important;
	border: none !important;
}
.content ul {
	margin: 0;
}
header.top_bar li a {
	font-size: 14px !important;
}
.button-wrapss {
	display: inline-block;
	position: absolute;
	top: 10px;
	right: 8px;
	color: #c0c1c3;
	font-size: 12px;
}
.innerds input {
	margin: 0 !important;
	background: #7d7e84 !important;
	border: none !important;
	height: 22px !important;
	color: #fff !important;
}
.innerds {
	margin-top: 9px;
}
.logo_hide {
	display: none;
}
.pass {
	padding: 0 !important;
}
.dd {
	padding: 0 !important;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
	.logo_hide {
		display: block;
	}
	.innerds {
		position: absolute;
		width: 100%;
		top: 70px;
	}
	.logos {
		display: none;
	}
	.small-6.columns.cnte {
		text-align: center;
	}
	.pass {
		padding: 15px !important;
	}
	span.label.label-primary.meds {
		right: 45px;
		top: -6px;
	}
	.top-bar-section {
		display: inline-block;
		width: 100% !important;
		background: #fff;
		margin-top: 15px;
	}
	header.top_bar li a {
		font-size: 12px !important;
		margin-left: 10px;
	}
	.bannertexts h1 {
		text-align: center;
		color: #fff;
		margin-top: 0;
		font-size: 16px;
		margin-bottom: 0px;
	}
	.bannertexts h2 {
		text-align: center;
		color: #fff;
		margin-top: 0;
		font-size: 14px;
		margin-bottom: 0px;
	}
	.box_innnenr {
		text-align: center;
		padding: 15px 10px;
		color: #fff;
		min-height: 325px;
		margin-bottom: 10px;
	}
	.box_innnenr p {
		min-height: auto;
	}
	.button-group > li {
		display: inline-block;
		margin: 0 14px !important;
	}
	.ssd {
		padding-top: 5px;
		padding-bottom: 0;
		margin-bottom: 5px !important;
	}
	.innerds input {
		margin: 0 !important;
		background: #7d7e84 !important;
		border: none !important;
		height: 34px !important;
		color: #fff !important;
	}
	.button-wrapss {
		display: inline-block;
		position: absolute;
		top: 5px;
		right: 8px;
		color: #c0c1c3;
		font-size: 12px;
	}
	.button-group.even-1 {
		text-align: center;
	}
	.top_bar .container {
		padding: 0px;
	}
	.top-bar .toggle-topbar.menu-icon {
		margin-top: 3px;
		top: 50%;
	}
	.top-bar-section li:not(.has-form) a:not(.button) {
		background: transparent !important;
		line-height: 0.8125rem !important;
		padding: 0 0.9375rem;
	}
	li.small-6.columns.cnte.dd {
		margin-top: 15px;
	}
	.top-bar {
		background: #333333;
		height: 2.8125rem;
		line-height: 0.8125rem !important;
		margin-bottom: 0;
		overflow: hidden;
		position: relative;
	}
	.top-bar .toggle-topbar {
		display: block !important;
	}
	ul.title-area {
		width: 100%;
	}
}

.top-bar-section ul li {
	background: transparent !important;
}
.top-bar-section li:not(.has-form) a:not(.button) {
	background: transparent !important;
	line-height: 2.8125rem;
	padding: 0 0.9375rem;
}
.content.ds-home {
	margin-top: 0px;
}
// This file is style.css

// Add your CSS here
#noticemsg .close-reveal-modal {
	color: #aaa;
	cursor: pointer;
	font-size: 2.5rem !important;
	font-weight: 100;
	line-height: 1;
	position: absolute;
	top: 0;
	right: 0.1rem !important;
}
.top-bar-section li:not(.has-form) a:not(.button) {
	background: transparent !important;
	line-height: 1.8125rem !important;
	padding: 0 0.9375rem;
}

.top-bar .toggle-topbar.menu-icon {
	margin-top: 0px !important;
}

//@media screen and (min-width:768px)
.top-nav-search{
	opacity: .7;
}
.its-free{
	width:25%;
	margin-left:-550px;
}
@media (max-width: 767px) {
	.top-nav .secondary-nav {
		display: block !important;
	}
}
.top-nav .secondary-nav .has-dd.dd-active+.dd-menu.tertiary-nav {
	display: block;
	position: absolute;
	top: .125rem;
	left: 0;
	width: 100%;
	line-height: 2.5rem
}
