/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * image-cropper.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Import External CSS
 * + Cropper Modal
 * + Small Modal
 * + Large Modal
 * 
 * Read more: http://fengyuanchen.github.io/cropper/
 *			- https://github.com/fengyuanchen/cropper/blob/v1.0.0/README.md
 * Import/Reference CSS:
 *			- https://cdnjs.cloudflare.com/ajax/libs/cropper/1.0.0/cropper.min.css
 *			- http://fengyuanchen.github.io/cropper/css/main.css
 *
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Import External CSS-----------------------------------------------------*/

/* Cropper Main.css */
.img-container,
.img-preview {
	background-color: #f7f7f7;
	width: 100%;
	text-align: center;
	overflow: hidden;
	border: solid 1px $grey6;
}
.img-container {
	min-height: 200px;
	max-height: 516px;
	margin: 0 auto 20px;
    width:100%;
	> img { max-width: 100%; }
	//@media (min-width: 768px) { min-height: 516px; }
}

.docs-preview { margin-right: -15px; }
.img-preview {
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
	> img { max-width: 100%; }
}
.preview-lg {
	width: 263px;
	height: 148px;
}
.preview-md {
	width: 139px;
	height: 78px;
}
.preview-sm {
	width: 69px;
	height: 39px;
}
.preview-xs {
	width: 35px;
	height: 20px;
	margin-right: 0;
}

.docs-data > .input-group {
	margin-bottom: 10px;
	> label { min-width: 80px; }
	> span { min-width: 50px; }
}

.docs-buttons > .btn,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
	margin-right: 5px;
	margin-bottom: 10px;
}

.docs-toggles > .btn,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
	margin-bottom: 10px;
}

.docs-tooltip {
	display: block;
	margin: -6px -12px;
	padding: 6px 12px;
	> .icon {
		margin: 0 -3px;
		vertical-align: top;
	}
}
.tooltip-inner { white-space: normal; }

.btn-upload .tooltip-inner,
.btn-toggle .tooltip-inner {
	white-space: nowrap;
}

.btn-toggle {
	padding: 6px;
	> .docs-tooltip {
		margin: -6px;
		padding: 6px;
	}
}

@media (max-width: 400px) {
	.btn-group-crop {
		margin-right: -15px!important;
		> .btn {
			padding-left: 5px;
			padding-right: 5px;
		}
		.docs-tooltip {
			margin-left: -5px;
			margin-right: -5px;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

.docs-options .dropdown-menu {
	width: 100%;
	> li {
		padding: 3px 20px;
		&:hover { background-color: #f7f7f7; }
		> label { display: block; }
	}
}

.docs-cropped .modal-body {
	text-align: center;
	> img, > canvas { max-width: 100%; }
}

.docs-diagram .modal-dialog { max-width: 352px; }


/* Footer */
.docs-footer {
	margin-top: 100px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #0066bf;
	background-color: #0074d9;
	color: #ddd;

	a {
		color: #eee;
		&:hover, &:focus { color: #fff; }
		+ a { margin-left: 10px; }
	}
}

@media (min-width: 768px) {
	.back-to-top { float: right; }
}
/*-----------------------------------------------------Import External CSS-*/

/*-Image Cropper Modal-----------------------------------------------------*/
.cropping-modal {
	.tooltip {
		width: auto;
		&.fade { background-color: transparent; }
	}
}
/*-----------------------------------------------------Image Cropper Modal-*/

/*-Small Modal-------------------------------------------------------------*/
.cropper-modal-sm {
	.preview-btns { visibility: hidden; }
	@media #{$large-up} {
		.modal-dialog {
			width: rem-calc(850px);
			.modal-body {
				width: 100%;
				.img-container {
					float: left;
					//max-width: rem-calc(545px);
                    width: 100%;
					margin-right: rem-calc(10px);
				}
				.docs-preview {
					display: inline-block;
					max-width: rem-calc(263px);
					margin: 0;
				}
				.docs-buttons {
					display: inline-block;
					//max-width: 263px;
					.advanced-options {
						.btn-group { margin-top: rem-calc(5px); }
					}
				}
				.preview-btns .btn-group { clear: left; }
				.save-close {
					clear: left;
					float: right;
				}
			}
		}// END .modal-dialog
	}//END media:large
	@media #{$medium-only}, #{$small-only} {
		.modal-dialog .modal-body {
			width: 100%;
			.docs-preview {
				float: left;
				max-width: 50%;
				margin: 0;
			}
			.docs-buttons {
				display: inline-block;
				max-width: 50%;
				.advanced-options .btn-group { margin-bottom: rem-calc(5px); }
			}
			.preview-btns .btn-group { clear: left; }
			.save-close { margin-top: rem-calc(25px); }
			@media (max-width: 600px) {
				.docs-preview,
				.docs-buttons {
					display: block;
					clear: both;
					max-width: 100%;
				}
				.preview-btns { display: none; }
			}//END media:small
		}
	}
}
/*-------------------------------------------------------------Small Modal-*/

/*-Large Modal-------------------------------------------------------------*/
.cropper-modal-lg {
	@media #{$large-up} {
		.modal-xlg {
			width: 1120px;
			height: 900px;
		}
	}
}
/*-------------------------------------------------------------Large Modal-*/

/*-Large Modal-------------------------------------------------------------*/
.cropper-modal-cover {
  .preview-btns { visibility: hidden; }
  @media #{$large-up} {
    .modal-dialog {
      width: 1200px;
      height: 400px;
      .modal-body {
        width: 100%;
        .img-container {
          float: left;
          width: 1146px;
          margin-right: 10px;
          min-height: 300px;
        }
        .row{
          max-width: 1146px;
        }
        .docs-preview {
          display: inline-block;
          max-width: rem-calc(263px);
          margin: 0;
        }
        .docs-buttons {
          display: inline-block;
          //max-width: 263px;
          .advanced-options {
            .btn-group { margin-top: rem-calc(5px); }
          }
        }
        .preview-btns .btn-group { clear: left; }
        .save-close {
          clear: left;
          float: right;
        }
      }
    }
  }
}
/*-------------------------------------------------------------Large Modal-*/