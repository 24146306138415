/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * profiles-user.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + User Profile - Home Page
 * + User Profile - About Page
 * + User Profile - Connections Page
 * + Profile Management
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-User Profile - Home Page------------------------------------------------*/
.profile-user-home {}
.profile-user-info {}
/*------------------------------------------------Profile Page - Home Page-*/

/*-User Profile - About Page-----------------------------------------------*/
.timeline {
	&.resume, &.recent-activity {
		ul {
			list-style: none;
			border-left: solid 1px $tertiary;
			padding: rem-calc(1px 0 4px);
			margin: rem-calc(0 0 0 15px);
			li {
                position:relative;
				line-height: rem-calc(30px);
				margin: rem-calc(15px 0 15px -15px);
                padding: rem-calc(0 0 0 42px);
				width: 100%;
				clear: both;
				.avatar {
					position:absolute;
                    top:0;
                    left:0;
				}
                .event-description {
                    float: right;
                    width: calc(100% - 40px);
                }
				&:first-child { margin-top: rem-calc(5px); }
				.time-stamp {
					float: right;
					font-style: italic;
				}
			}//END li
		}//END ul
	}
    .more-arrow {
        display: none;
    }
}//END .timeline

.profilePage { 
	.section.timeline.recent-activity ul li {
		.avatar.xsmall .combined-icons .material-icons { line-height: rem-calc(16px); }
		.time-stamp {
			float: right;
			font-style: italic;
            line-height: 1.35rem;
		}
	}
}//END .profilePage

.profile-about { 
	.section.timeline.resume ul li {
		.job-info {
			display: block;
			width: calc(100% - rem-calc(40px));
		//	.job-title { font-weight: 500; }
			.company-name {
				font-weight: 300;
				display: block;
			}
		}
	}
}//END .profile-about
/*-----------------------------------------------Profile Page - About Page-*/

/*-User Profile - Connections Page-----------------------------------------*/
.profile-user-connections {
    .connections-header {
        padding-bottom: rem-calc(10px);
    //  border-bottom: solid 1px $grey6;
    }
    h3 { color: $gray3; }
    .connections-types {
        line-height: rem-calc(37px);
        a {
            font-weight: 300;
        //  &.type-connections { margin: 0 6%; }
            &.type-followers,
            &.type-all {}
        }
    }
    .input-group {
        input, button { margin-bottom: 0; }
    }

    @media #{$small-only} {
        .connections-types {
            display: block;
            text-align: center;
            .type-connections { margin: 0 10%; }
            .type-followers { float: right; }
            .type-all { float: left; }
        }
    }
    @media #{$xsmall-only} {
        .connections-types {
            a {
                font-size: rem-calc(13px);
                &.type-connections { margin: 0 1%; }
            }
        }
    }
    @media #{$medium-only} {
        .connections-header { width: 100% }
    }
    @media #{$medium-up} {
        .connections-types {
            float: left;
            width: 60%;
            .type-connections { margin: 0 10%; }
        }
        .input-group {
            float: right;
            width: 40%;
        }
    }
    @media #{$large-up} {
        .connections-types {
            max-width: rem-calc(363px);
            .type-connections { margin: rem-calc(0 10px); }
        }
        .input-group { max-width: rem-calc(242px); }
    }
    @media #{$xlarge-up} {
        .connections-types {
            max-width: rem-calc(450px);
            .type-connections { margin: 0 6%; }
        }
        .input-group { max-width: rem-calc(300px); }
    }
}

.user-info {
    .user-name, .user-job, .user-location, .user-company {
        //need to truncate
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .user-name {
        color: $main-blue;
        font-size: rem-calc(20px);
        font-weight: 500;
    }
    .user-job, .user-location, .user-company {
        font-weight: 300;
        font-size: rem-calc(14px);
        line-height: rem-calc(18px);
    }
    .user-job { color: $gray4; }
    .user-company, .user-location { color: $gray9; }
}

// People (pending-connections)
.peoples {
    clear: both;
    @media #{$medium-only} { width: 100% }

    .user-info {
        display: block;
        border-top: solid 1px $silver;
        width: 100%;
        clear: both;
        height: rem-calc(140px);
        padding: rem-calc(20px 0 19px);
        .user-avatar {
            float: left;
            margin-right: rem-calc(10px);
        }
        .user-name { margin-top: rem-calc(15px); }

        .btn-dropdown {
            float: right;
            min-width: rem-calc(135px);
            margin-top: rem-calc(-50px);
            font-size: rem-calc(14px);
        }
    }//END .user-info
    @media #{$small-only} {
        .user-info {
            .user-job { margin-right: rem-calc(135px); }
            .btn-dropdown {
                width: rem-calc(125px);
                padding: rem-calc(1px 5px);
                font-size: rem-calc(12px);
            }
        }
    }//END media:small
    @media #{$xsmall-only} {
        .user-info {
            .user-name { margin-top: 0; }
            .user-job { margin-right: 0; }
            .btn-dropdown {
                float: left;
                clear: left;
                margin: rem-calc(0px 0 20px 103px);
            }
        }
    }//END media:small
}
// People
.all-peoples {
    clear: both;
    padding-top: 1px;
    @media #{$medium-only} { width: 100% }
    .user-info {
        float: left;
        width: rem-calc(145px);
        text-align: center;
        margin: rem-calc(0 20px 20px 0);
        &:nth-child(3) { clear: left; }
        .user-name { margin-top: rem-calc(8px); }
        .user-location { margin-bottom: rem-calc(15px); }
        @media #{$xsmall-only} {
            margin: rem-calc(0 10px 20px 0);
            &:nth-of-type(odd) { margin-right: 0; }
        }//END media:xsmall
    }
}
/*-----------------------------------------User Profile - Connections Page-*/

/*-User Profile - Activity Log---------------------------------------------*/
.statuses {
	p {
		text-align: center;
		font-size: rem-calc(20px);
		max-width: rem-calc(435px);
        margin: rem-calc(20px) auto;
	}
	.grand-total {
		text-align: center;
		color: $main-blue;
		font-size: rem-calc(70px);
		line-height: rem-calc(72px);
		font-weight: 100;
	}
}
/*---------------------------------------------User Profile - Activity Log-*/

.profile-user-activity {
    .column-one-half {
        @media #{$small-only} {
            width: 100%;
        }
    }
}

/*-Profile Management------------------------------------------------------*/
.manageProfile {
    .settings_sliders{
        @media #{$large-up} { width: 76.6%; }
        @media #{$xlarge-up} { width: 64.4%; }
        .settings_group {
            width: 100%;
            border-bottom: solid 1px $grey6;
            .group_content {
                .checkbox.setting {
                    position: relative;
                    clear: left;
                    .help_text {
                        color: $gray9;
                        font-size: rem-calc(12px);
                    }
                }
                .accordion_label.accordion_trigger {
                    .btn-edit {
                        float: right;
                        color: $gray9;
                        background-color: transparent;
                        &:hover { color: $gray4; }
                    }
                }//END .accordion_label
                .accordion_content {
                    display: none;
                    input[type="text"] {
                        display: block;
                        max-width: rem-calc(555px);
                    }
                    .btn {
                        width: rem-calc(135px);
                        &.btn-submit { margin-right: rem-calc(10px); }
                    }
                }//END .accordion_content
            }
            &.email_group {}
            &.password_group {}
            &.notifications_group {}
            + .btn-submit { margin-top: rem-calc( 10px); }
        }
        @media #{$medium-up} {
            .settings_group {
                display: table;
                padding: rem-calc(20px 0);
                .group_label {
                    display: table-cell;
                    vertical-align: top;
                    width: rem-calc(145px);
                    padding: rem-calc(0 10px);
                    font-weight: bold;
                    .settings_subheading {
                        font-weight: 500;
                        font-size: rem-calc(14px);
                    }
                }
                .group_content {
                    display: table-cell;
                    vertical-align: top;
                    padding: rem-calc(0 10px);
                    .checkbox.setting {
                        margin-right: rem-calc(50px);
                        //  padding-left: rem-calc(32px);
                        .switch {
                            float: left;
                            margin: rem-calc(0px 10px 0 0);
                            &.notification-label {
                                color: $gray9;
                                font-size: rem-calc(12px);
                                &.system { margin-left: 5px; }
                                &.email { margin-left: 13px; }
                            }
                            + label {
                                display: block;
                                margin-left: rem-calc(116px);
                                font-size: rem-calc(16px);
                            }
                        }
                        .help_text { margin: rem-calc(0 0 20px 116px); }
                    }//END .checkbox.setting
                }
            }
        }//END media:medium-up
        @media #{$small-only} {
            .settings_group {
                padding: rem-calc(10px 0);
                .group_content {
                    .checkbox.setting {
                        position: relative;
                        clear: left;
                        .switch {
                            float: right;
                            margin: rem-calc(0 0 0 10);
                            &:before {
                                display: block;
                                color: $gray9;
                                font-size: rem-calc(12px);
                                text-align: center;
                            }
                            &.system:before { content: 'System'; }
                            &.email:before { content: 'Email'; }
                            + label {
                                display: block;
                                margin-right: rem-calc(116px);
                            }
                        }
                        .help_text {
                            clear: left;
                            margin-right: rem-calc(116px);
                            line-height: rem-calc(16px);
                        }
                    }//END .checkbox.setting

                    .accordion_label.accordion_trigger {
                        .btn-edit {
                            float: right;
                            color: $gray9;
                            background-color: transparent;
                            margin-bottom: 0;
                            &:hover { color: $gray4; }
                        }
                        .trigger_value {
                            position: relative;
                            display: inline-block;

                            overflow-wrap: break-word;
                            word-wrap: break-word;
                            -ms-word-break: break-all;
                            word-break: break-all;
                            word-break: break-word;

                        }
                    }//END .accordion_label
                    .accordion_content {
                        font-size: rem-calc(14px);
                        margin: rem-calc(5 0 10);
                    }
                }
                &.email_group .trigger_value { font-size: rem-calc(14px); }
            }
        }//END media:small
        @media #{$xsmall-only} {
            .settings_group {
                display: block;
                clear: both;
                .trigger_value { max-width: rem-calc(250px); }
                &.email_group .trigger_value { font-size: rem-calc(12px); }
                .group_content .accordion_content .btn.btn-submit { margin-right: rem-calc(16px); }
            }
        }//END media:xsmall

    }
    .settings_form {
        .settings_group {
            width: 100%;
            &.email_group { border-bottom: solid 1px $grey6; }
            .group_content {
                .checkbox.setting {
                    position: relative;
                    clear: left;
                    .help_text {
                        color: $gray9;
                        font-size: rem-calc(12px);
                    }
                }
                .accordion_label.accordion_trigger {
                    .btn-edit {
                        float: right;
                        color: $gray9;
                        background-color: transparent;
                        &:hover { color: $gray4; }
                    }
                }//END .accordion_label             
                .accordion_content {
                    display: none;
                    input[type="text"] {
                        display: block;
                        max-width: rem-calc(555px);
                    }
                    .btn {
                        width: rem-calc(135px);
                        &.btn-submit { margin-right: rem-calc(10px); }
                    }
                }//END .accordion_content
            }
            &.email_group {}
            &.password_group {}
            &.notifications_group {}
            &.newsletters_group {}
            + .btn-submit { margin-top: rem-calc( 10px); }
        }
        @media #{$medium-up} {
            .settings_group {
                display: table;
                padding: rem-calc(20px 0);
                .group_label {
                    display: table-cell;
                    vertical-align: top;
                    width: rem-calc(145px);
                    padding: rem-calc(0 10px 0 0);
                    font-weight: bold;
                    .settings_subheading {
                        font-weight: 500;
                        font-size: rem-calc(14px);
                    }
                }
                .group_content {
                    display: table-cell;
                    vertical-align: top;
                    .checkbox.setting {
                    //  margin-right: rem-calc(50px);
                        .switch {
                            float: left;
                            margin: rem-calc(0px 10px 0 0);
                            &.label {
                                color: $gray9;
                                font-size: rem-calc(12px);
                                padding-bottom: rem-calc(10px);
                                &.system { margin-left: 5px; }
                                &.email { margin-left: 13px; }
                            }
                            + label {
                                display: block;
                                font-size: rem-calc(16px);
                            }
                        }//END .switch
                    }//END .checkbox.setting
                }

                &.password_group { padding-bottom: 0; }
                &.notifications_group {
                    padding-top: 0;
                    .group_content .checkbox.setting {
                        .switch + label { margin-left: rem-calc(116px); }
                        .help_text { margin: rem-calc(0 0 20px 116px); }
                    }
                }//END .notifications_group
                &.newsletters_group {
                    padding-top: 0;
                    .group_content .checkbox.setting {
                        .switch + label { margin-left: rem-calc(58px); }
                        .help_text { margin: rem-calc(0 0 20px 58px); }
                    }
                }//END .newsletter_group
            }
        }//END media:medium-up
        @media #{$small-only} {
            .settings_group {
                padding: rem-calc(10px 0);
                .group_content {
                    .checkbox.setting {
                        position: relative;
                        clear: left;
                        .switch {
                            float: right;
                            margin: rem-calc(0 0 0 10);
                            &:before {
                                display: block;
                                color: $gray9;
                                font-size: rem-calc(12px);
                                text-align: center;
                            }
                            &.system:before { content: 'System'; }
                            &.email:before { content: 'Email'; }
                            + label {
                                display: block;
                                margin-right: rem-calc(116px);
                            }
                        }
                        .help_text {
                            clear: left;
                            margin-right: rem-calc(116px);
                            line-height: rem-calc(16px);
                        }
                    }//END .checkbox.setting

                    .accordion_label.accordion_trigger {
                        .btn-edit {
                            float: right;
                            color: $gray9;
                            background-color: transparent;
                            margin-bottom: 0;
                            &:hover { color: $gray4; }
                        }
                        .trigger_value {
                            position: relative;
                            display: inline-block;

                            overflow-wrap: break-word;
                            word-wrap: break-word;
                            -ms-word-break: break-all;
                            word-break: break-all;
                            word-break: break-word;

                        }
                    }//END .accordion_label
                    .accordion_content {
                        font-size: rem-calc(14px);
                        margin: rem-calc(5 0 10);
                    }
                }
                &.email_group .trigger_value { font-size: rem-calc(14px); }
            }
        }//END media:small
        @media #{$xsmall-only} {
            .settings_group {
                display: block;
                clear: both;
                .trigger_value { max-width: rem-calc(250px); }
                &.email_group .trigger_value { font-size: rem-calc(12px); }
                .group_content .accordion_content .btn.btn-submit { margin-right: rem-calc(16px); }
            }
        }//END media:xsmall

    }//END #settings_form
}
/*------------------------------------------------------Profile Management-*/
