/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * vendor-ratings.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Vendor Ratings Hero
 * + Main Page Content
 *   - Products Media Tab
 *	 - Vendor Category Tab Selector
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Vendor Ratings Hero Section---------------------------------------------*/
.vendors-home {
	&.content { margin-top: 0; }
	.hero-banner {
		background-color: rgba(0,0,0,0.35);
		background-repeat: no-repeat;
		background-position: center top;

		.container { height: auto; }
		@media #{$small-only} {
			background-image: url('../images/category-banners/vendor-ratings-home_sm.jpg');
		//	.container { height: rem-calc(259px); }
		}
		@media #{$medium-only} {
			background-image: url('../images/category-banners/vendor-ratings-home_md.jpg');
			.container {
			//	height: rem-calc(335px);
				padding: 0;
			}
		}
		@media #{$large-up} {
			background-image: url('../images/category-banners/vendor-ratings-home_lg.jpg');
		//	.container { height: rem-calc(405px); }
		}
		@media #{$xlarge-up} {
			background-image: url('../images/category-banners/vendor-ratings-home_xl.jpg');
			background-repeat: no-repeat;
			background-position: center top;
		}
		@media #{$xxlarge-up} {
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), #656160;
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), -moz-linear-gradient(left,  #95958b 49%, #656160 51%);
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), -webkit-gradient(linear, left top, right top, color-stop(49%,#95958b), color-stop(51%,#656160));
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), -webkit-linear-gradient(left,  #95958b 49%,#656160 51%);
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), -o-linear-gradient(left,  #95958b 49%,#656160 51%);
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), -ms-linear-gradient(left,  #95958b 49%,#656160 51%);
			background: url('../images/category-banners/vendor-ratings-home_xl.jpg'), linear-gradient(to right,  #95958b 49%,#656160 51%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95958b', endColorstr='#656160',GradientType=1 );
			background-repeat: no-repeat;
			background-position: center top;
		}
		.container {
			h2 {
				color: #fff;
				font-weight: 300;
			}
			.search-verified {
				color: #fff;
				float: left;
				span {
					font-size: rem-calc(18px);
					font-weight: 100;
					strong { font-weight: 500; }
					&.bullet { margin: rem-calc(0 15px); }
					&.verified {
						font-weight: 500;
						img { max-width: rem-calc(30px); }
					}				}
				.input-group {
					margin-top: rem-calc(20px);
					max-width: rem-calc(477px);
				}

			}
			.btn-orange {
				float: right;
				margin-top: rem-calc(35px);
			}
			@media #{$large-up} {
				h2 {
					margin: rem-calc(85px 0 30px);
				//	margin: rem-calc(85px 0 100px);
					line-height: rem-calc(50px);
				}
			}
			@media #{$medium-only} {
				h2 {
					margin: rem-calc(80px 0);
					line-height: rem-calc(35px);
				}
			}
			@media #{$small-only} {
				h2 {
					margin: rem-calc(45px 0 30px);
					line-height: rem-calc(27px);
				}
				.search-verified {
					max-width: 50%;
					text-align: center;

					span {
						font-weight: 300;
						&.vendors, &.ratings, &.verified {
							display: block;
							margin: 0 auto;
						}
					}
					.bullet { display: none; }
					.input-group { margin-top: rem-calc(5px); }
				}

				.btn-orange {
					margin-top: rem-calc(80px);
					padding: rem-calc(5px 10px 5px 5px);
					font-size: rem-calc(14px);
					svg {
						width: rem-calc(16px);
						height: rem-calc(16px);
					}
				}
			}
			@media #{$xsmall-only} {
				h2 {
					font-size: rem-calc(24px);
					line-height: rem-calc(24px);
					margin: rem-calc(16px 0px);
					br { display: none; }
				}
				.search-verified {
					max-width: 100%;
					text-align: left;
					span {
						&.vendors, &.ratings { display: inline-block; }
						&.verified { display: block; }
					}
					.bullet.one {
						display: inline-block;
						margin: rem-calc(0 5px);
					}
					.input-group {
						margin-top: rem-calc(10px);
						width: 80%;
						.input-group-btn button { margin-bottom: 0px; }
					}
				}
				.btn-orange {
					margin-top: rem-calc(15px);
					float: none;
				}
			}//END media:xsmall
		}
	}
}

.vendor-home.main-content {
	.how-it-works {
		background-color: $lt-blue-bg;
		padding: rem-calc(45px 32px 35px);
		text-align: center;
		.btn-stroked-orange {
			clear: both;
			margin: 0 auto;
		//	color: $vendor-orange;
		//	background-color: $lt-blue-bg;
			background-color: transparent;
			border-width: 2px;
			&:hover, &:active, &:focus {
				background-color: $orange-bg-disabled;
			}
		}
		.title {
			text-align: center;
			text-transform: uppercase;
			margin-bottom: rem-calc(30px);
			margin-top: 0;
		}
		ol {
			position: relative;
			list-style: none;
			text-align: center;
			width: 100%;
			margin: 0 auto;
			padding: 0;
			li {
				position: relative;
				padding: 0;
				margin: 0 auto rem-calc(20px);
				text-align: center;
				img {
					display: block;
					margin: 0 auto;
					max-width: rem-calc(111px);
					max-height: rem-calc(111px);
				}
				strong {
					display: block;
					font-size: rem-calc(20px);
					font-weight: 300;
					color: $gray4;
				}
				.step {
					display: block;
					display: block;
					font-size: rem-calc(16px);
					line-height: rem-calc(20px);
					font-weight: 300;
					color: $gray4;
				}
			}
		}
		@media #{$medium-up} {
			ol {
				li {
					float: left;
					width: 30%;
					&:nth-child(2) { margin: auto 5%; }
					strong { margin: rem-calc(20px 0 12px); }
					@media #{$medium-only} {
						.line-break { display: none; }
					}
				}
			}
		}
		@media #{$small-only} {
			ol {
				li {
					clear: both;
					min-height: rem-calc(80px);
					margin-bottom: rem-calc(50px);
					text-align: left;
					img {
						float: left;
						margin-right: rem-calc(10px);
					}
					strong {
						display: inline-block;
						margin: rem-calc(15px 0 5px);
					}
					@media (min-width: 480px) {
						.line-break { display: none; }
					}
				}
			}
		}
		@media #{$xsmall-only} {
			padding: rem-calc(35px 22px 25px);
			ol {
				li {
					text-align: center;
					img {
						float: none;
						margin: 0 auto;
					}
					strong, .step { display: block; }
				}
			}
		}
/*		@media #{$small-only} {
			ol {
				width: 100%;
				clear: both;
				li {
					float: none;
					clear: both;
					max-width: 100%;
					margin: 0 3% rem-calc(20px);
					img {
						display: inline-block;
						float: left;
						margin: 0 3% 7% 3%;
						max-width: 40%;
					}
					span {
						float: left;
						max-width: 50%;
					}
					&:nth-child(2) {
						img { min-height: rem-calc(80px); }
						span { max-width: 50%; }
					}
				}
			}
			.btn-stroked-orange {
				float: none;
				clear: both;
				width: 90%;
				margin: 0 auto;
			}
		}
		@media #{$medium-up} {
			ol li {
				text-align: center;
				width: 27%;//rem-calc(175px);
				margin: 0 4% rem-calc(20px);
				img {
					margin: 0 30%;
					height: rem-calc(80px);
				}
				span {
					clear: both;
					text-align: center;
					max-width: rem-calc(130px);
					margin-left: rem-calc(10px);
				}
				&:nth-child(2) { margin: 0 1.5%; }
			}
		}
		/*@media #{$large-up} {
			ol li {
				width: 30%;
				margin: 0 3% rem-calc(20px);
				img {
					display: inline-block;
					float: left;
					margin: 0 3% 7% 3%;
				}
				span {
					float: left;
					max-width: 45%;
				}
				&:nth-child(2) {
					margin: 0 2%;
					img { min-height: rem-calc(80px); }
				}
				&:first-child { margin-right: 0; }
				&:last-child { margin-left: 0; }
			}
		}*/
	}
}

//All Vendors Page
.byCategory,
.allVendors {
	.hero-banner {
		background-color: #0a2554;
		background-repeat: no-repeat;
		background-position: center top;
		@media #{$small-only} {
			background-image: url('../images/category-banners/allvendors_sm.jpg');
			.container { height: rem-calc(213px); }
		}
		@media #{$medium-only} {
			background-image: url('../images/category-banners/allvendors_md.jpg');
			.container { height: rem-calc(213px); }
		}
		@media #{$large-up} {
			background-image: url('../images/category-banners/allvendors_lg.jpg');
			.container { height: rem-calc(213px); }
		}
		@media #{$xlarge-up} {
			background-image: url('../images/category-banners/allvendors_xl.jpg');
			background-repeat: no-repeat;
			background-position: center top;
		}
		.container {
			.search-verified {
				color: #fff;
				float: left;
				@media #{$large-only}, #{$medium-only} { max-width: 70%; }
				span {
					font-size: rem-calc(18px);
					font-weight: 100;
					.linebreak { display: none; }
					&.hero-description {
						font-size: rem-calc(20px);
						font-weight: 300;
						max-width: 75%;
						@media #{$xsmall-only} { 
							font-size: rem-calc(16px);
							line-height: rem-calc(18px);
						}//END media:xsmall
					}
				}
				.input-group {
					margin-top: rem-calc(20px);
					max-width: rem-calc(477px);
				}
			}
			.btn-ghost,
			.btn-orange {
				float: right;
				margin-top: rem-calc(15px);
			}
			@media #{$large-up} {
				h2 {
					margin: rem-calc(35px 0 20px);
					line-height: rem-calc(50px);
				}
			}
			@media #{$medium-only} {
				h2 {
					margin: rem-calc(30px 0 15px);
					line-height: rem-calc(35px);
				}
				.search-verified {
					.instructions .linebreak { display: inherit; }
					.input-group { margin-top: 0; }
				}
			}
			@media #{$small-only} {
				h2 {
					margin: rem-calc(15px 0 0);
					line-height: rem-calc(27px);
				}
				.search-verified {
					max-width: 100%;
					.instructions {
						display: inline-block;
						margin: rem-calc(10 0 5);
						font-weight: 300;
						font-size: rem-calc(12px);
						line-height: rem-calc(16px);
					}
					.input-group { margin: 0; }
				}
				.btn-orange {
					margin-top: rem-calc(15px);
					padding: rem-calc(5px 10px 5px 5px);
					font-size: rem-calc(14px);
					svg {
						width: rem-calc(16px);
						height: rem-calc(16px);
					}
				}
				@media (min-width: 480px) and (max-width: 555px) {
					h2 { margin: rem-calc(5px 0 0); }
					.search-verified .instructions { margin: rem-calc(5 0); }
					.btn-orange { margin-top: rem-calc(5px); }
				}
			}
			@media #{$xsmall-only} {
				h2 {
					font-size: rem-calc(24px);
					line-height: rem-calc(24px);
					margin: rem-calc(15px 10px 10px 0);

				}
				.search-verified {
					line-height: 1.125rem;
					.input-group {
						margin-top: rem-calc(10px);
						width: 80%;
					}
				}
				.search-verified .instructions { margin: rem-calc(5 0 0); }
				.btn-orange { float: none; }
			}//END media:xsmall
		}
	}
	@media #{$medium-up} {
		&.productsView .hero-content .hero-description {
			max-width: 65%;
			float: left;
			clear: left;
			+ .btn-orange { margin-top: 0; }
		}
	}//END media:medium
	@media #{$medium-only}, #{$xsmall-only} {
		&.productsView .pagination-content .filter-container + .headline-border.first { clear: both; }
	}
	.category-ad {
		margin: 1rem auto 0 auto;
		@media #{$large-up} {
			width: 1145px;
			height: 94px;
		}//END media
		> a > img { max-width: 100%; }
	}
}
.allVendors {
	@media #{$xsmall-only} {
		.hero-description {
			font-size: 1rem;
			line-height: 1.125rem;
			margin-bottom: rem-calc(10px);
		}
		.input-group-btn {
			button {
				margin-bottom: 0px;
			}
		}
	}
}

//END .allVendors Hero Section

.category-page.byCategory {
//	.hero-banner { margin-bottom: rem-calc(40px); }
	.main-content {
		.vendor-cat {
			display: block;
			clear: both;
			margin: rem-calc(25px 0);
			strong.parent-cat-title {
				display: block;
				clear: both;
			//	margin-bottom: rem-calc(15px);

				color: $gray4;
				font-size: rem-calc(24px);
				line-height: rem-calc(24px);
				font-weight: 300;
			}
			.sub-cat {
				display: inline-block;
				width: 30%;
				margin: rem-calc(15 0);
				margin-right: 2%;
				strong.sub-cat-title {
					color: $main-blue;
					font-size: rem-calc(20px);
					font-weight: 300;
				}
				span {
					color: $gray4;
					display: block;
					font-size: rem-calc(14px);
					&.count-companies {}
					&.count-ratings {}
				}
				@media #{$medium-only}, #{$xsmall-only} {
					width: 46%;
				}
			}//END .sub-cat
		}
		.arrow-cta {
			clear: both;
			float: right;
			font-weight: 300;
		}//END .arrow-cta
	}
}

/*---------------------------------------------Vendor Ratings Hero Section-*/

/*-Main Content------------------------------------------------------------*/
.vendor-home.main-content {
	position: relative;
	.column-left, .column-right {
		position: relative;
		h3 {
			margin-top: rem-calc(20px);
			@media #{$small-only} { clear: both; }
		}
	}
	a.arrow-cta	.material-icons { color: $gray7; }
}//END .vendor-home.main-content
.allVendors .main-content {
	.column-left.byName { margin-top: rem-calc(30px); }
	.pagination-centered { margin-top: rem-calc(30px); }
}

// left-hand Section
h3 .verified-reviews {
	display: block;
	clear: both;
	font-size: 14px;
	color: $main-blue;
}
//right-hand section
.recentVRs {
	h3 .btn { float: right; }
	.review {
		display: block;
		clear: both;
		border-bottom: solid 1px $gray5;
		margin-top: 20px;
	}
	.pagination-centered {
		width: 100%;
		position: relative;
		margin-top: rem-calc(20px);
	}
	@media #{$medium-only} {
		.verified {
			display:none;
		}
	}
}

//Buyer's Guide
.buyers-guide {
	&.column-two-thirds {
		text-align: center;
		h3 { text-align: left; }
		img {
			width: rem-calc(109px);
			margin: rem-calc(0 18px 0 0);
			float: left;
		}
		p {
			float: left;
			max-width: 79%;
			text-align: left;
			font-size: rem-calc(16px);
			line-height: rem-calc(24px);
		}
		.get-guides {
			display: block;
			clear: both;
			margin: 0 auto;
			ul li { white-space: nowrap;text-align: right;}
			a.buyer-guide {
				white-space: nowrap;
				font-size: rem-calc(20px);
				font-weight: 400;
				line-height: rem-calc(30px);
				margin: rem-calc(0 20px);


			}
		}
		@media #{$medium-only} {
			img {
				float: left;
				display: inline-block;
				margin-right: rem-calc(7px);
			}
			p {
				float: none;
				max-width: 100%;
				font-size: rem-calc(14px);
			//	text-align: justify;
			}
			.get-guides a { display: block; }
		}
		@media #{$small-only} {
			img { margin: rem-calc(10px 10px 10px 0); }
			p { max-width: 73%; }
			.get-guides a.buyer-guide {
				margin: 0;
				&:first-child { margin: rem-calc(0 10px 0 0); }
			}
		}
		@media #{$xsmall-only} {
			img {
				float: none;
				max-width: 100%;
				margin: rem-calc(10px 0);
			}
			p {
				float: none;
				max-width: 100%;
			}
			.get-guides a { display: block; }
		}//END media-queries
	}//END .column-two-thirds
	&.column-one-third {
		img {
			max-width: rem-calc(85px);
			margin: rem-calc(0 18px 0 0);
			float: left;
		}
		strong {
			color: $main-blue;
			font-weight: 600;
			font-size: rem-calc(20px);
			line-height: rem-calc(22px);
		}
		p {
			font-size: rem-calc(16px);
			line-height: rem-calc(22px);
		}
		.get-guides {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				width: 75%;
				float: right;
				white-space: nowrap;
				margin: rem-calc(1 0);
				text-align:right;
				&:nth-child(odd) { margin-right: 0%; }
				a {
					font-weight: 300;
					font-size: rem-calc(16px);
					line-height: rem-calc(22px);
					.material-icons { color: $gray3; }

				}
			}
			@media #{$medium-only} {
				li {
					width: 47%;
					&:nth-child(odd) { margin-right: 6%; }
				}
			}
			@media #{$small-only} {
				display: block;
				flex: none;
				clear: both;
				li { float: left; }
			}//END media:small
			@media #{$xsmall-only} {
				li {
					float: none;
					width: 100%;
				}
			}//END media:xsmall
		}
		@media #{$small-only} {
			+ div {
				clear: both;
				padding-top: rem-calc(1px);
			}
		}
	}//END .column-one-third
}

/* ---- vendor-ratings/categories Page --- */
.vendor-categories {
	.categories-table.categories-list {
		display: table;
		border: 0px;
		margin-top: rem-calc(30px);
		.table-header {
			background-color: $main-blue;
			color: #fff;
			font-size: rem-calc(16px);
		}
		.row {
			display: table-row;
			.cell {
				padding: rem-calc(12px 15px);
				vertical-align: middle;
				&.label-title {
					text-align: left;
				}
				&.count {
					width: 20%;
					text-align: center;
					border-left: solid 0px $table-border;
					color: $gray7;
					font-weight: 400;
				}
				@media #{$xsmall-only}, #{$medium-only} {
					font-size: rem-calc(14px);
					padding: rem-calc(8px 10px);
				}//END media:xsmall,medium
			}
			.center{
				text-align: center;
			}
			.categories-title {
				font-size: rem-calc(18px);
				font-weight: 400;
			}
			.meta-icons {
				font-size: rem-calc(14px);
				font-weight: 500;
			}
			@media #{$xsmall-only}, #{$medium-only} {
				.categories-title {
					float: left;
					font-size: rem-calc(14px);
					font-weight: 400;
				}
				.meta-icons {
					font-weight: 400;
					a { font-weight: 400; }
					.views, .comments { display: block; }
				}
			}
		}
		@media #{$small-only} {
			.table-striped {
				width: 100%;
				border-bottom: none;
				&:last-child {
					border-bottom: solid 1px $table-border;
					border-top: none;
					.row {
						&:nth-of-type(odd) { background: $table-background; }
						&:nth-of-type(even) { background: #fff; }
					}
				}
			}
		}
	}
}//END .forum-boards
.load-more { margin-top: rem-calc(10px); }
/*------------------------------------------------------Forum Landing Page-*/

.recognize-excellence {
	.excellence.award { margin: rem-calc(20px) auto; }
	.award.description {
		display: block;
		text-align: left;
		font-style: italic;
		font-weight: 300;
	}
	.award + .btn-midi {
		float: right;
		margin-top: rem-calc(20px);
	}
	@media #{$small-only} {
		.award {
			display: inline-block;
			float: left;
			max-width: 49%;

			&.excellence { margin-right: 2%; }
			&.description {}
			+ .btn-midi {
				float: none;
				clear: both;
				width: 100%;
				margin: rem-calc(20px) auto;
			}
		}
	}
	@media #{$xsmall-only} {
		.award {
			display: block;
			clear: both;
			float: none;
			max-width: 100%;
			margin: rem-calc(20px) auto;
			&.excellence { width: 100%; }
			&.description {}
			+ .btn-midi { margin: 0; }
		}
	}
}
/*------------------------------------------------------------Main Content-*/

/*-Find Vendor By----------------------------------------------------------*/
//Find by Vendor Category
.vendor-category {
	margin-top: rem-calc(20);
	&:first-child { margin-top: 0; }

	.category-title {
		color: $gray4;
		font-weight: 300;
		font-size: rem-calc(18px);
	}
	a.vendor-subcat {
		display: block;
		font-weight: 300;
		font-size: rem-calc(16px);
		line-height: rem-calc(24px);
		.vendor-amount {}
	}
	+ .arrow-cta {
		clear: both;
		float: right;
	}
}

.column-container {
	@media #{$large-up}, #{$small-only} and (min-width: 615px) {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
		column-gap: 3%;
		column-width: 30%;
	}
	@media #{$medium-only}, (max-width: 614px) and (min-width: 480px) {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
		column-gap: 2%;
		column-width: 49%;
	}
	.vendor-category {
		display: inline-block;
		margin: 0 0 rem-calc(5px);
		width: 100%;
	}
}

//Find by Vendor Category
.byName {
	.arrow-cta {
		clear: both;
		float: right;
	}
	@media #{$large-up} {
		.search-vendors {
			max-width: 50%;
			float: right;
			margin-top: rem-calc(-45px);
		}
	}

	//Alphabetical Tabs
	.tabs.horizontal {
		clear: both;
		margin: 0;
		width: 100%;
		li.tab-title {
			position: relative;
			width: 3.75%;
			z-index: 1;
			a {
				font-family: monospace;
				position: relative;
				background-color: $form-disabled-bg;
				color: $main-blue;
				font-weight: 100;
				font-size: rem-calc(16px);
				padding: rem-calc(5px 0);
				text-align: center;
				max-height: rem-calc(35);
				min-height: 1rem;
				border-top: 5px solid transparent;

				// z-index: 1;
				&:hover { color: $blue-hover; }
			}
			&.active {
				overflow: hidden;
				background-color: transparent;
				height: rem-calc(35);
				padding: rem-calc(0 5);
				margin: rem-calc(0 -5);
				&:last-child { margin: rem-calc(0 -4 0 -6); }
				z-index: 2;
				a {
					border-top-color: $blue-default;
					background-color: #fff;
					color: $tertiary;
					font-weight: 500;
					padding: rem-calc(5px);
					// z-index: 2;
					&:hover { background-color: #fff; }
				}
			}
			@media #{$large-only} {
				&.active {
					padding: rem-calc(0 5 0 1);
					&:first-child { margin: rem-calc(0 -7px 0 -1px); }
					&:last-child { margin: remc-calc(0 -4px); }
				}
			}
			@media #{$small-only} {
				width: 3.73%;
				a {
					font-size: rem-calc(12px);
					font-weight: 300;
				}
				&.active {
					width: 4.75%;
					text-align: center;
					padding: rem-calc(0 5px);
					margin: rem-calc(0 -7px);
					a {
						padding: rem-calc(5px 2px);
						box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.36);
					}
					&:first-child {
						margin: rem-calc(0 -7px 0 -6px);
						a { padding: rem-calc(5px 7px); }
					}
					&:last-child {
						margin: rem-calc(0 -7px 0 -6px);
						a { padding: rem-calc(5px 7px); }
					}
					@media (max-width: 600px) {
						&:first-child a { padding: rem-calc(5px 3px); }
						&:last-child a { padding: rem-calc(5px 3px); }
					}//END media:600
				}
			}
		}
		+ .tabs-content {
			.content {
				padding: rem-calc(30px);
				&.active { margin-top: 0; }

				.vendor-subcat {
					font-weight: 300;
					font-size: rem-calc(16px);
					line-height: rem-calc(20px);
					margin: rem-calc(5 0);
					.vendor-amount {}
				//	&:before { content: '\2022 \2002'; }
				}//END .vendor-subcat
				ul {
					padding: 0;
					margin: 0;
					li {
						list-style: disc;
						margin-left: 10px;
						color: $main-blue;
						&:hover { color: $blue-hover; }
					}
					@media #{$large-up}, #{$small-only} and (min-width: 480px) {
						-moz-column-count: 2;
						-webkit-column-count: 2;
						column-count: 2;
						column-gap: 2%;
						column-width: 49%;
					}
					@media #{$xlarge-up} {
						-moz-column-count: 3;
						-webkit-column-count: 3;
						column-count: 3;
						column-gap: 3%;
						column-width: 30%;
					}
				}//END ul
			}
		}//END .tabs-content
	}//END .tabs.horizontal
	@media #{$xsmall-only}, #{$medium-only} {
		height: 100%;
		.tabs.horizontal {
/*			display: none;
			+ .tabs-content { display: none; }
*/			//Let's make it vertical
			float: left;
			width: 10%;
			margin-right: 5%;
			height: 75%;
			li.tab-title {
				float: left;
				clear: left;
				width: 100%;
				height: 4%;
				padding: 0;
				margin: 0;
				a {
					padding: rem-calc(5px);
					margin: 0;
					width: 100%;
					height: 100%;
					line-height: rem-calc(17px);
					vertical-align: middle;
					border-top: none;
					border-left: 5px solid #fff;
					box-shadow: inset -5px 0px 5px -4px rgba(0,0,0,0.36);
				}
				&.active {
					width: 100%;
					padding: 0;
					margin: 0;
					a {
						line-height: rem-calc(35px);
						font-size: rem-calc(16px);
						font-weight: 500;
						color: $gray4;

						width: 100%;
						padding: 0;
						margin: 0;
						border-left-color: $main-blue;
					//	box-shadow: -2px 0px 5px 0px rgba(0,0,0,0.36);
					}
					&:first-child,
					&:last-child {
						padding: 0;
						margin: 0;
						a {
							padding: 0;
							margin: 0;
						}
					}
					&:first-child a {
					//	border-top: inset 1px rgba(0,0,0,0.36);
						-webkit-box-shadow: inset 0px 9px 5px -9px rgba(0,0,0,0.75);
						-moz-box-shadow: inset 0px 9px 5px -9px rgba(0,0,0,0.75);
						box-shadow: inset 0px 9px 5px -9px rgba(0,0,0,0.75);
					}
					&:last-child a {
					//	border-bottom: solid 1px rgba(0,0,0,0.36);
						-webkit-box-shadow: inset 0px -9px 5px -9px rgba(0,0,0,0.75);
						-moz-box-shadow: inset 0px -9px 5px -9px rgba(0,0,0,0.75);
						box-shadow: inset 0px -9px 5px -9px rgba(0,0,0,0.75);
					}
				}

			}
			+ .tabs-content {
				float: left;
				max-width: 85%;
				.active { padding: 0; }
			}
		}
	}//END media:xsmall,medium
	@media #{$xsmall-only} {
		.arrow-cta {
			display: block;
			clear: both;
			margin-top: rem-calc(25px);
		}
		+ div {
			clear: both;
			padding-top: 1px;
		}
	}
}//END .byName

.column-one-third.column-left {
	#panelCategory,
	#panelName {
		padding: 0;
	}
	.vendor-category {
		margin-top: 0;
		margin-bottom: rem-calc(5px);
		&:first-child { margin-top: rem-calc(15px); }
		&:last-child { margin-bottom: 0; }
	}

	#panelName.byName {
		ul.tabs.vertical {
			float: left;
			width: 10%;
			margin-bottom: 0;
			margin-left: -10px;

			li.tab-title {
				height: 4%;
				padding: 0;
				margin: 0;

				position: relative;
				z-index: 1;
				a {
					padding: rem-calc(5px);
					margin: 0;
					width: 100%;
					max-height: 100%;

					border-top: none;
					border-left: 5px solid #fff;
					box-shadow: inset -5px 0px 5px -4px rgba(0, 0, 0, 0.36);

					background-color: #f2f2f2;
					color: #376caf;
					font-family: monospace;
					font-weight: 100;
					font-size: rem-calc(16px);
					line-height: rem-calc(17px);
					text-align: center;
					vertical-align: middle;
					&:hover { background-color: #fff; }
				}
				&.active {
					padding: 0;
					margin: 0;

					overflow: hidden;
					background-color: transparent;
					height: rem-calc(35px);
					z-index: 2;
					a {
						color: #444444;
						background-color: #fff;
						border-left-color: #376caf;
						box-shadow: none;
						height: 100%;
						line-height: 2em;
					}
					&:first-child a { box-shadow: inset 0px 9px 5px -9px rgba(0, 0, 0, 0.75); }
				}
			}//END li.tab-title
			+ .tabs-content {
				max-width: 90%;
				margin-left: 10%;
				margin-bottom: 0;
			}
		}//END ul.tabs.vertical
		#byName { height: 100%; }//END #byName

		.content {
			ul li {
				list-style: disc;
				margin-left: 10px;
				color: #376caf;
			}
			&.active {
				height: 100%;
				padding: rem-calc(5px 0 0);
				a { font-weight: 300; }
			}//END &.active
		}

	}//END #panelName
}//END .column-one-third.column-left
.column-two-thirds.column-right {}//END .column-two-thirds.column-right
/*----------------------------------------------------------Find Vendor By-*/

/* --- Vendor Category Tab Selector --- */
#vendorTabs {
	a.btn {
		float: right;
		margin-top: 5px;
		@media #{$large-only} {
			display: none;
		}
	}
	ul {
		li.tab-title a {
			line-height: rem-calc(15px);
			.products {
				display: block;
				font-size: rem-calc(13px);
				font-weight: 300;
			}
		}
	}
	.tabs-content {
		margin-bottom: 0;
		.content {
			&.active {
				padding-top: 0;
				padding-bottom: 0;
			}
			.product-info {
				border-bottom: solid 1px $silver;
				clear: both;
				height: rem-calc(83px);
				padding: rem-calc(8px 0 10px);
				&:last-child { border-bottom: none; }
				.company-logo {
					float: left;
					margin-right: rem-calc(10px);
				}
				.company-name {
					color: $main-blue;
					font-size: rem-calc(16px);
					font-weight: 300;
					margin-top: rem-calc(10px);
					//need to truncate
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.product-rating-summary {
					color: $gray4;
					font-size: rem-calc(11px);
					margin-top: rem-calc(-5px);
				}
				@media #{$small-only} {
					height: rem-calc(63px);
					.company-logo {
						margin-right: rem-calc(5px);
						img {
							width: rem-calc(45px);
							height: rem-calc(45px);
						}
					}
					.company-name {
						font-size: rem-calc(14px);
						margin-top: rem-calc(3px);
						width: 73%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.product-rating-summary { font-size: rem-calc(10px); }
				}//END Media Query
			}
		}
	}// END .tabs-content

	.view-all {
		font-size: rem-calc(14px);
		@media #{$medium-up} {
			float: left;
			margin: rem-calc(-20px 0 20px);
		}
	}
}//END #vendorTabs
@media #{$large-up} { 
	.column-two-thirds.column-right #vendorTabs {
		.tabs.vertical + .tabs-content .content {
			// max-width: 50%;
			// float: left;
			&.active { padding-left: rem-calc(5px); }
			.product-info {
				float: left;
				clear: none;
				min-width: calc(50% - 5px);
				max-width: rem-calc(50%);
				margin-right: rem-calc(5px);
			}
		}//END .tabs-content
	}//END .column-one-third.column-left #vendorTabs
}//END media xarge

//Displaying the Star Ratings boxen
.star-ratings-sprite {
	background: url("../images/components/rate-star-sprite.png") repeat-x;
	font-size: 0;
	height: 25px;
	line-height: 0;
	overflow: hidden;
	text-indent: -999em;
	width: 125px;
	margin: 0 3px;
	.rating {
		background: url("../images/components/rate-star-sprite.png") repeat-x;
		background-position: 0 100%;
		float: left;
		height: 25px;
		display: block;
	}
	&.small {
		background-image: url("../images/components/rate-star-sprite_sm.png");
		height: 14px;
		width: 70px;
		.rating {
			background-image: url("../images/components/rate-star-sprite_sm.png");
			height: 14px;
		}
	}//END .small
	&.large {
		background-image: url("../images/components/rate-star-sprite_lg.png");
		height: 57px;
		width: 285px;
		.rating {
			background-image: url("../images/components/rate-star-sprite_lg.png");
			height: 57px;
		}
	}//END .large
	&.xlarge {
		background-image: url("../images/components/rate-star-sprite_xl.png");
		height: 70px;
		width: 350px;
		.rating {
			background-image: url("../images/components/rate-star-sprite_xl.png");
			height: 70px;
		}
		@media #{$xsmall-only} {
			background-image: url("../images/components/rate-star-sprite_lg.png");
			height: 57px;
			width: 285px;
			.rating {
				background-image: url("../images/components/rate-star-sprite_lg.png");
				height: 57px;
			}
		}
	}//END .xlarge
}//END .star-ratings-sprite

//Displaying the .product-info outside of the tabs
.product-ratings {
	.product-info {
		border-bottom: solid 1px $silver;
		clear: right;
		line-height: rem-calc(20px);
		padding: rem-calc(10px 0 12px 0);
		.department-name {
			color: $main-blue;
			font-size: rem-calc(16px);
			line-height: rem-calc(18px);
			font-weight: 300;
			margin-top: rem-calc(5px);
		}
		.product-rating-summary {
			color: $gray4;
			font-size: rem-calc(11px);
			margin: 0;
			.star-rating {
				float: left;
				margin: rem-calc(2px 0 0);
			}
			.star-ratings-sprite { margin-left: 0; }
			.recommended {
				.full { display: inherit; }
				.abbrev { display: none; }
			}
			@media #{$xsmall-only}, #{$large-only} {
				.recommended {
					.full { display: none; }
					.abbrev { display: inherit; }
				}
			}
		}//END .product-rating-summary
		.company-name {
			color: $gray4;
			font-size: rem-calc(12px);
			margin: 0;
			a {
				color: $main-blue;
				font-weight: 300;
			}
		}
	}
	&.vrCatProd .company-logo {
		float: left;
		margin: rem-calc(0px 10px 0 0);
	}//END .vrCatProd
}//END /product-ratings


/* --- Actual Reviews --- */
.review {
	.review-header {
		.vendor-reviewed, .vendor-reviewed a {
			font-size: rem-calc(21px);
			line-height: rem-calc(21px);
			font-weight: 400;
			color: $main-blue;
			padding-bottom: rem-calc(6px);
		}
		.company-avatar {
			float: left;
			padding: rem-calc(0 12px 6px 0);
		}
		.review-title {
			font-size: rem-calc(18px);
			line-height: rem-calc(20px);
			font-weight: 300;
			color: $gray4;
		}
		.reviewed-by {
			font-size: rem-calc(13.26px);
			font-weight: 300;
			font-style: italic;
			color: $gray4;
			.job-title {}
		}
		.time-reviewed {
			font-size: 12px;
			line-height: 22px;
			font-weight: 100;
			color: $gray3;
			float: right;
		}
		.review-subhead {
			display: block;
			clear: both;
			/*img.type-icon {
				float: left;
				width: rem-calc(51px);
				height: rem-calc(47px);
				margin-right: rem-calc(14px);
			}*/
			.star-rating {
				float: left;
				margin: rem-calc(2px 0 0);
			}
			.recommended {
				float: left;
				font-size: rem-calc(13px);
				line-height: rem-calc(30px);
				color: $gray4;
				.answer {
					color: $gray7;
					font-size: rem-calc(15px);
					font-weight: 700;
					.fa-thumbs-up { font-size: rem-calc(18px); }
				}
			}
		}//END .review-subhead
		img.verified-badge {
			clear: right;
			float: right;
			margin-bottom: rem-calc(15px);
			color: $green-btn;
		}
	}//END .review-head
	.review-content {
		clear: both;
		margin: rem-calc(18px 0);
		p {
			font-size: rem-calc(16px);
			line-height: rem-calc(24px);
			font-weight: 300;
			color: $gray4;
			margin: 0;
			strong { font-weight: 500; }
		}
	}//END .review-content
	.review-footer {
		clear: both;
		.who-likes {
			display: block;
			clear: both;
		}
		@media #{$large-up} {
			.who-likes {
				display: inline;
				clear: none;
			}
		}//END media:medium
	}//END .review-footer
}

/* --- Comments on Vendor Review --- */
.commentSection.onReviews {
	display:none;
	background-color: $lightbluetint;
	margin-top: 0;
	.pagination-controls { display: none; }
	.post-comment {
		margin-bottom: 0;
		padding: rem-calc(15px);
	}
	@media #{$large-up} {
		margin-left: rem-calc(48px);
		.post-comment {
			min-width: 100%;
			.commenter.profile {
				min-width: 100%;
				.avatar-status-widget {
					float: left;
					width: rem-calc(75px);
					height: rem-calc(75px);
					margin-right: rem-calc(10px);
				}//END .avatar-status-widget
			}
			.comment-content { max-width: 100%; clear: both; }
			.comment-footer { margin-left: 0%; }
		}
	}//END media:large
	.number-of-comments,
	.showMore {
		font-weight: 300;
		font-size: rem-calc(14px);
		padding: rem-calc(0 15px);
	}
	.number-of-comments { float: right; }
}//END .onReviews

/* --- Products At-A-Glance --- */
.premium-listing-header {
  background: $lightbluetint;
  color: $tertiary;
  font-weight: 500;
  font-size: rem-calc(14px);
  padding: rem-calc(6 10);
  padding-bottom: 0;
  margin-top: rem-calc(12);
  text-transform: uppercase;
}

.product-summary {
	position: relative;
	width: 100%;
	border-bottom: solid 1px $grey6;
	padding-bottom: rem-calc(6px);
	padding: rem-calc(28px 0 20px);
	.company-avatar.profile {
		display: inline-block;
		vertical-align: top;
		height: 100%;
		width: 25%;
		min-width: rem-calc(117px);
		margin-right: rem-calc(10px);
		font-size: rem-calc(10px);
		position: relative;
		z-index: 2;
		.company-featured-info {
			margin-top: rem-calc(10px);
			font-size: rem-calc(12px);
			line-height: rem-calc(24px);
			font-weight: 400;
			.label-info{ font-weight: 300; }
			a {
				font-weight: 300;
				.material-icons {
					font-size: rem-calc(18px);
					color: $gray9;
				}
			}
		}
	}
	.summary-content {
		display: inline-block;
		width: 100%;
		max-width: calc(90%  - 127px);
		height: 100%;
		font-weight: 300;
		margin-top: rem-calc(10px);
		position: relative;
		z-index: 1;
		.product-summary-header {
			width: 100%;
			.product-name {
				font-size: rem-calc(24px);
				font-weight: 500;
			}
			.by-line {
				display: block;
				font-size: rem-calc(16px);
				margin-bottom: rem-calc(14px);
			}
			.company-averages {
				font-size: rem-calc(14px);
				line-height: rem-calc(20px);
				.star-ratings-sprite { margin: rem-calc(0 0 5px 0); }
				.ratings-number,
				.recommended,
				.dealerships {
					display: inline-block;
					@media #{$large-up} { width: 30%; }
					strong { font-weight: 500; }
				}
				.recommended {
					.full { display: inherit; }
					.abbrev { display: none; }
				}
			}
			.category-name a { font-weight: 300; }
		}
		.product-content {
			width: 100%;
			margin: rem-calc(10px) 0;

			font-size: 14px;
			line-height: rem-calc(24px);
			height: rem-calc(72px); //show 3 lines
			p { font-size: 14px; }

			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			@media #{$medium-only}, #{$xsmall-only} {
				max-width: 85%;
			}//END media
		}
	}
	.product-footer {
		clear: both;
		.interact-icons { text-align: right; }
	}

	&.premium-listing {
		border: solid $lightbluetint;
		border-width: rem-calc(0 10 12 10);
		background: #fff;
		padding: rem-calc(24 10);
	}

	@media #{$medium-only}, #{$xsmall-only} {
		.company-avatar.profile {
			float: left;
			margin: rem-calc(0 5px 10px 0);
		}
		.summary-content {
			display: block;
			margin-top: 0;
			max-width: 100%;
			.product-summary-header {
				float: left;
				display: inline-block;
				max-width: calc(100% - 136px);
				.product-name { font-size: rem-calc(16px); }
				.company-averages {
					margin-left: 0;
					font-size: rem-calc(14px);
					line-height: rem-calc(20px);
					.star-ratings-sprite { margin-bottom: rem-calc(5px); }
					.recommended {
						.full { display: none; }
						.abbrev { display: inherit; }
					}
					.ratings-number .verified { display: none; }
				}
			}
			.product-content {
				width: 100%;
				clear: both;
				margin: rem-calc(10px 0);
				font-size: rem-calc(12px);
				line-height: rem-calc(16px);
				font-size: 12px;
				line-height: 1.5em;
				height: 4.5em; //show 3 lines
			}
		}
		.product-footer { clear: both; }
	}//END media:medium,xsmall
	@media #{$xsmall-only} {
		.product-footer .btn { padding: rem-calc(5px 15px); }
		.summary-content .product-summary-header { max-width: calc(100% - 146px); }
	}//END media:xsmall
}

/* --- Products Media Tab --- */
.media-header {
	.not-provided div {
		width: 43%;
		margin: 20px 4% 20px 0; 
		display: inline-block;
	}
	.not-provided div:nth-child(2) {
		margin-right: 0;
	}
	.not-provided div:nth-child(3) {
		display: none;
	}
	a.left:nth-child(even) { clear: left; }
	@media #{$large-up} {
		.not-provided div {
			width: 30%;
			margin-right: 4%;
		}
		.not-provided div:nth-child(2) {
			margin-right: 4%;
		}
		.not-provided div:nth-child(3) {
			display: inline-block;
			margin-right: 0;
		}
	}
	.media-img {
		height: 160px;
		overflow: hidden;
	}
}


/* --- Product Date Filters --- */
.pagination-container{ position:relative; }
.filter-container {
	float: right;
	font-weight:bold;
	color:$main-blue;
}
.date-filters{
	input {
		border:none;
		background:transparent;
		color:$main-blue;
		cursor:pointer;
		width:rem-calc(72px);
		font-size:rem-calc(12px);
		font-weight:bold;
		outline:none;
	}
	input:hover {
		text-decoration:underline;
	}
	input.date_start {
		text-align:right;
	}
	.date-range-datepicker-load {
		font-size:rem-calc(18px);
		cursor:pointer;
	}
	.date-range-datepicker-load:hover {
		color: $green-btn;
	}
}
/*----------------------------------------------------------Vendor Ratings-*/
