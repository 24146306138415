/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * ads.scss
 * =========================================================================
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*--- Ad Containers ---*/
.responsive-ad-container {
	position: relative;
	height: 0;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	//	#google_image_div > a > .img_ad { max-width: 100%!important; }
	}
}

.side-column-ad {
	margin-top: 30px;
	text-align: center;
}


/**
 * Ratios
 * Returns the ratio for specified dimensions.
 */
@mixin generateRatios($width, $height) {
    padding-bottom: percentage($height / $width);
}


/*--- Home Page Takeover Ad ---*/
.takeover_1145x94 {
	@include generateRatios(1145,94);
//	padding-bottom: 8%;
	&.takeover-monday {}
	&.takeover-thursday {}
	@media #{$medium-only}, #{$small-only} {
		display: none;
	}
}

/*---House 300x150---*/
.house_150x300 {
	@include generateRatios(150,300);
	@media #{$medium-only}, #{$small-only} {
		display: none;
	}
}

/*---Exclusive 300x250---*/
.exclusive_300x250 {
	@include generateRatios(300,250);
}

/*---ROS 300x250---*/
.ros_250x300 {
	@include generateRatios(300,250);
}

/*---ROS 300x100---*/
.ros_100x300 {
	@include generateRatios(300,100);
	&.one {}
	&.two {}
	&.three {}
}

/*-Takeover Ads------------------------------------------------------------*/
.takeover-bg {
	margin-top: 0;
	background-repeat: repeat-x;
	background-position: center top;
	@media #{$small-only} { background: #fff; }
	@media #{$xlarge-up} {
		.hero-content { padding: rem-calc(36px); }
		.main-content { padding: rem-calc(0 36px); }
	}
	@media #{$large-only} {
		.hero-content { padding: rem-calc(23px); }
		.main-content { padding: rem-calc(0 23px); }
	}
	.takeover-ad {
		padding: rem-calc(20px 0);
		+ .container {
			background-color: #fff;
			&.department-page { padding-top: rem-calc(15px); }
		}
	}

	.profile-blog,
	.profile-container,
	.profile-nav,
	.main-content {
		background-color: #fff;
		padding: rem-calc(0 15px);
		@media #{$large-only} {
			padding: rem-calc(0 23px);
			&.home-nav { padding: 0; }
		}
		@media #{$xlarge-up} {
			padding: rem-calc(0 36px);
			&.home-nav { padding: 0; }
		}

	}
	.profile-blog {
		padding-top: rem-calc(15px);
		@media #{$large-only} { padding-top: rem-calc(23px); }
		@media #{$xlarge-up} { padding-top: rem-calc(36px); }
	}

	@media #{$large-up} {
		.hero-banner.fluid-container {
			max-width: rem-calc(970px);
			padding: rem-calc(0 15px);
			margin: 0 auto;
			.container { padding: 0; }
			.btn-lg.btn-blue { right: rem-calc(30); }
		}
		.home.hero-content .sidekick-ads { 
			margin: rem-calc(0 0 0 6px);
			img { border: 1px solid $grey6; }
		}
	}// END media:large
	@media #{$xlarge-up} {
		.hero-banner.fluid-container { max-width: rem-calc(1145px); }
	}// END media:xlarge
}
.forum-dep .takeover-bg .main-content { margin-left: rem-calc(-15px); }

//hiding ads on mobile
@media #{$medium-only}, #{$small-only} {
	.takeover-bg {
		background: none !important;
		.takeover-ad { display: none; }
	}
}

/*------------------------------------------------------------Takeover Ads-*/
