/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * colors.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Variables
 * - Form Elements
 * - Buttons
 * + Interpolations for Mixins
 * 
 * @TODO: Update TOC as necessary.
 *      - Consolodate "grays"
 * !! Name Your SASS Variables Modularly !!
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Variables---------------------------------------------------------------*/

/* --- Primary Color Palette --- */
$main-blue: #376caf; //Primary Blue
$dark-blue: #1e365d; //Secondary Blue
$uni-red: #ff5018; //University Red
$vendor-orange: #cc6500; //Vendor Orange
$data-yellow: #fddd1c; //Benchmarking
$news-green: #0a9e02; //News Green

/* --- Secondary Color Palette - Tints --- */
$swiss-coffee: #e2dddb; //Gray Tint
$greentint: #c8d7ba; //Green Tint
$lightbluetint: #d5e0e9; //Light Blue Tint
$darkbluetint: #b3c0d5; //Dark Blue Tint

/* --- Monochromatic Tints --- */
$alabaster: #F8F8F8;
$silver: #CCCCCC;
$tertiary: #636468;
$mine-shaft: #444444;


//Name Your SASS Variables Modularly
$gray1: #263238;
$gray2: #b6b6b6;
$gray3: $tertiary;//#808080;
$gray4: #444444;
$gray5: #cccccc;
$grey6: #e6e6e6;
$gray6b: #6b6563;
$gray7: #7e7877;
$gray80: $gray3;
$gray9: #999999;
$gray96: #969696;

$lt-blue-bg: #cbd9ea;
$blue-text: #135489;
$notice-red: #bf3837;
$error-red: #c93e27;
$success-green: #48a54c;
$warning-yellow: #f3bf50;

$table-border: #89a9d0;
$table-background: #f1f4f8;
$comment-border: #cbcbcb;
$comment-icons: #909090;
$footer-blue: $dark-blue;//#1f324e;
$login-green: $success-green;//#4a8244;
$green-badge: #159c04;
$red-badge: #d11d00;

/* --- Buttons --- */
$disabled: #cccccc; //#c8c8c8;
$disabled-border: $disabled;//#cfd8dc;

/*--- Button Colors ---*/
$blue-default: $main-blue;//#376caf;
$blue-hover: #5da5ff;
$blue-active: #274360;
$blue-bg-disabled: #e9eff7;
$blue-text-disabled: #9fb9d9;

$red-btn: $uni-red;//#c93e27;
$red-hover: #ff7d68;
$red-active: #a4321f;
$red-bg-disabled: #f8e9e6;
$red-text-disabled: #f0b7bc;

$green-btn: $news-green;//#48a54c;
$green-hover: #65cf6a;
$green-active: #2e7731;
$green-bg-disabled: #eaf6eb;
$green-text-disabled: #74b776;

$orange-btn: $vendor-orange;//#b16029;
$orange-hover: #fb9149;
$orange-active: #8e4412;
$orange-bg-disabled: #f9f0e9;
$orange-text-disabled: #dfaf8f;

$yellow-btn: $data-yellow;//#f3bf50;
$yellow-hover: #f7d283;
$yellow-active: #d89d1f;
$yellow-bg-disabled: #faf3e5;
$yellow-text-disabled: #e3ca94;

/* --- Form Elements --- */
$form-disabled-bg: #f2f2f2;
$form-border: #acacac;
$form-border-warning: $yellow-btn; //#f3bf50;
$form-border-error: $error-red; //#c93e27;
$form-border-success: $green-btn; //#66bb6a;
$form-placeholder-icon: $silver; //#cccccc;
$form-readonly: #444444;
$form-placeholder: #9b9b9b;
$switch-off: #b6b6b6;

/*--- Alert Colors ---*/
$alert-blue: $blue-default;
$alert-info: $blue-default; //#376caf;
$alert-info-bg: $blue-bg-disabled; //#e9eff7;

$alert-red: $error-red;
$alert-error: $error-red; //#c93e27;
$alert-error-bg: $red-bg-disabled; //#f8e9e6;

$alert-green: $success-green;
$alert-success: $success-green; //#48a54c;
$alert-success-bg: $green-bg-disabled; //#eaf6eb;

$alert-yellow: $warning-yellow;
$alert-warning: $warning-yellow; //#f3bf50;
$alert-warning-bg: $yellow-bg-disabled; //#fcf6e8;

/*---------------------------------------------------------------Variables-*/
