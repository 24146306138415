/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * layout.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Layout
 * - Grid
 * - Header
 * - Footer
 * - Forms
 * + Panels
 * + Department Pages
 *
 *
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Layout------------------------------------------------------------------*/
//This is a "macro" directory (defining the global wireframe). It contains a number of files for the main sections of the layout
@import "grid.scss";		// Grid system

//navs
@import "header.scss";		// Header
@import "footer.scss";		// Footer
// @import "sidebar.scss";	// Sidebar

//components
@import "forms.scss";		// Forms
@import "categories.scss";	// Category Pages
@import "search.scss";		// Search Page

@import "ads.scss";

/*--- Panels ---*/
.panel {
	width: rem-calc(300px);
	height: rem-calc(220px);
	padding: rem-calc(30px 35px);
	background: #ffffff;
	border-radius: rem-calc(5px);
	box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.36);
	-webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.36);
	-moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.36);
	h5 {
		color: $blue-default;
		i { color: $comment-icons; }
	}
	&.has-img {
		padding: rem-calc(0 0 30px);
		img {
			width: rem-calc(300px);
			height: rem-calc(83px);
			border-top-left-radius: rem-calc(4px);
			border-top-right-radius: rem-calc(4px);
		//	margin: rem-calc(-30px -35px 0);
		}
		h5, p { padding: rem-calc(0 30px); }
	}
}
.clear { clear: both; }
.loginpage .content.loginModal {
	width: 100%;
	max-width: rem-calc(500px);
	margin: auto;
	.close-reveal-modal { display: none; }
}

.hero-banner {
	color: #fff;
/*	@media #{$small-only} { min-height: rem-calc(213px); }
	@media #{$medium-only} { min-height: rem-calc(213px); }
	@media #{$large-up} { min-height: rem-calc(213px); }
*/	min-height: rem-calc(213px);
	.hero-content,
	.hero-description,
	h2, h4, p { color: #fff; }

	.hero-description {
		font-weight: 300;
		@media #{$medium-up} {
			font-size: rem-calc(20px);
			line-height: rem-calc(20px);
		}
		@media #{$small-only} {
			font-size: rem-calc(16px);
			line-height: rem-calc(18px);
		}
	}
	&.solution {
		background-position: center top;
		background-repeat: no-repeat;
		background-color: #8ca3c3;
		@media #{$medium-up} {
			.btn-orange {
				float: right;
				margin-top: rem-calc(35px);
			}
		}//END media:medium
	}
}
.nomargintop { margin-top: 0; }
.nomarginbottom { margin-bottom: 0; }
/*------------------------------------------------------------------Layout-*/

/*-Department Pages--------------------------------------------------------*/
//Pages that use department.blade template
.departmentpage {
	/*--- Cover Photo ---*/
	.cover-photo {
		position: relative;
		height: rem-calc(200px);
		background-color: $footer-blue;
		background-repeat: no-repeat;
		background-position: center top;
		color: #fff;
		margin-bottom: rem-calc(20px);
		@media #{$small-only} { margin: 0 -15px 20px; }
		.hero-content {
			position: relative;
			width: 100%;
			height: 100%;
			padding: rem-calc(10px 15px 0px);
			.btns-connect {
				float: right;
			}
		} //END .hero-content
	}

	h3.headline-border {
		.verified-reviews {
			display: inline;
			font-size: 14px;
			color: $main-blue;
			.verified-number {}
		}
		.btn { float: right; }

		@media #{$large-only} {
			.verified-reviews .verified { display: none; }
		}//END media:large
		@media #{$medium-only}, #{$xsmall-only} {
			.mobile-icon-btn { display: none; }
			.verified-reviews .verified { display: none; }
		}
	}//END h3

	.rate-pod {
		display: block;
		width: 100%;
		margin: rem-calc(5px) auto 0;
		text-align: center;
	}
} //END .departmentpage

/*--------------------------------------------------------Department Pages-*/
