body {
  background-color: #f5f6f8 !important;

  font-size: 14px;

  line-height: 22px;
}
.row {
  margin: 0 auto;
  max-width: 70.5rem !important;
  width: 100%;
}

ul {
  padding-left: 0px;
}

li {
  list-style: none !important;
}

a {
  text-decoration: none !important;
}

header.top_bar nav {
  border-radius: 0;

  border: none;

  margin-bottom: 0;
}

nav {
  margin-bottom: 0;
}

.navbar {
  margin-bottom: 0px !important;
}

a.navbar-brand.logo img {
  position: relative;

  bottom: 5px;
}

ul.nav.navbar-nav.menus a {
  font-size: 19px;

  color: #636468;

  padding: 25px;
}

ul.nav.navbar-nav.menus a:hover {
  color: #636468;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent !important;

  border-color: #337ab7;
}

ul.nav.navbar-nav.navbar-right.rightmenus a {
  font-size: 19px;

  color: #fff;
}

li.tops {
  margin-top: 10px;
}

.dropss a {
  font-size: 14px !important;
}

@media screen and (min-width: 768px) {
  .navbar-brand-centered {
    position: absolute;

    left: 50%;

    display: block;

    width: 160px;

    text-align: center;

    background-color: transparent;

    top: 15px;
  }

  .navbar > .container .navbar-brand-centered,
  .navbar > .container-fluid .navbar-brand-centered {
    margin-left: -80px;
  }

  .top-nav .secondary-nav {
    display: block !important;
  }
}

li.tops.fons a {
  font-size: 18px;

  color: #fff !important;
}

.label-primary {
  background-color: #ff0000 !important;
}

section.mainmenu {
  background: #636468;
}

section.companysec {
  padding-top: 40px;

  padding-bottom: 40px;
}

.innnercom label {
  font-weight: 100;

  color: #636468;

  font-size: 16px;

  margin-bottom: 10px;
}

.innnercom select {
  border-radius: 0;

  border: 1px solid #dcdcdc;

  background: #fff;

  height: 40px;

  color: #000;
}

.tamps {
  margin-top: 10px;
}

.tamps a {
  font-size: 18px;

  text-decoration: none !important;
}

.innnercom2 {
  display: inline-block;

  width: 100%;

  background: #fff;

  border: 1px solid #ccc;

  margin-bottom: 20px;

  padding-bottom: 20px;
}

.innnercom {
  display: inline-block;

  width: 100%;

  padding-bottom: 20px;

  margin-bottom: 20px;
}

h4.lavs {
  text-align: center;

  padding-top: 15px;

  padding-bottom: 20px;

  border-bottom: 1px solid #ccc;

  font-size: 15px;

  font-weight: 600;
}

span.label.label-primary.meds {
  border-radius: 100%;
  font-size: 8px;
  position: absolute;
  top: 0px;
  right: 5px;
  height: 14px;
  width: 14px;
  padding: 3px;
}

form.navbar-form.navbar-right.foms input {
  width: 220px;

  height: 25px;

  color: #fff;

  background: #7d7e84 !important;

  border: none;
}

i.fa.fa-search.seds {
  position: absolute;

  top: 22px;

  right: 22px;

  color: #c1c2c4;
}

form.navbar-form.navbar-right.foms {
  margin-top: 17px;
}

.bannertexts h1 {
  text-align: center;
  color: #fff;
  margin-top: 0;
  font-size: 43px;
  margin-bottom: 0px;
  font-weight: 300;
}

.bannertexts h2 {
  text-align: center;
  color: #fff;
  margin-top: 0;
  font-size: 27px;
  margin-bottom: 0px;
}

.bannertexts p {
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 0;
}

.box_innnenr {
  text-align: center;
  padding: 20px 15px;
  color: #fff;
  min-height: 193px;
}
.box_innnenr .icons i {
  font-size: 40px;
}

.box_innnenr p {
  font-size: 13px;
  margin-bottom: 10px;
  min-height: 64px;
}
.readmoress a {
  padding: 6px 9px;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 10px;
}

.readmoress {
  margin-top: 5px;

  display: inline-block;
}

.icons i {
  font-size: 24px;
}

.cusm_rowe {
  display: inline-block;

  width: 100%;
}
.cusm_rowe .div-click:hover
{
  cursor:pointer;
}

.register-button {
  width: 156px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 50px;
}

.register-button button {
  background: none;
  border: 1px solid white;
  border-radius: 25px;
  padding: 10px 46px;
  font-weight: 500;
}
.register-button .search-category {
  background: none;
  border: 1px solid white;
  border-radius: 25px;
  padding: 10px 46px;
  font-weight: 400;
  color: #FFFFFF;
}

.register-button .btn-blue {
  background: white;
  color: #376caf;
  border: 1px solid white;
  border-radius: 25px;
  padding: 10px 46px;
  font-weight: 400;
}

.register-button img {
  position: absolute;
  top: 0;
  left: 110%;
}
.register-button {
  button:hover,
  button:focus,
  button:active {
    background-color: unset !important;
  }
}

.tecys h3 {
  font-size: 18px;
  color: #fff;
}
.tecys p, .tecys a, .tecys a:hover, .tecys a:active, tecys a:visited {
  font-size: 14px;
  color: #fff;
}
header.top_bar {
  background: #376cb0;
}

.menushd li {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 18px !important;
  color: #fff;
}
.menushd li a {
  color: #fff;
  font-size: 15px;
}
.content li {
  display: inline-block;
}
.contain-to-grid.custym {
  background: #636468;
}
/*.mega {
    top: 97px !important;
}*/
.mega {
  margin-top: 13px !important;
  border: none !important;
}
.content ul {
  margin: 0;
}
header.top_bar li a {
  font-size: 14px !important;
}
.button-wrapss {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 8px;
  color: #c0c1c3;
  font-size: 12px;
}
.innerds input {
  margin: 0 !important;
  background: #7d7e84 !important;
  border: none !important;
  height: 22px !important;
  color: #fff !important;
}
.innerds {
  margin-top: 9px;
}
.logo_hide {
  display: none;
}
.pass {
  padding: 0 !important;
}
.dd {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .logo_hide {
    display: block;
  }
  .innerds {
    position: absolute;
    width: 100%;
    top: 70px;
  }
  .logos {
    display: none;
  }
  .small-6.columns.cnte {
    text-align: center;
  }
  .pass {
    padding: 15px !important;
  }
  span.label.label-primary.meds {
    right: 45px;
    top: -6px;
  }
  .top-bar-section {
    display: inline-block;
    width: 100% !important;
    background: #fff;
    margin-top: 15px;
  }
  header.top_bar li a {
    font-size: 12px !important;
    margin-left: 10px;
  }
  .bannertexts h1 {
    text-align: center;
    color: #fff;
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .bannertexts h2 {
    text-align: center;
    color: #fff;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .box_innnenr {
    text-align: center;
    padding: 15px 10px;
    color: #fff;
    min-height: 325px;
    margin-bottom: 10px;
  }
  .box_innnenr p {
    min-height: auto;
  }
  .button-group > li {
    display: inline-block;
    margin: 0 14px !important;
  }
  .ssd {
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 5px !important;
  }
  .innerds input {
    margin: 0 !important;
    background: #7d7e84 !important;
    border: none !important;
    height: 34px !important;
    color: #fff !important;
  }
  .button-wrapss {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 8px;
    color: #c0c1c3;
    font-size: 12px;
  }
  .button-group.even-1 {
    text-align: center;
  }
  .top_bar .container {
    padding: 0px;
  }
  .top-bar .toggle-topbar.menu-icon {
    margin-top: 3px;
    top: 50%;
  }
  .top-bar-section li:not(.has-form) a:not(.button) {
    background: transparent !important;
    line-height: 0.8125rem !important;
    padding: 0 0.9375rem;
  }
  li.small-6.columns.cnte.dd {
    margin-top: 15px;
  }
  .top-bar {
    background: #333333;
    height: 2.8125rem;
    line-height: 0.8125rem !important;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
  }
  .top-bar .toggle-topbar {
    display: block !important;
  }
  ul.title-area {
    width: 100%;
  }
}

.top-bar-section ul li {
  background: transparent !important;
}
.top-bar-section li:not(.has-form) a:not(.button) {
  background: transparent !important;
  line-height: 2.8125rem;
  padding: 0 0.9375rem;
}
.content.ds-home {
  margin-top: 0px;
}
// This file is style.css

// Add your CSS here
#noticemsg .close-reveal-modal {
  color: #aaa;
  cursor: pointer;
  font-size: 2.5rem !important;
  font-weight: 100;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0.1rem !important;
}
.top-bar-section li:not(.has-form) a:not(.button) {
  background: transparent !important;
  line-height: 1.8125rem !important;
  padding: 0 0.9375rem;
}

.top-bar .toggle-topbar.menu-icon {
  margin-top: 0px !important;
}

//@media screen and (min-width:768px)
.top-nav-search{
  opacity: .7;
}
.its-free{
  width:25%;
  margin-left:-550px;
}
@media (max-width: 767px) {
  .top-nav .secondary-nav {
    display: block !important;
  }
}

@media (max-width: 1366px) {
  .register-button{
    display: none;
  }
}
