/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * avatars.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Profile Avatars
 * 
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Profile Avatars---------------------------------------------------------*/
.img-circle { border-radius: 50%; }
img.avatar, .avatar {
	//default size
	width: rem-calc(40px);
	height: rem-calc(40px);

	&.xsmall {
		width: rem-calc(30px);
		height: rem-calc(30px);
		/*@media #{$xsmall-only} {
			max-width: rem-calc(18px);
			max-height: rem-calc(18px);
		}*/
	}
	&.small {
		width: rem-calc(37px);
		height: rem-calc(37px);
	}
	&.medium {
		width: rem-calc(65px);
		height: rem-calc(65px);
	}
	&.large {
		width: rem-calc(93px);
		height: rem-calc(93px);
	}
	&.wiki {}
	&.silver {
		width: rem-calc(40px + 12);
		height: rem-calc(40px + 12);
		&.xsmall {
			width: rem-calc(30px + 12);
			height: rem-calc(30px + 12);
		}
		&.small {
			width: rem-calc(37px + 12);
			height: rem-calc(37px + 12);
		}
		&.medium {
			width: rem-calc(65px + 12);
			height: rem-calc(65px + 12);
		}
		&.large {
			width: rem-calc(93px + 12);
			height: rem-calc(93px + 12);
		}
	}
	&.gold {
		width: rem-calc(40px + 24);
		height: rem-calc(40px + 24);
		&.xsmall {
			width: rem-calc(30px + 24);
			height: rem-calc(30px + 24);
		}
		&.small {
			width: rem-calc(37px + 24);
			height: rem-calc(37px + 24);
		}
		&.medium {
			width: rem-calc(65px + 24);
			height: rem-calc(65px + 24);
		}
		&.large {
			width: rem-calc(93px + 24);
			height: rem-calc(93px + 24);
		}
	}
}

.company-logo,
.company-avatar {
	.avatar {
		display: block;
		border: solid 1px $grey6;
	}
}

/* --- Generic Avatars --- */
.avatar.generic {
	text-align: center;
	color: #fff;

	.icon {
		font-size: rem-calc(24px);
		line-height: rem-calc(40px);
	}
	&.xsmall {
		.icon {
			font-size: rem-calc(18px);
			line-height: rem-calc(30px);
		}
		/*@media #{$xsmall-only} {
			position: relative;
			.icon {
				font-size: rem-calc(12px);
				position: absolute;
				top: rem-calc(-6px);
				left: rem-calc(2px);
			}
		}*/
	}
	&.small .icon { line-height: 37px; }
	&.medium .icon {
		font-size: rem-calc(40px);
		line-height: rem-calc(65px);
	}
	&.large .icon {
		font-size: rem-calc(60px);
		line-height: rem-calc(93px);
	}

	&.red { background-color: $uni-red; }
	&.orange { background-color: $vendor-orange; }
	&.yellow {
		background-color: $data-yellow;
		color: $gray4;
	}
	&.green { background-color: $news-green; }
	&.blue { background-color: $main-blue; }
	&.gray { background-color: $tertiary; }

}

/*--- Custom Icon Avatar ---*/
.avatar {
	.combined-icons .material-icons {
		font-size: rem-calc(24px);
		line-height: rem-calc(20px);

		width: rem-calc(24px);
		height: rem-calc(24px);
		&.create { margin-left: rem-calc(-20px); }
	}
	&.xsmall {
		 .combined-icons .material-icons {
			font-size: rem-calc(16px);
			line-height: rem-calc(20px);

			width: rem-calc(16px);
			height: rem-calc(16px);
			&.create { margin-left: rem-calc(-10px); }
		}
	}
	&.small .combined-icons .material-icons {
		font-size: rem-calc(22px);
		line-height: rem-calc(20px);

		width: rem-calc(22px);
		height: rem-calc(22px);
		&.create { margin-left: rem-calc(-20px); }
	}
	&.medium .combined-icons .material-icons {
		font-size: rem-calc(40px);
		line-height: rem-calc(35px);

		width: rem-calc(40px);
		height: rem-calc(40px);
		&.create { margin-left: rem-calc(-35px); }
	}
	&.large .combined-icons .material-icons {
		font-size: rem-calc(60px);
		line-height: rem-calc(50px);

		width: rem-calc(60px);
		height: rem-calc(60px);
		&.create { margin-left: rem-calc(-55px); }
	}
}

/*---------------------------------------------------------Profile Avatars-*/

/*-Status Badges-----------------------------------------------------------*/
.avatar-status-widget {
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	img.avatar {
		width: auto;
		height: 100%;
		padding: 3px;

		@media #{$medium-up} { position: absolute; }
		z-index: 1;
	}

	&.xsmall {
		width: rem-calc(30px);
		height: rem-calc(30px);
		img.avatar { padding: 2px; }
	}
	&.small {
		width: rem-calc(37px);
		height: rem-calc(37px);
		img.avatar { padding: 3px; }
	}
	&.medium {
		width: rem-calc(65px);
		height: rem-calc(65px);
		img.avatar { padding: 4px; }
	}
	&.large {
		width: rem-calc(93px);
		height: rem-calc(93px);
		img.avatar { padding: 5px; }
	}

	.status-level {
		display: inline-block;
		width: 100%;
		height: 100%;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
	}
	&.contributor .status-level {
		background-image: url('../images/avatars/status-bronze.png');
	}
	&.senior-contributor .status-level {
		background-image: url('../images/avatars/status-silver.png');
	}
	&.master .status-level {
		background-image: url('../images/avatars/status-gold.png');
	}
	//User Connections
	&.user-avatar {
		display: block;
		margin: 0 auto;
		.status-level, img.avatar { display: block; }
	}
}
/*-----------------------------------------------------------Status Badges-*/
