/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * alerts.scss
 * =========================================================================
 * 
 * @TODO: Update TOC as necessary.
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Alerts------------------------------------------------------------------*/
.alert-box {
	font-size: 18px;
	font-weight: 300;
	.close { opacity: 1; }
	&.info {
		color: $alert-info;
		border-color: $alert-info;
		background-color: $alert-info-bg;
		.close { color: $alert-info; }
	}
	&.success {
		color: $alert-success;
		border-color: $alert-success;
		background-color: $alert-success-bg;
		.close { color: $alert-success; }
	}
	&.alert {
		color: $alert-error;
		border-color: $alert-error;
		background-color: $alert-error-bg;
		.close { color: $alert-error; }
	}
	&.warning {
		color: $alert-warning;
		border-color: $alert-warning;
		background-color: $alert-warning-bg;
		.close { color: $alert-warning; }
	}
}
.system-notificaions {
	margin: 0 auto;
	max-width: rem-calc(970px);
}

/*-Interpolations for Mixins-----------------------------------------------*/
// %alert-info {
// 	color: $alert-info;
// 	border-color: $alert-info;
// 	background-color: $alert-info-bg;
// }
// %alert-success {
// 	color: $alert-success;
// 	border-color: $alert-success;
// 	background-color: $alert-success-bg;
// }
// %alert-error {
// 	color: $alert-error;
// 	border-color: $alert-error;
// 	background-color: $alert-error-bg;
// }
// %alert-warning {
// 	color: $alert-warning;
// 	border-color: $alert-warning;
// 	background-color: $alert-warning-bg;
// }
/*-----------------------------------------------Interpolations for Mixins-*/

/*-Alerts-Mixins-----------------------------------------------------------*/
// @mixin alert-color(color) {
// 	@extend %#alert-{$color};
// 	color: $alert-#{color};
// 	border-color: $alert-#{color};
// 	background-color: $alert-#{color}-bg;
// 	.close { color: $alert-#{color}; }
// }
/*--- Button Color Mixins - Stroked ---*/
// .alert-box.info {
// 	@include alert-color("info");
// }
// .alert-box.success {
// 	@include alert-color("success");
// }
// .alert-box.alert {
// 	@include alert-color("error");
// }
// .alert-box.warning {
// 	@include alert-color("warning");
// }
/*-----------------------------------------------------------Alerts-Mixins-*/
