/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * layout.scss
 * =========================================================================
 *
 * Table of Contents
 * -----------------
 *
 * + Breakpoints
 * + Containers (Bootstrap)
 * + Category Columns
 *
 * @TODO: Update TOC as necessary.
 *
 * @BUG:
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/*-Breakpoints-------------------------------------------------------------*/
@media #{$medium-only} {
	.hide-on-medium { display: none; }
}
// Here we define the lower and upper bounds for each media size
@media #{$xsmall-only} { //(max:479)
	.hide-for-xsmall-only { display: none; }
}
@media #{$small-only} {} //(max:767)

@media #{$medium-up} {} //(min:768)
@media #{$medium-only} {} //(min:768) and (max:991)

@media #{$large-up} {} //(min:992)
@media #{$large-only} {} //(min:992) and (max:1199)

@media #{$xlarge-up} {} //(min:1200)
@media #{$xlarge-only} {} //(min:1200) and (max:1919)

@media #{$xxlarge-up} {} //(min:1920)

/*-------------------------------------------------------------Breakpoints-*/

/*-Bootstrap-Containers----------------------------------------------------*/
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	min-width: rem-calc(290px);
}
@media #{$medium-up} {
	.container { width: 750px; }
}
@media #{$large-up} {
	.container { width: 970px; }
}
@media #{$xlarge-up} {
	.container { width: 1145px; }
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	// margin-right: auto;
	// margin-left: auto;
}

.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
	margin-right: -15px;
	margin-left: -15px;
}
@media (min-width: 768px) {
	.container > .navbar-header,
	.container-fluid > .navbar-header,
	.container > .navbar-collapse,
	.container-fluid > .navbar-collapse {
		margin-right: 0;
		margin-left: 0;
	}
}
@media (min-width: 768px) {
	.navbar > .container .navbar-brand,
	.navbar > .container-fluid .navbar-brand {
		margin-left: -15px;
	}
}

@media screen and(min-width:768px) {
	.navbar-brand-centered {
		position: absolute;
		left: 50%;
		display: block;
		width: 160px;
		text-align: center;
		background-color: transparent;
		top: 15px;
	}
	.navbar > {
		.container .navbar-brand-centered {
			margin-left: -80px;
		}
		.container-fluid .navbar-brand-centered {
			margin-left: -80px;
		}
	}
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
	display: table;
	content: " ";
}
.container:after,
.container-fluid:after {
	clear: both;
}
/*--------------------------------------------------------------Containers-*/

/*-Columns-----------------------------------------------------------------*/
@media #{$medium-up} {
	.column-left {
		margin-right: 3.4%;
		float: left;
		clear: left;
	}
	.column-right {
		float: right;
		clear: right;
	}
//Halfsies
	.column-one-half { width: 48.3%; }

//Thirds
	.column-one-third { width: 32.2%; }
	.column-two-thirds { width: 64.4%; }
	@media #{$medium-only} {
		.column-two-thirds,
		.column-one-third { width: 48.3%; }
		.single-post, .user-blog {
			.column-one-third { width: 32.2%; }
			.column-two-thirds { width: 64.4%; }
		}
	}
//Three Column Layout
	.three-columns {
		.column-left { margin-right: 0; }
		.column-middle {
			float: left;
			margin: auto 1.7%;
			@media #{$medium-only} { margin:  0 0 0 3.4%; }
		}
	}
}
/*-----------------------------------------------------------------Columns-*/

/*-Masonry-----------------------------------------------------------------*/
.main-content .grid {
	position: relative;
	@media #{$small-only} {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;

		-webkit-flex-flow: column;
		-moz-flex-flow: column;
		-ms-flex-flow: column;
		flex-flow: column;
	}
}
@media #{$medium-up} {
	.grid {
		min-width: calc(100% - 72px); //72px = 36px left/right padding
	//	min-width: 750px;
		/* clearfix */
		.grid:after {
			content: '';
			display: block;
			clear: both;
		}
	//	@media #{$large-up} { min-width: 970px; }
	//	@media #{$xlarge-up} { min-width: 1145px; }
	}
	/* ---- grid-item ---- */
	.grid-sizer,
	.grid-item {
		width: 48.3%;
	}

	.gutter-sizer { width: 3.4%; }
	.grid-item {
		float: left;
		//Halfsies
		&.column-one-half { width: 48.3%; }
/*		//Thirds
		&.column-one-third {
			width: 32.2%;
			margin-left: 1.7%;
		}
		&.column-two-thirds {
			width: 64.4%;
			margin-right: 1.7%;
		}
		@media #{$medium-only} {
			&.column-two-thirds,
			&.column-one-third {
				width: 48.3%;
				max-width: rem-calc(347px);
			}
		}
		@media #{$large-up} {
			&.column-two-thirds,
			&.column-one-third {
				margin: 0;
			}
			&.column-two-thirds { max-width: rem-calc(590px); }
			&.column-one-third { max-width: rem-calc(320px); }
		}
		@media #{$xlarge-up} {
			&.column-two-thirds { max-width: rem-calc(718px); }
			&.column-one-third { max-width: rem-calc(359px); }
		}
*/
	}
}
@media #{$small-only} {
	.grid {
		min-width: 290px;
		/* clearfix */
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	/* ---- grid-item ---- */
	.grid-sizer,
	.grid-item {
		width: 100%;
	}
	.gutter-sizer { width: 0%; }
	.grid-item { float: none; }
}
/*-----------------------------------------------------------------Masonry-*/

/*-Clearing----------------------------------------------------------------*/
/* From Foundation:
	// We use this to do clear floats
	@mixin clearfix {
	  &:before, &:after { content: " "; display: table; }
	  &:after { clear: both; }
	}
	.clearfix { @include clearfix; }
*/
.clearfix { clear: both; }
/*----------------------------------------------------------------Clearing-*/
